import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { Container } from 'react-bootstrap';
import {
  Button, Col, Divider, Row, notification, Spin,
} from 'antd';
import * as R from 'ramda';
import Lightbox from 'react-image-lightbox';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { EditOutlined, MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import BottomTabNavigator from '../../components/BottomTabNavigator';
import TradeWisdomItem from './tradeWisdomItem';
import LoadingSkeleton from '../posts/LoadingSkeleton';
import { AsyncS3Upload } from '../../utils/s3Uploader';
import { axiosAuth } from '../../api';
import CreateEditModal from './createEditModal';
import {
  CenteredDiv,
  LeftSidebarProfile,
  LeftSidebarProfileHeader,
  MainBoxFull,
  PostsContainer,
  UserImg,
  UserName,
  UserPlan,
} from '../posts/styled';
import AuthorBioModal from '../posts/AuthorBioModal';
import MenuBar from '../posts/MenuBar';
import defaultUser from '../../assets/defaultUser.jpg';
import { capitalizeText } from '../../utils/helper';
import logo1 from '../../assets/logo1.svg';
import HamburgerMenuLinks from '../../components/HamburgerMenuLink';
import QuotesSidebar from '../../components/QuotesSidebar';

const TradeWisdom = () => {
  let loadingGlobal = false;
  const hasMore = useRef(true);
  const [allPosts, setAllPosts] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(null);
  const [activeKey, changeKey] = useState(1);
  const [lightboxImage, setLightBoxImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showEditCreateModal, setShowEditCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [createUpdateLoading, setCreateUpdateLoading] = useState(false);
  const [loadingMore, setloadingMore] = useState(false);
  const [isOpenBioModal, setOpenBioModal] = useState(false);
  const [isMenuOpen, toggleHamburger] = useState(false);
  const user = useSelector((state) => state.common.user);
  const history = useHistory();

  const getTradeList = () => {
    window.scrollTo(0, 0);

    setLoading(true);
    setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
    }, 1500);

    const endpoint = `/tradeWisdom/all?offset=${currentPage * 10}&limit=10`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllPosts(res?.data?.data);
          }
          if (res?.data?.data?.length < 10) {
            hasMore.current = false;
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to load posts. Please try again later!!',
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchMore = (currentPage = 0) => {
    if (!hasMore.current) {
      return;
    }
    const endpoint = `/tradeWisdom/all?offset=${currentPage * 10}&limit=10`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllPosts(res?.data?.data);
          }
          if (res?.data?.data?.length < 10) {
            hasMore.current = false;
          }
        }
        loadingGlobal = false;
        setloadingMore(false);
        setLoading(false);
      })
      .catch((err) => {
        setloadingMore(false);
        setLoading(false);
        loadingGlobal = false;
      });
  };

  useEffect(() => {
    getTradeList();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const finalDataNew = R.uniqBy(R.prop('_id'), [...finalData, ...allPosts]);
    setFinalData(finalDataNew);
  }, [allPosts]);

  const handleScroll = useCallback(
    (e) => {
      const windowHeight = 'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
      const { body } = document;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (
        windowBottom + 200 >= docHeight
        && !loadingMore
        && hasMore.current
        && !loadingGlobal
      ) {
        loadingGlobal = true;
        setloadingMore(true);
        fetchMore(currentPage + 1);
        setCurrentPage(currentPage + 1);
      }
    },
    [currentPage],
  );

  const handleDeletePost = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this record?')) {
      axiosAuth
        .delete(`tradeWisdom/delete/${id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Trade Wisdom deleted successfully!!',
            });
            const itemIndex = finalData.findIndex((item) => item._id === id);
            const updatedData = R.remove(itemIndex, 1, finalData);
            setFinalData(updatedData);
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete record. Please try again!!',
          });
        });
    }
  };

  const handleCreateUpdate = (data) => {
    const { title, filesList } = data;
    if (!title) {
      notification.error({
        message: 'Create Trade Wisdom Error',
        description: 'Please enter title!!',
      });
      return;
    }
    if (!filesList?.length) {
      notification.error({
        message: 'Create Trade Wisdom Error',
        description: 'Please upload at least one chart image!!',
      });
      return;
    }
    setCreateUpdateLoading(true);

    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq('alreadyUploaded', true)),
        R.map(R.prop('thumbUrl')),
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      let payload = {
        images: alreadyUploadedImages.concat(images),
        title,
        _id: showEditCreateModal._id,
      };

      if (isEdit) {
        payload = {
          ...payload,
          _id: showEditCreateModal._id,
        };
      }

      const apiProvider = !isEdit ? axiosAuth.post : axiosAuth.patch;
      apiProvider(isEdit ? 'tradeWisdom/update' : 'tradeWisdom/create', payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (isEdit) {
              notification.success({
                message: 'Success',
                description: 'Trade Wisdom updated successfully!!',
              });
              const updatedData = R.update(
                R.findIndex(R.propEq('_id', showEditCreateModal._id))(
                  finalData,
                ),
                res?.data?.data,
                finalData,
              );
              setFinalData(updatedData);
            } else {
              setFinalData(R.insert(0, res?.data?.data, finalData));
              notification.success({
                message: 'Success',
                description: 'Trade Wisdom created successfully!!',
              });
            }
          } else {
            notification.error({
              message: 'Error',
              description: 'Failed to post. Please try again later!!',
            });
          }
          setCreateUpdateLoading(false);
          setShowEditCreateModal(false);
          setIsEdit(false);
        })
        .catch(() => {
          setCreateUpdateLoading(false);
        });
    });
  };

  return (
    <PostsContainer isMobileOnly={isMobile} className="posts">
      <HamburgerMenuLinks
        isMenuOpen={isMenuOpen}
        toggleMenu={(value) => toggleHamburger(value)}
      />
      <AuthorBioModal
        isOpenBioModal={isOpenBioModal}
        resetData={(data) => setOpenBioModal(data)}
        alreadyAuthoreData={user?.investorType}
      />
      <Container>
        {isMobile && (
          <div className="menu-btn-heading-parent">
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: '25px',
                position: 'absolute',
                zIndex: 1000,
              }}
            />
            <div className="posts-heading text-center">
              <img
                aria-hidden
                onClick={() => history.push('/')}
                alt=""
                src={logo1}
                className="d-inline-block align-top brand-image"
              />
            </div>
          </div>
        )}
        {showEditCreateModal && (
          <CreateEditModal
            isEdit={isEdit}
            showEditCreateModal={showEditCreateModal}
            toggleEditModal={(data) => {
              setShowEditCreateModal(data);
              setIsEdit(false);
            }}
            handleCreateUpdate={handleCreateUpdate}
            createUpdateLoading={createUpdateLoading}
          />
        )}
        {user?.userType === 'admin' && (
          <>
            <Row justify="end">
              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 18 }}>
                <Button
                  className="save-post-button"
                  onClick={() => setShowEditCreateModal(true)}
                  style={{ float: 'right' }}
                >
                  Create Trade Wisdom
                </Button>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        {isLightBoxVisible && (
          <Lightbox
            mainSrc={lightboxImage[photoIndex]}
            nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length]}
            prevSrc={
              lightboxImage[
                (photoIndex + lightboxImage.length - 1) % lightboxImage.length
              ]
            }
            onCloseRequest={() => {
              toggleLightBox(false);
              setPhotoIndex(null);
            }}
            onMovePrevRequest={() => setPhotoIndex(
              (photoIndex + lightboxImage.length - 1) % lightboxImage.length,
            )}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxImage.length)}
          />
        )}
        <Row>
          <Col xs={0} sm={0} md={0} lg={5}>
            <div className={finalData?.length > 2 ? 'sticky-box' : ''}>
              <MainBoxFull>
                <LeftSidebarProfile>
                  <LeftSidebarProfileHeader />
                  <CenteredDiv>
                    <UserImg
                      className="cursor-pointer"
                      src={user?.profileImg || defaultUser}
                      onClick={() => history.push('/profile')}
                    />
                    <UserName
                      className="cursor-pointer"
                      onClick={() => history.push('/profile')}
                    >
                      {capitalizeText(user?.name ?? '')}
                    </UserName>
                    <UserPlan>
                      <Row justify="center">
                        <div className="mr-1">
                          {user?.investorType ?? 'Author bio'}
                        </div>
                        <EditOutlined
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpenBioModal(true);
                          }}
                        />
                      </Row>
                    </UserPlan>
                  </CenteredDiv>
                </LeftSidebarProfile>
                <LeftSidebarProfile>
                  <MenuBar history={history} />
                </LeftSidebarProfile>
              </MainBoxFull>
            </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />
          <Col xs={24} sm={24} md={24} lg={12}>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              finalData?.length ? finalData.map((item) => (
                <TradeWisdomItem
                  key={item._id}
                  data={item}
                  setLightBoxImage={setLightBoxImage}
                  toggleLightBox={toggleLightBox}
                  handleDeletePost={handleDeletePost}
                  setPhotoIndex={setPhotoIndex}
                />
              )) : <p className="mb-20 text-center mt-25"><strong>No Records Found</strong></p>
            )}
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />
          <Col xs={0} sm={0} md={0} lg={1}>
            {!isMobile && (
              <div>
                <QuotesSidebar isSticky={finalData?.length > 2} />
              </div>
            )}
          </Col>
        </Row>
        <Row className="pb-20" justify="center">
          {loadingMore ? (
            <Spin tip="Fetching more data..." />
          ) : !hasMore.current ? (
            finalData?.length ? (
              <p className="mb-20">That's all folks</p>
            ) : (
              <></>
            )
          ) : (
            ''
          )}
        </Row>
        {isMobile && <BottomTabNavigator />}
      </Container>
    </PostsContainer>
  );
};

export default TradeWisdom;
