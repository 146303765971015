export const TRADE_TAGS = [
  'Intraday',
  'Positional or Swing',
  'Futures and Options',
  'Potential Trade/Breakout',
  'Cryptocurrency',
  'Currency',
  'Commodities',
  'Geopolitics',
  'Macroeconomics',
  'Fundamental',
  'Psychology',
  'Watchlist',
  'Other',
];

export const DISCLAIMER_ADMIN = 'The views expressed in the community by the admin are for educational purposes only and should not be construed as investment advice. You agree, by accessing our community that TheLogicalTraders.com bears no liability for any posts on the community or any losses suffered by following any advice posted in the community. The community is designed for education, discussion and exchange of information only. You, and not TheLogicalTraders.com, assume the entire cost and risk associated with any trading decisions you take.';

export const DISCLAIMER_USER = 'The views expressed in the community are views of the users writing the post, and not that of TheLogicalTraders.com. You agree, by accessing our community that TheLogicalTraders.com bears no liability for any posts on the community or any losses suffered by following any advice posted in the community. The community is designed for education, discussion and exchange of information only, and anything posted here should not be construed as investment advice. You, and not TheLogicalTraders.com, assume the entire cost and risk associated with any trading decisions you take.';
