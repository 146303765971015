import React from "react";
import { PostImg } from "../../routes/posts/styled";
import "./preloader.scss";

const Image = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <React.Fragment>
      <img
        className='image thumb'
        alt={props.alt}
        src={props.thumb || props.src}
        style={{ visibility: isLoaded ? "hidden" : "visible" }}
      />
      <PostImg
        onLoad={() => {
          setIsLoaded(true);
        }}
        style={{ opacity: isLoaded ? 1 : 0 }}
        alt={props.alt}
        src={props.src}
        onClick={props.onClick}
      />
    </React.Fragment>
  );
};

export default Image;
