import React, { useCallback, useRef, useState } from 'react';
import { slide as Hamburger } from 'react-burger-menu';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { connect, useSelector } from 'react-redux';
import {
  MainBox,
  LeftSidebarProfile,
  LeftSidebarProfileHeader,
  UserImg,
  CenteredDiv,
  UserName,
  UserPlan,
  MobileNavbarItem,
} from '../../routes/posts/styled.jsx';
import { capitalizeText, logoutUserHandler } from '../../utils/helper';
import defaultUser from '../../assets/defaultUser.jpg';
import AuthorBioModal from '../../routes/posts/AuthorBioModal';
import { axiosAuth } from '../../api';
import { logout } from '../../actions/common';
import QuantityCalculator from '../QuantityCalculator/index.jsx';

const HamburgerMenuLinks = ({ isMenuOpen, toggleMenu, logoutUser }) => {
  const user = useSelector((state) => state.common.user);
  const [isOpenBioModal, setOpenBioModal] = useState(false);
  const childRef = useRef();
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    toggleMenu(false);
    history.push(path);
  }, []);

  const handleLogoutClick = () => {
    logoutUserHandler(logoutUser, history);
  };

  return (
    <div>
      <QuantityCalculator ref={childRef} />
      {isMobile && (
        <Hamburger
          onOpen={() => toggleMenu(true)}
          onClose={() => toggleMenu(false)}
          isOpen={isMenuOpen}
        >
          <MainBox isMobile={isMobile}>
            <LeftSidebarProfile>
              <LeftSidebarProfileHeader />
              <CenteredDiv>
                <UserImg
                  className="cursor-pointer"
                  src={user?.profileImg || defaultUser}
                  onClick={() => history.push('/profile')}
                />
                <UserName
                  className="cursor-pointer"
                  onClick={() => history.push('/profile')}
                >
                  {capitalizeText(user?.name ?? '')}
                </UserName>
                <UserPlan>
                  <Row justify="center">
                    <div className="mr-1">
                      {user?.investorType ?? 'Author bio'}
                    </div>
                    <EditOutlined
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleMenu(false);
                        setOpenBioModal(true);
                      }}
                    />
                  </Row>
                </UserPlan>
              </CenteredDiv>
            </LeftSidebarProfile>
          </MainBox>
          <div>
            <MobileNavbarItem
              className="mt-10"
              onClick={() => handleLinkClick('/profile')}
            >
              My Profile
            </MobileNavbarItem>
            <MobileNavbarItem
              onClick={() => {
                toggleMenu(false);
                childRef.current.toggleCalcModal(true);
              }}
            >
              Shares Calculator
            </MobileNavbarItem>
            {/* <MobileNavbarItem onClick={() => handleLinkClick('/about-us')}>
              About Us
            </MobileNavbarItem> */}
            {/* <MobileNavbarItem onClick={() => handleLinkClick('/blogs')}>
              Blogs
            </MobileNavbarItem> */}
            {/* <MobileNavbarItem
              onClick={() => handleLinkClick('/our-performance')}
            >
              Our Performance
            </MobileNavbarItem> */}
            <MobileNavbarItem onClick={() => handleLinkClick('/contact-us')}>
              Contact Us
            </MobileNavbarItem>
            <MobileNavbarItem onClick={() => handleLogoutClick()}>
              Logout
            </MobileNavbarItem>
          </div>
        </Hamburger>
      )}
      <AuthorBioModal
        isOpenBioModal={isOpenBioModal}
        resetData={(data) => setOpenBioModal(data)}
        alreadyAuthoreData={user?.investorType}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(HamburgerMenuLinks);
