import React, { Suspense, useRef } from 'react';
import { Col, Row } from 'antd';
import { Container } from 'react-bootstrap';
import { isMobile, isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ScrollArrow from '../../components/ScrollTop';
import Share from '../../components/ShareContainer';
import Counter from '../../components/Counter';
import HomeBg from '../../components/HomeBg';
import RupeeBag from '../../assets/rupee-bag.png';
import homeImage from '../../assets/bannerImage.png';
import mobileImage from '../../assets/banner-m.png';

import VideoSection from '../../components/HomeBg/VideoSection';
import WhatYouGet from '../../components/WhatYouGet';
import HeadingUnderline from '../../components/HeadingUnderline';

const Testimonials = React.lazy(() => import('../../components/Testimonials'));
const HowItWorks = React.lazy(() => import('../../components/HowItWorks'));
const BetterForYou = React.lazy(() => import('../../components/BetterForYou'));
const HowYouBenefit = React.lazy(() => import('../../components/HowYouBenefit'));
const Subscribe = React.lazy(() => import('../../components/Subscribe'));
const BlogsBrief = React.lazy(() => import('../../components/BlogsBrief'));
const FeaturedOn = React.lazy(() => import('../../components/FeaturedOnSection'));
const Experts = React.lazy(() => import('../../components/OurExperts'));

const Home = () => {
  const performanceRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop - 80, behavior: 'smooth' });
  const executeScroll = () => scrollToRef(performanceRef);

  const HomeBackground = styled.div`
    background-image: url(${!isMobileOnly ? homeImage : mobileImage});
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
  `;

  return (
    <Suspense fallback={(
      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <img style={{ marginTop: '100px' }} width="100px" src={RupeeBag} alt="TLT" />
      </div>
    )}
    >
      <Helmet>
        <meta
          name="description"
          content="How to become a trader, can you become a full time trader, best Indian stock market service, which stocks to buy, daily stock tips, best shares to buy, best Indian share market course, how to trade commodities, how to invest in shares, how to trade futures and options, how to trade forex, intraday calls, free stock market tips, best trading websites in India, commodity market trading in India, how to make money in stock market, how to become a profitable trader, can you make money regularly in the stock market, bank nifty options trading, nifty options trading, free stock tips, daily intraday calls free"
        />
      </Helmet>
      <Share />
      <HomeBackground className="black-bg">
        <HomeBg executeScroll={executeScroll} />
      </HomeBackground>
      <div className="clearfix" />
      <Row className="black-bg padding-30">
        <Col span={24}>
          <WhatYouGet />
        </Col>
      </Row>
      <div className="clearfix">
        {/* <div
          ref={performanceRef}
          style={{ paddingBottom: '0px' }}
          className="grey-bg padding-30"
        >
          <Row justify="center" className="grey-bg">
            <Col>
              <HeadingUnderline title="Our Performance" />
            </Col>
          </Row>
        </div>
        <div className="grey-bg padding-30">
          <VideoSection
            heading1="₹1.54 Lakh to ₹24.44 Lakh"
            heading2="We did it & you can too."
            url="https://www.youtube.com/watch?v=9J90XAL-LUU"
            videoRight
            showBottomMargin
            showTopMargin
            type={1}
          />
        </div>
        <div
          className="grey-bg padding-30"
          style={{ paddingTop: !isMobileOnly && '60px' }}
        >
          <VideoSection
            heading1="100% returns in 6 months"
            heading2="in 2021"
            url="https://youtu.be/5naFLpEJy7M"
            showBottomMargin
            type={1}
          />
        </div> */}
        {/* <Row className="testimonials-brief-bg">
          <Col span={24}>
            <HowItWorks />
          </Col>
        </Row> */}
        <div className="black-bg padding-30">
          <BetterForYou />
        </div>
        <div className="clearfix" />
        <div className="white-bg padding-30">
          <HowYouBenefit />
        </div>
        <div className="clearfix" />
        <Row className="blogs-brief-bg">
          <Col span={24}>
            <Testimonials />
          </Col>
        </Row>
        <div className="black-bg">
          <Experts />
        </div>
        {!isMobile && (
          <>
            <div className="clearfix" />
            <Counter />
            <div className="clearfix" />
          </>
        )}
        <div className="grey-bg padding-30">
          <VideoSection
            heading1="Learn from experts who have"
            heading2="a track record of over 20 years"
            url="https://youtu.be/ZdAS2vdugPM"
            videoRight
            showBottomMargin
            showTopMargin
            type={2}
          />
        </div>
        <Row className="testimonials-brief-bg">
          <FeaturedOn />
        </Row>
        <Row className="blogs-brief-bg">
          <BlogsBrief />
        </Row>
        <div className="clearfix" />
        <div className="clearfix" />
        <Row
          justify="center"
          style={{ marginTop: '25px', marginBottom: '25px' }}
        >
          <Container className="subscribe-section">
            <Subscribe />
          </Container>
        </Row>

        {!isMobileOnly && <ScrollArrow />}
        <div className="clearfix" />
      </div>
    </Suspense>
  );
};

export default Home;
