import styled from "styled-components";
import { RightMainBox } from "../../routes/posts/styled";

export const AuthoreName = styled.div`
  font-weight: bolder;
  font-size: 15px;
  text-align: right;
  margin-top: 15px;
`;

export const AuthoreTitle = styled.div`
  font-size: 14px;
  text-align: left !important;
  font-family: italic, "Times New Roman", serif;
  font-style: italic;
`;

export const LessPaddedRightBox = styled(RightMainBox)`
  padding: 5px;
`;

export const SliderContainer = styled.div`
  background-color: white;
  position: relative;
  height: 100%;
`;
