import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import Timer from '../Timer';

const Counter = () => (
  <>
    <Row className="counter-background">
      <Col xs={24} sm={12} md={12} lg={6}>
        <Timer
          start={0}
          end={3000}
          delay={0.5}
          duration={2}
          title="Financial articles published"
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={6}>
        <Timer
          start={0}
          end={25000000}
          delay={0.5}
          duration={2}
          title="Page views garnered"
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={6}>
        <Timer
          start={0}
          end={6000}
          showPlusSign
          delay={0.5}
          duration={2}
          title="International subscribers"
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={6}>
        <Timer
          start={0}
          end={20}
          delay={0.5}
          duration={2}
          title="Global websites feature us"
        />
      </Col>
    </Row>
  </>
);

export default Counter;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LargeText = styled.span`
  font-size: 27px;
  color: white;
  font-weight: bold;
  color: #f9ae00;
`;

export const LargeRedText = styled(LargeText)`
  color: #e00101;
`;

export const BigText = styled.span`
  font-size: 22px;
  color: white;
  font-weight: bold;
`;

export const LossWhiteText = styled.div`
  color: white;
  font-size: 25px;
  text-align: center;
  width: 100%;
  font-weight: bold;
`;

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  height: 100%;
`;

export const SecondDiv = styled(MainDiv)`
  flex-direction: row;
  height: 100%;
`;
