import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../components/HeadingUnderline";

const StyledSpan = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SmallSpacer = styled.div`
  height: 10px;
`;

const UnderlinedHeader = styled.h5``;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Spacer />
      <HeadingUnderline title='Privacy Policy' />
      <p>
        TheLogicalTraders.com realizes the importance of maintaining your
        privacy. We value your privacy and appreciate your trust in us. This
        policy describes how we treat the user information that we collect on{" "}
        <a href='https://www.thelogicaltraders.com'>
          www.thelogicaltraders.com
        </a>{" "}
        and other offline sources.
      </p>
      <Spacer />
      <UnderlinedHeader>
        Restricting the collection of personal data
      </UnderlinedHeader>
      <p>
        We don’t sell, lease or share your personal information with any third
        party except to comply with the law, develop our products, or protect
        our rights. The policy applies to both current and former visitors to
        our website and our customers.
      </p>
      <Spacer />
      <UnderlinedHeader>Consent</UnderlinedHeader>
      <p>
        By visiting and/or using our website, you agree to this privacy policy.
      </p>
      <Spacer />
      <UnderlinedHeader>Information we collect</UnderlinedHeader>
      <h6>
        Contact Information:{" "}
        <StyledSpan>
          We might collect your name, email ID, mobile number, age, gender, and
          internet protocol addresses.
        </StyledSpan>
      </h6>
      <SmallSpacer />
      <h6>
        Payment and Billing Information:{" "}
        <StyledSpan>
          We might collect your billing name and payment method. We NEVER
          collect your credit or debit card number or expiry date or other
          details pertaining to your credit or debit card.
        </StyledSpan>
      </h6>
      <p>
        Credit card and debit card information will be obtained and processed by
        our digital payment partner Razorpay.
      </p>
      <SmallSpacer />
      <h6>
        Log Files:{" "}
        <StyledSpan>
          We use log files to record the information of visitors when they visit
          TheLogicalTraders.com. We use log files to collect information like
          Internet Protocol Addresses, Browser Type, and Internet Service
          Provider, date and time stamp, number of clicks, and so on.
        </StyledSpan>
      </h6>
      <SmallSpacer />
      <h6>
        Tracking:{" "}
        <StyledSpan>
          We use tracking tools like Facebook pixel, Google global site tag,
          Google analytics, browser cookies and other required tags for
          collecting information about your usage of our website. This helps us
          improve the performance of our website and to re-market visitors on
          different ad platforms who have visited our website in the past.
        </StyledSpan>
      </h6>
      <Spacer />
      <p>
        Website visitors who don’t want to have cookies placed on their
        computers should set their browsers to refuse cookies before visiting
        TheLogicalTraders.com. Some features on TheLogicalTraders.com may not
        function properly without the aid of cookies.
      </p>
      <p>
        Opening our emails and or clicking on links can be tracked by our email
        marketing software and/or other tools that we use to check the response
        to our emails.
      </p>
      <Spacer />
      <UnderlinedHeader>Privacy Policy Changes</UnderlinedHeader>
      <p>
        Although most changes are likely to be minor, we may change our Privacy
        Policy from time to time, and it’s in our sole discretion. We encourage
        visitors to frequently check the Privacy Policy page for any changes.
        Your continued use of TheLogicalTraders.com after any change in this
        policy will constitute your acceptance of such a change.
      </p>
      <Spacer />
      <UnderlinedHeader>Contact</UnderlinedHeader>
      <p>
        Any visitor who requests a quote, fills our lead form or newsletter
        form, or provides us their details in any other way is thereby giving us
        the right to contact them about our services, show them remarketing ads,
        or email them to let them know about our services.
      </p>
      <Spacer />
    </Container>
  );
};

export default PrivacyPolicy;
