import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './routes/home';
import About from './routes/about';
import SignUp from './components/SignUp';
import Blogs from './routes/blogs';
import Pricing from './routes/pricing';
import Login from './components/Login';
import BlogDetail from './routes/blogDetail';
import ContactUs from './routes/contactUs';
import AllQueries from './routes/allQueries';
import AllUsers from './routes/allUsers';
import AllSubscribers from './routes/allSubscribers';
import MyProfile from './routes/myProfile';
import PrivacyPolicy from './routes/privacyPolicy';
import Performance from './routes/performance';
import LandingPage from './routes/landingPage';
import LandingPageWebinar from './routes/landingPage/webinar';
import LandingPageEbook from './routes/landingPage/ebook';
import AllLeads from './routes/allLeads';
import Posts from './routes/posts';
import FAQS from './routes/faqs';
import RefundPolicy from './routes/refundPolicy';
import Disclaimer from './routes/disclaimer';
import TermsConditions from './routes/termsConditions';
import ResetPassword from './routes/resetPassword';
import JoinUsLandingPage from './routes/landingPage/joinUsLandingPage';
import ThankYou from './routes/thankYou';
import NotFound from './routes/notFound';
import ReviewPosts from './routes/posts/ReviewPosts';
import WatchList from './routes/watchList';
import CommunityTerms from './routes/communityTerms';
import Notifications from './routes/notifications';
import TradeWisdom from './routes/tradeWisdom';
import SinglePost from './routes/singlePost';
import SingleWatchList from './routes/singleWatchList';
import SingleTradeWisdom from './routes/singleTradeWisdom';
import Dashboard from './routes/dashboard';
import RenewSubscription from './routes/renewSubscription';
import UserDashboard from './routes/userDashboard';
import Webinar from './routes/webinar';
import WebinarRegister from './routes/webinarRegister';
import RegisterThankYou from './routes/registerThankYou';

function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => (!localStorage.getItem('authToken') ? (
          <Home />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/login"
        component={() => (!localStorage.getItem('authToken') ? (
          <Login />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/register"
        component={() => (!localStorage.getItem('authToken') ? (
          <SignUp />
        ) : (
          <Redirect to="/thank-you" />
        ))}
      />
      <Route exact path="/about-us" component={() => <About />} />
      <Route exact path="/blogs" component={() => <Blogs />} />
      <Route exact path="/blogs/:title" component={() => <BlogDetail />} />
      <Route exact path="/pricing-plans" component={() => <Pricing />} />
      <Route exact path="/contact-us" component={() => <ContactUs />} />
      <Route exact path="/privacy-policy" component={() => <PrivacyPolicy />} />
      <Route exact path="/faqs" component={() => <FAQS />} />
      <Route exact path="/our-performance" component={() => <Performance />} />
      <Route
        exact
        path="/reset-password"
        component={() => (!localStorage.getItem('authToken') ? (
          <ResetPassword />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/join-us"
        component={() => (!localStorage.getItem('authToken') ? (
          <JoinUsLandingPage />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/landing-page"
        component={() => (!localStorage.getItem('authToken') ? (
          <LandingPage />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/renew-subscription/:token"
        component={() => (!localStorage.getItem('authToken') ? (
          <RenewSubscription />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/landing-page-ebook"
        component={() => (!localStorage.getItem('authToken') ? (
          <LandingPageEbook />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route
        exact
        path="/landing-page-webinar"
        component={() => (!localStorage.getItem('authToken') ? (
          <LandingPageWebinar />
        ) : (
          <Redirect to="/user-dashboard" />
        ))}
      />
      <Route exact path="/disclaimer" component={() => <Disclaimer />} />
      <Route exact path="/refund-policy" component={() => <RefundPolicy />} />
      <Route
        exact
        path="/terms-and-conditions"
        component={() => <TermsConditions />}
      />
      <Route
        exact
        path="/all-queries"
        component={() => (localStorage.getItem('authToken') ? (
          <AllQueries />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/all-posts-review"
        component={() => (localStorage.getItem('authToken') ? (
          <ReviewPosts />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/all-users"
        component={() => (localStorage.getItem('authToken') ? <AllUsers /> : <Redirect to="/" />)}
      />
      <Route
        exact
        path="/all-subscribers"
        component={() => (localStorage.getItem('authToken') ? (
          <AllSubscribers />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/all-leads"
        component={() => (localStorage.getItem('authToken') ? <AllLeads /> : <Redirect to="/" />)}
      />
      <Route
        exact
        path="/posts/:postId"
        component={() => (localStorage.getItem('authToken') ? (
          <SinglePost />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/posts"
        component={() => (localStorage.getItem('authToken') ? <Posts /> : <Redirect to="/" />)}
      />
      <Route
        exact
        path="/posts/:postId/:notificationId"
        component={() => (localStorage.getItem('authToken') ? (
          <SinglePost />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/watchlist/:title"
        component={() => (localStorage.getItem('authToken') ? (
          <SingleWatchList />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        exact
        path="/watchlist"
        component={() => (localStorage.getItem('authToken') ? (
          <WatchList />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        exact
        path="/watchlist/:title/:notificationId"
        component={() => (localStorage.getItem('authToken') ? (
          <SingleWatchList />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        path="/notifications"
        component={() => (localStorage.getItem('authToken') ? (
          <Notifications />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        exact
        path="/trade-wisdom"
        component={() => (localStorage.getItem('authToken') ? (
          <TradeWisdom />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        exact
        path="/trade-wisdom/:wisdomId"
        component={() => (localStorage.getItem('authToken') ? (
          <SingleTradeWisdom />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        exact
        path="/trade-wisdom/:wisdomId/:notificationId"
        component={() => (localStorage.getItem('authToken') ? (
          <SingleTradeWisdom />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route
        exact
        path="/community-terms-and-conditions"
        component={() => (localStorage.getItem('authToken') ? (
          <CommunityTerms />
        ) : (
          <Redirect to="/" />
        ))}
      />

      <Route exact path="/thank-you" component={() => <ThankYou />} />
      <Route
        exact
        path="/profile"
        component={() => (localStorage.getItem('authToken') ? (
          <MyProfile />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/dashboard"
        component={() => (localStorage.getItem('authToken') ? (
          <Dashboard />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route
        exact
        path="/user-dashboard"
        component={() => (localStorage.getItem('authToken') ? (
          <UserDashboard />
        ) : (
          <Redirect to="/" />
        ))}
      />
      <Route exact path="/webinar" component={() => <Webinar />} />
      <Route
        exact
        path="/webinar-registration"
        component={() => (!localStorage.getItem('authToken') ? (
          <WebinarRegister />
        ) : (
          <Redirect to="/dashboard" />
        ))}
      />
      <Route
        exact
        path="/webinar-register-success"
        component={() => (!localStorage.getItem('authToken') ? (
          <RegisterThankYou />
        ) : (
          <Redirect to="/dashboard" />
        ))}
      />
      <Route path="/404" component={() => <NotFound />} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default Routes;
