import React, { useEffect, useState } from 'react';
import {
  HomeFilled,
  BellOutlined,
  LineChartOutlined,
  EyeOutlined,
  HomeOutlined,
  EyeFilled,
  BellFilled,
  FundFilled,
} from '@ant-design/icons';
import * as R from 'ramda';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import './bottomTab.scss';
import { axiosAuth } from '../../api';

const tabs = [
  {
    route: '/posts',
    icon: <HomeOutlined className="bottom-tab-icon" />,
    filledIcon: <HomeFilled className="bottom-tab-icon" />,
    label: 'Home',
    showOnThisTab: true,
  },
  // {
  //   route: '/trade-wisdom',
  //   icon: <LineChartOutlined className="bottom-tab-icon" />,
  //   filledIcon: <FundFilled className="bottom-tab-icon" />,
  //   label: 'Wisdom',
  //   showOnThisTab: true,
  // },
  {
    route: '/watchlist',
    icon: <EyeOutlined className="bottom-tab-icon" />,
    filledIcon: <EyeFilled className="bottom-tab-icon" />,
    label: 'Watch List',
    showOnThisTab: true,
  },
  {
    route: '/notifications',
    icon: <BellOutlined className="bottom-tab-icon" />,
    filledIcon: <BellFilled className="bottom-tab-icon" />,
    label: 'Notification',
    showCount: true,
    showOnThisTab: false,
  },
];

const BottomTabNavigator = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const [count, setCount] = useState(0);

  const getAllNotifications = () => {
    axiosAuth
      .get('/notification/all?offset=0&limit=50')
      .then((res) => {
        const newcount = R.pipe(
          R.filter(R.propEq('read', false)),
          R.length,
        )(res?.data?.data ?? []);
        setCount(newcount);
      })
      .catch(() => {
        console.error('Failed to load notifications');
      });
  };

  useEffect(() => {
    getAllNotifications();
  }, [pathname]);

  const handleItemClick = (route) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    history.push(route);
  };

  return (
    <nav
      className="bottom-tab-navigator navbar fixed-bottom navbar-light"
      role="navigation"
      style={{ padding: '5px 10px' }}
    >
      <Nav className="w-100">
        <div className=" d-flex flex-row justify-content-around w-100">
          {tabs.map((tab, index) => (
            <NavItem className="nav-item-bottom-tab" key={`tab-${index}`}>
              <NavLink
                onClick={() => handleItemClick(tab?.route)}
                className="nav-link"
              >
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  {pathname == tab.route ? tab?.filledIcon : tab?.icon}
                  {pathname != '/notifications'
                    && tab.showCount
                    && count > 0 && (
                      <div className="bottom-tab-notification-count">
                        {count > 9 ? '9+' : count}
                      </div>
                  )}
                  <div className="text-black">{tab.label}</div>
                </div>
              </NavLink>
            </NavItem>
          ))}
        </div>
      </Nav>
    </nav>
  );
};

export default BottomTabNavigator;
