import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import BlogBrief from '../../components/blog/blogBrief';
import { getEnvValue } from '../../utils/helper';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';
import { axiosAuth } from '../../api';

const ReadMore = styled.p`
  font-family: Titillium Web;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
  color: #f9ae00;
`;

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const history = useHistory();
  const baseUrl = getEnvValue('IMAGE_URL');
  const isUserLoggedIn = useSelector((state) => state.common.authToken);
  const handleBlogClick = useCallback((path) => {
    history.push(`blogs/${path}`);
  }, []);

  useEffect(() => {
    setLoading(true);
    axiosAuth
      .get('blogs/all')
      .then((res) => {
        if (res?.data?.data) {
          setAllBlogs(res?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <CenteredDiv>
        <LoadingOutlined style={{ fontSize: 30, color: '#f9ae00' }} spin />
      </CenteredDiv>
    );
  }

  return (
    <>
      <Helmet>
        <title>Blogs written by The Logical Traders</title>
        <meta
          name="description"
          content="The Logical Traders Blog, Are the Logical Traders blogs good, Are the Logical Traders blogs helpful, "
        />
      </Helmet>
      <Container className="blogs-brief" style={{ marginBottom: '50px' }}>
        <Spacer />
        <HeadingUnderline title="Blogs" />
        <Row>
          {allBlogs.map((blog) => (
            <Col key={blog?.id} className="mb-20" md={12} sm={24} lg={8}>
              <BlogBrief
                onClick={() => history.push(`/blogs/${blog?.slug}`)}
                imageSrc={blog?.featureImage}
                isLocked={!isUserLoggedIn && blog?.is_locked?.toLowerCase() === 'yes'}
                title={blog?.title}
                description={(
                  <>
                    <p>{blog?.shortDescription}</p>
                    <ReadMore>Read More</ReadMore>
                  </>
                )}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Blogs;
