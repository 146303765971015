import React, { useState, useEffect } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import {
  ShareContainer,
  ShareLi,
  ShareText,
  ShareTextLi,
  ShareLiArrow,
} from "../../styledComponents";
import fb from "../../assets/facebook.svg";
import linkedin from "../../assets/linkedin.svg";
import whatsapp from "../../assets/whatsapp.svg";
import twitter from "../../assets/twitter.svg";
import telegram from "../../assets/telegram.svg";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

const MinDiv = styled.div`
  min-height: 250px;
  width: 1px;
  @media (max-width: 767px) {
    min-height: 200px;
  }
`;

const Share = () => {
  const [showContainer, setShowContainer] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(null);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const handleShowHideClick = (e) => {
    setShowContainer(!showContainer);
  };

  if (
    isMobileOnly &&
    scrollPosition < 0.52
    // || (!isMobileOnly && scrollPosition < 0.53)
  ) {
    return <></>;
  }
  return (
    <ShareContainer showContainer={showContainer}>
      {showContainer ? (
        <>
          <ShareTextLi>Share</ShareTextLi>
          <ShareLi type={"facebook"}>
            <FacebookShareButton
              url={"https://www.thelogicaltraders.com"}
              quote='The Logical Traders'
            >
              <>
                <img height='25px' width='25px' src={fb} />{" "}
                <ShareText>Facebook</ShareText>
              </>
            </FacebookShareButton>
          </ShareLi>
          <ShareLi type={"whatsapp"}>
            <WhatsappShareButton url={"https://www.thelogicaltraders.com"}>
              <>
                <img height='25px' width='30px' src={whatsapp} />{" "}
                <ShareText>WhatsApp</ShareText>
              </>
            </WhatsappShareButton>
          </ShareLi>
          <ShareLi type={"linkedin"}>
            <LinkedinShareButton url={"https://www.thelogicaltraders.com"}>
              <img height='25px' width='30px' src={linkedin} />{" "}
              <ShareText>LinkedIn</ShareText>
            </LinkedinShareButton>
            <></>
          </ShareLi>
          <ShareLi type={"twitter"}>
            <TwitterShareButton url={"https://www.thelogicaltraders.com"}>
              <>
                <img height='25px' width='30px' src={twitter} />{" "}
                <ShareText>Twitter</ShareText>
              </>
            </TwitterShareButton>
          </ShareLi>
          <ShareLi type={"telegram"}>
            <TelegramShareButton url={"https://www.thelogicaltraders.com"}>
              <>
                <img height='25px' width='30px' src={telegram} />{" "}
                <ShareText>Telegram</ShareText>
              </>
            </TelegramShareButton>
          </ShareLi>
        </>
      ) : (
        <MinDiv />
      )}
      <ShareLiArrow onClick={handleShowHideClick}>
        {showContainer ? (
          <DoubleRightOutlined onClick={handleShowHideClick} />
        ) : (
          <DoubleLeftOutlined onClick={handleShowHideClick} />
        )}
      </ShareLiArrow>
    </ShareContainer>
  );
};

export default Share;
