import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Divider,
  Collapse,
  Row,
  Button,
  notification,
  Col,
  Tabs,
  Select,
} from 'antd';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { ExtraActions, GapDiv, PostImg } from './styled';
import { axiosAuth } from '../../api';

import AddEditWatchList from './addEditWatchList';
import { AsyncS3Upload } from '../../utils/s3Uploader';
import { LoadingNotificationSkeleton } from '../posts/LoadingSkeleton';
import AuthorBioModal from '../posts/AuthorBioModal';
import {
  CenteredDiv,
  LeftSidebarProfile,
  LeftSidebarProfileHeader,
  MainBoxFull,
  PostsContainer,
  UserImg,
  UserName,
  UserPlan,
} from '../posts/styled';
import defaultUser from '../../assets/defaultUser.jpg';
import MenuBar from '../posts/MenuBar';
import { capitalizeText } from '../../utils/helper';
import BottomTabNavigator from '../../components/BottomTabNavigator';
import logo1 from '../../assets/logo1.svg';
import HamburgerMenuLinks from '../../components/HamburgerMenuLink';
import QuotesSidebar from '../../components/QuotesSidebar';
import { menuList } from './constants';
import { Spacer } from '../about';

const { TabPane } = Tabs;
const { Option } = Select;

const WatchList = () => {
  const { Panel } = Collapse;
  const user = useSelector((state) => state.common.user);
  const { title } = useParams();
  const [showEditCreateModal, setShowEditCreateModal] = useState(null);
  const [activeKey, changeKey] = useState(title);
  const [watchListData, setWatchListData] = useState([]);
  const [loading, setLoading] = useState(title);
  const [isEdit, setIsEdit] = useState(false);
  const [createUpdateLoading, setCreateUpdateLoading] = useState(false);
  const [lightboxImage, setLightBoxImage] = useState('');
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [isOpenBioModal, setOpenBioModal] = useState(false);
  const [isMenuOpen, toggleHamburger] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [tabList, setTabList] = useState([]);
  const [watchListFilter, setWatchListFilter] = useState([]);
  const [activeTab, setaActiveTab] = useState('All');

  const history = useHistory();

  useEffect(() => {
    let result = ['All'].concat(filterList);
    if (!filterList.length) {
      result = ['All'].concat(menuList);
    }
    setTabList(result);
  }, [filterList]);

  const handleFaqChange = (key) => {
    changeKey(key);
  };

  const getWatchListData = (menuData = []) => {
    setLoading(true);
    const endpoint = '/watchlist/all';
    axiosAuth
      .post(endpoint, { types: menuData })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setWatchListData(res?.data?.data);
            setWatchListFilter(res?.data?.data);
          } else {
            setWatchListData([]);
            setWatchListFilter([]);
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to load watch list. Please try again later!!',
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWatchListData();
    setIsScroll(true);
  }, []);

  const getExtraActions = (item) => {
    if (user?.userType === 'admin') {
      return (
        <ExtraActions>
          <EditOutlined
            onClick={(event) => {
              event.stopPropagation();
              setIsEdit(true);
              setShowEditCreateModal(item);
            }}
          />
          <DeleteOutlined
            onClick={(event) => {
              event.stopPropagation();
              deleteHandler(item);
            }}
          />
        </ExtraActions>
      );
    }
  };

  const deleteHandler = (data) => {
    if (window.confirm('Are you sure want to delete this watch list?')) {
      axiosAuth
        .delete(`watchlist/delete/${data._id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Watch List deleted successfully!!',
            });
            const itemIndex = watchListData.findIndex(
              (item) => item._id === data._id,
            );
            if (itemIndex > -1) {
              const updatedData = R.remove(itemIndex, 1, watchListData);
              setWatchListData(updatedData);
              setWatchListFilter(updatedData);
            }
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete Watch List. Please try again!!',
          });
        });
    }
  };

  const handleCreateUpdate = (data) => {
    const { title, filesList, selectedType } = data;
    if (!title) {
      notification.error({
        message: 'Create Watch List Error',
        description: 'Please enter title!!',
      });
      return;
    }
    if (!filesList?.length) {
      notification.error({
        message: 'Create Watch List Error',
        description: 'Please upload at least one chart image!!',
      });
      return;
    }
    if (!selectedType) {
      notification.error({
        message: 'Create Watch List Error',
        description: 'Please Select at least one type!!',
      });
      return;
    }
    setCreateUpdateLoading(true);

    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq('alreadyUploaded', true)),
        R.map(R.prop('thumbUrl')),
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      const payload = {
        images: alreadyUploadedImages.concat(images),
        title,
        types: [selectedType],
        _id: showEditCreateModal._id,
      };

      const apiProvider = !isEdit ? axiosAuth.post : axiosAuth.patch;
      apiProvider(isEdit ? 'watchlist/update' : 'watchlist/create', payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (isEdit) {
              notification.success({
                message: 'Success',
                description: 'Watch List updated successfully!!',
              });
              const updatedData = R.update(
                R.findIndex(R.propEq('_id', showEditCreateModal._id))(
                  watchListData,
                ),
                res?.data?.data,
                watchListData,
              );
              setWatchListData(updatedData);
              setWatchListFilter(updatedData);
            } else {
              if (user?.userType == 'admin') {
                setWatchListData(R.insert(0, res?.data?.data, watchListData));
                setWatchListFilter(R.insert(0, res?.data?.data, watchListData));
              }
              notification.success({
                message: 'Success',
                description: 'Watch List created successfully!!',
              });
            }
          } else {
            notification.error({
              message: 'Error',
              description: 'Failed to post. Please try again later!!',
            });
          }
          setCreateUpdateLoading(false);
          setShowEditCreateModal(false);
          setIsEdit(false);
        })
        .catch((err) => {
          setCreateUpdateLoading(false);
        });
    });
  };

  const getImagesComp = (images) => {
    if (images?.length == 1) {
      return (
        <Row className="mb-10">
          <Col span={24}>
            <PostImg
              onClick={() => {
                setLightBoxImage(images);
                toggleLightBox(true);
              }}
              src={images[0]}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row className="mb-10">
        {images.map((item) => (
          <Col span={12}>
            <div style={{ padding: '15px', height: '100%' }}>
              <PostImg
                onClick={() => {
                  setLightBoxImage(images);
                  toggleLightBox(true);
                }}
                showBorder
                src={item}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };
  const ref = {};

  useEffect(() => {
    if (isScroll && ref && ref[title] && ref[title]) {
      const height = ref[title].getBoundingClientRect().top - 70;
      window.scrollTo(0, height);
      setIsScroll(false);
    }
  }, [ref]);

  const getSelectedTabsData = (key) => {
    setaActiveTab(key);

    if (key === 'All') {
      setWatchListFilter(watchListData);
    } else if (key === 'Other') {
      const data = watchListData.filter((item) => {
        if (!item?.types?.length) {
          return true;
        }
      });
      setWatchListFilter(data);
    } else {
      const data = watchListData.filter((item) => {
        if (item?.types?.includes(key)) {
          return true;
        }
      });
      setWatchListFilter(data);
    }
  };

  const handleFilters = (value) => {
    setFilterList(value);
    setaActiveTab('All');
    getWatchListData(value);
  };

  return (
    <PostsContainer isMobileOnly={isMobile} className="posts">
      <HamburgerMenuLinks
        isMenuOpen={isMenuOpen}
        toggleMenu={(value) => toggleHamburger(value)}
      />
      <AuthorBioModal
        isOpenBioModal={isOpenBioModal}
        resetData={(data) => setOpenBioModal(data)}
        alreadyAuthoreData={user?.investorType}
      />
      <Container>
        {isMobile && (
          <div className="menu-btn-heading-parent">
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: '25px',
                position: 'absolute',
                zIndex: 1000,
              }}
            />
            <div className="posts-heading text-center">
              <img
                aria-hidden
                onClick={() => history.push('/')}
                alt=""
                src={logo1}
                className="d-inline-block align-top brand-image"
              />
            </div>
          </div>
        )}
        {isLightBoxVisible && (
          <Lightbox
            mainSrc={lightboxImage[photoIndex]}
            nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length]}
            prevSrc={
              lightboxImage[
                (photoIndex + lightboxImage.length - 1) % lightboxImage.length
              ]
            }
            onCloseRequest={() => toggleLightBox(false)}
            onMovePrevRequest={() => setPhotoIndex(
              (photoIndex + lightboxImage.length - 1) % lightboxImage.length,
            )}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxImage.length)}
          />
        )}
        {user?.userType === 'admin' && (
          <>
            <Row justify="end">
              <Button
                className="save-post-button"
                onClick={() => setShowEditCreateModal(true)}
              >
                Create Watchlist
              </Button>
            </Row>
            <Divider />
          </>
        )}
        {showEditCreateModal && (
          <AddEditWatchList
            isEdit={isEdit}
            showEditCreateModal={showEditCreateModal}
            toggleEditModal={(data) => {
              setShowEditCreateModal(data);
              setIsEdit(false);
            }}
            handleCreateUpdate={handleCreateUpdate}
            createUpdateLoading={createUpdateLoading}
          />
        )}
        <Row>
          <Col xs={0} sm={0} md={0} lg={5}>
            <div className={watchListData?.length > 10 ? 'sticky-box' : ''}>
              <MainBoxFull>
                <LeftSidebarProfile>
                  <LeftSidebarProfileHeader />
                  <CenteredDiv>
                    <UserImg
                      className="cursor-pointer"
                      src={user?.profileImg || defaultUser}
                      onClick={() => history.push('/profile')}
                    />
                    <UserName
                      className="cursor-pointer"
                      onClick={() => history.push('/profile')}
                    >
                      {capitalizeText(user?.name ?? '')}
                    </UserName>
                    <UserPlan>
                      <Row justify="center">
                        <div className="mr-1">
                          {user?.investorType ?? 'Author bio'}
                        </div>
                        <EditOutlined
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpenBioModal(true);
                          }}
                        />
                      </Row>
                    </UserPlan>
                  </CenteredDiv>
                </LeftSidebarProfile>
                <LeftSidebarProfile>
                  <MenuBar history={history} />
                </LeftSidebarProfile>
              </MainBoxFull>
            </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />
          <Col xs={24} sm={24} md={24} lg={12}>
            <Select
              style={{ width: '100%' }}
              value={filterList}
              mode="multiple"
              showArrow
              placeholder="Select categorie(s)"
              onChange={handleFilters}
            >
              {menuList.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <Spacer />
            <Tabs
              defaultActiveKey="All"
              activeKey={activeTab}
              style={{ height: '100%' }}
              size="large"
              type="card"
              onChange={getSelectedTabsData}
              className="watchlist-tab"
            >
              {tabList.map((item) => (
                <TabPane tab={item} key={item}>
                  <Collapse
                    className="site-collapse-custom-collapse-gray"
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    activeKey={activeKey}
                    onChange={handleFaqChange}
                  >
                    {loading ? (
                      <LoadingNotificationSkeleton />
                    ) : (
                      watchListFilter?.map((item, index) => (
                        <>
                          <Panel
                            className="site-collapse-custom-panel-watchlist no-margin-bottom"
                            header={item.title}
                            key={item._id}
                            extra={getExtraActions(item)}
                          >
                            <div
                              ref={(el) => (ref[item._id] = el)}
                              className="mt-10"
                            >
                              {getImagesComp(item?.images)}
                            </div>
                          </Panel>
                          <Divider />
                          <GapDiv />
                        </>
                      ))
                    )}
                  </Collapse>
                  {watchListFilter?.length === 0 && (
                    <Row className="mb-20" justify="center">
                      <p><strong>The watchlist is currently empty</strong></p>
                    </Row>
                  )}
                </TabPane>
              ))}
            </Tabs>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />
          <Col xs={0} sm={0} md={0} lg={5}>
            {!isMobile && (
              <div>
                <QuotesSidebar isSticky={watchListData?.length > 10} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {isMobile && <BottomTabNavigator />}
    </PostsContainer>
  );
};

export default WatchList;
