import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import { Container } from 'react-bootstrap';
import { FooterContainer } from '../../styledComponents';
import logo2 from '../../assets/logo2.svg';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import 'react-whatsapp-widget/dist/index.css';
import moment from 'moment';

const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  flex: 1;
  @media (max-width: 768px) {
    margin-bottom: 15px;
    height: initial;
  }
`;

const LogoImage = styled.img`
  width: 250px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const SiteListSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  flex: 1;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 15px;
    height: initial;
  }
`;

const SiteLinkModified = styled(SiteListSection)`
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const FollowUsHeading = styled.h6`
  color: #f9ae00;
`;

const SocialIconsList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  li {
    float: left;
    color: #f9ae00;
    list-style-type: none;
    padding: 0px 10px;
  }
  li:not(:last-child) {
    border-right: 1px solid #f9ae00;
  }
  li:hover {
    cursor: pointer;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #c4c4c4;
  margin-bottom: 25px;
`;

const Parent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CopyrightText = styled.p`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
`;

const SocialAParent = styled.a``;

const LandingFooter = () => {
  const history = useHistory();
  const location = useLocation();

  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);

  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <LogoSection>
              <LogoImage src={logo2} />
            </LogoSection>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <SiteLinkModified>
              <ul className="site-links-list">
                <li onClick={() => handleLinkClick('/terms-and-conditions')}>
                  Terms & Conditions
                </li>
                <li onClick={() => handleLinkClick('/disclaimer')}>
                  Disclaimer
                </li>
                <li onClick={() => handleLinkClick('/refund-policy')}>
                  Refund Policy
                </li>
              </ul>
            </SiteLinkModified>
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row>
          <CopyrightText>
            © Objective Traders Pvt Ltd
            {' '}
            {moment().format('YYYY')}
            {' '}
            - All rights reserved
          </CopyrightText>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default LandingFooter;
