import React, { useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Col, Collapse, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { isUserLoggedIn } from '../../utils/helper';
import { SignUpNavItem } from '../../styledComponents';
import HeadingUnderline from '../../components/HeadingUnderline';

const { Panel } = Collapse;

const Spacer = styled.div`
  height: 20px;
`;

const FAQS = () => {
  const [activeKey, changeKey] = useState('');
  const handleFaqChange = (key) => {
    changeKey(key);
  };
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);
  return (
    <Container>
      <Spacer />
      <HeadingUnderline title="FAQs" />
      <Collapse
        className="site-collapse-custom-collapse"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        onChange={handleFaqChange}
      >
        <Panel
          className="site-collapse-custom-panel"
          header="I am a beginner and have no knowledge of the stock market. Can I join?"
          key="51"
        >
          <p>
            Yes beginners can join too. We also have a course for
            beginners that is FREE for 12 and 24 months subscribers.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="How much capital should I have before I become a member?"
          key="52"
        >
          <p>
            The Logical Traders is an educational community so there’s no
            minimum capital requirement.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="I currently have a job, will I benefit from joining The Logical Traders?"
          key="53"
        >
          <p>
            Yes. In fact, our trading style is best suited for people who don’t
            have time to track the markets for the entire day. We take swing and
            positional trades, so people with jobs can accommodate these
            practices easily.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="How long will it take for me to recover subscription fees?"
          key="54"
        >
          <p>
            There are a lot of variables on which recovery of the fees depends
            like capital in account, trading frequency etc.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Is there an option for EMI payment?"
          key="55"
        >
          <p>
            Yes we offer regular EMI and No Cost EMI for 12 and 24
            months membership to our clients.

          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="How will I get the alerts of the trades that you place?"
          key="56"
        >
          <p>
            The alert will be given via our Telegram channel in real time,
            meaning we will post a message on Telegram almost instantly whenever
            we take a trade.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="What is the difference between a paid Telegram Channel and a free channel?"
          key="57"
        >
          <p>
            Free Telegram channel will not have live alerts to the trades that
            we place.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="What style of trading do you do?"
          key="58"
        >
          <p>
            We mostly take positional & swing trades. Intraday trades will be
            extremely rare.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Do you offer a course for beginners?"
          key="59"
        >
          <p>
            Yes we have a course that is accessible to 12 and 24
            months subscribers at no extra cost
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="How long is the membership valid?"
          key="60"
        >
          <p>
            We offer 6-month, 12-month and 24-month membership.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="Do you teach about futures, options, commodities and currencies?"
          key="61"
        >
          <p>
            Yes we take trades in futures, options, commodities & currencies too
            and will provide regular updates of the same whenever we do.
          </p>
        </Panel>

        <Panel
          className="site-collapse-custom-panel"
          header="What type of educational content will I get as a member?"
          key="62"
        >
          <p>
            Apart from live updates of our trades, you will also get a detailed
            explanation of why we take trades. You will also get other
            educational insights in the form of blogs, videos, book
            recommendations, and international market analysis from time to time
            that will make you a better trader.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="I don’t have any prior knowledge of the stock market, should I still subscribe?"
          key="2"
        >
          <p>
            Throughout the period of your membership, we will provide necessary
            study material (in the form of articles, videos, books or podcasts),
            either made by The Logical Traders or by some third-party, which
            will help the members gradually get a hang of the world of finance.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Do you teach investing and trading to beginners?"
          key="3"
        >
          <p>
            We provide relevant and timely study material to the members, but we
            believe in teaching them mainly through our real time trades.
            {' '}
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="What and how will you teach the members?"
          key="4"
        >
          <p>
            We will teach the members how experts trade by providing them real
            time access to our trades along with the thought process and
            reasoning behind the trade. Apart from that, we will provide timely
            study material in the form of articles, podcasts, video explanations
            (either made by The Logical Traders or any other third party that we
            find credible) and book recommendations. We believe this will
            massively shorten the learning curve of an individual.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="What topics will you cover?"
          key="5"
        >
          <p>
            We will cover a variety of topics like fundamental analysis,
            technical analysis, intermarket analysis, commodities, currencies,
            macroeconomics, microeconomics, and geopolitics.
            {' '}
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Do you give stock recommendations to members?"
          key="6"
        >
          <p>
            We do not provide trading recommendations & are not SEBI registered
            investment advisors. We just give real time access to the trades
            that we take or are looking to enter for educational purposes only.
            Our members can decide to copy or coattail our trades, but we
            recommend them to do their own due diligence & are not liable for
            any monetary losses.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Do you provide intraday tips?"
          key="7"
        >
          <p>
            We do not believe in intraday trading and don’t take trades on a
            daily basis. Our trades are mostly swing trades and a real time
            alert will be provided to our members when we place those trades.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="How many trades will The Logical Traders take on a monthly basis?"
          key="8"
        >
          <p>
            We only take trades when we see a clear opportunity, which can
            sometimes even take a month to present itself. Thus, for certain
            months, we may not even take a single trade. However, on average we
            will take somewhere around 30 to 60 trades in a year.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Do The Logical Traders trade in derivatives as well?"
          key="9"
        >
          <p>
            We often take trades in the derivatives market and we will provide a
            reason and an explanation for the same.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Do you trade commodities and currencies?"
          key="10"
        >
          <p>Yes, we often trade in commodities and currencies.</p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="I lost money after following your trade, what do I do?"
          key="11"
        >
          <p>
            We do not provide trading recommendations and thus are not liable
            for any monetary loss. We provide real time access to our trades so
            that people can learn how experts trade and that’s for educational
            purpose only. We always tell our members to do their own due
            diligence and manage risk carefully.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Do you have a trading course?"
          key="12"
        >
          <p>
            As of now, we do not offer a trading course. However, we may decide
            to do it if enough people want us to provide a summary of our
            expertise in the form of a video course.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Will I make money from the stock market after becoming a member?"
          key="13"
        >
          <p>
            Unfortunately, there are no guarantees in the stock market and we
            don’t offer it either. The ability to make money will vary from
            individual to individual.
          </p>
        </Panel>
        <Panel
          className="site-collapse-custom-panel"
          header="Is there a refund policy?"
          key="14"
        >
          <p>
            We have a 7-day refund policy, wherein a subscriber can choose to
            cancel their membership for a full refund.
          </p>
        </Panel>
      </Collapse>
      <Spacer />
      {!isUserLoggedIn() && (
        <Row justify="center" className="blogs-brief">
          <Col span="24">
            <h5 className="text-center">
              Got your doubts cleared? Great. Join us today.
            </h5>
          </Col>
          <Col className="text-center" span="24">
            {!isMobileOnly ? (
              <SignUpNavItem
                style={{ marginBottom: '20px' }}
                className="pulse"
                isButton
                onClick={() => handleLinkClick('/register')}
              >
                BECOME A MEMBER NOW
              </SignUpNavItem>
            ) : (
              <div style={{ marginBottom: '10px' }}>
                <SignUpNavItem
                  className="pulse"
                  isButton
                  onClick={() => handleLinkClick('/register')}
                >
                  BECOME A MEMBER NOW
                </SignUpNavItem>
              </div>
            )}
          </Col>
        </Row>
      )}
      <Spacer />
      <Spacer />
      <h6>
        <em>
          Still have doubts? Feel free to
          {' '}
          <a href="mailto:support@thelogicaltraders.com?subject=Query">
            contact us by email
          </a>
          , or drop us a text on WhatsApp by clicking the button on the bottom
          right corner of the page.
        </em>
      </h6>
      <Spacer />
    </Container>
  );
};

export default FAQS;
