import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import HeadingUnderline from '../../components/HeadingUnderline';
import { getEnvValue } from '../../utils/helper';

const Spacer = styled.div`
  height: 20px;
`;

const RefundPolicy = () => {
  const baseUrl = getEnvValue('IMAGE_URL');
  return (
    <>
      <Helmet>
        <title>The Logical Traders offer a 7-day Refund</title>
        <meta
          name="description"
          content="The Logical Traders refund policy, The Logical Traders refund, The Logical Traders review, The Logical Traders money back guarantee,"
        />
      </Helmet>
      <Container>
        <Spacer />
        <HeadingUnderline title="Refund policy" />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="refund" src={`${baseUrl}/refund-badge.png`} height="150px" />
        </div>
        <Spacer />
        <p>
          We offer a full money-back guarantee for all purchases made on our
          website. If you are not satisfied with the product that you have
          purchased from us, you can get the money back no questions asked. You
          are eligible for a full reimbursement within 7 calendar days of your
          purchase.
        </p>
        <p>
          We will not be refunding the convenience fees & GST charged by your
          bank or merchant (in case of EMI or any other mode of payment).
        </p>
        <p>
          If you decide to unlock the locked videos of our free course(s)
          and then ask for a refund, you will be only entitled to 80%
          refund of the amount you have paid while purchasing subscription.
          However, you will still be able to access of free courses
        </p>
        <p>
          After the seven-day period, you will no longer be eligible for a
          refund. We encourage our customers to try our service for the first
          seven days after their purchase to ensure it fits their needs.
        </p>
        <Spacer />
        <h6>
          <em>
            If you have any other queries about our refund policy,
            {' '}
            <a href="mailto:support@thelogicaltraders.com?subject=Query">
              contact us by email
            </a>
            {' '}
            or drop us a text on WhatsApp by clicking the button on the
            bottom-right corner of the screen.
          </em>
        </h6>
        <Spacer />
      </Container>
    </>
  );
};

export default RefundPolicy;
