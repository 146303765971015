import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Upload, Form, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as R from "ramda";
import TextArea from "antd/lib/input/TextArea";
import { uuid } from "uuidv4";
import {checkImageSize ,setFileListBySize} from '../../utils/helper'

const CreateEditModal = (props) => {
  const {
    showEditCreateModal,
    toggleEditModal,
    isEdit,
    handleCreateUpdate,
    createUpdateLoading,
  } = props;

  const [title, setTitle] = useState("");
  const [filesList, setFileList] = useState([]);

  useEffect(() => {
    if (isEdit) {
      setTitle(showEditCreateModal?.title);
      setFileList(
        showEditCreateModal?.images?.map((item) => ({
          thumbUrl: item,
          alreadyUploaded: true,
        }))
      );
    }
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const resetData = () => {
    toggleEditModal(null);
  };

  const handleRemove = async (file) => {
    if (file?.alreadyUploaded) {
      const index = filesList.findIndex(
        (item) => item.thumbUrl === file.thumbUrl
      );
      setFileList(R.remove(index, 1, filesList));
      return false;
    }
    return true;
  };

  const handleData = useCallback(
    (e) => {
      var items = (e.clipboardData || e.originalEvent.clipboardData).items;
      var blob = null;
      var filetype = "";
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
          filetype = items[i].type;
          break;
        }
      }
      // load image if there is a pasted image
      if (blob !== null) {
        if (filesList.length < 4) {
          var reader = new FileReader();
          reader.onload = function (event) {
            fetch(event.target.result)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], "clipboard-image.png", {
                  type: filetype,
                });
                const fileObj = {
                  type: filetype,
                  thumbUrl: URL.createObjectURL(file),
                  uid: uuid(),
                  percent: 100,
                  originFileObj: file,
                  status: "done",
                  name: file.name,
                };
                setFileList([...filesList, fileObj]);
              });
          };
          reader.readAsDataURL(blob);
        } else {
          notification["error"]({
            message: "Clipboard paste error",
            description: "Sorry, maximum image upload limit is 4",
          });
        }
      }
    },
    [filesList]
  );

  return (
    <Modal
      onCancel={() => resetData()}
      visible={showEditCreateModal}
      title={isEdit ? "Edit" : "Create"}
      className='create-post-modal'
      footer={[
        <Button
          loading={createUpdateLoading}
          className='save-post-button'
          onClick={() => handleCreateUpdate({ title, filesList })}
        >
          {isEdit ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Form layout='vertical'>
        <Row>
          <Col span={24}>
            <Form.Item label='Title' required>
              <TextArea
                placeholder='Share your trade/Paste chart image from clipboard'
                allowClear
                value={title}
                onChange={({ target }) => setTitle(target?.value)}
                autoSize={{
                  minRows: 2,
                  maxRows: 4,
                }}
                onPaste={handleData}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Upload Charts' required>
              <Upload
                action=''
                accept='.png,.jpg,.jpeg,image/png,image/jpeg'
                listType='picture-card'
                fileList={filesList}
                // onPreview={handlePreview}
                beforeUpload={(file) => {
                  checkImageSize(file);
                }}
                onChange={({ fileList }) => {
                  setFileListBySize(fileList, setFileList);
                }}
                onRemove={handleRemove}
              >
                {filesList?.length >= 4 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateEditModal;
