import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const PostsContainer = styled.div`
  background-color: #f3f2ef;
  min-height: 500px;
  padding-top: ${({ isMobileOnly }) => (isMobileOnly ? '0px' : '25px')};
  padding-bottom: 20px;
`;

export const MainBox = styled.div`
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  min-height: ${({ isMobile }) => (isMobile ? '200px' : '340px')};
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '230px')};
`;

export const MainBoxFull = styled(MainBox)`
  min-width: 230px;
`;

export const RulesMainBox = styled(MainBox)`
  margin-top: 20px;
`;

export const LeftSidebarProfile = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 12px 12px 16px;
  border-bottom-color: rgba(0, 0, 0, 0.08);
  word-wrap: break-word !important;
  word-break: break-word !important;
`;

export const LeftSidebarProfileHeader = styled.div`
  background: linear-gradient(110deg, #f9ae00 60%, #ffed4b 60%);
  background-position: center;
  background-size: 462px;
  background-position: center;
  background-size: cover;
  display: block;
  height: 54px;
  margin: -12px -12px 0;
`;

export const CenteredDiv = styled.div`
  text-align: center;
`;

export const UserImg = styled.img`
  margin: -38px auto 12px;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  color: #fff;
  box-shadow: none;
  width: 72px;
  height: 72px;
  box-sizing: border-box;
  background-clip: content-box;
`;

export const UserName = styled.div`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
`;

export const UserPlan = styled.p`
  margin-top: 4px !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  line-height: 1.33333;
  font-weight: 400;
`;

export const PostBox = styled(MainBox)`
  padding: 15px;
  max-width: 100%;
  min-height: inherit;
`;

export const PostInputBox = styled.div`
  display: flex;
`;

export const MainBoxParent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const MainBoxParentLeft = styled(MainBoxParent)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

export const RightMainBox = styled(MainBox)`
  min-width: 200px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  padding: 20px 15px;
  min-height: ${({ isMobile }) => (isMobile ? '200px' : '340px')};
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '230px')};
`;

export const RuleList = styled.ul`
  padding-left: 15px;
  li {
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

export const SingleItem = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
  position: relative;
  color: ${({ isActive }) => (isActive ? '#f9ae00' : 'black')};
  &:hover {
    color: #f9ae00;
  }
`;

export const TagListStyle = styled.ul`
  padding-left: 15px;
  display: grid;
  span {
    margin-bottom: 10px;
  }
`;

export const WatchlistStyled = styled(RuleList)`
  padding-left: 0px;
`;

export const PostBoxImg = styled(UserImg)`
  margin: -6px 5px 0px;
  width: 50px;
  height: 50px;
`;

export const PostItemHeader = styled.div`
  padding-right: 40px;
  flex-wrap: nowrap;
  padding: 12px 16px 0;
  margin-bottom: 8px;
  align-items: center;
  display: flex !important;
`;

export const AbsoluteDiv = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute !important;
`;

export const UserProfileImageParent = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
`;

export const UserImage = styled.img`
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  object-position: center;
  background-position: 50%;
  border-radius: 50% !important;
  background-color: white;
  color: white;
  border: none;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  background-clip: content-box;
  outline: 0;
`;

export const UserNameParent = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 8px;
  overflow: hidden;
  position: relative !important;
  display: flex;
`;

export const UserNameBold = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  color: black;
  font-size: 15px;
  line-height: 1.42857;
  font-weight: 600;
  width: 150px;
  margin-bottom: 5px;
`;

export const InvestorType = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 400;
`;

export const ContentContainer = styled.div`
  max-height: none;
  display: block;
  -webkit-line-clamp: initial;
  position: relative;
  max-width: 928px;
  display: block;
  line-height: 2rem !important;
  max-height: 6rem;
  margin: 0 16px;
  overflow: hidden;
`;

export const PostContentDiv = styled.div`
  margin: 0 16px;
`;

export const PostImg = styled.img`
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  border: ${({ showBorder }) => (showBorder ? '2px solid #f9ae00' : 'none')};
  border-radius: ${({ showBorder }) => (showBorder ? '5px' : 'none')};
  &:hover {
    cursor: pointer;
  }
`;

export const CommentsCountContainer = styled.div`
  line-height: 1.33333;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  margin: 0 16px;
  padding: 8px 0;
`;

export const DisclaimerContainer = styled.div`
  line-height: 1.33333;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  margin: 0 16px;
  padding: 8px 0;
  font-style: italic;
  color: dimgrey;
  font-size: 12px;
`;

export const ActionButtonsContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  min-height: 40px;
  padding: 4px 15px;
  flex-wrap: wrap;
`;

export const FiltersDiv = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  word-wrap: break-word !important;
  word-break: break-word !important;
  flex-wrap: wrap;
`;

export const SelectFilterHeading = styled.p`
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const SelectFilterText = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const CommentSections = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
`;

export const PostInactive = styled(CommentSections)`
  justify-content: center;
`;

export const StyledTextAreaDiv = styled.div`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

export const StyledTextArea = styled(TextArea)`
  border-radius: 20px;
`;

export const CommentListBox = styled.div`
  padding: 10px;
`;

export const CommentUserImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
`;

export const CommentParent = styled(UserNameParent)`
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 8px;
  position: relative;
  flex-direction: column;
`;

export const CommentContent = styled.div`
  margin-top: 10px;
`;

export const CommentActionButton = styled.div`
  position: absolute;
  right: 0;
`;

export const AdminProfitableTag = styled.div`
  display: flex;
`;

export const RupeeBagImage = styled.img`
  height: 50px;
  width: 50px;
`;

export const WatchListLink = styled.div`
  color: black;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  &:hover {
    color: #ffed4b;
  }
`;

export const RolesLink = styled.div`
  color: #b8b7ad;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: #ffed4b;
  }
`;

export const FormulaImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileNavbarItem = styled.div`
  display: flex;
  margin-left: 10px;
  margin-bottom: 3px;
`;

export const ShowMoreButton = styled.span`
  cursor: pointer;
  margin-left: 3px;
  text-decoration: underline;
  font-weight: bold;
`;

export const ProfitableTagContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfitableTradersText = styled.span`
  font-size: 11px;
`;

export const AdminTag = styled.div`
  width: 40px;
  height: 20px;
  border-radius: 3px;
  margin-top: -3px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  background-color: rgb(0, 136, 204);
`;
