import { Container } from "react-bootstrap";
import React from "react";
import image1 from "../../assets/value2.svg";
import image2 from "../../assets/value1.svg";
import image3 from "../../assets/offering2.svg";
import styled from "styled-components";
import { Row, Col } from "antd";
import HeadingUnderline from "../HeadingUnderline";

const ContentList = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const ContentListIcon = styled.img`
  height: 70px;
  margin-bottom: 10px;
`;

const ContentListText = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentSubText = styled.span`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentBorderDiv = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 7px -2px #000000;
  background: white;
  border-radius: 8px;
  width: 80%;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const HowYouBenefitLandingFree = () => {
  return (
    <Container>
      <Row justify='center'>
        <Col>
          <HeadingUnderline title='How You Benefit' />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <ContentListIcon src={image1} />
              <ContentListText>Practical Learning</ContentListText>
              <ContentSubText>
                We explain our trades so <br />
                that you can learn to think & act
                <br /> like profitable traders.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <ContentListIcon src={image2} />
              <ContentListText>Unique Insights</ContentListText>
              <ContentSubText>
                You get exclusive information <br />
                into the markets from top-ranked
                <br /> international finance experts.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <ContentListIcon src={image3} />
              <ContentListText>Credible Experts</ContentListText>
              <ContentSubText>
                You learn from experts who have
                <br /> 20+ years of record of success in the <br />
                international & Indian stock market.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
      </Row>
    </Container>
  );
};

export default HowYouBenefitLandingFree;
