import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Divider, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import RupeeBag from '../../assets/rupee-bag.png';
import Oops from '../../assets/oops.jpg';
import { capitalizeText } from '../../utils/helper';
import { HOME } from '../../utils/icons';
import CTAContent from '../../components/LandingPage/CTAContent';
import { Spacer } from '../about';
import gPlay from '../../assets/google-play.png';
import appStore from '../../assets/app-store.png';

export const SocialMediaButton = styled.a`
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  backface-visibility: hidden;
  transition: box-shadow 0.2s ease, border-color 0.2s ease, opacity 0.3s;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  font-size: 0;
  border-radius: 4px;
  opacity: 1;
  margin: 6px;
  background-color: ${({ color }) => color || 'white'};
  &:hover {
    box-shadow: none;
    border-color: transparent;
    background-color: black;
  }
`;

export const SocialMediaIcon = styled.span`
  background: rgba(255, 255, 255, 0.1);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  transition: all 0.2s ease;
  z-index: 2;
`;

export const SocialMediaText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding: 0 10px 0 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  box-sizing: border-box;
  transition: all 0.2s ease;
  backface-visibility: hidden;
  position: relative;
  z-index: 2;
  color: #fff;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  margin: 10px 0px;
  a:first-child {
    margin-left: 0;
  }
`;

const ParentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const RupeeImage = styled.img`
  height: 60px;
  width: 60px;
  margin-bottom: 10px;
`;

const OopsImage = styled(RupeeImage)`
  width: 250px;
`;

export const GreyText = styled.h4`
  color: grey;
  text-align: center;
  margin: 10px 0px 15px 0px;
`;

const ContentContainer = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 350px;
  }
`;

export const AppIconsImage = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const AppIconsContainer = styled.div`
  display: flex;
  width: 230px;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const IconDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ThankYou = () => {
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    history.push('/posts');
  }, []);

  const user = useSelector((state) => state.common.user);
  const paymentData = user?.payment ?? null;
  return (
    <Container>
      <ParentContainer>
        {user?.name ? (
          <ContentContainer>
            <RupeeImage src={RupeeBag} />
            <h4 style={{ textAlign: 'center' }}>
              {`Congrats, You're a member${
                user?.name ? `, ${capitalizeText(user?.name)}!` : ''
              }`}
            </h4>
            <Row justify="center">
              <GreyText>
                Download our mobile app using the following links and get access
                to The Logical Traders Hub (Only available on mobile).
              </GreyText>
            </Row>
            <Row>
              <AppIconsContainer>
                <a
                  href="https://play.google.com/store/apps/details?id=com.tltapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconDiv>
                    <p style={{ fontWeight: 'bold' }}>Android App</p>
                    <AppIconsImage src={gPlay} />
                  </IconDiv>
                </a>
                <a
                  href="https://apps.apple.com/us/app/the-logical-traders-hub/id6444373526"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconDiv>
                    <p style={{ fontWeight: 'bold' }}>iOS App</p>
                    <AppIconsImage src={appStore} />
                  </IconDiv>
                </a>
              </AppIconsContainer>
            </Row>
            <Row justify="center">
              <h4 style={{ marginTop: '15px' }} className="text-center">
                You can join the community by clicking the button below
              </h4>
              <SocialMediaButton color="darkorchid" onClick={handleLinkClick}>
                <SocialMediaIcon>
                  <HOME />
                </SocialMediaIcon>
                <SocialMediaText>Join Community</SocialMediaText>
              </SocialMediaButton>
            </Row>
            {paymentData?.planType === 'Half-Yearly'
            || paymentData?.planType === 'Yearly' ? (
              <>
                <Divider />
                <h4 className="text-center">
                  To watch courses, you can login on
                  {' '}
                  <a
                    href="https://course.thelogicaltraders.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://course.thelogicaltraders.com
                  </a>
                  {' '}
                  or you can download our course app using the following links
                </h4>
                <Row>
                  <AppIconsContainer>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.tltcourseapp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconDiv>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          Android Course App
                        </p>
                        <AppIconsImage src={gPlay} />
                      </IconDiv>
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/the-logical-traders-course/id1663487740"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconDiv>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          iOS Course App
                        </p>
                        <AppIconsImage src={appStore} />
                      </IconDiv>
                    </a>
                  </AppIconsContainer>
                </Row>
              </>
              ) : (
                ''
              )}
            <Spacer />
          </ContentContainer>
        ) : (
          <ContentContainer>
            <OopsImage src={Oops} />
            <h4 style={{ textAlign: 'center' }}>
              You're not a member of The Logical Traders
            </h4>
            <Row justify="center">
              <CTAContent text="" />
            </Row>
          </ContentContainer>
        )}
      </ParentContainer>
    </Container>
  );
};

export default ThankYou;
