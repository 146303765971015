import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { HOME } from "../../utils/icons";
import {
  SocialMediaButton,
  SocialMediaIcon,
  SocialMediaText,
} from "../thankYou";

const NotFound = () => {
  const history = useHistory();
  const handleLinkClick = useCallback(() => {
    history.push("/");
  }, []);

  return (
    <div id='notfound'>
      <div class='notfound'>
        <div class='notfound-404'>
          <h1>404</h1>
          <h2>Page not found</h2>
          <div>
            <div>
              <SocialMediaButton color='darkorchid' onClick={handleLinkClick}>
                <SocialMediaIcon>
                  <HOME />
                </SocialMediaIcon>
                <SocialMediaText>Go To Home</SocialMediaText>
              </SocialMediaButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
