import React, { useEffect, useState } from 'react';
import { CSVLink, CSVDownload } from 'react-csv';
import moment from 'moment';
import {
  Col, Row, Button, Upload, Input, InputNumber, Radio,
} from 'antd';
import * as XLSX from 'xlsx';
import ReactExport from 'react-export-excel';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'antd/lib/modal/Modal';
import { axiosAuth, getEnvValue } from '../../api';
import UserListModal from './userListModal';
import './dashboard.scss';
import { toast } from 'react-toastify';
import { getPlanDetailsById } from '../../utils/helper';

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalNormalUsers, setTotalNormalUsers] = useState(0);
  const [totalAdminUsers, setTotalAdminUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalDeActiveUsers, setTotalDeActiveUsers] = useState(0);
  const [totalWatchList, setTotalWatchList] = useState(0);
  const [totalReviewPost, setTotalReviewPost] = useState(0);
  const [totalOneToday, setAllToday] = useState(0);
  const [totalAllSevenDay, setTotalSevenDay] = useState(0);
  const [allExpiring, setAllExpiring] = useState(0);
  const [isModalVisible, setShowModal] = useState(false);
  const [isOpen, toggleConverterModal] = useState(false);
  const [modifiedData, setModifiedData] = useState(null);
  const [limiter, setLimiter] = useState('');
  const [username, setName] = useState('');
  const [useremail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [txnId, setTxnId] = useState('');
  const [contactNo, setContact] = useState(null);
  const [amountPaid, setAmountPaid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [planType, setPlanType] = useState(2);
  const history = useHistory();

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const getUsers = () => {
    axiosAuth.get('user/all').then((res) => {
      if (res?.data?.data) {
        setTotalUsers(res.data.data);
        const finalData = res?.data?.data.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
        setTotalNormalUsers(
          finalData.filter((item) => item.userType == 'normal'),
        );
        setTotalAdminUsers(
          finalData.filter((item) => item.userType == 'admin'),
        );
        setTotalActiveUsers(finalData.filter((item) => item.active));
        setTotalDeActiveUsers(finalData.filter((item) => !item.active));
        const today = finalData.filter((item) => {
          if (item?.payment?.length) {
            const days = moment(item.payment[0].endDate).diff(
              new Date(),
              'days',
            );
            if (days === 1) return true;
          }
        });
        setAllToday(today);
        const seven = finalData.filter((item) => {
          if (item?.payment?.length && item?.active) {
            const days = moment(item.payment[0].endDate).diff(
              new Date(),
              'days',
            );
            if (days > 0 && days <= 7) return true;
          }
        });
        setTotalSevenDay(seven);
        const allExpired = finalData.filter((item) => {
          if (item?.payment?.length && item?.active) {
            const days = moment(item.payment[0].endDate).diff(
              new Date(),
              'days',
            );
            if (days < 0) return true;
          }
        });
        setAllExpiring(allExpired);
      }
    });
  };

  const getWatchListData = () => {
    const endpoint = '/watchlist/all';
    axiosAuth.post(endpoint).then((res) => {
      if (res?.data?.data && res?.data?.status) {
        setTotalWatchList(res.data.data);
      }
    });
  };

  const getReviewPost = () => {
    const endpoint = '/post/allPosts';
    axiosAuth.post(endpoint, { verificationStatus: 'pending' }).then((res) => {
      if (res?.data?.data && res?.data?.status) {
        setTotalReviewPost(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getUsers();
    getWatchListData();
    getReviewPost();
  }, []);

  const AnalyticsBox = ({
    color, count, text, onClick,
  }) => (
    <div
      style={{ borderBottom: `3px solid ${color}` }}
      className="analytics-box box-shadow-effect"
      onClick={onClick || null}
    >
      <div className="analytics-details-container">
        <span className="analytics-details-heading">{text}</span>
        <span className="analytics-details-count">
          <strong>{count}</strong>
        </span>
      </div>
    </div>
  );

  const openPage = (path) => {
    history.push(path);
  };

  const openModalHandler = (type) => {
    setShowModal(type);
  };

  const handleExcelUpload = (data) => {
    const { file } = data;
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
    /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const finalData = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      const modifiedGAData = finalData.map((item) => {
        const userString = item[0];
        const userDataArray = userString?.split(' - ');
        return {
          name: userDataArray[1],
          email: `${userDataArray[0]}@${userDataArray[3]}`,
          contact: userDataArray[2],
        };
      });
      setModifiedData(modifiedGAData);
    };
    reader.readAsBinaryString(file?.originFileObj);
  };

  const paymentData = getPlanDetailsById(planType);
  const paymentDetails = {
    amount: paymentData.amount,
    months: paymentData.months,
    planType: paymentData.planType,
  };

  const handleCreateClick = () => {
    if (useremail && username && password && contactNo && txnId && planType && amountPaid) {
      setLoading(true);
      const endDate = new Date();
      if (paymentDetails.months == 6) {
        endDate.setMonth(new Date().getMonth() + 6);
      } else if (paymentDetails.months == 12) {
        endDate.setMonth(new Date().getMonth() + 12);
      } else if (paymentDetails.months == 24) {
        endDate.setMonth(new Date().getMonth() + 24);
      }
      const signUpPayload = {
        name: username,
        email: useremail,
        contactNo,
        password,
        portfolio: '',
        experience: '',
        investorType: '',
        userType: 'normal',
        payment: {
          amount: amountPaid,
          txnId,
          startDate: new Date(),
          endDate,
          planType: paymentDetails.planType,
          orderId: '',
        },
        active: true,
        deviceToken: [],
      };

      axiosAuth.post('auth/signup', signUpPayload).then((res) => {
        if (
          res.data
            && res.data.status
            && res.data.data
            && res.data.data.user
            && res.data.data.token
        ) {
          toast('Registration success!!', { type: toast.TYPE.SUCCESS });
          setLoading(false);
        } else {
          setLoading(false);
          toast(
            res?.data?.message
                || 'Failed to register',
            { type: toast.TYPE.ERROR },
          );
        }
      }).catch(() => {
        toast('Failed to register.',
          { type: toast.TYPE.ERROR });
        setLoading(false);
      });
    }
  };

  const headers = [
    { label: 'Email', key: 'email' },
  ];

  let allListUsers = [];
  if (limiter) {
    let emailFound = false;
    modifiedData.forEach((item) => {
      if (item.email === limiter) {
        emailFound = true;
      }
      if (emailFound) {
        allListUsers.push(item);
      }
    });
  } else {
    allListUsers = modifiedData;
  }

  return (
    <>
      <Modal footer={[]} visible={isOpen} onCancel={() => toggleConverterModal(false)}>
        <Row justify="center">
          <AvatarUploader
            style={{ height: '100px', width: '100px' }}
            listType="picture-card"
            showUploadList={false}
            action=""
            onChange={handleExcelUpload}
          >
            Click here to upload excel
          </AvatarUploader>
        </Row>
        <Row justify="center">
          <input value={limiter} onChange={(e) => setLimiter(e.target.value)} />
          {allListUsers?.length && (
            <>
              <CSVLink data={allListUsers} headers={headers}>
                Download
              </CSVLink>
              <div style={{ width: '100%' }}>
                {allListUsers.map((item) => item.email).join(',')}
              </div>
            </>
          )}
        </Row>
      </Modal>
      <Container style={{ marginTop: '25px' }}>
        <UserListModal
          isModalVisible={Boolean(isModalVisible)}
          resetData={() => setShowModal(false)}
          userList={
            isModalVisible === 'all'
              ? allExpiring
              : isModalVisible === 'seven'
                ? totalAllSevenDay
                : totalOneToday
          }
        />
        <Row>
          <h3>Users Analytics</h3>
        </Row>
        <div className="analytics-data-container">
          <AnalyticsBox
            color="green"
            count={totalUsers?.length || 0}
            text="Total Users"
          />
          <AnalyticsBox
            color="lightBlue"
            count={totalNormalUsers?.length || 0}
            text="Total Normal Users"
          />
          <AnalyticsBox
            color="black"
            count={totalAdminUsers?.length || 0}
            text="Total Admin Users"
          />
          <AnalyticsBox
            color="orange"
            count={totalActiveUsers?.length || 0}
            text="Total Active Users"
          />
          <AnalyticsBox
            color="red"
            count={totalDeActiveUsers?.length || 0}
            text="Total Inactive Users"
          />
        </div>

        <div className="analytics-data-container mt-5">
          <AnalyticsBox
            color="blue"
            count={totalOneToday?.length || 0}
            text={'User\'s Subscription Expiring Today'}
            onClick={() => openModalHandler('today')}
          />
          <AnalyticsBox
            color="yellow"
            count={totalAllSevenDay?.length || 0}
            text={'User\'s Subscription Expiring in 7 days'}
            onClick={() => openModalHandler('seven')}
          />
          <AnalyticsBox
            color="yellow"
            count={allExpiring?.length || 0}
            text="All Expired Subscriptions but activated"
            onClick={() => openModalHandler('all')}
          />
        </div>

        <Row style={{ marginTop: '35px' }}>
          <h3>Community Analytics</h3>
        </Row>

        <div className="analytics-data-container">
          <AnalyticsBox
            onClick={() => openPage('/all-posts-review')}
            color="black"
            count={totalReviewPost?.length || 0}
            text="Total under review post"
          />
          <AnalyticsBox
            color="lightBlue"
            onClick={() => openPage('/watchlist')}
            count={totalWatchList?.length || 0}
            text="Total watch list"
          />
        </div>
      </Container>

      <Container className="mt-25" style={{ marginBottom: '50px' }}>
        <Row style={{ marginTop: '35px' }}>
          <h3>Admin Pages Links</h3>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage('/all-users')}>
              All Users
            </Button>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage('/all-queries')}>
              All Queries
            </Button>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage('/all-leads')}>
              All Leads
            </Button>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Button type="primary" onClick={() => openPage('/all-subscribers')}>
              All Subscribers
            </Button>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Button type="primary" onClick={() => toggleConverterModal(true)}>
              Excel converter
            </Button>
          </Col>
          <Col sm={8} md={8} lg={8} />
        </Row>
      </Container>

      <Container className="mt-25" style={{ marginBottom: '50px' }}>
        <Row style={{ marginTop: '35px' }}>
          <h3>Create User</h3>
        </Row>
        <Row style={{ marginTop: '35px' }}>
          <Col span={24}>
            <p>Name</p>
            <Input style={{ width: '200px', marginBottom: '10px' }} value={username} onChange={(e) => setName(e.target.value)} />
          </Col>
          <Col span={24}>
            <p>Email</p>
            <Input style={{ width: '200px', marginBottom: '10px' }} value={useremail} onChange={(e) => setEmail(e.target.value)} />
          </Col>
          <Col span={24}>
            <p>Password</p>
            <Input style={{ width: '200px', marginBottom: '10px' }} value={password} onChange={(e) => setPassword(e.target.value)} />
          </Col>
          <Col span={24}>
            <p>Contact</p>
            <InputNumber style={{ width: '200px', marginBottom: '10px' }} value={contactNo} onChange={(value) => setContact(value)} />
          </Col>
          <Col span={24}>
            <p>Plan</p>
            <Radio.Group onChange={(e) => setPlanType(e.target.value)} value={planType}>
              <Radio value={1}>Six</Radio>
              <Radio value={2}>Annual</Radio>
              <Radio value={3}>2 Years</Radio>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <p>Amount Paid</p>
            <InputNumber style={{ width: '200px', marginBottom: '10px' }} value={amountPaid} onChange={(value) => setAmountPaid(value)} />
          </Col>
          <Col span={24}>
            <p>Txn ID</p>
            <Input style={{ width: '200px', marginBottom: '10px' }} value={txnId} onChange={(e) => setTxnId(e.target.value)} />
          </Col>
        </Row>
        <Row>
          <Button loading={loading} disabled={loading} type="primary" onClick={handleCreateClick}>
            Create
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;

const AvatarUploader = styled(Upload)`
  .ant-upload {
    width: 128px;
    height: 128px;
  }`;
