import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Collapse, Row, Button, notification, Col } from "antd";
import * as R from "ramda";
import { ExtraActions, GapDiv, PostImg } from "../watchList/styled";
import { useSelector } from "react-redux";
import Lightbox from "react-image-lightbox";
import { axiosAuth } from "../../api";
import {
  CaretRightOutlined,
  DeleteOutlined,
  MenuOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import AddEditWatchList from "../watchList/addEditWatchList";
import { AsyncS3Upload } from "../../utils/s3Uploader";
import { LoadingNotificationSkeleton } from "../posts/LoadingSkeleton";
import { Container } from "react-bootstrap";
import { PostsContainer } from "../posts/styled";
import GoBack from "../../components/GoBack";
import BottomTabNavigator from "../../components/BottomTabNavigator";
import logo1 from "../../assets/logo1.svg";
import HamburgerMenuLinks from "../../components/HamburgerMenuLink";

const WatchList = () => {
  const { Panel } = Collapse;
  const user = useSelector((state) => state.common.user);
  const { title, notificationId } = useParams();
  const [showEditCreateModal, setShowEditCreateModal] = useState(null);
  const [activeKey, changeKey] = useState(title);
  const [watchListData, setWatchListData] = useState(null);
  const [loading, setLoading] = useState(title);
  const [isEdit, setIsEdit] = useState(false);
  const [createUpdateLoading, setCreateUpdateLoading] = useState(false);
  const [lightboxImage, setLightBoxImage] = useState("");
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [isMenuOpen, toggleHamburger] = useState(false);

  const history = useHistory();

  const handleFaqChange = (key) => {
    changeKey(key);
  };

  useEffect(() => {
    setLoading(true);
    const endpoint = `/watchlist/byId/${title}`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setWatchListData(res?.data?.data);
        } else {
          notification["error"]({
            message: "Error",
            description: "Failed to load watch list. Please try again later!!",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    setIsScroll(true);
    if (notificationId) {
      axiosAuth.post("/notification/markAsRead", {
        notificationIds: [notificationId],
      });
    }
  }, []);

  const getExtraActions = (item) => {
    if (user?.userType === "admin") {
      return (
        <ExtraActions>
          <EditOutlined
            onClick={(event) => {
              event.stopPropagation();
              setIsEdit(true);
              setShowEditCreateModal(item);
            }}
          />
          <DeleteOutlined
            onClick={(event) => {
              event.stopPropagation();
              deleteHandler(item);
            }}
          />
        </ExtraActions>
      );
    }
  };

  const deleteHandler = (data) => {
    if (window.confirm("Are you sure want to delete this watch list?")) {
      axiosAuth
        .delete(`watchlist/delete/${data._id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification["success"]({
              message: "Success",
              description: "Watch List deleted successfully!!",
            });
            history.push("/watchlist");
          }
        })
        .catch(() => {
          notification["error"]({
            message: "Error",
            description: "Failed to delete Watch List. Please try again!!",
          });
        });
    }
  };

  const handleCreateUpdate = (data) => {
    const { title, filesList } = data;
    if (!title) {
      notification["error"]({
        message: "Create Watch List Error",
        description: "Please enter title!!",
      });
      return;
    }
    if (!filesList?.length) {
      notification["error"]({
        message: "Create Watch List Error",
        description: "Please upload at least one chart image!!",
      });
      return;
    }
    setCreateUpdateLoading(true);

    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq("alreadyUploaded", true)),
        R.map(R.prop("thumbUrl"))
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      const payload = {
        images: alreadyUploadedImages.concat(images),
        title,
        _id: showEditCreateModal._id,
      };

      const apiProvider = !isEdit ? axiosAuth.post : axiosAuth.patch;
      apiProvider(isEdit ? "watchlist/update" : "watchlist/create", payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (isEdit) {
              notification["success"]({
                message: "Success",
                description: "Watch List updated successfully!!",
              });
              setWatchListData(res?.data?.data);
            } else {
              if (user?.userType == "admin") {
                setWatchListData(R.insert(0, res?.data?.data, watchListData));
              }
              notification["success"]({
                message: "Success",
                description: "Watch List created successfully!!",
              });
            }
          } else {
            notification["error"]({
              message: "Error",
              description: "Failed to post. Please try again later!!",
            });
          }
          setCreateUpdateLoading(false);
          setShowEditCreateModal(false);
          setIsEdit(false);
        })
        .catch((err) => {
          setCreateUpdateLoading(false);
        });
    });
  };

  const getImagesComp = (images) => {
    if (images?.length == 1) {
      return (
        <Row className='mb-10'>
          <Col span={24}>
            <PostImg
              onClick={() => {
                setLightBoxImage(images);
                toggleLightBox(true);
              }}
              src={images[0]}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className='mb-10'>
          {images.map((item) => (
            <Col span={12}>
              <div style={{ padding: "15px", height: "100%" }}>
                <PostImg
                  onClick={() => {
                    setLightBoxImage(images);
                    toggleLightBox(true);
                  }}
                  showBorder
                  src={item}
                />
              </div>
            </Col>
          ))}
        </Row>
      );
    }
  };

  return (
    <PostsContainer isMobileOnly={isMobile} className='posts'>
      <HamburgerMenuLinks
        isMenuOpen={isMenuOpen}
        toggleMenu={(value) => toggleHamburger(value)}
      />
      <Container>
        {isMobile && (
          <div className='menu-btn-heading-parent'>
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: "25px",
                position: "absolute",
                zIndex: 1000,
              }}
            />
            <div className='posts-heading text-center'>
              <img
                onClick={() => history.push("/")}
                alt=''
                src={logo1}
                className='d-inline-block align-top brand-image'
              />
            </div>
          </div>
        )}
        {isLightBoxVisible && (
          <Lightbox
            mainSrc={lightboxImage[photoIndex]}
            nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length]}
            prevSrc={
              lightboxImage[
                (photoIndex + lightboxImage.length - 1) % lightboxImage.length
              ]
            }
            onCloseRequest={() => toggleLightBox(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + lightboxImage.length - 1) % lightboxImage.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightboxImage.length)
            }
            onCloseRequest={() => toggleLightBox(false)}
          />
        )}

        {showEditCreateModal && (
          <AddEditWatchList
            isEdit={isEdit}
            showEditCreateModal={showEditCreateModal}
            toggleEditModal={(data) => {
              setShowEditCreateModal(data);
              setIsEdit(false);
            }}
            handleCreateUpdate={handleCreateUpdate}
            createUpdateLoading={createUpdateLoading}
          />
        )}
        <Row>
          <Col className='mb-10 mt-10'>
            <GoBack path='/watchlist' />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Collapse
              className='site-collapse-custom-collapse-gray'
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              activeKey={activeKey}
              onChange={handleFaqChange}
            >
              {loading ? (
                <LoadingNotificationSkeleton />
              ) : (
                watchListData && (
                  <Fragment>
                    <Panel
                      className='site-collapse-custom-panel-watchlist no-margin-bottom'
                      header={watchListData?.title}
                      key={watchListData?._id}
                      extra={getExtraActions(watchListData)}
                    >
                      <div className='mt-10'>
                        {getImagesComp(watchListData?.images)}
                      </div>
                    </Panel>
                    <Divider />
                    <GapDiv />
                  </Fragment>
                )
              )}
            </Collapse>
            {!watchListData && (
              <Row className='mb-20' justify='center'>
                <p>There is no list corresponding this ID</p>
              </Row>
            )}
          </Col>
        </Row>
        {isMobile && <BottomTabNavigator />}
      </Container>
    </PostsContainer>
  );
};

export default WatchList;
