import styled from "styled-components";

export const WatchContainer = styled.div`
  min-height: 500px;
  margin: 25px 50px;
  @media (max-width: 768px) {
    margin: 25px 20px;
  }
`;

export const ExtraActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40px;
`;

export const PostImg = styled.img`
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 350px;
  background-position: 50%;
  background-size: cover;
  border: ${({ showBorder }) => (showBorder ? "2px solid #f9ae00" : "none")};
  border-radius: ${({ showBorder }) => (showBorder ? "5px" : "none")};
  &:hover {
    cursor: pointer;
  }
`;

export const GapDiv = styled.div`
  min-height: 20px;
  background: #f3f2ef;
`;
