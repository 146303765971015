import React, { useState, useCallback } from 'react';
import { slide as Hamburger } from 'react-burger-menu';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout, setModalType } from '../../actions/common';
import {
  CapitalizedText,
  MobileNavbarItem,
  SignUpNavItem,
  CommunityButton,
} from '../../styledComponents';
import { logoutUserHandler } from '../../utils/helper';

const HamburgerMenu = ({ logoutUser }) => {
  const tokenIsExist = useSelector((state) => state.common.authToken);
  const [isMenuOpen, toggleMenu] = useState(false);
  const user = useSelector((state) => state.common.user);
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    toggleMenu(false);
    history.push(path);
  }, []);

  const handleLogoutClick = () => {
    logoutUserHandler(logoutUser, history);
  };

  const handlePostCheck = (path) => {
    handleLinkClick(user?.agree ? path : '/community-terms-and-conditions');
  };

  return (
    <Hamburger
      onOpen={() => toggleMenu(true)}
      onClose={() => toggleMenu(false)}
      isOpen={isMenuOpen}
    >
      {tokenIsExist && (
        <CapitalizedText style={{ marginBottom: '20px' }}>
          <strong>{user?.name || ''}</strong>
        </CapitalizedText>
      )}
      {tokenIsExist && (
        <CommunityButton isButton onClick={() => handlePostCheck('/posts')}>
          Community
        </CommunityButton>
      )}
      {user?.userType === 'admin' && (
        <MobileNavbarItem
          onClick={() => handleLinkClick('/dashboard')}
          className="mt-10"
        >
          Dashboard
        </MobileNavbarItem>
      )}
      {tokenIsExist && (
        <MobileNavbarItem
          className="mt-10"
          onClick={() => handleLinkClick('/profile')}
        >
          My Profile
        </MobileNavbarItem>
      )}
      <MobileNavbarItem onClick={() => handleLinkClick('/')}>
        Home
      </MobileNavbarItem>
      {/* <MobileNavbarItem onClick={() => handleLinkClick('/about-us')}>
        About Us
      </MobileNavbarItem> */}
      {/* <MobileNavbarItem onClick={() => handleLinkClick('/blogs')}>
        Blogs
      </MobileNavbarItem> */}
      {/* {!tokenIsExist && (
        <MobileNavbarItem onClick={() => handleLinkClick('/pricing-plans')}>
          Pricing
        </MobileNavbarItem>
      )} */}
      {/* <MobileNavbarItem onClick={() => handleLinkClick('/our-performance')}>
        Our Performance
      </MobileNavbarItem> */}
      <MobileNavbarItem onClick={() => handleLinkClick('/contact-us')}>
        Contact Us
      </MobileNavbarItem>
      {tokenIsExist ? (
        <MobileNavbarItem onClick={() => handleLogoutClick()}>
          Logout
        </MobileNavbarItem>
      ) : (
        <>
          <MobileNavbarItem onClick={() => handleLinkClick('/login')}>
            Login
          </MobileNavbarItem>
          <br />
          <SignUpNavItem isButton onClick={() => handleLinkClick('/register')}>
            Become a Memeber Now
          </SignUpNavItem>
        </>
      )}
    </Hamburger>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setModalType: (type) => dispatch(setModalType(type)),
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(HamburgerMenu);
