import { Row, Col, Modal } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Tele1 from "../../assets/telegram(1).svg";
import Tele2 from "../../assets/telegram(2).svg";
import Tele3 from "../../assets/telegram(3).svg";
import { SignUpNavItem } from "../../styledComponents";
import {
  ValueItemParent,
  ValueItem,
  ValueItemImage,
  ValueItemHeading,
  PText,
} from "../../routes/about";
import { CloseCircleOutlined } from "@ant-design/icons";

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MainContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Chapter1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const MainHeading = styled.span`
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #0c0e0f;
`;

const SubHeading = styled.span`
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: #000000;
  margin: 35px 0 15px 0;
`;

const SubHeading2 = styled.span`
  margin
  font-size: 24px;
  text-align: center;
  margin: 35px 0 15px 0;
`;

export const JoinTelegramButton = styled(SignUpNavItem)`
  background: ${({ theme }) => theme.backgrounds.telegram};
  border: ${({ isButton, theme }) =>
    isButton && `1px solid ${theme.backgrounds.telegram}`};
`;

const OurValues = () => {
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <ValueItemParent>
            <ValueItem showWhite>
              <ValueItemImage src={Tele1} />
              <ValueItemHeading>Practical Learning</ValueItemHeading>
              <PText>
                Trading is mostly about psychology & emotions, something we
                believe you can’t learn from courses or tips.
              </PText>
            </ValueItem>
          </ValueItemParent>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ValueItemParent>
            <ValueItem showWhite>
              <ValueItemImage src={Tele2} />
              <ValueItemHeading>Unique Insights</ValueItemHeading>
              <PText>
                You get insights into the markets from top-ranked international
                finance experts.
              </PText>
            </ValueItem>
          </ValueItemParent>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ValueItemParent>
            <ValueItem showWhite>
              <ValueItemImage src={Tele3} />
              <ValueItemHeading>Credible Experts</ValueItemHeading>
              <PText>
                You learn from professionals who are profitable & trusted by
                thousands of investors worldwide.
              </PText>
            </ValueItem>
          </ValueItemParent>
        </Col>
      </Row>
    </div>
  );
};

const TelegramSection = () => {
  const [isVisible, toggleModal] = useState(false);
  const handleToggleModal = () => {
    toggleModal(!isVisible);
  };

  return (
    <Container>
      <Modal
        visible={isVisible}
        onCancel={handleToggleModal}
        footer={null}
        className='telegram-popup'
      >
        <CloseCircleOutlined
          className='close-icon'
          onClick={handleToggleModal}
        />
        <Row justify='center'>
          <h4>Telegram Channel Details</h4>
        </Row>
        <hr style={{ marginBottom: "25px" }} />
        <Row justify='center'>
          <b>Channel Link:&nbsp;&nbsp;</b>
          <a target='_blank' href='https://t.me/TheLogicalTradersFree'>
            t.me/TheLogicalTradersFree (Click To Join)
          </a>
        </Row>
        <Row justify='center'>
          <b>Channel Name:&nbsp;&nbsp;</b>
          TheLogicalTradersFree
        </Row>
        <Row justify='center' style={{ marginTop: "25px" }}>
          <p>
            <b>Note:&nbsp;&nbsp;</b>
            <span style={{ color: "red" }}>
              The above channel link may not work for Jio users. Please search
              the channel name{" "}
              <span style={{ color: "#0088cc", fontWeight: "bold" }}>
                "TheLogicalTradersFree"
              </span>{" "}
              on Telegram and join manually.
            </span>
          </p>
        </Row>
      </Modal>
      <Row justify='center' className='blogs-brief'>
        <Col span={24}>
          <FlexDiv>
            <MainContentDiv>
              <Chapter1>
                <MainHeading>
                  Are You Still Wasting Money on Stock Market Courses &
                  Webinars?
                </MainHeading>
                <MainHeading>STOP RIGHT NOW!</MainHeading>

                <SubHeading>JOIN OUR FREE TELEGRAM CHANNEL INSTEAD!</SubHeading>
                <Row justify='center'>
                  <JoinTelegramButton
                    className='pulse-telegram'
                    isButton
                    onClick={() => handleToggleModal()}
                  >
                    JOIN TELEGRAM CHANNEL
                  </JoinTelegramButton>
                </Row>

                <SubHeading2>
                  Here’s what you’ll get on our Telegram Channel that
                  <br /> you won’t get even with ₹20,000 Courses
                </SubHeading2>
                <OurValues />
                <SubHeading2>
                  And the best part is, you get all this for <b>FREE!!</b>
                  <br />
                  Wasting thousands of rupees on courses or seminars doesn’t
                  make sense anymore.
                </SubHeading2>
                <SubHeading>SO JOIN OUR FREE TELEGRAM CHANNEL NOW!</SubHeading>
                <Row justify='center'>
                  <JoinTelegramButton
                    className='pulse-telegram'
                    isButton
                    onClick={() => handleToggleModal()}
                  >
                    JOIN TELEGRAM CHANNEL
                  </JoinTelegramButton>
                </Row>
              </Chapter1>
            </MainContentDiv>
          </FlexDiv>
        </Col>
      </Row>
    </Container>
  );
};

export default TelegramSection;
