import React, { memo } from 'react';
import {
  Col, Divider, Popover, Row,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import defaultUser from '../../assets/defaultUser.jpg';
import {
  PostItemHeader,
  UserProfileImageParent,
  UserImage,
  UserNameParent,
  UserNameBold,
  InvestorType,
  PostContentDiv,
  PostImg,
} from '../posts/styled';
import { capitalizeText, getDateTextFromDate } from '../../utils/helper';
import '../posts/post.scss';

const TradeWisdomItem = ({
  data,
  setLightBoxImage,
  toggleLightBox,
  handleDeletePost,
  setPhotoIndex,
}) => {
  const user = useSelector((state) => state.common.user);
  const getImagesComp = (images) => {
    if (images?.length == 1) {
      return (
        <Row className="mb-10">
          <Col span={24}>
            <PostImg
              onClick={() => {
                setLightBoxImage(images);
                toggleLightBox(true);
                setPhotoIndex(0);
              }}
              src={images[0]}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row className="mb-10">
        {images.map((item, index) => (
          <Col span={12}>
            <div style={{ padding: '15px', height: '100%' }}>
              <PostImg
                onClick={() => {
                  setLightBoxImage(images);
                  toggleLightBox(true);
                  setPhotoIndex(index);
                }}
                showBorder
                src={item}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="post-box">
      <PostItemHeader>
        <UserProfileImageParent>
          <UserImage src={data?.postedBy?.profileImg ?? defaultUser} />
        </UserProfileImageParent>
        <UserNameParent>
          <div>
            <UserNameBold>
              {capitalizeText(data?.postedBy?.name || '')}
            </UserNameBold>
            <InvestorType>{getDateTextFromDate(data?.createdAt)}</InvestorType>
          </div>
        </UserNameParent>

        {user?.userType == 'admin' && (
          <div>
            <Popover
              trigger="click"
              content={(
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <a href="#" onClick={(e) => handleDeletePost(e, data?._id)}>
                    Delete Trade Wisdom
                  </a>
                </div>
              )}
            >
              <MoreOutlined className="more-item-icon" />
            </Popover>
          </div>
        )}
      </PostItemHeader>
      <Divider className="reduced-divider-margin-10" />
      <div>
        <PostContentDiv>{ReactHtmlParser(data?.title ?? '')}</PostContentDiv>
      </div>
      <div className="mt-10">{getImagesComp(data?.images)}</div>
    </div>
  );
};

export default memo(TradeWisdomItem);
