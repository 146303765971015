import React, { Fragment } from 'react';
import {
  Button, Popover, Spin, Tag,
} from 'antd';
import {
  MessageOutlined,
  HeartFilled,
  SendOutlined,
  HeartOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import {
  ActionButtonsContainer,
  StyledTextArea,
  StyledTextAreaDiv,
  UserProfileImageParent,
  UserImage,
  UserNameBold,
  InvestorType,
  CommentSections,
  CommentUserImage,
  CommentParent,
  CommentContent,
  DisclaimerContainer,
  CommentActionButton,
  FormulaImg,
  ShowMoreButton,
  MainBoxParentLeft,
  RightMainBox,
  SelectFilterHeading,
  FiltersDiv,
  SliderContainer,
} from './styled';
import {
  capitalizeText,
  getDateTextFromDate,
  getRandomNumberInRange,
} from '../../utils/helper';
import defaultUser from '../../assets/defaultUser.jpg';
import formula from '../../assets/formula.png';
import { DISCLAIMER_ADMIN, DISCLAIMER_USER } from './constants';

export const getTagsDetails = (data) => {
  const { setVisibility, isVisible } = data;
  return (
    <div
      style={{ display: 'flex', margin: '15px 15px 0 15px', flexFlow: 'wrap' }}
    >
      {data?.targetPrice1 && (
        <Tag className="text-bold tag-item-post">
          Target1: &#8377;
          {data?.targetPrice1}
        </Tag>
      )}
      {data?.targetPrice2 && (
        <Tag className="text-bold tag-item-post">
          Target2: &#8377;
          {data?.targetPrice2}
        </Tag>
      )}
      {data?.stopLoss && (
        <Tag className="text-bold tag-item-post">
          Stop Loss: &#8377;
          {data?.stopLoss}
        </Tag>
      )}
      {data?.risk && (
        <Popover
          trigger={['click', 'hover']}
          onVisibleChange={(visible) => {
            if (!visible) {
              setVisibility(false);
            }
          }}
          content={(
            <div>
              <p>
                Risk is calculated on the total portfolio
                <br />
                & not on individual stock price
              </p>
              <p>
                <b>
                  Formula for calculating the
                  <br />
                  number of shares to buy/sell:-
                </b>
              </p>
              <FormulaImg>
                <img width="250px" height="70px" src={formula} />
              </FormulaImg>
              <br />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setVisibility(true);
                }}
              >
                See Example
              </a>
              {isVisible && (
                <div>
                  {isMobileOnly ? (
                    <>
                      <p>
                        If your capital is Rs 1,00,000.
                        {' '}
                        <br />
                        And you buy a stock worth Rs
                        <br />
                        100, taking a risk of 1% (Rs 1000)
                        <br />
                        and the stop loss is at
                        <br />
                        Rs 95, the number of shares you
                        <br />
                        should buy will be
                        {' '}
                      </p>
                      <p>= (1,00,000 *1%) / (100-95)</p>
                      <p>= (1,000) / (5)</p>
                      <p>= 200 shares</p>
                      <p>
                        200 shares means you will only
                        <br />
                        allocate Rs 20,000 to this
                        <br />
                        trade. If the stop loss at Rs 95
                        <br />
                        {' '}
                        gets hit, you lose only Rs
                        <br />
                        1000 (1% of your portfolio).
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        If your capital is Rs 1,00,000. And you buy a stock
                        worth Rs
                        <br />
                        100, taking a risk of 1% (Rs 1000) and the stop loss is
                        at
                        <br />
                        Rs 95, the number of shares you should buy will be
                        {' '}
                      </p>
                      <p>= (1,00,000 *1%) / (100-95)</p>
                      <p>= (1,000) / (5)</p>
                      <p>= 200 shares</p>
                      <p>
                        200 shares means you will only allocate Rs 20,000 to
                        this
                        <br />
                        trade. If the stop loss at Rs 95 gets hit, you lose only
                        Rs
                        <br />
                        1000 (1% of your portfolio).
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        >
          <Tag className="text-bold tag-item-post" color="#ff0000">
            Risk:
            {' '}
            {`${data?.risk}%`}
          </Tag>
        </Popover>
      )}
    </div>
  );
};

export const getDisclaimerText = (data, lessMore, setLessMore, isAdmin) => {
  const text = isAdmin ? DISCLAIMER_ADMIN : DISCLAIMER_USER;

  return (
    data?.showDisclaimer && (
      <DisclaimerContainer>
        {text.length > 79 ? (
          lessMore ? (
            <div>
              {text}
              <ShowMoreButton onClick={() => setLessMore(false)}>
                See Less
              </ShowMoreButton>
            </div>
          ) : (
            <div>
              {text.substr(0, 79)}
              <ShowMoreButton onClick={() => setLessMore(true)}>
                See Full Disclosure
              </ShowMoreButton>
            </div>
          )
        ) : (
          <div>{text}</div>
        )}
      </DisclaimerContainer>
    )
  );
};

export const getActionButtons = (
  data,
  shouldSpin,
  isLike,
  likeHandler,
  handleCommentClick,
) => (
  <ActionButtonsContainer>
    <div
      className="like-count-container"
      onClick={(e) => {
        likeHandler(data?._id, !isLike);
      }}
    >
      {isLike ? (
        <HeartFilled
          spin={shouldSpin}
          className="like-icon"
          onClick={(e) => {
            e.stopPropagation();
            likeHandler(data?._id, !isLike);
          }}
          style={{ fontSize: '16px' }}
        />
      ) : (
        <HeartOutlined
          spin={shouldSpin}
          className="like-icon"
          onClick={(e) => {
            e.stopPropagation();
            likeHandler(data?._id, !isLike);
          }}
          style={{ fontSize: '16px' }}
        />
      )}
      <span className="ml-1">{data?.likes?.length}</span>
    </div>

    <div
      onClick={() => handleCommentClick(data?._id)}
      className="like-count-container"
    >
      <MessageOutlined
        className="comment-icon"
        onClick={(e) => {
          e.stopPropagation();
          handleCommentClick(data?._id);
        }}
        style={{ fontSize: '16px' }}
      />
      <span className="ml-1">{data?.comments?.length}</span>
    </div>
  </ActionButtonsContainer>
);

export const getParentCommentSection = (
  data,
  setCommentText,
  handleAddComment,
  commentText,
  user,
  isAddingComment,
) => {
  const isLoading = isAddingComment == data?._id;
  return (
    <CommentSections className="create-comment-modal">
      <UserProfileImageParent>
        <UserImage src={user?.profileImg ?? defaultUser} />
      </UserProfileImageParent>
      <StyledTextAreaDiv>
        <StyledTextArea
          placeholder="Please enter your comment"
          disabled={isLoading}
          allowClear
          value={commentText}
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              e.preventDefault();
              handleAddComment(commentText, data?._id, setCommentText);
            }
          }}
          onChange={({ target }) => setCommentText(target?.value)}
          autoSize={{
            minRows: 2,
            maxRows: 4,
          }}
        />
      </StyledTextAreaDiv>
      <Button
        className="comment-button"
        type="text"
        onClick={() => handleAddComment(commentText, data?._id, setCommentText)}
      >
        {isLoading ? (
          <Spin style={{ color: 'black', fontSize: '18px' }} size="small" />
        ) : (
          <SendOutlined style={{ color: 'black', fontSize: '18px' }} />
        )}
      </Button>
    </CommentSections>
  );
};

export const getChildCommentSection = (
  data,
  setReplayCommentText,
  handleAddReplayComment,
  commentReplayText,
  item,
  user,
  isAddingReply,
) => {
  const isLoading = data?._id == isAddingReply?.postId && item._id == isAddingReply?.commentId;
  return (
    <div style={{ marginLeft: '7px' }}>
      <CommentSections className="create-comment-modal">
        <UserProfileImageParent>
          <UserImage src={user?.profileImg ?? defaultUser} />
        </UserProfileImageParent>
        <StyledTextAreaDiv>
          <StyledTextArea
            placeholder="Please enter your reply"
            allowClear
            value={commentReplayText}
            disabled={isAddingReply}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                e.preventDefault();
                handleAddReplayComment(
                  commentReplayText,
                  data?._id,
                  item._id,
                  setReplayCommentText,
                );
              }
            }}
            onChange={({ target }) => {
              setReplayCommentText(target?.value);
            }}
            autoSize={{
              minRows: 2,
              maxRows: 4,
            }}
          />
        </StyledTextAreaDiv>
        <Button
          className="comment-button"
          type="text"
          onClick={() => handleAddReplayComment(
            commentReplayText,
            data?._id,
            item._id,
            setReplayCommentText,
          )}
        >
          {isLoading ? (
            <Spin style={{ color: 'black', fontSize: '18px' }} size="small" />
          ) : (
            <SendOutlined style={{ color: 'black', fontSize: '18px' }} />
          )}
        </Button>
      </CommentSections>
    </div>
  );
};

export const commentBox = (
  user,
  itemRep,
  defaultUser,
  handleDeleteReplayComment,
  data,
  item,
) => (
  <>
    <CommentUserImage src={itemRep?.commentedBy?.profileImg ?? defaultUser} />
    <CommentParent>
      <UserNameBold>
        {capitalizeText(itemRep?.commentedBy?.name)}
      </UserNameBold>
      <InvestorType>{getDateTextFromDate(itemRep?.createdAt)}</InvestorType>
      <CommentContent>{itemRep?.content}</CommentContent>
      {(user?.userType == 'admin'
          || user?._id === itemRep?.commentedBy?._id) && (
          <CommentActionButton>
            <Popover
              trigger="click"
              content={(
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <a
                    href="#"
                    onClick={(e) => handleDeleteReplayComment(e, data, itemRep, item)}
                  >
                    Delete Comment
                  </a>
                </div>
              )}
            >
              <MoreOutlined className="more-item-icon" />
            </Popover>
          </CommentActionButton>
      )}
    </CommentParent>
  </>
);

export const getRulesList = () => (
  <>
    <ul>
      <li>
        No hateful comments, threats, snide remarks etc. to other users.
      </li>
      <li>Keep the discussions limited to the financial markets.</li>
      <li>No spam, misleading, harmful or promotional posts or comments.</li>
      <li>
        No posts or comments about politics, religion or any other sensitive
        topics.
      </li>
      <li>
        If you have any feedback or grievances, please share them with us via
        WhatsApp or Email instead of posting about it in the community.
      </li>
      <li>
        Single-user access: Please note that one premium account is meant to
        be accessed by a single person only. You can access your account
        through multiple devices, but multiple user access is prohibited.
      </li>
      <li>
        No duplication or redistribution of content found in the community
        without taking consent from TheLogicalTraders.com
      </li>
      <li>
        Disagreements and debates are welcome, but please keep them civil.
      </li>
      <li>No targeting or harassment of other users of any kind.</li>
      <li>No doxxing.</li>
    </ul>
    <div>
      In case of breach of community rules, TheLogicalTraders.com reserves the
      right to temporarily or permanently suspend the account of the user(s)
      found violating the guidelines.
    </div>
  </>
);
