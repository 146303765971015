import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { TestimonialsSlider } from '../../styledComponents';
import blackComa from '../../assets/balck-coma.png';
import Logan from '../../assets/Logan.jfif';
import nilay from '../../assets/nilay.jpeg';
import vinodh from '../../assets/vinodh.jpeg';
import devante from '../../assets/Devante.jfif';
import sunny from '../../assets/sunny.jfif';
import udit from '../../assets/udit.jpg';
import sumit from '../../assets/sumit.jpg';
import HeadingUnderline from '../HeadingUnderline';
import { getEnvValue } from '../../utils/helper';

export const NameList = styled.ul`
  list-style: none;
  padding-left: 15px;
  li {
    text-align: left;
  }
  li:first-child {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  li:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #554d56;
  }
`;

export const UserImage = styled.img`
  height: 100px;
  width: 100px !important;
  border: ${({ noBorder }) => (noBorder ? 'none' : '2px solid #f9ae00 !important')};
  border-radius: 50px;
  object-fit: cover;
  @media (max-width: 767px) {
    height: 60px;
    width: 60px !important;
  }
`;

export const CommaImg = styled.img`
  padding-top: 4px;
  height: 20px;
  width: 19px !important;
`;

export const TestimonialText = styled.p`
  margin-bottom: 0;
  text-align: left;
`;

export const Name = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const Position = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  color: #f9ae00;
`;

export const Location = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;
class Testimonials extends Component {
  render() {
    const baseUrl = getEnvValue('IMAGE_URL');
    const { showNoMargin, showGreyBg } = this.props;
    return (
      <Container className={`testimonials-parent ${showGreyBg && 'grey-bg'}`}>
        <Row justify="center" className="blogs-brief">
          <Col>
            <HeadingUnderline title={'We\'ve Helped Investors Worldwide'} />
          </Col>
        </Row>
        <TestimonialsSlider
          className={`testimonial-slider ${
            showNoMargin && 'testimonial-slider-content'
          }`}
        >
          <Carousel
            showThumbs={false}
            showIndicators={false}
            swipeable={false}
            showStatus={false}
            showArrows
            infiniteLoop
            autoPlay={false}
            interval={15000}
            stopOnHover
            useKeyboardArrows
            transitionTime={800}
          >
            <div>
              <Row className={`${showGreyBg && 'grey-bg'}`}>
                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={nilay} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;As someone who has flushed tens of thousands of
                          rupees down the drain on Indian stock market seminars
                          and courses, I can say with 100% confidence that I
                          have never acquired so much knowledge anywhere else.
                          And as an added bonus, I followed one of their trades
                          and recovered the subscription fees in a matter of
                          hours.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Nilay Sahu,</Name>
                          <Position>
                            &nbsp;Senior Manager - Programs, Ex Tata Motors
                            Limited
                          </Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Pune, India</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={`${baseUrl}/vikram.jpeg`} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;Even though I have been in the finance field for
                          decades, I have benefited from their analysis and
                          would definitely recommend their services to people.
                          Since I invest in both Indian and overseas markets, I
                          benefit greatly from their fantastic work.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Vijay Vikram Singh,</Name>
                          <Position>
                            &nbsp;Ex- Vice President at Wells Fargo Securities
                          </Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Mumbai, India</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={Logan} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;I got extremely lucky when I stumbled upon
                          Ayush’s article on why the market is headed for a
                          crash in 2020. The article made a lot of sense to me
                          and I had a conversation with him on email. I then
                          decided to reposition my portfolio bracing for a
                          crash. Then a few days later, the markets fell off a
                          cliff. I’ve been a keen follower ever since.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Logan Fuqua,</Name>
                          <Position>
                            &nbsp;Associate Engineer, Kirby Building Systems
                          </Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Kentucky, U.S.A.</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div>
              <Row className={`${showGreyBg && 'grey-bg'}`}>
                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={udit} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;This is one of the few stock market education
                          platforms where the experts are transparent about
                          their investing track record, and that gives me
                          confidence in their knowledge.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Udit Pillai,</Name>
                          <Position>
                            &nbsp;Senior Research Engineer, Synopsis
                          </Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Khandwa, M.P</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={sunny} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;I have learnt a lot by reading their commentary
                          on the stock market and economics. The information
                          they provide is invaluable.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Sunny Pavelka,</Name>
                          <Position>&nbsp;Banker</Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Budapest, Hungary</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={vinodh} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;They offer a totally unique service. Their focus
                          on international markets gives me many ideas that I
                          then apply to my trading. For sure, I have benefited
                          from their services and would definitely subscribe
                          time & again.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Vinodh Kanna RJ,</Name>
                          <Position>
                            &nbsp;Senior Software Engineer, DHL IT Services
                          </Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Chennai, India</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div>
              <Row className={`${showGreyBg && 'grey-bg'}`}>
                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={sumit} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;After looking at how they trade, I now
                          understand why I was struggling as a trader.
                          Personally, I feel like they have delivered on their
                          promise of shortening the learning curve.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Sumit Chauhan,</Name>
                          <Position>&nbsp;Fitness Instructor</Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>Delhi, India</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {/* <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && "padding-20-all"
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={""} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;This is definitely the best value-for-money
                          stock market educational service that I’ve found in
                          India. Although I’m just a beginner, I have been able
                          to make money with the help of The Logical Traders.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Anonymous</Name>
                          <Position>&nbsp;Director</Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>India</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col> */}

                <Col lg={22} md={22} sm={22} xs={22} offset={1}>
                  <Row
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col lg={4} md={6} xs={6}>
                      <UserImage src={devante} />
                    </Col>
                    <Col lg={19} md={17} xs={17}>
                      <div>
                        <TestimonialText>
                          <CommaImg src={blackComa} />
                          &nbsp;I don’t invest in India so I am not a member of
                          The Logical Traders. But as a regular reader of the
                          Motley Fool, I have read and relied on many of Harsh’s
                          articles to make investments. Those investments have
                          been extremely lucrative for me and I would recommend
                          everyone to follow his work.
                        </TestimonialText>
                        <TestimonialText>
                          <Name>Devante Hollowel,</Name>
                          <Position>&nbsp;Student</Position>
                        </TestimonialText>
                        <TestimonialText>
                          <Location>North Carolina, U.S.A</Location>
                        </TestimonialText>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Carousel>
        </TestimonialsSlider>
      </Container>
    );
  }
}

export default Testimonials;
