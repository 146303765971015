import React, { memo, useCallback } from 'react';
import './notification.scss';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GiWisdom, AiOutlineEye, MdLocalPostOffice } from 'react-icons/all';
import { PostContentDiv } from '../posts/styled';
import { getDateTextFromDate } from '../../utils/helper';

const NotificationBox = ({ data }) => {
  const history = useHistory();
  const handleClick = useCallback(() => {
    const { data: notificationData } = data;
    if (notificationData?.type === 'post') {
      history.push(`/posts/${notificationData?.id}`);
    }

    if (notificationData?.type === 'tradewisdom') {
      history.push(`/trade-wisdom/${notificationData?.id}`);
    }

    if (notificationData?.type === 'watchlist') {
      history.push(`/watchlist/${notificationData?.id}`);
    }
  }, [data]);

  const getIconFromType = () => {
    const { data: notificationData } = data;
    if (notificationData?.type === 'post') {
      return <MdLocalPostOffice style={{ fontSize: '20px' }} />;
    }
    if (notificationData?.type === 'tradewisdom') {
      return <GiWisdom style={{ fontSize: '20px' }} />;
    }
    if (notificationData?.type === 'watchlist') {
      return <AiOutlineEye style={{ fontSize: '20px' }} />;
    }
    return <MdLocalPostOffice style={{ fontSize: '20px' }} />;
  };

  return (
    <div
      aria-hidden
      className={`${!data?.read ? 'unread-notification' : ''} notification-box`}
      onClick={handleClick}
    >
      <NotificationContainer>
        <ContentContainer>
          <div>
            <NotificationContentDiv>
              {getIconFromType()}
              &nbsp;
              <strong>{data?.title ?? ''}</strong>
            </NotificationContentDiv>
          </div>
          <div className="mt-10">
            <NotificationContentDiv>{data?.body ?? ''}</NotificationContentDiv>
          </div>
        </ContentContainer>
        <TimeContainer>{getDateTextFromDate(data?.createdAt)}</TimeContainer>
      </NotificationContainer>

    </div>
  );
};

export default memo(NotificationBox);

const NotificationContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 8;
`;

const TimeContainer = styled.div`
  flex: 2;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  padding-top: 5px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const NotificationContentDiv = styled(PostContentDiv)`
  display: flex;
  align-items: center;
`;
