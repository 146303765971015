import { Col, Row } from "antd";
import React, { Component } from "react";
import HeadingUnderline from "../HeadingUnderline";
import { Container } from "react-bootstrap";
import InfoImage from "../../assets/Info_image.svg";
import InfoResponsiveImage from "../../assets/responsive_info.svg";
import { isMobileOnly } from "react-device-detect";

class HowItWorks extends Component {
  render() {
    return (
      <Container>
        <Row justify='center' className='blogs-brief'>
          <Col>
            <HeadingUnderline title='How It Works' />
          </Col>
        </Row>
        <Row justify='center' className='blogs-brief'>
          {isMobileOnly ? (
            <img width='100%' src={InfoResponsiveImage} />
          ) : (
            <img width='100%' src={InfoImage} className='how-it-works' />
          )}
        </Row>
      </Container>
    );
  }
}

export default HowItWorks;
