import React from "react";
import { Col, Modal, Row } from "antd";

const UserListModal = (props) => {
  const { isModalVisible, resetData, userList } = props;
  return (
    <Modal
      onCancel={() => resetData()}
      visible={isModalVisible}
      title="User List"
      className="create-post-modal"
      footer={null}
    >
      {userList?.length === 0 && <div>There is no user exist</div>}
      {userList?.length > 0 && (
        <Row>
          {userList.map((item) => {
            return <Col span={24}>{item.name}</Col>;
          })}
        </Row>
      )}
    </Modal>
  );
};

export default UserListModal;
