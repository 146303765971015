import Modal from 'antd/lib/modal/Modal';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Row, Button } from 'antd';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { axiosAuth } from '../../api';
import { isUserLoggedIn } from '../../utils/helper';
import newsletter from '../../assets/newsletter.png';

const ExitIntentModal = () => {
  const [email, setEmail] = useState(null);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [showIntentPopup, toggleIntentModal] = useState(false);

  const setModalData = (forceDisplay) => {
    const IsModalAlreadyDisplayed = localStorage.getItem('modalDisplayed');
    const neverShowSubscribe = localStorage.getItem('neverShowSubscribe');
    if (
      (forceDisplay
        || !IsModalAlreadyDisplayed
        || IsModalAlreadyDisplayed == 'false')
      && pathname === '/' && !neverShowSubscribe
    ) {
      toggleIntentModal(true);
      localStorage.setItem(
        'modalDisplayExpiryTimestamp',
        +moment().add(1, 'days'),
      );
      localStorage.setItem('modalDisplayed', true);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      let mouseY = 0;
      window.addEventListener('load', () => {
        window.addEventListener('mousemove', (event) => {
          mouseY = event.clientY;
        });

        document.addEventListener('mouseleave', () => {
          if (mouseY < 100 && !isUserLoggedIn()) {
            const lastModalDisplayedTime = localStorage.getItem(
              'modalDisplayExpiryTimestamp',
            );
            if (lastModalDisplayedTime) {
              if (Number(lastModalDisplayedTime) < Date.now()) {
                setModalData(true);
              }
            } else {
              setModalData(false);
            }
          }
        });
      });
    }
  }, []);

  const handleCancel = () => {
    toggleIntentModal(false);
  };

  const handleClick = () => {
    const payload = {
      email,
    };
    setLoading(true);
    axiosAuth
      .post('newsletter/subscribe', payload)
      .then((res) => {
        if (res?.data?.data) {
          toast(res?.data?.message, {
            type: toast.TYPE.SUCCESS,
          });
          localStorage.setItem('neverShowSubscribe', 'true');
          setEmail(null);
          toggleIntentModal(false);
        } else {
          toast('Failed to subscribe. Please try again!!', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Failed to subscribe. Please try again!!', {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <Modal
      className="exit-intent-popup"
      visible={showIntentPopup}
      onCancel={handleCancel}
    >
      <CloseCircleOutlined className="close-icon" style={{ fontSize: '16px' }} onClick={handleCancel} />
      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      >
        <NewsletterImageParent />
        <NewsletterImage src={newsletter} />
        <MainHeading>
          WE PREDICTED THE 2020 CRISIS
          <br />
          BUT 2022 COULD BE
          <br />
          MUCH WORSE
        </MainHeading>
        <SubHeading>
          If you want to know about the upcoming
          <br />
          crisis & how we plan to trade around it,
          <br />
          subscribe to our FREE Newsletter!
        </SubHeading>
        <Row justify="center">
          <div style={{ display: 'flex' }}>
            <Form.Group>
              <Form.Control
                value={email || ''}
                name="email"
                className="newletter-input"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
          </div>
        </Row>
        <Row>
          <Button
            disabled={!email || loading}
            onClick={handleClick}
            loading={loading}
            className="subscribe-btn"
            size="large"
          >
            SUBSCRIBE
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default ExitIntentModal;

const NewsletterImageParent = styled.div`
  width: 100%;
  height: 100px;
  background: red;
  position: relative;
  border-radius: 5px;
`;

const NewsletterImage = styled.img`
  width: 200px;
  margin-top: -100px;
  z-index: 1;
  margin-bottom: 20px;
  margin-left: 30px;
`;

const MainHeading = styled.h5`
  font-weight: bold;
  text-align: center;
`;

const SubHeading = styled.h6`
  text-align: center;
  color: red;
`;
