import {
  Card, Col, Row, Button, Modal, Upload, Spin, Divider,
} from 'antd';
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { Container, Form } from 'react-bootstrap';
import moment from 'moment';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { axiosAuth } from '../../api';
import {
  getPortfolioValue,
  getInvestorType,
  getExperienceValue,
  numberWithCommas,
  getEnvValue,
  checkImageSize,
  setFileListBySize,
  generateRandomId,
} from '../../utils/helper';
import S3Upload from '../../utils/s3Uploader';
import { SET_USER_DATA_PATCH } from '../../actions/common';
import { COURSE_PLAN_DETAILS } from '../../constants';
import { Spacer } from '../about';
import {
  AppIconsContainer, AppIconsImage, GreyText, IconDiv, SocialMediaButton, SocialMediaIcon, SocialMediaText,
} from '../thankYou';
import { HOME } from '../../utils/icons';
import gPlay from '../../assets/google-play.png';
import appStore from '../../assets/app-store.png';

const MyProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [showCourseModal, toggleCourseModal] = useState(false);
  const [showProfileModal, toggleProfileModal] = useState(false);
  const [activatingCourse, setActivatingCourse] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [loadingChangeProfilePic, setLoadingChangeProfilePic] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState({
    oldPassword: null,
    newPassword: null,
  });

  useEffect(() => {
    setLoading(true);
    axiosAuth
      .get('auth')
      .then((res) => {
        if (res?.data) {
          setProfileData(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const validateError = useCallback(
    (name, value) => {
      if (name === 'newPassword' || name === 'oldPassword') {
        let modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        if (name == 'newPassword' && value) {
          if (value === oldPassword) {
            modifiedErrors = {
              ...errors,
              [name]: 2,
            };
          } else {
            const passwordRegex = /^[a-zA-Z1-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,20}$/;
            modifiedErrors = {
              ...errors,
              [name]: passwordRegex.test(value) ? false : 1,
            };
          }
        }
        setErrors(modifiedErrors);
      }
    },
    [newPassword, errors],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === 'oldPassword') {
        setOldPassword(value);
      } else if (name === 'newPassword') {
        setNewPassword(value);
      }
      validateError(name, value);
    },
    [validateError],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [validateError],
  );

  const handleChangePassword = () => {
    setLoadingChangePassword(true);
    axiosAuth
      .post('user/changePassword', {
        oldPassword,
        newPassword,
      })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          toast('Password changed successfully!!', {
            type: toast.TYPE.SUCCESS,
          });
          toggleModal(false);
          setOldPassword('');
          setNewPassword('');
          setErrors({
            oldPassword: null,
            newPassword: null,
          });
        } else {
          toast('Incorrect old password', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoadingChangePassword(false);
      })
      .catch((err) => {
        setLoadingChangePassword(false);
      });
  };

  const uploadPicture = (imageData) => {
    const { key } = imageData;
    axiosAuth
      .patch('user/update', {
        profileImg: `${getEnvValue('CLOUDFRONT_BASE_IMAGE_URL')}${key}` ?? '',
      })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          toast('Profile image changed successfully!!', {
            type: toast.TYPE.SUCCESS,
          });
          dispatch({
            type: SET_USER_DATA_PATCH,
            payload: { profileImg: res?.data?.data?.profileImg ?? '' },
          });
          let userDataSaved = localStorage.getItem('user');
          userDataSaved = userDataSaved ? JSON.parse(userDataSaved) : {};
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...userDataSaved,
              profileImg: res?.data?.data?.profileImg ?? '',
            }),
          );
          toggleProfileModal(false);
          setFileList([]);
        } else {
          toast('Failed to change profile picture. Please try again!!', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoadingChangeProfilePic(false);
      })
      .catch((err) => {
        setLoadingChangeProfilePic(false);
      });
  };

  const handleChangeProfilePic = () => {
    const [{ originFileObj }] = fileList;
    setLoadingChangeProfilePic(true);
    S3Upload(originFileObj, uploadPicture);
  };

  const isSaveDisabled = useMemo(() => {
    if (!oldPassword || !newPassword) {
      return true;
    } if (!errors.oldPassword && !errors.newPassword) {
      return false;
    }
    return true;
  }, [errors, oldPassword, newPassword]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleActivateClick = () => {
    const paymentDetails = R.last(profileData?.payment ?? []);
    if (paymentDetails?.planType === 'Half-yearly') {
      toast('Free course can\'t be activated for six months membership. Please upgrade your membership plan for Free course activation', { type: toast.TYPE.ERROR });
      return;
    }

    setActivatingCourse(true);
    axiosAuth
      .post('learning/user/isUserExists', { email: profileData?.email })
      .then((mainres) => {
        if (mainres?.data?.data) {
          toast('You already have access to the course', {
            type: toast.TYPE.ERROR,
          });
          setActivatingCourse(false);
        } else {
          const randomPassword = generateRandomId(10);
          const signUpPayload = {
            name: profileData?.name ?? '',
            email: profileData?.email ?? '',
            contactNo: profileData?.contactNo ?? '',
            password: randomPassword,
            userType: 'normal',
            profileImg: '',
            active: true,
          };
          axiosAuth.post('learning/auth/signup', signUpPayload).then((res) => {
            if (res?.data?.status && res?.data?.data && res?.data?.data?.token) {
              const purchasedCourseDetails = [{
                amount: COURSE_PLAN_DETAILS.course_amount,
                courseId: COURSE_PLAN_DETAILS._id,
                startDate: moment().format('YYYY/MM/DD'),
                isRefundPeriodOver: false,
                course_name: COURSE_PLAN_DETAILS.course_name,
                slug: COURSE_PLAN_DETAILS.slug,
                txnId: paymentDetails.txnId,
                endDate: moment().add(12, 'months'),
                userType: 'subscription',
              }];
              const courseAxiosAuth = axios.create({
                baseURL: getEnvValue('API_BASE_URL'),
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${res?.data?.data?.token}`,
                },
              });
              courseAxiosAuth
                .post('learning/courses/purchaseCourse', purchasedCourseDetails)
                .then((innerRes) => {
                  if (innerRes?.data?.status) {
                    axiosAuth
                      .post('user/addCourseDetails', {
                        username: profileData?.email,
                        password: randomPassword,
                      }).then((userRes) => {
                        setProfileData(userRes?.data?.data);
                        toast('Course activated successfully!!', { type: toast.TYPE.SUCCESS });
                        setActivatingCourse(false);
                      }).catch(() => {
                        setActivatingCourse(false);
                        toast('Failed to activate course. Please contact admin for further support!!', { type: toast.TYPE.ERROR });
                      });
                  }
                }).catch(() => {
                  setActivatingCourse(false);
                  toast('Failed to activate course. Please contact admin for further support!!', { type: toast.TYPE.ERROR });
                });
            } else {
              setActivatingCourse(false);
              toast('Failed to activate course. Please contact admin for further support!!', { type: toast.TYPE.ERROR });
            }
          }).catch(() => {
            setActivatingCourse(false);
            toast('Failed to activate course. Please contact admin for further support!!', { type: toast.TYPE.ERROR });
          });
        }
      }).catch(() => {
        toast('Failed to activate course. Please contact admin for further support!!', { type: toast.TYPE.ERROR });
        setActivatingCourse(false);
      });
  };

  const handleLinkClick = () => {
    history.push('/posts');
  };

  const paymentDetails = R.last(profileData?.payment ?? []);
  return (
    <Container style={{ marginTop: '50px' }}>
      <Modal
        visible={showCourseModal}
        onCancel={() => toggleCourseModal(false)}
        footer={[]}
      >
        <Card title="Course Details" style={{ marginTop: '20px' }}>
          <Row>
            To watch our course, login to this website
            &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://tinyurl.com/yckr9t6t"
            >
              https://tinyurl.com/yckr9t6t
            </a>
            &nbsp;
            with below credentials.
          </Row>
          <Row>
            In case, the password doesn't work, please click on forgot password
            there and reset your password.
          </Row>
          <Spacer />
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <h6>Username</h6>
              <p>{profileData?.addCourseDetails?.username}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <h6>Default Password</h6>
              <p>{profileData?.addCourseDetails?.password}</p>
            </Col>
          </Row>

        </Card>
      </Modal>
      <Modal
        visible={showModal}
        onCancel={() => {
          setOldPassword('');
          setNewPassword('');
          setErrors({ oldPassword: null, newPassword: null });
          toggleModal(false);
        }}
        maskClosable={false}
        footer={[
          <Button
            type="primary"
            onClick={handleChangePassword}
            loading={loadingChangePassword}
            disabled={isSaveDisabled}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <Form.Group>
            <Form.Label className="required">Old Password</Form.Label>
            <Form.Control
              value={oldPassword}
              name="oldPassword"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Enter old password"
              isInvalid={errors.oldPassword}
            />
            <Form.Control.Feedback type="invalid">
              Old password is required!!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">New Password</Form.Label>
            <Form.Control
              value={newPassword}
              name="newPassword"
              className="custom-field-focus"
              onBlur={handleBlur}
              onChange={handleInputChange}
              type="password"
              placeholder="Enter new password"
              isInvalid={errors.newPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.newPassword === true
                ? 'New password is required!!'
                : errors.newPassword === 1
                  ? 'Please use a strong password'
                  : 'Old password & new password cannot be same'}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Modal>
      <Modal
        title="Change Profile Picture"
        visible={showProfileModal}
        onCancel={() => {
          setFileList([]);
          toggleProfileModal(false);
        }}
        maskClosable={false}
        footer={[
          <Button
            type="primary"
            onClick={handleChangeProfilePic}
            loading={loadingChangeProfilePic}
            disabled={!fileList?.length}
          >
            Save
          </Button>,
        ]}
      >
        <div className="custom-upload-box">
          <ImgCrop grid quality="0.8">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={onPreview}
              beforeUpload={(file) => checkImageSize(file)}
              onChange={({ fileList }) => {
                setFileListBySize(fileList, setFileList);
              }}
            >
              {fileList.length == 0 && (
                <p>
                  Click to Choose
                  <br />
                  or
                  <br />
                  {' '}
                  Drag Image
                </p>
              )}
            </Upload>
          </ImgCrop>
        </div>
      </Modal>
      {loading ? <Row justify="center"><Spin style={{ color: 'red' }} /></Row>
        : (
          <>
            <Card title="My Profile">
              <Row style={{ marginBottom: '30px' }}>
                <Button
                  style={{ marginRight: '10px', marginBottom: '5px' }}
                  type="primary"
                  onClick={() => toggleProfileModal(true)}
                >
                  Change Profile Picture
                </Button>
                ,
                <Button style={{ marginRight: '10px', marginBottom: '5px' }} type="primary" onClick={() => toggleModal(true)}>
                  Change Password
                </Button>
                ,
                {/* {!profileData?.addCourseDetails?._id && (
                  <Button loading={activatingCourse} disabled={activatingCourse} type="primary" onClick={handleActivateClick}>
                    Activate Free Course
                  </Button>
                )} */}
                {profileData?.addCourseDetails?._id && (
                  <Button type="primary" onClick={() => toggleCourseModal(true)}>
                    Show Course Details
                  </Button>
                )}
              </Row>
              <Row>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Name</h6>
                  <p>{profileData?.name}</p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Email</h6>
                  <p>{profileData?.email}</p>
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Active Plan</h6>
                  <p>{paymentDetails?.planType || 'N/A'}</p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Amount Paid (INR)</h6>
                  <p>{numberWithCommas(paymentDetails?.amount || 0)}</p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Start Date</h6>
                  <p>
                    {paymentDetails?.startDate
                      ? moment(paymentDetails?.startDate).format('DD-MM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>End Date</h6>
                  <p>
                    {paymentDetails?.endDate
                      ? moment(paymentDetails?.endDate).format('DD-MM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Transaction Id</h6>
                  <p>{paymentDetails?.txnId || 'N/A'}</p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <h6>Plan expiry in </h6>
                  <p>
                    {paymentDetails?.endDate
                      ? `${moment
                        .utc(paymentDetails?.endDate)
                        .diff(moment.utc(), 'days')} Days`
                      : 'N/A'}
                  </p>
                </Col>
              </Row>
            </Card>
            {profileData?.addCourseDetails?._id && (
              <Card title="Course Details" style={{ marginTop: '20px' }}>
                <Row>
                  To watch our course, login to this website
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://tinyurl.com/yckr9t6t"
                  >
                    https://tinyurl.com/yckr9t6t
                  </a>
                  &nbsp;
                  with below credentials.
                </Row>
                <Row>
                  In case, the password doesn't work, please click on
                  forgot password there and reset your password.
                </Row>
                <Spacer />
                <Row>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <h6>Username</h6>
                    <p>{profileData?.addCourseDetails?.username}</p>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <h6>Default Password</h6>
                    <p>{profileData?.addCourseDetails?.password}</p>
                  </Col>
                </Row>

              </Card>
            )}
            {!profileData?.agree && (
            <Card title="Community" style={{ marginTop: '20px' }}>
              <Row>Join the community by clicking the button below</Row>
              <SocialMediaButton color="darkorchid" onClick={handleLinkClick}>
                <SocialMediaIcon>
                  <HOME />
                </SocialMediaIcon>
                <SocialMediaText>Join Community</SocialMediaText>
              </SocialMediaButton>
            </Card>
            )}
            <Card title="Apps Corner" style={{ margin: '20px 0' }}>
              <Row justify="center">
                <GreyText>
                  Download our mobile app using the following links and get access
                  to The Logical Traders Hub (Only available on mobile).
                </GreyText>
              </Row>
              <Row>
                <AppIconsContainer>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tltapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconDiv>
                      <p style={{ fontWeight: 'bold' }}>Android App</p>
                      <AppIconsImage src={gPlay} />
                    </IconDiv>
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/the-logical-traders-hub/id6444373526"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconDiv>
                      <p style={{ fontWeight: 'bold' }}>iOS App</p>
                      <AppIconsImage src={appStore} />
                    </IconDiv>
                  </a>
                </AppIconsContainer>
              </Row>
              {paymentDetails?.planType === 'Half-Yearly'
            || paymentDetails?.planType === 'Yearly' ? (
              <>
                <Divider />
                <h4 className="text-center">
                  To watch courses, you can login on
                  {' '}
                  <a
                    href="https://course.thelogicaltraders.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://course.thelogicaltraders.com
                  </a>
                  {' '}
                  or you can download our course app using the following links
                </h4>
                <Row>
                  <AppIconsContainer>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.tltcourseapp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconDiv>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          Android Course App
                        </p>
                        <AppIconsImage src={gPlay} />
                      </IconDiv>
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/the-logical-traders-course/id1663487740"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconDiv>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          iOS Course App
                        </p>
                        <AppIconsImage src={appStore} />
                      </IconDiv>
                    </a>
                  </AppIconsContainer>
                </Row>
              </>
                ) : (
                  ''
                )}
              <Spacer />
            </Card>
          </>
        )}
    </Container>
  );
};

export default MyProfile;
