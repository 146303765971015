import React, { useMemo, useRef, useState } from 'react';
import {
  Button, Col, Row, Spin,
} from 'antd';
import { Container } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import {
  CardsContainer,
  CardsParent,
  CenteredContainer,
  EMIText,
  FeatureList,
  FeatureListInnerUl,
  FeatureListItem,
  FeatureListItemInnerUlLI,
  PayContainer,
  PlanCard,
  PlanCardContent,
  PlanCardHeader,
  PriceDiv,
  PriceText,
  RealPriceText,
  UndelinedSpan,
} from '../../styledComponents';
import { baseUrl, SafeCheckoutImg, Tick } from '../../components/SignUp';
import tick from '../../assets/tick.svg';
import close from '../../assets/close.svg';
import PayByRazorPay from '../../components/RazorPay';
import { BestValueTag } from '../pricing';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';
import { getPlanDetailsById } from '../../utils/helper';
import { axiosAuth } from '../../api';
import PLAN_PRICES from '../../constants';

const RenewSubscription = () => {
  const childRef = useRef();
  const [activePlan, setActivePlan] = useState(2);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);

  const history = useHistory();

  const handlePlanClick = (planType) => {
    setActivePlan(planType);
  };

  const params = useParams();
  // const decodeDetail = useMemo(() => jwt_decode(params.token), [params]);
  const decodeDetail = {};

  const paymentDetails = useMemo(() => {
    const paymentData = getPlanDetailsById(activePlan);
    return {
      amount: paymentData.amount,
      months: paymentData.months,
      planType: paymentData.planType,
    };
  }, [activePlan]);

  const handleLinkClick = (path) => {
    history.push(path);
  };

  const handlePaymentSuccess = (paymentResponse = {}) => {
    if (paymentResponse.razorpay_payment_id) {
      setTransactionLoading(true);
      const endDate = new Date();
      if (paymentDetails.months == 6) {
        endDate.setMonth(new Date().getMonth() + 6);
      } else if (paymentDetails.months == 12) {
        endDate.setMonth(new Date().getMonth() + 12);
      } else if (paymentDetails.months == 24) {
        endDate.setMonth(new Date().getMonth() + 24);
      }
      const renewPayload = {
        amount: paymentDetails.amount / 100,
        txnId: paymentResponse.razorpay_payment_id,
        startDate: new Date(),
        endDate,
        planType: paymentDetails.planType,
        orderId: paymentResponse.razorpay_order_id,
        token: params.token,
      };

      axiosAuth.post('user/renewSubscription', renewPayload).then((res) => {
        setPaymentLoading(false);
        setTransactionLoading(false);
        if (res?.data?.status) {
          toast('Subscription renewed successfully. Please login to continue', {
            type: toast.TYPE.SUCCESS,
          });
          handleLinkClick('/login');
        } else {
          toast(
            'Failed to renew subscription. Please try again or write to us for further support!!',
            { type: toast.TYPE.ERROR },
          );
        }
      }).catch(() => {
        setPaymentLoading(false);
        setTransactionLoading(false);
        toast(
          'Failed to renew subscription. Please try again or write to us for further support!!',
          { type: toast.TYPE.ERROR },
        );
      });
    }
  };

  return (
    <>
      {transactionLoading && (
      <BlackLoader>
        <Spin
          indicator={(
            <LoadingOutlined
              style={{ fontSize: isMobileOnly ? 30 : 50, color: '#f9ae00', marginBottom: '15px' }}
              spin
            />
          )}
        />
        <TransactionProcess>
          Please wait while your transaction is in process.
          <br />
          Do not refresh your browser or press the back button.
        </TransactionProcess>
      </BlackLoader>
      )}
      <div>
        <Spacer />
        <HeadingUnderline title="Renew Subscription" />
        <CardsParent className="leadform">
          <CardsContainer>
            <Row>
              <Col xs={24} md={8} className="plan-card">
                <PlanCard
                  active={activePlan === 1}
                  onClick={() => {
                    handlePlanClick(1);
                    if (activePlan === 1) {
                      childRef.current.handlePayment();
                    }
                  }}
                >
                  <PlanCardHeader>6 Month Membership</PlanCardHeader>
                  <PlanCardContent>
                    <PriceDiv>
                      <PriceText>
                        {' '}
                        ₹
                        {PLAN_PRICES.six.base}
                      </PriceText>
                    &nbsp;&nbsp;
                      <RealPriceText>
                        {' '}
                        ₹
                        {PLAN_PRICES.six.discount}
                      </RealPriceText>
                    </PriceDiv>
                    <EMIText>
                    &nbsp;
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          Upcoming "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(1)}
                        className="get-started-btn"
                        size="large"
                      >
                        {activePlan === 1 ? (
                          'Go'
                        ) : (
                          'Select'
                        )}
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
              <Col xs={24} md={8} className="plan-card">
                <PlanCard
                  active={activePlan === 2}
                  onClick={() => {
                    handlePlanClick(2);
                    if (activePlan === 2) {
                      childRef.current.handlePayment();
                    }
                  }}
                >
                  <PlanCardHeader>12 Month Membership</PlanCardHeader>
                  <BestValueTag>Most Popular</BestValueTag>
                  <PlanCardContent>
                    <PriceDiv>
                      <PriceText>
                        {' '}
                        ₹
                        {PLAN_PRICES.twelve.base}
                      </PriceText>
                    &nbsp;&nbsp;
                      <RealPriceText>
                        ₹
                        {PLAN_PRICES.twelve.discount}
                      </RealPriceText>
                    </PriceDiv>
                    <EMIText>
                      6 months no cost EMI at ₹
                      {PLAN_PRICES.twelve.emi}
                      /month
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Profitable Trading Masterclass"
                          <br />
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Upcoming "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(2)}
                        className="get-started-btn"
                        size="large"
                      >
                        {activePlan === 2 ? (
                          'Go'
                        ) : (
                          'Select'
                        )}
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
              <Col xs={24} md={8} className="plan-card">
                <PlanCard
                  active={activePlan === 3}
                  exclusive
                  onClick={() => {
                    handlePlanClick(3);
                    if (activePlan === 3) {
                      childRef.current.handlePayment();
                    }
                  }}
                >
                  <PlanCardHeader>24 Month Membership</PlanCardHeader>
                  <PlanCardContent>
                    <PriceDiv>
                      <PriceText>
                        ₹
                        {PLAN_PRICES.twentyFour.base}
                      </PriceText>
                    &nbsp;&nbsp;
                      <RealPriceText>
                        ₹
                        {PLAN_PRICES.twentyFour.discount}
                      </RealPriceText>
                    </PriceDiv>
                    <EMIText>
                      6 months no cost EMI at ₹
                      {PLAN_PRICES.twentyFour.emi}
                      /month
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our monthly newsletter
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Upcoming "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(3)}
                        className="get-started-btn"
                        size="large"
                      >
                        {activePlan === 3 ? (
                          'Go'
                        ) : (
                          'Select'
                        )}
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
            </Row>

            <PayContainer>
              <PayByRazorPay
                ref={childRef}
                description={`${paymentDetails.planType} Plan`}
                amount={paymentDetails.amount}
                handlePaymentSuccess={handlePaymentSuccess}
                name={decodeDetail?.name ?? ''}
                contact={decodeDetail?.contactNo ?? ''}
                email={decodeDetail?.email ?? ''}
                isDisabled={!params?.token}
                paymentLoading={paymentLoading}
                setPaymentLoading={setPaymentLoading}
              />
              <Row
                justify="center"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <Link to="/login">Take me to Login</Link>
              </Row>
              <Row>
                <Container>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '15px',
                    }}
                  >
                    <SafeCheckoutImg src={`${baseUrl}/gsc.png`} />
                  </div>
                </Container>
              </Row>
            </PayContainer>
          </CardsContainer>
        </CardsParent>
      </div>
    </>

  );
};

export default RenewSubscription;

const BlackLoader = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TransactionProcess = styled.h5`
  color: white;
  text-align: center;
`;
