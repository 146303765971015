import { Button, Col, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import {
  PricingCardsContainer,
  CardsParent,
  CenteredContainer,
  FeatureList,
  FeatureListItem,
  PlanCard,
  PlanCardContent,
  PlanCardHeader,
  RealPriceText,
  PriceText,
  FeatureListItemInnerUlLI,
  FeatureListInnerUl,
  UndelinedSpan,
  PriceDiv,
  EMIText,
} from '../../styledComponents';
import tick from '../../assets/tick.svg';
import close from '../../assets/close.svg';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';
import Testimonials from '../../components/Testimonials';
import DiscountCards from '../../components/DiscountCards';
import LandingFAQS from '../../components/LandingPage/LandingFaqs';
import { getEnvValue } from '../../utils/helper';
import { SafeCheckoutImg } from '../../components/SignUp';
import PLAN_PRICES from '../../constants';

export const BestValueTag = styled.div`
  right: -11px;
  font-size: 16px;
  background: #f9ae00;
  padding: 4px 16px;
  line-height: 22px;
  top: 0px;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  font-weight: 700;
  color: black;
  border-radius: 0px 4px 0 4px;
  &:after {
    content: "";
    position: absolute;
    top: 30px;
    right: 0;
    border: 5px solid transparent;
    border-left: 5px solid #f9ae00 !important;
    border-top: 5px solid #f9ae00 !important;
  }
`;

const Tick = styled.img`
  height: 14px;
  width: inherit !important;
  margin-right: 5px;
  margin-bottom: 3px;
`;

const Pricing = () => {
  const baseUrl = getEnvValue('IMAGE_URL');
  const history = useHistory();
  const tokenIsExist = useSelector((state) => state.common.authToken);

  useEffect(() => {
    if (tokenIsExist) {
      history.push('/');
    }
  }, []);

  const handlePlanClick = useCallback((id) => {
    history.push(`/register?plan_selected=${id}`);
  }, []);

  const handleBuyCourseClick = () => {
    window.location.href = 'https://course.thelogicaltraders.com/';
  };

  return (
    <>
      <Helmet>
        <title>Paid membership plans of The Logical Traders</title>
        <meta
          name="description"
          content="How much does it cost to become a member, Becoming a member of The Logical Traders,
          What do The Logical Traders subscribers get, The Logical Traders free, The Logical Traders login, The Logical Traders members, The Logical Traders free trial, how to become a member of The Logical Traders, The Logical Traders discount, The Logical Traders coupon code, The Logical Traders free membership, The Logical Traders offer, The Logical Traders discount offer"
        />
      </Helmet>
      <Spacer />
      <HeadingUnderline title="Pricing Plans" />
      <TypeContainer>
        <TypeHeading small>
          Course
        </TypeHeading>
        <CourseText>
          India’s Only Trading Course Made by Experts
          Who Show Their Long-Term Performance
        </CourseText>
        <CenteredContainer>
          <Button
            onClick={handleBuyCourseClick}
            className="get-started-btn"
            size="large"
          >
            Buy Course
          </Button>
        </CenteredContainer>
      </TypeContainer>
      <Spacer />
      <TypeContainer>
        <TypeHeading>
          Subscription
        </TypeHeading>
        <DiscountCards handlePlanClick={handlePlanClick} />
        <Spacer />
        <CardsParent className="leadform">
          <PricingCardsContainer>
            <Row>
              <Col xs={24} sm={12} md={12} lg={8} className="plan-card">
                <PlanCard>
                  <PlanCardHeader>6 Month Membership</PlanCardHeader>
                  <PlanCardContent>
                    <PriceDiv>
                      <PriceText>
                        ₹
                        {PLAN_PRICES.six.base}
                      </PriceText>
                  &nbsp;
                  &nbsp;
                      <RealPriceText>
                        ₹
                        {PLAN_PRICES.six.discount}
                      </RealPriceText>
                    </PriceDiv>
                    <EMIText>&nbsp;</EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          Upcoming "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(1)}
                        className="get-started-btn"
                        size="large"
                      >
                        Get Started
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} className="plan-card">
                <PlanCard exclusive onClick={() => handlePlanClick(3)}>
                  <PlanCardHeader>12 Month Membership</PlanCardHeader>
                  <BestValueTag>Most Popular</BestValueTag>
                  <PlanCardContent>
                    <PriceDiv>
                      <PriceText>
                        ₹
                        {PLAN_PRICES.twelve.base}
                      </PriceText>
                  &nbsp;
                  &nbsp;
                      <RealPriceText>
                        ₹
                        {PLAN_PRICES.twelve.discount}
                      </RealPriceText>
                    </PriceDiv>
                    <EMIText>
                      6 months no cost EMI at ₹
                      {PLAN_PRICES.twelve.emi}
                      /month
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Profitable Trading Masterclass"
                          <br />
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Upcoming "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(3)}
                        className="get-started-btn"
                        size="large"
                      >
                        Get Started
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} className="plan-card">
                <PlanCard exclusive onClick={() => handlePlanClick(3)}>
                  <PlanCardHeader>24 Month Membership</PlanCardHeader>
                  <PlanCardContent>
                    <PriceDiv>
                      <PriceText>
                        ₹
                        {PLAN_PRICES.twentyFour.base}
                      </PriceText>
                    &nbsp;
                    &nbsp;
                      <RealPriceText>
                        ₹
                        {PLAN_PRICES.twentyFour.discount}
                      </RealPriceText>
                    </PriceDiv>
                    <EMIText>
                      6 months no cost EMI at ₹
                      {PLAN_PRICES.twentyFour.emi}
                      /month
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our monthly newsletter
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Upcoming "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(3)}
                        className="get-started-btn"
                        size="large"
                      >
                        Get Started
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
            </Row>
          </PricingCardsContainer>
        </CardsParent>
      </TypeContainer>
      <Row>
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SafeCheckoutImg src={`${baseUrl}/gsc.png`} />
            {/* <p>
              To know more about our refund policy,{" "}
              <Link to='/refund-policy'>click here</Link>
            </p> */}
          </div>
        </Container>
      </Row>
      <Row className="testimonials-brief-bg grey-bg">
        <Col span={24}>
          <Testimonials showNoMargin showGreyBg />
        </Col>
      </Row>

      <div className="white-bg padding-30">
        <LandingFAQS />
      </div>

      <Row className="testimonials-brief-bg grey-bg">
        <Container>
          <p style={{ textAlign: 'center' }}>
            <strong>
              If you have any queries, you can check our
              {' '}
              <Link to="/faqs">FAQs</Link>
              .
              <br />
              Still have doubts? Feel free to
              {' '}
              <Link to="/contact-us">contact us by email</Link>
              , or drop us a
              text on WhatsApp by clicking the icon on the bottom right corner
              of the page.
            </strong>
          </p>
        </Container>
      </Row>
    </>
  );
};

export default Pricing;

const TypeContainer = styled.div`
  border: 5px solid #f9ae00;
  margin: ${isMobile ? '10px 20px' : '10px 50px'};
  border-radius: 10px;
  padding: 50px 0;
`;

const TypeHeading = styled.div`
  margin-top: -73px;
  position: absolute;
  display: flex;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  background: white;
  width: ${({ small }) => (small ? '130px' : '180px')};
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const CourseText = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;
