import ReactGA from 'react-ga';
import moment from 'moment';
import { toast } from 'react-toastify';
import { axiosAuth } from '../api';

export const getEnvValue = (name) => {
  const value = process.env[`REACT_APP_${name}`];
  return value || '';
};

export const getPlanDetailsById = (id) => {
  let planDetails = {};
  switch (id) {
    case 1:
      planDetails = {
        amount: 919900,
        planType: 'Quarterly',
        months: 3,
      };
      break;
    case 2:
      planDetails = {
        amount: 1799900,
        planType: 'Half-Yearly',
        months: 6,
      };
      break;
    case 3:
      planDetails = {
        amount: 3499900,
        planType: 'Yearly',
        months: 12,
      };
      break;
    default:
      return planDetails;
  }
  return planDetails;
};

export const isUserLoggedIn = () => {
  const token = localStorage.getItem('authToken');
  return !!token;
};

export const makeURLPattern = (str) => str.replace(/\s+/g, '-').toLowerCase();

export const getPortfolioValue = (type) => {
  let data = 'N/A';
  if (type == '1') {
    data = 'Under 100000';
  } else if (type == '2') {
    data = '100000 - 250000';
  } else if (type == '3') {
    data = '250000 - 500000';
  } else if (type == '4') {
    data = '500000 - 1000000';
  } else if (type == '5') {
    data = 'Above 1000000';
  }
  return data;
};

export const getInvestorType = (type) => {
  let data = 'N/A';
  if (type == '1') {
    data = 'Short Term';
  } else if (type == '2') {
    data = 'Long Term';
  } else if (type == '3') {
    data = 'Intraday';
  }
  return data;
};

export const getExperienceValue = (type) => {
  let data = 'N/A';
  if (type == '1') {
    data = 'Less than 1 year';
  } else if (type == '2') {
    data = '1 - 3 years';
  } else if (type == '3') {
    data = '3 - 5 years';
  } else if (type == '4') {
    data = '5 - 10 years';
  } else if (type == '5') {
    data = 'More than 10 years';
  }
  return data;
};

export const numberWithCommas = (x) => {
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
};

export const capitalizeText = (text = '') => text.charAt(0).toUpperCase() + text.slice(1);

export const checkIsLocalhost = () => (
  window.location.hostname === 'localhost'
    || window.location.hostname === '127.0.0.1'
    || window.location.hostname === ''
);

export const triggerGAEvent = (data) => {
  ReactGA.event(data);
};

export const handleBecomeMemberTrackingClick = () => {
  const data = {
    category: 'Links',
    action: 'Become a member website',
    label: 'Clicked on become a member button',
    value: 1,
  };
  triggerGAEvent(data);
};

export const handleVideoPlayTracking = (type) => {
  const data = {
    category: 'Video',
    action: `Played video ${type}`,
    label: `Played video ${type}`,
    value: type,
  };
  triggerGAEvent(data);
};

export const getDateTextFromDate = (date = '') => {
  const m = moment(date.split('T')[0], 'YYYY-MM-DD');
  m.set({
    h: 1, m: 1, s: 1, ms: 1,
  });
  const differenceDays = moment.utc().diff(m, 'days');
  if (differenceDays == 0) {
    return 'Today';
  } if (differenceDays == 1) {
    return 'Yesterday';
  } if (differenceDays == 2) {
    return '2d ago';
  } if (differenceDays == 3) {
    return '3d ago';
  } if (differenceDays == 4) {
    return '4d ago';
  } if (differenceDays == 5) {
    return '5d ago';
  } if (differenceDays == 6) {
    return '6d ago';
  } if (differenceDays == 7) {
    return '7d ago';
  } if (differenceDays > 7 && differenceDays <= 14) {
    return '1 week ago';
  } if (differenceDays > 14 && differenceDays <= 21) {
    return '2 weeks ago';
  } if (differenceDays > 21 && differenceDays <= 28) {
    return '3 weeks ago';
  }
  return m.format('DD MMM YYYY');
};

export const getRandomNumberInRange = (min, max) => Math.floor(Math.random() * (max - min) + min);

export const logoutUserHandler = async (logoutUser, history) => {
  axiosAuth.get('auth/logout');
  logoutUser();
  setTimeout(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    history.push('/');
  }, 150);
};

export const checkImageSize = (file) => {
  const size = file.size / (1024 * 1024);
  if (size >= 2) {
    toast('Image size should be less than 2 MB.', {
      type: toast.TYPE.ERROR,
    });
    return false;
  }
  return true;
};

export const setFileListBySize = (fileList, setFileList) => {
  fileList.forEach((item, index) => {
    const size = item.size / (1024 * 1024);
    if (size >= 2) {
      fileList.splice(index, 1);
    }
  });
  setFileList(fileList);
};

export const isUserDetailsUpdate = (oldData, newData) => {
  const oldPayment = JSON.stringify(oldData.payment);
  const newPayment = newData?.payment?.length && JSON.stringify(newData.payment[0]);
  if (
    oldData?.active === newData?.active
    && oldData?.agree === newData?.agree
    && oldData?.email === newData?.email
    && oldData?.experience === newData?.experience
    && oldData?.investorType === newData?.investorType
    && oldData?.isProfitable === newData?.isProfitable
    && oldData?.name === newData?.name
    && oldData?.portfolio === newData?.portfolio
    && oldData?.profileImg === newData?.profileImg
    && oldData?.userType === newData?.userType
    && oldData?._id === newData?._id
    && oldPayment === newPayment
  ) {
    return true;
  }
  return false;
};

export const generateRandomId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random()
* charactersLength));
  }
  return result;
};
