import { Col, Row } from 'antd';
import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import {
  RiCommunityLine, GiWisdom, AiOutlineEye, TiCalculator,
  IoNotifications,
} from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import HeadingUnderline from '../../components/HeadingUnderline';
import QuantityCalculator from '../../components/QuantityCalculator';
import { Spacer } from '../about';

const UserDashboard = () => {
  const history = useHistory();
  const childRef = useRef();
  return (
    <Container>
      <QuantityCalculator ref={childRef} />
      <Spacer />
      <Row>
        <Col span={24}>
          <HeadingUnderline title="Dashboard" />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Col style={{ display: 'flex', justifyContent: 'center' }} xs={24} sm={6} lg={6}>
          <div
            className="new-box"
            aria-hidden="true"
            onClick={() => history.push('/posts')}
          >
            <div className="box">
              <div className="boxContent">
                <RiCommunityLine style={{ fontSize: '50px' }} />
                <h1 className="title">Community</h1>
              </div>
            </div>
          </div>
        </Col>

        {/* <Col style={{ display: 'flex', justifyContent: 'center' }} xs={24} sm={6} lg={6}>
          <div
            className="new-box"
            aria-hidden="true"
            onClick={() => history.push('/trade-wisdom')}
          >
            <div className="box">
              <div className="boxContent">
                <GiWisdom style={{ fontSize: '50px' }} />
                <h1 className="title">Trade Wisdom</h1>
              </div>
            </div>
          </div>
        </Col> */}

        <Col style={{ display: 'flex', justifyContent: 'center' }} xs={24} sm={6} lg={6}>
          <div
            className="new-box"
            aria-hidden="true"
            onClick={() => history.push('/watchlist')}
          >
            <div className="box">
              <div className="boxContent">
                <AiOutlineEye style={{ fontSize: '50px' }} />
                <h1 className="title">Watchlist</h1>
              </div>
            </div>
          </div>
        </Col>

        <Col style={{ display: 'flex', justifyContent: 'center' }} xs={24} sm={6} lg={6}>
          <div
            aria-hidden="true"
            onClick={() => childRef.current.toggleCalcModal(true)}
            className="new-box"
          >
            <div className="box">
              <div className="boxContent">
                <TiCalculator style={{ fontSize: '50px' }} />
                <h1 className="title">Trade Calculator</h1>
              </div>
            </div>
          </div>
        </Col>

        <Col style={{ display: 'flex', justifyContent: 'center' }} xs={24} sm={6} lg={6}>
          <div
            className="new-box"
            aria-hidden="true"
            onClick={() => history.push('/notifications')}
          >
            <div className="box">
              <div className="boxContent">
                <IoNotifications style={{ fontSize: '50px' }} />
                <h1 className="title">Notification</h1>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDashboard;
