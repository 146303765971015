import { CloseCircleOutlined } from "@ant-design/icons";
import { Row, Modal } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { JoinTelegramButton } from "./TelegramSection";

const TelegramButton = ({ customStyle }) => {
  const [isVisible, toggleModal] = useState(false);
  const handleToggleModal = () => {
    toggleModal(!isVisible);
  };
  return (
    <Container>
      <Modal
        visible={isVisible}
        onCancel={handleToggleModal}
        footer={null}
        className='telegram-popup'
      >
        <CloseCircleOutlined
          className='close-icon'
          onClick={handleToggleModal}
        />
        <Row justify='center'>
          <h4>Telegram Channel Details</h4>
        </Row>
        <hr style={{ marginBottom: "25px" }} />
        <Row justify='center'>
          <b>Channel Link:&nbsp;&nbsp;</b>
          <a target='_blank' href='https://t.me/TheLogicalTradersFree'>
            t.me/TheLogicalTradersFree (Click To Join)
          </a>
        </Row>
        <Row justify='center'>
          <b>Channel Name:&nbsp;&nbsp;</b>
          TheLogicalTradersFree
        </Row>
        <Row justify='center' style={{ marginTop: "25px" }}>
          <p>
            <b>Note:&nbsp;&nbsp;</b>
            <span style={{ color: "red" }}>
              The above channel link may not work for Jio users. Please search
              the channel name{" "}
              <span style={{ color: "#0088cc", fontWeight: "bold" }}>
                "TheLogicalTradersFree"
              </span>{" "}
              on Telegram and join manually.
            </span>
          </p>
        </Row>
      </Modal>

      <Row justify='center' style={{ ...customStyle }}>
        <JoinTelegramButton
          className='pulse-telegram'
          isButton
          onClick={() => handleToggleModal()}
        >
          JOIN TELEGRAM CHANNEL
        </JoinTelegramButton>
      </Row>
    </Container>
  );
};

export default TelegramButton;
