import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'antd';
import { toast } from 'react-toastify';
import Logo1 from '../assets/logo1.svg';
import { checkIsLocalhost, getEnvValue, triggerGAEvent } from '../utils/helper';
import { axiosAuth } from '../api';

const PayByRazorPay = forwardRef((props, ref) => {
  let generatedOrderId = '';
  const {
    description,
    amount,
    handlePaymentSuccess,
    name,
    contact,
    email,
    isDisabled,
    paymentLoading,
    setPaymentLoading,
  } = props;
  const isLocalhost = checkIsLocalhost();
  const emailAddress = email.split('@');
  const options = {
    key: getEnvValue(isLocalhost ? 'RAZORPAY_TEST_KEY' : 'RAZORPAY_PROD_KEY'),
    amount,
    name: 'The Logical Traders',
    description,
    image: Logo1,
    handler(response) {
      const data = {
        category: 'Payment',
        action: 'Payment Success',
        label: `${emailAddress[0] || ''} - ${name || ''} - ${contact || ''} - ${
          emailAddress[1] || ''
        }`,
        value: Number(amount) / 100,
      };
      triggerGAEvent(data);
      const conversionData = {
        category: 'Conversion',
        action: 'Subscription Purchased',
        label: 'Subscription Purchased',
        value: Number(amount) / 100,
      };
      triggerGAEvent(conversionData);
      handlePaymentSuccess({ ...response, generatedOrderId });
    },
    modal: {
      ondismiss() {
        const data = {
          category: 'Payment',
          action: 'Payment Cancelled',
          label: `${emailAddress[0] || ''} - ${name || ''} - ${
            contact || ''
          } - ${emailAddress[1] || ''}`,
          value: Number(amount) / 100,
        };
        triggerGAEvent(data);
        setPaymentLoading(false);
      },
      confirm_close: true,
    },
    prefill: {
      name,
      contact,
      email,
    },
    theme: {
      color: '#F9AE00',
      hide_topbar: false,
    },
  };

  const openPayModal = (orderId) => {
    const rzp1 = new window.Razorpay({ ...options, order_id: orderId || '' });
    rzp1.on('payment.failed', (response) => {
      const data = {
        category: 'Payment',
        action: 'Payment Failed',
        label: `${emailAddress[0] || ''} - ${name || ''} - ${contact || ''} - ${
          emailAddress[1] || ''
        }`,
        value: Number(amount) / 100,
      };
      triggerGAEvent(data);
      generatedOrderId = '';
    });
    rzp1.open();
  };

  const handlePayClick = () => {
    setPaymentLoading(true);
    axiosAuth
      .post('/order/create', {
        amount,
        name,
        email,
      })
      .then((res) => {
        generatedOrderId = res?.data?.data?.orderId;
        openPayModal(res?.data?.data?.orderId);
      })
      .catch((err) => {
        toast('Failed to complete payment process. Please try again', {
          type: toast.TYPE.ERROR,
        });
        const data = {
          category: 'Payment',
          action: 'Payment Failed',
          label: `${emailAddress[0] || ''} - ${name || ''} - ${
            contact || ''
          } - ${emailAddress[1] || ''}`,
          value: Number(amount) / 100,
        };
        triggerGAEvent(data);
        setPaymentLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    handlePayment(orderId) {
      generatedOrderId = orderId;
      openPayModal(orderId);
    },
  }));

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Button
        onClick={handlePayClick}
        disabled={isDisabled || paymentLoading}
        loading={paymentLoading}
        className="signUpBtn"
        style={{ width: '250px', borderRadius: '6px' }}
        size="large"
      >
        Pay
      </Button>
    </>
  );
});

export default PayByRazorPay;
