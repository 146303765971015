import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

const ActionButton = () => {
  const history = useHistory();
  const handleClick = () => history.push('/webinar-registration');

  return (
    <button
      onClick={handleClick}
      type="button"
      className={`button-85 ${isMobile ? 'button-85-mobile' : ''}`}
    >
      GET THE STRATEGY @&#8377;
      <span style={{ textDecoration: 'line-through' }}>2999</span>
      &nbsp; For FREE
    </button>
  );
};

export default ActionButton;
