import React, { useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import HeadingUnderline from '../../components/HeadingUnderline';
import value1 from '../../assets/value1.svg';
import value2 from '../../assets/value2.svg';
import value3 from '../../assets/value3.svg';
import offering1 from '../../assets/offering1.svg';
import offering2 from '../../assets/offering2.svg';
import offering3 from '../../assets/offering3.svg';
import Logo from '../../assets/bull-logo.svg';
import blackComa from '../../assets/balck-coma.png';
import ayush from '../../assets/ayush.png';
import harsh from '../../assets/harsh.png';
import dhairya from '../../assets/dhairya.png';
import {
  CommaImg,
  Location,
  Name,
  Position,
  TestimonialText,
  UserImage,
} from '../../components/Testimonials';

import { SignUpNavItem } from '../../styledComponents';
import { getEnvValue } from '../../utils/helper';

const baseUrl = getEnvValue('IMAGE_URL');

export const ExpertsContent = () => (
  <>
    <Col lg={22} md={22} sm={22} xs={22} offset={1}>
      <Row className="mb-20 slider-box padding-20-all">
        <Col lg={4} md={6} xs={6}>
          <ImageDiv>
            <UserImageModified src={ayush} noBorder />
          </ImageDiv>
        </Col>
        <Col lg={19} md={17} xs={17}>
          <div>
            <TestimonialText>
              <CommaImg src={blackComa} />
              &nbsp; Ayush is the founder of The Logical Traders. He was voted
              the 2nd best financial blogger of Wall Street in 2014 & has
              written over 500 financial articles for international companies.
              He also manages money for several U.S.-based & Indian clients.
              He is the primary trader at The Logical Traders and turned ₹1.3
              Lakh to over ₹24 Lakh In 2020. All the trading decisions of The
              Logical Traders are finalized by him.
            </TestimonialText>
            <TestimonialText>
              <strong>
                <em>Ayush Singh</em>
              </strong>
            </TestimonialText>
            <TestimonialText>
              <AreaHeading>Area of Expertise:&nbsp;</AreaHeading>
              <AreaText>
                Technical Analysis, Short-Selling, Macroeconomics,
                Commodities, and Geopolitics.
              </AreaText>
            </TestimonialText>
          </div>
        </Col>
      </Row>
    </Col>
    <Col lg={22} md={22} sm={22} xs={22} offset={1}>
      <Row className="mb-20 slider-box padding-20-all">
        <Col lg={4} md={6} xs={6}>
          <ImageDiv>
            <UserImageModified src={harsh} noBorder />
          </ImageDiv>
        </Col>
        <Col lg={19} md={17} xs={17}>
          <div>
            <TestimonialText>
              <CommaImg src={blackComa} />
              &nbsp; Harsh is an integral team member of The Logical Traders.
              He has consistently ranked among the top 50 financial bloggers
              of Wall Street and has written over 2,000 articles for
              international finance companies. Many of his international stock
              picks have appreciated over 500% and consequently he has amassed
              a great following worldwide. Harsh is currently employed as a
              contract writer at The Motley Fool, U.S.A.
            </TestimonialText>
            <TestimonialText>
              <strong>
                <em>Harsh Chauhan</em>
              </strong>
            </TestimonialText>
            <TestimonialText>
              <AreaHeading>Area of Expertise:&nbsp;</AreaHeading>
              <AreaText>
                Fundamental analysis, Cryptocurrencies, Macroeconomics, Real
                Estate Investing, and Technology & Automobile Sector.
              </AreaText>
            </TestimonialText>
          </div>
        </Col>
      </Row>
    </Col>

    <Col lg={22} md={22} sm={22} xs={22} offset={1}>
      <Row className="mb-20 slider-box padding-20-all">
        <Col lg={4} md={6} xs={6}>
          <ImageDiv>
            <UserImageModified src={dhairya} noBorder />
          </ImageDiv>
        </Col>
        <Col lg={19} md={17} xs={17}>
          <div>
            <TestimonialText>
              <CommaImg src={blackComa} />
              &nbsp; Dhairya is the accounting and modelling expert at The
              Logical Traders. Dhairya has written over 500 financial articles
              for Seeking Alpha and has consistently ranked among the top 150
              financial bloggers. He is a Chartered Financial Analyst Level III
              candidate and a CA Final candidate. He has enjoyed great
              international success in turnaround investing.
            </TestimonialText>
            <TestimonialText>
              <strong>
                <em>Dhairya Mehta</em>
              </strong>
            </TestimonialText>
            <TestimonialText>
              <AreaHeading>Area of Expertise:&nbsp;</AreaHeading>
              <AreaText>
                Accounting, Financial Modelling, Turnaround Investing,
                Contrarian Investing, and Commodities.
              </AreaText>
            </TestimonialText>
          </div>
        </Col>
      </Row>
    </Col>
  </>
);

export const Spacer = styled.div`
  height: 20px;
`;

export const LogoImage = styled.img`
  width: 280px;
  height: 280px;
  @media (max-width: 1024px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

export const WhatWeDoContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const LogoSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const TextSection = styled(LogoSection)`
  flex: 2;
`;

export const TextData = styled.ul`
  padding-left: 0 !important;
  padding-right: 40px;
  li {
    margin-bottom: 20px;
    color: white;
    list-style-type: none;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  li:first-child {
    color: #f9ae00;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    padding-right: 0;
  }
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const PText = styled.p`
  margin-bottom: 0px;
  text-align: center;
`;

export const ValueItemParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ showWhite }) => showWhite && 'white'};
`;

export const ValueItem = styled.div`
  display: flex;
  background: ${({ showWhite }) => showWhite && 'white'};
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 1px 11px -2px #000000;
  border-radius: 8px;
  width: 300px;
  min-height: 250px;
  @media (max-width: 991px) {
    min-height: 284px;
  }
  @media (max-width: 767px) {
    min-height: 250px;
  }
`;

export const ValueItemImage = styled.img`
  height: 80px;
  margin-bottom: 10px;
`;

export const ValueItemHeading = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: black;
  margin-bottom: 10px;
  white-space: nowrap;
`;

const BGImage = styled.div`
  background-image: url(${baseUrl}/about-us-bg.png);
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  height: 230px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    height: 145;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    margin-bottom: 20px;
  }
  }
  @media (max-width: 767px) {
    height: 130px;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    margin-bottom: 20px;
  }
`;

const ProfitableParent = styled.div`
  display: flex;
  min-height: 300px;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ProfitableText = styled.span`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
`;

const LastProfitableText = styled(ProfitableText)`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const ImageDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const UserImageModified = styled(UserImage)`
  height: 120px;
  width: initial !important;
  @media (max-width: 767px) {
    height: 70px;
  }
`;

const AreaHeading = styled.span`
  color: #f9ae00;
  font-weight: bold;
  font-size: 12px;
`;

const AreaText = styled(AreaHeading)`
  color: black;
  font-weight: bold;
  font-size: 12px;
`;

const WhoWeAre = () => (
  <div className="padding-30">
    <HeadingUnderline title="Who We Are" />
    {isMobile ? (
      <>
        <PText>
          <strong>
            Hello. Welcome to The Logical Traders. We’re glad you’re here.
            {' '}
          </strong>
        </PText>
        <PText>
          Have you ever lost money in the stock market because of
          self-proclaimed gurus? Don’t worry, you’re not alone.
        </PText>
        <PText>
          The gurus want you to buy their expensive stock market courses,
          tips, or secret strategies etc. But the bitter truth is that
        </PText>
        <Spacer />
        <PText>
          <strong>THEY. DON’T. WORK.</strong>
        </PText>
        <Spacer />
        <PText>
          The one thing that these experts have in common is that they never
          disclose their track record. They may show you occasional screen
          shots of profits, but never the long-term performance.
          {' '}
        </PText>
        <Spacer />
        <PText>
          Now ask yourself, why would someone hide their track record if
          they’re profitable? The answer is they wouldn’t unless they were
          losing money.
        </PText>
        <Spacer />
        <PText>
          And now ask yourself, can you really succeed by learning from people
          who aren’t profitable themselves? We highly doubt it.
        </PText>
        <Spacer />
        <PText>
          This is why we decided to start The Logical Traders. We believe you
          deserve to learn from experts who don’t just claim to be experts to
          fleece money from you, but are actually profitable, and transparent
          about their long-term performance.
        </PText>
      </>
    ) : (
      <>
        <PText>
          <strong>
            Hello. Welcome to The Logical Traders. We’re glad you’re here.
            {' '}
          </strong>
        </PText>
        <PText>
          Have you ever lost money in the stock market because of
          self-proclaimed gurus? Don’t worry, you’re not alone.
        </PText>
        <PText>
          The gurus want you to buy their expensive stock market courses,
          tips, or secret strategies etc. But the bitter truth is that
        </PText>
        <Spacer />
        <PText>
          <strong>THEY. DON’T. WORK.</strong>
        </PText>
        <Spacer />
        <PText>
          The one thing that these experts have in common is that they never
          disclose their track record. They may show you occasional
          <br />
          {' '}
          screen shots of profits, but never the long-term performance.
          {' '}
        </PText>
        <Spacer />
        <PText>
          Now ask yourself, why would someone hide their track record if
          they’re profitable? The answer is they wouldn’t unless they were
          <br />
          losing money.
        </PText>
        <Spacer />
        <PText>
          And now ask yourself, can you really succeed by learning from people
          who aren’t profitable themselves? We highly doubt it.
        </PText>
        <Spacer />
        <PText>
          This is why we decided to start The Logical Traders. We believe you
          deserve to learn from experts who don’t just claim to be
          <br />
          {' '}
          experts to fleece money from you, but are actually
          profitable, and transparent about their long-term performance.
        </PText>
      </>
    )}
  </div>
);

const OurValues = () => (
  <div className="padding-30">
    <HeadingUnderline title="Our Values" />
    <Row>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={value1} />
            <ValueItemHeading>Complete Transparency</ValueItemHeading>
            <PText>
              Unlike others, we share our record in the market with you so
              that you know you’re learning from credible experts.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={value2} />
            <ValueItemHeading>Practical Learning</ValueItemHeading>
            <PText>
              Trading is mostly about psychology & emotions, something we
              believe you can’t learn from courses or tips.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={value3} />
            <ValueItemHeading>Providing Value</ValueItemHeading>
            <PText>
              We leverage our expertise to deliver valuable insights to you
              that you won’t find anywhere else.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
    </Row>
  </div>
);

const Offering = () => (
  <div className="padding-30">
    <HeadingUnderline title={'What We\'re Offering You'} />
    <Row>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={offering1} />
            <ValueItemHeading>Affordable Learning</ValueItemHeading>
            <PText>
              We won’t charge you exorbitant amounts of money because our goal
              is not to fleece you.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={offering2} />
            <ValueItemHeading>Credible Experts</ValueItemHeading>
            <PText>
              You learn from professionals who are profitable & trusted by
              thousands of investors worldwide.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={offering3} />
            <ValueItemHeading>Exclusive Content</ValueItemHeading>
            <PText>
              We don’t just draw lines on charts, we give you deep insights
              into the international & Indian market.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
    </Row>
  </div>
);

const Profitable = () => {
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);
  return (
    <Row>
      <Col span={24}>
        <ProfitableParent>
          <ProfitableText>Learn to be a profitable trader now.</ProfitableText>
          <LastProfitableText>
            Don’t delay your financial independence.
            {' '}
          </LastProfitableText>
          <SignUpNavItem
            className={`pulse ${!isMobileOnly && 'show-margin'}`}
            isButton
            style={{ margin: '20px 0px' }}
            onClick={() => handleLinkClick('/register')}
          >
            BECOME A MEMBER NOW
          </SignUpNavItem>
        </ProfitableParent>
      </Col>
    </Row>
  );
};

const AboutExperts = () => (
  <>
    <Helmet>
      <title>How we make profitable traders</title>
      <meta
        name="description"
        content="The Logical Traders review, are The Logical Traders good, Should I buy The Logical Traders membership, does the Logical Traders work, What are The Logical Traders services, How good is The Logical Traders, The Logical Traders good, The Logical Traders fake, The Logical Traders members, The Logical Traders Quora"
      />
    </Helmet>

    <div className="grey-bg padding-30">
      <HeadingUnderline title="Our Team" />
      <Container>
        <Row className="grey-bg">
          <ExpertsContent />
        </Row>
      </Container>
    </div>
  </>
);

const WhatWeDo = () => (
  <Container fluid style={{ padding: 0 }}>
    <div className="black-bg padding-30" style={{ flex: 1 }}>
      <Container>
        <HeadingUnderline
          title="What We Do"
          whitetext
          noMargin={isMobileOnly}
        />
        <WhatWeDoContainer>
          <Row>
            <Col xs={24} sm={24} md={24} lg={8}>
              <LogoSection>
                <LogoImage src={Logo} />
              </LogoSection>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <TextSection>
                <TextData>
                  <li>
                    There are only two ways to become a successful trader
                    </li>
                  <li>
                    1) Learn from your mistakes and become profitable after
                    years of hard work and tons of losses.
                      <br />
                    <br />
                    2) Learn from mentors who know what they are doing, and
                    save your time and money.
                      {' '}
                  </li>
                  <li>
                    With mentors, you learn faster and lose a lot less money.
                    This is why we provide you real time access to our trades
                    along with explanation, so that you can learn to think and
                    act like professional traders.
                      {' '}
                  </li>
                </TextData>
              </TextSection>
            </Col>
          </Row>
        </WhatWeDoContainer>
      </Container>
    </div>
  </Container>
);

const About = () => (
  <Row>
    <Spacer />

    <Col span="24">
      <BGImage>
        <div>
          <HeadingUnderline title="About Us" whitetext noMargin />
        </div>
      </BGImage>
    </Col>

    <Container>
      <WhoWeAre />
      <OurValues />
    </Container>

    <WhatWeDo />
    <Container>
      <Offering />
    </Container>
    <Container fluid style={{ padding: 0 }}>
      <AboutExperts />
    </Container>
    <Container>
      <Profitable />
    </Container>
  </Row>
);

export default About;
