import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import * as R from 'ramda';
import {
  HomeOutlined,
  SolutionOutlined,
  LogoutOutlined,
  BellOutlined,
  EyeOutlined,
  HomeFilled,
  BellFilled,
  EyeFilled,
  CalculatorOutlined,
  CalculatorFilled,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GiWisdom } from 'react-icons/gi';
import { SingleItem } from './styled';
import { axiosAuth } from '../../api';
import { logout } from '../../actions/common';
import { logoutUserHandler } from '../../utils/helper';
import QuantityCalculator from '../../components/QuantityCalculator';

const MenuBar = ({ history, logoutUser, handle }) => {
  const childRef = useRef();
  const [count, setCount] = useState(0);
  const location = useLocation();

  const getAllNotifications = () => {
    axiosAuth
      .get('/notification/all?offset=0&limit=50')
      .then((res) => {
        const count = R.pipe(
          R.filter(R.propEq('read', false)),
          R.length,
        )(res?.data?.data ?? []);
        setCount(count);
      })
      .catch((err) => {
        console.error('Failed to load notifications');
      });
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  const logoutHandler = () => {
    logoutUserHandler(logoutUser, history);
  };

  const handleMenuItemClick = (link) => {
    if (link == location.pathname) {
      window.scrollTo({
        top: 0,
      });
      setTimeout(() => {
        history.push(link);
      }, 500);
    } else {
      history.push(link);
    }
  };

  const menuList = [
    {
      name: 'Home',
      icon: <HomeOutlined style={{ fontSize: '18px' }} />,
      activeIcon: <HomeFilled style={{ fontSize: '18px' }} />,
      onCLick: () => handleMenuItemClick('/posts'),
    },
    {
      name: 'Notification',
      icon: <BellOutlined style={{ fontSize: '18px' }} />,
      activeIcon: <BellFilled style={{ fontSize: '18px' }} />,
      showCount: true,
      onCLick: () => handleMenuItemClick('/notifications'),
    },
    // {
    //   name: 'Trade Wisdom',
    //   icon: <GiWisdom style={{ fontSize: '18px' }} />,
    //   activeIcon: <GiWisdom style={{ fontSize: '18px' }} />,
    //   onCLick: () => handleMenuItemClick('/trade-wisdom'),
    // },
    {
      name: 'Watchlist',
      icon: <EyeOutlined style={{ fontSize: '18px' }} />,
      activeIcon: <EyeFilled style={{ fontSize: '18px' }} />,
      onCLick: () => handleMenuItemClick('/watchlist'),
    },
    {
      name: 'Trade Calculator',
      icon: <CalculatorOutlined style={{ fontSize: '18px' }} />,
      activeIcon: <CalculatorFilled style={{ fontSize: '18px' }} />,
      onCLick: () => childRef.current.toggleCalcModal(true),
    },
    {
      name: 'Log Out',
      icon: <LogoutOutlined style={{ fontSize: '18px' }} />,
      onCLick: logoutHandler,
    },
  ];

  const getIsActive = () => {
    if (location.pathname === '/posts') {
      return 'Home';
    } if (location.pathname === '/notifications') {
      return 'Notification';
    } if (location.pathname === '/watchlist') {
      return 'Watchlist';
    } if (location.pathname === '/trade-wisdom') {
      return 'Trade Wisdom';
    }
  };

  return (
    <>
      <QuantityCalculator ref={childRef} />
      <div>
        {menuList.map((menu) => {
          const currentKey = getIsActive();
          const isActive = currentKey == menu.name;
          return (
            <SingleItem isActive={isActive} key={menu.name}>
              {isActive ? menu.activeIcon : menu.icon}
              {location.pathname != '/notifications'
                && menu.showCount
                && count > 0 && (
                  <div className="notification-count">
                    {count > 9 ? '9+' : count}
                  </div>
              )}
              <span className="ml-2" onClick={menu.onCLick}>
                {menu.name}
              </span>
            </SingleItem>
          );
        })}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(MenuBar);
