import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const TermsConditions = () => {
  return (
    <Container>
      <Spacer />
      <HeadingUnderline title='Terms & Conditions' />
      <p>
        The Logical Traders is pleased to provide you with information and
        content provided to its subscribers through Telegram, Whats app,
        Instagram……etc.
      </p>
      <h5>DISTRIBUTION AND RESPONSIBILITY OF VISITORS</h5>
      <p>
        The information on this website is not intended for distribution to, or
        use by, any person who is a visitor but not a subsciber. If any of the
        person whether a visitor who is involved in trading or a subscriber
        invests in the stock market and does any monetary transaction himself by
        applying our market strategies then we are not responsible for any
        losses.
      </p>
      <h5>LIMITATIONS ON INVESTMENT GUIDANCE AND ADVICE</h5>
      <p>
        The Logical traders are not intended to provide legal or investment
        advice. You are solely responsible for determining whether any
        investment, investment strategy or related transaction is appropriate
        for you based on your personal investment objectives, financial
        circumstances and risk tolerance.
      </p>
      <h5>EDUCATIONAL CONTENT AND RESEARCH</h5>
      <p>
        The Logical traders may include general news and information,
        commentary, blogs, research reports and data concerning the foreign
        exchange markets and international financial markets that is only for
        educational purpose.
      </p>
      <h5>INDEMNIFICATION</h5>
      <p>
        As a condition of your use of this website, you agree to indemnify and
        hold Logical traders team harmless from and against any and all claims,
        losses, liability arising from your use of this site.
      </p>
      <Spacer />
    </Container>
  );
};

export default TermsConditions;
