import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Col, Input, InputNumber, Row, Switch, Table,
} from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import ReactExport from 'react-export-excel';
import Search from 'antd/lib/input/Search';
import { toast } from 'react-toastify';
import { Spacer } from '../about';
import {
  getExperienceValue,
  getInvestorType,
  getPortfolioValue,
} from '../../utils/helper';
import { axiosAuth } from '../../api';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const UsersContainer = styled.div`
  margin-top: 40px;
  padding: 0 20px;
`;

const AllUsers = ({ common }) => {
  const user = common?.user?.userType || '';
  const [allUsersList, setUsersList] = useState([]);
  const [allAdminUsersList, setAdminUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seeViewModal, toggleViewModal] = useState(false);
  const [userLoading, setUserLoading] = useState(null);
  const [userProfitableLoading, setUserProfitableLoading] = useState(null);
  const [viewData, setViewData] = useState({});
  const [search, setSearch] = useState('');
  const [showDeactivatedOnly, setShowDeactivatedOnly] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState(false);
  const [refundData, setRefundData] = useState(0);
  const [showRefundModal, setShowRefundModal] = useState(null);

  const getUsers = () => {
    axiosAuth
      .get('user/all')
      .then((res) => {
        if (res?.data?.data) {
          const finalData = res?.data?.data.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
          setUsersList(finalData.filter((item) => item.userType == 'normal'));
          setAdminUsersList(
            finalData.filter((item) => item.userType == 'admin'),
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleStatusChange = useCallback(
    (email, oldStatus) => {
      setUserLoading(email);
      axiosAuth
        .post('user/changeUserStatus', {
          email,
          active: !oldStatus,
        })
        .then((res) => {
          getUsers();
          setUserLoading(null);
        })
        .catch((err) => {
          setUserLoading(null);
        });
    },
    [allUsersList],
  );

  const handleIssueRefund = (data) => {
    setRefundData({
      name: data.name ?? '',
      email: data.email ?? '',
      amount: data?.payment?.length ? data?.payment[0]?.amount : 0,
      paymentId: data?.payment?.length ? data?.payment[0]?.txnId : '',
      orderId: data?.payment?.length ? data?.payment[0]?.orderId : '',
    });
    setShowRefundModal(true);
  };

  const handleFinalIssueRefund = () => {
    setRefundLoading(true);
    axiosAuth
      .post('/refund/create', {
        amount: refundAmount,
        paymentId: refundData.paymentId,
        orderId: refundData.orderId,
        name: refundData.name,
        email: refundData.email,
      })
      .then((res) => {
        setRefundLoading(false);
        setShowRefundModal(false);
        setRefundData(null);
        toast('Refund issued successfully!!', {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
        setRefundLoading(false);
        toast('Failed to issue refund. Please try again', {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const handleProfitableStatusChange = useCallback(
    (email, oldStatus) => {
      setUserProfitableLoading(email);
      axiosAuth
        .post('user/changeUserProfitableStatus', {
          email,
          isProfitable: !oldStatus,
        })
        .then((res) => {
          getUsers();
          setUserProfitableLoading(null);
        })
        .catch((err) => {
          setUserProfitableLoading(null);
        });
    },
    [allUsersList],
  );

  useEffect(() => {
    if (user == 'admin') {
      setLoading(true);
      getUsers();
    }
  }, [user]);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (sno, data, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => <p>{email}</p>,
    },
    {
      title: 'Contact',
      dataIndex: 'contactNo',
      key: 'contactNo',
      render: (contactNo) => <span>{contactNo}</span>,
    },
    {
      title: 'Plan Type',
      dataIndex: 'payment',
      key: 'payment',
      render: (payment) => {
        const [paymentData] = payment;
        return <span>{paymentData?.planType}</span>;
      },
    },
    {
      title: 'Plan Expiry on',
      dataIndex: 'payment',
      key: 'payment',
      width: 150,
      render: (payment) => {
        const [paymentData] = payment;
        return (
          <span>
            {paymentData?.endDate
              ? moment(paymentData.endDate).format('ddd DD MMM YYYY')
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'payment',
      key: 'payment',
      render: (payment) => {
        const [paymentData] = payment;
        return <span>{paymentData?.amount ?? 'N/A'}</span>;
      },
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (createdAt) => moment(createdAt).format('DD-MM-YYYY HH:MM:SS'),
    },
    {
      title: 'Actions',
      dataIndex: 'payment',
      key: 'payment',
      width: 250,
      render: (payment, data, index) => (
        <div style={{ display: 'flex', maxWidth: '250px', flexWrap: 'wrap' }}>
          <Button
            onClick={() => handleStatusChange(data.email, data.active)}
            loading={userLoading === data.email}
            type={data.active ? 'primary' : 'danger'}
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            {data.active ? 'Deactivate' : 'Activate'}
          </Button>
          <Button
            onClick={() => handleIssueRefund(data)}
            type="primary"
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            Issue Refund
          </Button>
          <Button
            onClick={() => handleProfitableStatusChange(data.email, data.isProfitable)}
            loading={userProfitableLoading === data.email}
            type={data.isProfitable ? 'success' : 'danger'}
            style={{
              marginRight: '5px',
              marginBottom: '5px',
              background: data.isProfitable && 'green',
              color: data.isProfitable && 'white',
            }}
          >
            {data.isProfitable ? 'Profitable' : 'Non-Profitable'}
          </Button>
          <Button
            onClick={() => {
              toggleViewModal(true);
              setViewData(data);
            }}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];

  const { payment = [] } = viewData || {};
  const [paymentDetails = {}] = payment || [];

  let filteredUsers = allUsersList.filter((item) => (
    (item.name || '').toLowerCase().includes(search.toLowerCase())
      || (item.userType || '').toLowerCase().includes(search.toLowerCase())
      || (item.email || '').toLowerCase().includes(search.toLowerCase())
      || (item.contactNo || 0) == search
  ));

  if (showDeactivatedOnly) {
    filteredUsers = filteredUsers.filter((x) => !x.active);
  } else {
    filteredUsers = filteredUsers.filter((x) => x.active);
  }

  return (
    <UsersContainer>
      <ExcelFile
        filename="All Users"
        element={(
          <Button type="primary" className="export-users">
            Export Users Excel
          </Button>
        )}
      >
        <ExcelSheet data={allUsersList} name="All Users">
          <ExcelColumn label="Name" value="name" />
          <ExcelColumn label="Email" value="email" />
          <ExcelColumn label="Contact" value="contactNo" />
          <ExcelColumn
            label="Plan Type"
            value={(col) => {
              const [paymentData] = col.payment;
              return paymentData?.planType ?? 'N/A';
            }}
          />
          <ExcelColumn
            label="Amount"
            value={(col) => {
              const [paymentData] = col.payment;
              return paymentData?.amount ?? 'N/A';
            }}
          />
          <ExcelColumn
            label="Joined On"
            value={(col) => moment(col.createdAt).format('DD-MM-YYYY HH:MM:SS')}
          />
          <ExcelColumn
            label="Expiry On"
            value={(col) => {
              const [paymentData] = col.payment;
              return moment(paymentData?.endDate).format('DD-MM-YYYY HH:MM:SS');
            }}
          />
        </ExcelSheet>
      </ExcelFile>
      <Modal
        title="Issue Refund"
        visible={showRefundModal}
        onCancel={() => setShowRefundModal(false)}
        footer={null}
        maskClosable={false}
      >
        <Row>
          <Col span={24}>
            Name:
            {' '}
            {refundData?.name}
          </Col>
          <Col span={24}>
            Email:
            {' '}
            {refundData?.email}
          </Col>
          <Col span={24}>
            Total Amount:
            {' '}
            {refundData?.amount}
          </Col>
        </Row>
        <Spacer />
        <Row>
          <InputNumber
            onChange={(value) => setRefundAmount(value)}
            value={refundAmount}
          />
        </Row>
        <Spacer />
        <Row>
          <Button
            loading={refundLoading}
            disabled={!refundAmount || refundLoading}
            onClick={handleFinalIssueRefund}
          >
            Confirm
          </Button>
        </Row>
      </Modal>
      <Modal
        title="Details"
        visible={seeViewModal}
        onCancel={() => toggleViewModal(false)}
        footer={null}
        maskClosable={false}
      >
        <h4>General Details</h4>
        <p>
          Name:
          {' '}
          {viewData?.name}
        </p>
        <p>
          Email:
          {' '}
          {viewData?.email}
        </p>
        <h4>Payment Details</h4>
        <p>
          Txn Id:
          {' '}
          {paymentDetails?.txnId}
        </p>
        <p>
          Amount:
          {' '}
          {paymentDetails?.amount}
        </p>
        <p>
          Start Date:
          {' '}
          {paymentDetails?.startDate
            ? moment(paymentDetails?.startDate).format('DD-MM-YYYY')
            : 'N/A'}
        </p>
        <p>
          End Date:
          {' '}
          {paymentDetails?.endDate
            ? moment(paymentDetails?.endDate).format('DD-MM-YYYY')
            : 'N/A'}
        </p>
        <p>
          Plan Type:
          {' '}
          {paymentDetails?.planType}
        </p>
        <p>
          Remaining Days:
          {' '}
          {paymentDetails?.endDate
            ? moment(paymentDetails?.endDate).diff(new Date(), 'days')
            : 'N/A'}
        </p>
      </Modal>
      {user == 'admin' ? (
        <>
          <Row className="mb-10">
            <Col span={8}>
              <Search
                placeholder="input search text"
                onChange={({ target }) => setSearch(target.value)}
              />
            </Col>
            <Col span={8}>
              <Switch
                style={{ marginLeft: '15px' }}
                checked={showDeactivatedOnly}
                onChange={(value) => setShowDeactivatedOnly(value)}
                checkedChildren="Activated"
                unCheckedChildren="Deactivated"
              />
            </Col>
          </Row>

          <h4>
            All
            {' '}
            {showDeactivatedOnly ? 'Deactivated' : 'Activated'}
            {' '}
            Users
          </h4>
          <Table
            loading={loading}
            dataSource={filteredUsers}
            columns={columns}
            pagination={{
              pageSizeOptions: [100, 200, 500],
              defaultPageSize: 500,
              showSizeChanger: true,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {user == 'admin' ? (
        <>
          <h4>Admin Users</h4>
          <Table
            loading={loading}
            dataSource={allAdminUsersList}
            columns={columns}
            pagination={{
              pageSizeOptions: [10, 20, 30],
              defaultPageSize: 10,
              showSizeChanger: true,
            }}
          />
        </>
      ) : (
        <></>
      )}
    </UsersContainer>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(AllUsers);
