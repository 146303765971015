import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { Container } from 'react-bootstrap';
import {
  Col, Row, notification, Spin,
} from 'antd';
import { EditOutlined, MenuOutlined } from '@ant-design/icons';
import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BottomTabNavigator from '../../components/BottomTabNavigator';
import NotificationBox from './notificationBox';
import { LoadingNotificationSkeleton } from '../posts/LoadingSkeleton';
import { axiosAuth } from '../../api';
import logo1 from '../../assets/logo1.svg';
import {
  CenteredDiv,
  LeftSidebarProfile,
  LeftSidebarProfileHeader,
  MainBoxFull,
  PostsContainer,
  UserImg,
  UserName,
  UserPlan,
} from '../posts/styled';
import defaultUser from '../../assets/defaultUser.jpg';
import AuthorBioModal from '../posts/AuthorBioModal';
import { capitalizeText } from '../../utils/helper';
import MenuBar from '../posts/MenuBar';
import HamburgerMenuLinks from '../../components/HamburgerMenuLink';
import QuotesSidebar from '../../components/QuotesSidebar';

const Notifications = () => {
  const hasMore = useRef(true);
  const [allNotifications, setAllNotifications] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMenuOpen, toggleHamburger] = useState(false);
  const [isOpenBioModal, setOpenBioModal] = useState(false);
  const [allUnreadIds, setAllUnreadIds] = useState([]);
  const [loadingMore, setloadingMore] = useState(false);

  const fetchMore = useCallback((currentPage) => {
    if (!hasMore.current) {
      return;
    }
    const endpoint = `notification/all?offset=${currentPage * 10}&limit=10`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllNotifications(res?.data?.data);
            setCurrentPage(currentPage);
          }
          if (res?.data?.data?.length < 10) {
            hasMore.current = false;
          }
        }
        setloadingMore(false);
        setLoading(false);
      })
      .catch((err) => {
        setloadingMore(false);
        setLoading(false);
      });
  }, []);

  const handleScroll = useCallback(
    (e) => {
      const windowHeight = 'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
      const { body } = document;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom + 200 >= docHeight && !loadingMore && hasMore.current) {
        setloadingMore(true);
        fetchMore(currentPage + 1);
      }
    },
    [currentPage, loadingMore, hasMore],
  );

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
    }, 100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    setLoading(true);
    const endpoint = `notification/all?offset=${currentPage * 10}&limit=10`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllNotifications(res?.data?.data);
          }
          if (res?.data?.data?.length < 10) {
            hasMore.current = false;
          }
        } else {
          notification.error({
            message: 'Error',
            description:
              'Failed to load notifications. Please try again later!!',
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const finalDataNew = R.uniqBy(R.prop('_id'), [
      ...finalData,
      ...allNotifications,
    ]);
    setFinalData(finalDataNew);
    const newallUnreadIds = R.pipe(
      R.filter(R.propEq('read', false)),
      R.map(R.prop('_id')),
    )(finalDataNew);
    setAllUnreadIds(newallUnreadIds);
  }, [allNotifications]);

  useEffect(() => {
    if (allUnreadIds.length) {
      axiosAuth.post('/notification/markAsRead', {
        notificationIds: allUnreadIds,
      });
    }
  }, [allUnreadIds]);

  const user = useSelector((state) => state.common.user);
  const history = useHistory();

  return (
    <PostsContainer isMobileOnly={isMobile} className="posts">
      <HamburgerMenuLinks
        isMenuOpen={isMenuOpen}
        toggleMenu={(value) => toggleHamburger(value)}
      />
      <Container>
        {isMobile && (
          <div className="menu-btn-heading-parent">
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: '25px',
                position: 'absolute',
                zIndex: 1000,
              }}
            />
            <div className="posts-heading text-center">
              <img
                aria-hidden
                onClick={() => history.push('/')}
                alt=""
                src={logo1}
                className="d-inline-block align-top brand-image"
              />
            </div>
          </div>
        )}
        <AuthorBioModal
          isOpenBioModal={isOpenBioModal}
          resetData={(data) => setOpenBioModal(data)}
          alreadyAuthoreData={user?.investorType}
        />
        <Row>
          <Col xs={0} sm={0} md={0} lg={5}>
            <div className={finalData?.length > 10 ? 'sticky-box' : ''}>
              <MainBoxFull>
                <LeftSidebarProfile>
                  <LeftSidebarProfileHeader />
                  <CenteredDiv>
                    <UserImg
                      className="cursor-pointer"
                      src={user?.profileImg || defaultUser}
                      onClick={() => history.push('/profile')}
                    />
                    <UserName
                      className="cursor-pointer"
                      onClick={() => history.push('/profile')}
                    >
                      {capitalizeText(user?.name ?? '')}
                    </UserName>
                    <UserPlan>
                      <Row justify="center">
                        <div className="mr-1">
                          {user?.investorType ?? 'Author bio'}
                        </div>
                        <EditOutlined
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpenBioModal(true);
                          }}
                        />
                      </Row>
                    </UserPlan>
                  </CenteredDiv>
                </LeftSidebarProfile>
                <LeftSidebarProfile>
                  <MenuBar history={history} />
                </LeftSidebarProfile>
              </MainBoxFull>
            </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />

          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col span={24}>
                {loading ? (
                  <LoadingNotificationSkeleton />
                ) : (
                  finalData?.map((item) => <NotificationBox key={item._id} data={item} />)
                )}
              </Col>
              <Col span={24}>
                <Row className="mb-20" justify="center">
                  {loadingMore ? (
                    <Spin />
                  ) : !hasMore.current ? (
                    finalData?.length ? (
                      <p className="mb-20">That's all folks</p>
                    ) : (
                      <p className="mb-20 mt-25"><strong>No Notifications Found</strong></p>
                    )
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />
          <Col xs={0} sm={0} md={0} lg={5}>
            {!isMobile && (
              <div>
                <QuotesSidebar isSticky={finalData?.length > 10} />
              </div>
            )}
          </Col>
        </Row>
        {isMobile && <BottomTabNavigator />}
      </Container>
    </PostsContainer>
  );
};

export default Notifications;
