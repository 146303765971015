import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import { handleVideoPlayTracking } from '../../utils/helper';

const VideoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  color: #0c0e0f;
  text-align: center;
  @media (max-width: 767px) {
    text-align: center;
    font-size: 24px;
    margin-top: ${({ showTop }) => (showTop ? '20px' : 0)};
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    font-size: 24px;
  }
`;

const VideoParent = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const VideoSection = ({
  heading1,
  heading2,
  url,
  videoRight,
  showTopMargin,
  showBottomMargin,
  type,
}) => {
  const [isPlaying, setPlaying] = useState(false);
  const handlePlayClick = () => {
    handleVideoPlayTracking(type || 1);
    setPlaying(true);
  };
  return (
    <div
      className={`${showTopMargin ? 'margin-40-top' : ''} ${
        showBottomMargin ? 'margin-40-bottom' : ''
      }`}
    >
      <Container>
        <Row>
          {!videoRight && (
            <Col xs={24} sm={24} md={24} lg={12}>
              <VideoParent videoRight={videoRight}>
                <div className="react-video-player-custom">
                  <ReactPlayer
                    url={url}
                    controls
                    width={isMobileOnly ? '350px' : '480px'}
                    height={isMobileOnly ? '197px' : '270px'}
                    pip
                    playing={isPlaying}
                    loop
                    onPlay={() => handleVideoPlayTracking(type || 1)}
                  />
                </div>
              </VideoParent>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={12}>
            <VideoContainer>
              <Heading showTop={!videoRight}>{heading1}</Heading>
              <Heading>{heading2}</Heading>
              {!isPlaying && (
                <Button
                  style={{ marginBottom: videoRight && '30px' }}
                  className="play-btn"
                  onClick={handlePlayClick}
                >
                  Play Now
                </Button>
              )}
            </VideoContainer>
          </Col>
          {videoRight && (
            <Col xs={24} sm={24} md={24} lg={12}>
              <VideoParent videoRight={videoRight}>
                <div className="react-video-player-custom">
                  <ReactPlayer
                    url={url}
                    controls
                    width={isMobileOnly ? '350px' : '480px'}
                    height={isMobileOnly ? '197px' : '270px'}
                    pip
                    playing={isPlaying}
                    loop
                    onPlay={() => handleVideoPlayTracking(type || 1)}
                  />
                </div>
              </VideoParent>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default VideoSection;
