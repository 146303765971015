import { Button, Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SignUpNavItem } from '../../styledComponents';
import {
  handleBecomeMemberTrackingClick,
  isUserLoggedIn,
} from '../../utils/helper';
import { BlackBoldText, ReturnsText } from '../LandingPage/MainSection';

const HomeBackground = styled.div`
  width: auto;
  height: ${isTablet ? '800px' : '550px'};
  background-position: center;
  background-repeat: repeat;
  display: flex;
  align-content: center;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 10px;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const HomeText = styled.span`
  font-size: 40px;
  color: #f9ae00;
  font-weight: 600;
  text-align: center;
  color: black;
  @media (max-width: 767px) {
    font-size: 20px;
    font-weight: 600;
  }
`;

const TextLine1 = styled(HomeText)`
  margin-top: 60px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: black;
  @media (max-width: 767px) {
    margin-top: 0px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`;

const SubLines = styled.span`
  margin-bottom: 5px;
  font-size: 18px;
  color: white;
  font-weight: 600;
  text-align: center;
`;

const ProofButton = styled(Button)`
  background: white;
  border: 2.5px solid #9932cc;
  box-sizing: border-box;
  box-shadow: 0px 4.42006px 3.86756px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  color: #9932cc;
  font-weight: bold;
  height: 44px;
  font-size: 16px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 10px;
  &:hover,
  &:active,
  &:focus {
    background: white !important;
    border: 2.5px solid #9932cc !important;
    color: #9932cc !important;
    box-shadow: 0px 6.42006px 5.86756px rgba(0, 0, 0, 0.35) !important;
  }
  @media (max-width: 767px) {
    width: inherit;
    height: 36px;
  }
`;

const HomeBg = ({ executeScroll }) => {
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    handleBecomeMemberTrackingClick();
    history.push(path);
  }, []);

  const Content = () => (
    <Row>
      <Col span={24}>
        <HomeBackground>
          <TextLine1>
            Know The Secrets We Used To Turn &lt;&#8377;2 Lakhs To &#8377;45.87
            Lakhs In 3 Years
          </TextLine1>
          {/* <HomeText>Unlock Our Trading Secrets</HomeText> */}
          {/* <ReturnsText>
            <BlackBoldText>
              2020 Returns: &#8377;1.54 Lakh to &#8377;24.44 Lakh
            </BlackBoldText>
            <br />
            <BlackBoldText>
              2021 Returns: &#8377;6.93 Lakh to &#8377;15.19 Lakh
            </BlackBoldText>
          </ReturnsText> */}
          <SubLines style={{ marginTop: '30px' }}>
            99% of traders lose money. Don’t be one of them.
          </SubLines>

          {!isUserLoggedIn() && (
            <Row justify="center" style={{ marginTop: '45px' }}>
              <SignUpNavItem
                className="pulse"
                isButton
                onClick={() => handleLinkClick('/register')}
              >
                BECOME A MEMBER NOW
              </SignUpNavItem>
            </Row>
          )}
          {/* <Row justify="center">
            <ProofButton onClick={executeScroll}>See Proof of Returns</ProofButton>
          </Row> */}
        </HomeBackground>
      </Col>
    </Row>
  );

  const MobileContent = () => (
    <Row>
      <Col span={24}>
        <HomeBackground>
          <TextLine1>
            Know The Secrets We Used To Turn &lt;&#8377;2 Lakhs To &#8377;45.87
            Lakhs In 3 Years
          </TextLine1>
          {/* <HomeText>Unlock Our Trading Secrets</HomeText> */}
          {/* <ReturnsText>
            <BlackBoldText>2020 Returns: &#8377;1.54 Lakh to &#8377;24.44 Lakh</BlackBoldText>
            <br />
            <BlackBoldText>2021 Returns: &#8377;6.93 Lakh to &#8377;15.19 Lakh</BlackBoldText>
          </ReturnsText> */}

          <SubLines style={{ marginTop: '25px' }}>
            99% of traders lose money.
            <br />
            {' Don’t be one of them.'}
            <br />
          </SubLines>

          {!isUserLoggedIn() && (
            <Row justify="center" style={{ marginTop: '25px' }}>
              <SignUpNavItem
                className="pulse"
                isButton
                onClick={() => handleLinkClick('/register')}
              >
                BECOME A MEMBER NOW
              </SignUpNavItem>
            </Row>
          )}
          <Row justify="center">
            <ProofButton onClick={executeScroll}>
              See Proof of Returns
            </ProofButton>
          </Row>
        </HomeBackground>
      </Col>
    </Row>
  );

  return isMobileOnly ? (
    <MobileContent />
  ) : (
    <Container>
      <Content />
    </Container>
  );
};

export default HomeBg;
