export const authoreList = [
  {
    title:
      "“Trade like you don’t need money. It takes so much pressure off you.”",
    author: "Martin Niemi",
  },
  {
    title:
      "“The essential element is that the markets are ultimately based on human psychology, and by charting the markets you’re merely converting human psychology into graphic representations. I believe that the human mind is more powerful than any computer in analyzing the implications of this graph.”",
    author: "Al Weiss",
  },
  {
    title:
      "“Environmental distractions and boredom cause a lack of focus. All of us have limits to our attention span and these are easily taxed during quiet times in the markets.”",
    author: "Brett Steenbarger",
  },
  {
    title:
      "“Those traders who have confidence in their own traders, who trust themselves to do what needs to be done without hesitation, are the ones who become successful. They no longer fear the erratic behavior of the market. They learn to focus on the information that helps them spot opportunities to make a profit, rather than focusing on the information that reinforces their fears..”",
    author: "Mark Douglas",
  },
  {
    title:
      "“Fatigue and mental overload create a loss of concentration. The demands of watching the screen hour after hour make it difficult to be sharp, creating fatigue effects that are well-known to pilots, car drivers, and soldiers.””",
    author: "Brett Steenbarger",
  },
  {
    title:
      "“While this may sound complicated, it all boils down to learning to believe that: (1) you don’t need to know what’s going to happen next to make money; (2) anything can happen; and (3) every moment is unique, meaning every edge and outcome is truly a unique experience. The trade either works or it doesn’t.”",
    author: "Mark Douglas",
  },
  {
    title:
      "“Trading is easy; anyone with a few bucks can do it. Making money, however, is a whole different ball game.”",
    author: "Marcel Link",
  },
  {
    title:
      "“The key is consistency and discipline. Almost anybody can make up a list of rules that are 80% as good as what we taught. What they can’t do is give (people) the confidence to stick to those rules even when things are going bad.”",
    author: "Richard Dennis",
  },
  { title: "“If you’re going to panic, panic early.”", author: "Unknown" },
  {
    title:
      "Overconfidence follows a string of success. It is common for traders to attribute success to skill and failure to situational, external factors. As a result, a string of even random wins can lead traders to become overconfident and veer from trading plans—especially by trading too frequently and/or trading excessive size.",
    author: "Brett Steenbarger",
  },
  {
    title:
      "“Fear of Regret—An inability to accept that you’ve made a wrong decision, which leads to holding onto losers too long or selling winners too soon.”",
    author: "Gavin McQuill",
  },
  {
    title:
      "“There seems to be some perverse human characteristic that likes to make easy things difficult.”",
    author: "Warren Buffett",
  },
  {
    title:
      "“We typically trade our beliefs about the market and once we’ve made up our minds about those beliefs, we’re not likely to change them. And when we play the markets, we assume that we are considering all the available information. Instead, our beliefs, through selective perception, may have eliminated the most useful information.”",
    author: "Van K. Tharp",
  },
  {
    title:
      "“Unwillingness to accept losses—This leads traders to alter their trade plans after trades have gone into the red, turning what were meant to be short-term trades into longer-term holds and transforming trades with small size into large trades by adding to losers.”",
    author: "Brett Steenbarger",
  },

  {
    title:
      "“Traders who are not ready to accept responsibility for the outcomes of their interpretations and actions will find themselves in a dilemma: How does one participate in an activity that allows complete freedom of choice, and at the same time avoid taking responsibility if the outcome of one’s choices are unexpected and not to one’s liking?”",
    author: "Mark Douglas",
  },
  {
    title:
      "“Representativeness—The tendency of people to see patterns within random events.”",
    author: "Gavin McQuill",
  },
  {
    title:
      "“If your goal is to trade like a professional and be a consistent winner, then you must start from the premise that the solutions are in your mind and not in the market.”",
    author: "Mark Douglas",
  },
  {
    title:
      "“Win or lose, everybody gets what they want out of the market. Some people seem to like to lose, so they win by losing money.”",
    author: "Jack Schwager",
  },
  {
    title:
      "“There is one important caveat to the notion that we live in a new economy, and that is human psychology, which appears essentially immutable.”",
    author: "Alan Greenspan",
  },
  {
    title:
      "“I think that if people look deeply enough into their trading patterns, they find that, on balance, including all their goals, they are really getting what they want, even though they may not understand it or want to admit it.”",
    author: "Jack Schwager",
  },
  {
    title:
      "“As human beings, we are free to act however we see fit, but when presented with danger or opportunity, most people act with surprising predictability.”",
    author: "Tom Williams",
  },
  {
    title:
      "“The key to trading success is emotional discipline. If intelligence were the key, there would be a lot more people making money trading.”",
    author: "Victor Sperandeo",
  },
  {
    title:
      "“Interestingly, the behavior of crows is paradoxical indicator when applied to stock markets. When most investors are in consensus and are driving the market in a particular direction, one naturally thinks that the consensus will continue ad infinitum and the best trading decision is to follow the crowd. But history has proven exactly the opposite. When driven strongly by consensus, crowd behavior is actually a contrary indicator.”",
    author: "Jason Van Bergen",
  },
  {
    title: "“You don’t have to make money back the same way you lost it.”",
    author: "Warren Buffett",
  },
  {
    title:
      "“Fools say that they lean by experience. I prefer to profit by others’ experience.”",
    author: "Bismark",
  },
  {
    title:
      "“Have an opinion on what the market should do but don’t decide what the market will do.”",
    author: "Bernard Baruch",
  },
  {
    title:
      "“Being wrong is acceptable. But staying wrong is totally unacceptable. Being wrong isn’t a choice, but staying wrong is. To play any game successfully, you have to have some skill, an edge, but beyond that it is money management. Good traders manage the downside; they don’t worry about the upside.”",
    author: "Mark Minervini",
  },
  {
    title: "“If you personalize losses, you can’t trade.”",
    author: "Bruce Kovner",
  },
  {
    title:
      "“Men, it has been well said, think in herd; it will be seen that they go mad in herds, while they recover their senses slowly, and only one by one.”",
    author: "Charles MacKay",
  },
  {
    title:
      "“However, knowledge becomes obsolete. Thus, the person who stops learning will slide backwards. One bit of knowledge at a critical moment can change the course of your whole career. You must open up to a continuous stream of information that is constantly available to you.”",
    author: "Van K. Tharp",
  },
  {
    title:
      "“Losing your position is aggravating, whereas losing your nerve is devastating.”",
    author: "Ed Seykota",
  },
  {
    title:
      "“I prefer not to dwell on past situations. I tend to cut bad trades as soon as possible, forget them, and then move on to new opportunities.”",
    author: "Ed Seykota",
  },
  {
    title:
      "“Trying to trade during a losing streak is emotionally devastating. Trying to play ‘catch up’ is lethal.”",
    author: "Ed Seykota",
  },
  {
    title:
      "“Be sensitive to subtle difference between ‘intuition’ and ‘wishing’.”",
    author: "Ed Seykota",
  },
  {
    title:
      "“If you don’t know who you are, the stock market is an expensive place to find out.”",
    author: "George Goodman",
  },
  {
    title:
      "“Never trade just to make a trade—even veteran traders can identify with this temptation. It takes tremendous discipline to stay away from frivolous trades due to boredom.”",
    author: "Jea Yu",
  },
  {
    title:
      "“I spend my day trying to make myself as happy and relaxed as I can be. If I have positions going against me, I get right out; if they are going for me, I keep them.”",
    author: "Paul Tudor Jones",
  },
  {
    title:
      "“I am always thinking about losing money as opposed to making money.”",
    author: "Paul Tudor Jones",
  },
  {
    title:
      "“Your trading performance is a direct reflection of how you feel internally, not the other way around. When you feel good, you perform at high levels.”",
    author: "Robert Koppel",
  },
  {
    title: "“Emotions can be managed, but not controlled.”",
    author: "Scott Billington",
  },
  {
    title: "“View each trade merely one is a series of probabilities.”",
    author: "Scott Billington",
  },
  {
    title: "“If you missed a trade, don’t worry, there is always another.”",
    author: "Walter Downs",
  },
  {
    title:
      "“Soros has taught me that when you have tremendous conviction on a trade, you have to go for the jugular. It takes courage to be a pig. It takes courage to ride a profit with huge leverage.”",
    author: "Stanley Druckenmiller",
  },
];

export const PaginationEndQuotes = [
  {
    title:
      "“When you are confused, it is best to do nothing. You are just going for a random walk and that is when you are liable to get mugged, because you don’t have staying power. You are likely to be faked out by some stray fluctuations because you lack the courage of your convictions. As my friend, Victor Niederhoffer says, the market destroys the weak—that is, investors who don’t have well founded convictions. You need some convictions to avoid getting faked out, but having the courage of your convictions could get you wiped out if your convictions are false. So, I prefer to take a stand only when I have well-founded convictions.”",
    author: "George Soros",
  },

  {
    title:
      "“Most often, traders have four fears. There’s the fear of being wrong, the fear of losing money, the fear of missing out and the fear of leaving money of the table. I found that basically, those four fears accounted for probably 90% to 95% of the trading errors we make. Let’s put it this way: If you can recognize opportunity, what’s going to prevent you from executing your trades properly? Your fear. Your fears immobilize you. Your fears distort your perception of market information in ways that don’t allow you to utilize what you know.”",
    author: "Mark Douglas",
  },

  {
    title:
      "“Being right is more important than being a genius. I think one reason why so many people try to pick tops and bottoms is that they want to prove to the world how smart they are. They think about winning rather than being a hero. Forget trying to judge trading success by how close you can come to picking major tops and bottoms, but rather by how well you can pick individual trades with merit based on favorable risk/return situations and a good percentage of winners. Go for consistency on a trade-to-trade basis, not perfect trades.”",
    author: "Jack Schwager",
  },
];
