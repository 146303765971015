import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Button, Col, Input, Row, Spin,
} from 'antd';
import { toast } from 'react-toastify';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import axios from 'axios';
import { setUserData } from '../../actions/common';
import { axiosAuth } from '../../api';
import PayByRazorPay from '../RazorPay';
import {
  AlreadyAccountText,
  BackContainer,
  LoginText,
  SignUpStepOneContainer,
  SignUpStepOneParent,
  GradientText,
  NavActionButtonGroup,
  CardsParent,
  CardsContainer,
  PlanCard,
  PlanCardHeader,
  PlanCardContent,
  CenteredContainer,
  FeatureList,
  FeatureListItem,
  PriceText,
  PlanCards,
  PayContainer,
  RealPriceText,
  FeatureListInnerUl,
  FeatureListItemInnerUlLI,
  UndelinedSpan,
  PriceDiv,
  EMIText,
} from '../../styledComponents';
import {
  getEnvValue,
  getPlanDetailsById,
  triggerGAEvent,
} from '../../utils/helper';
import tick from '../../assets/tick.svg';
import close from '../../assets/close.svg';
import { BestValueTag } from '../../routes/pricing';
import { Spacer } from '../../routes/about';
import Testimonials from '../Testimonials';
import DiscountCards from '../DiscountCards';
import HeadingUnderline from '../HeadingUnderline';
import PLAN_PRICES, {
  COURSE_PLAN_DETAILS,
  SECOND_COURSE_PLAN_DETAILS,
} from '../../constants';

export const SafeCheckoutImg = styled.img`
  height: 300px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    height: 150px;
  }
`;

export const Tick = styled.img`
  width: inherit !important;
  margin-right: 5px;
  height: 14px;
  margin-bottom: 4px;
`;

export const baseUrl = getEnvValue('IMAGE_URL');
const SignUp = ({ setLoggedInUserData, noMargin }) => {
  const childRef = useRef();
  const parentDivRef = React.createRef();
  const history = useHistory();
  const tokenIsExist = useSelector((state) => state.common.authToken);
  const { search } = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [activePlan, setActivePlan] = useState(2);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [portfolio, setPortfolio] = useState('');
  const [experience, setExperience] = useState('');
  const [investorType, setInvestorType] = useState('');
  const [coupon, setCouponCode] = useState('');
  const [joinUsLoading, setJoinUsLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [couponError, setCouponError] = useState(null);
  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [planConfig, setPlanConfig] = useState(null);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [countDownDate, setCountDownDate] = useState(null);
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
    portfolio: null,
    experience: null,
    investorType: null,
  });

  const getReturnValues = (remianingCountDown) => {
    if (remianingCountDown < 0) {
      return null;
    }
    // calculate time left
    const days = Math.floor(remianingCountDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (remianingCountDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor(
      (remianingCountDown % (1000 * 60 * 60)) / (1000 * 60),
    );
    const seconds = Math.floor((remianingCountDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds, remianingCountDown];
  };

  useEffect(() => {
    let intervalData = null;
    if (countDownDate) {
      intervalData = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);

      return () => clearInterval(intervalData);
    }
    clearInterval(intervalData);
  }, [countDownDate]);

  useEffect(() => {
    if (planConfig?._id) {
      const dateFrags = planConfig.expiry.split('T');
      const timeFrags = dateFrags[1]?.split(':');
      const expiryDate = new Date(planConfig.expiry);
      expiryDate.setHours(
        timeFrags[0]
          ? timeFrags[0] < 10
            ? Number(timeFrags[0].toString().charAt(1))
            : timeFrags[0]
          : 22,
      );
      expiryDate.setMinutes(
        timeFrags[1]
          ? timeFrags[1] < 10
            ? Number(timeFrags[1].toString().charAt(1))
            : 0
          : 0,
      );
      expiryDate.setSeconds(0);
      const currentTime = new Date().getTime();
      const expiryTime = expiryDate.getTime();

      if (expiryTime > currentTime) {
        setCountDownDate(expiryDate.getTime());
      }
    }
  }, [planConfig]);

  useEffect(() => {
    if (countDown < -1200 && planConfig?._id) {
      setCountDownDate(null);
      window.location.reload();
    }
  }, [countDown, planConfig]);

  useEffect(() => {
    const planSelected = new URLSearchParams(search).get('plan_selected');
    if (planSelected) {
      setActivePlan(Number(planSelected));
    }
  }, [search]);

  useEffect(() => {
    axiosAuth
      .get('plan/config')
      .then((res) => {
        console.log(res);
        if (res?.data?.data?.length) {
          const data = res?.data?.data[0];
          if (!data?.expired) {
            setPlanConfig(data);
          } else {
            setPlanConfig(null);
          }
        } else {
          setPlanConfig(null);
        }
      })
      .catch(() => {
        setPlanConfig(null);
      });
  }, []);

  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);

  const validateError = useCallback(
    (name, value) => {
      if (
        name === 'name'
        || name === 'email'
        || name === 'password'
        || name === 'contact'
      ) {
        let modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        if (name == 'email' && value) {
          const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          modifiedErrors = {
            ...errors,
            [name]: emailRegex.test(value) ? false : 1,
          };
        }
        if (name == 'contact' && value) {
          const contactRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
          modifiedErrors = {
            ...errors,
            [name]: contactRegex.test(value) ? false : 1,
          };
        }
        if (name == 'password' && value) {
          const passwordRegex = /^[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,20}$/;
          modifiedErrors = {
            ...errors,
            [name]: passwordRegex.test(value) ? false : 1,
          };
        }

        setErrors(modifiedErrors);
      } else {
        let errorType = false;
        if (!value) {
          errorType = 1;
        } else if (password !== value) {
          errorType = 2;
        }
        const modifiedErrors = {
          ...errors,
          [name]: errorType,
        };
        setErrors(modifiedErrors);
      }
    },
    [password, errors],
  );

  const handleApplyClick = () => {
    if (coupon) {
      setApplyingCoupon(true);
      setCouponError(null);
      axiosAuth
        .post('learning/coupon/validate', { code: coupon })
        .then((res) => {
          setApplyingCoupon(false);
          if (res?.data?.data?.code) {
            setCouponCodeData(res?.data?.data);
          } else {
            setCouponError(res?.data?.message ?? 'Invalid coupon code!!');
            setCouponCodeData(null);
          }
        })
        .catch(() => {
          setCouponError('Failed to apply coupon code!!');
          setCouponCodeData(null);
          setApplyingCoupon(false);
        });
    }
  };

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === 'name') {
        setName(value);
      } else if (name === 'email') {
        setEmail(value);
      } else if (name === 'contact') {
        setContact(value);
      } else if (name === 'password') {
        setPassword(value);
      } else if (name === 'portfolio') {
        setPortfolio(value);
      } else if (name === 'confirmPassword') {
        setConfirmPassword(value);
      } else if (name === 'experience') {
        setExperience(value);
      } else if (name === 'investorType') {
        setInvestorType(value);
      } else if (name === 'coupon') {
        setCouponCode(value);
      }
      validateError(name, value);
    },
    [validateError],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [validateError],
  );

  const isSignUpDisabled = useMemo(
    () => !name
      || !email
      || !contact
      || !password
      || !confirmPassword
      || password !== confirmPassword
      || !activePlan,
    [name, email, password, confirmPassword, activePlan],
  );

  const joinUsDisabled = useMemo(
    () => !name
      || !email
      || !contact
      || !password
      || !confirmPassword
      || password !== confirmPassword
      || !termsAgreed,
    [name, email, password, confirmPassword, termsAgreed],
  );

  const handlePageNav = useCallback(
    (type) => {
      if (type === 'next') {
        if (activePage < 3) {
          setActivePage(3);
        }
      } else if (activePage > 0) {
        setActivePage(1);
      }
    },
    [activePage],
  );

  const handleJoinUsClick = useCallback(() => {
    setJoinUsLoading(true);
    axiosAuth.post('user/isUserExists', { email }).then((res) => {
      setJoinUsLoading(false);
      if (res?.data?.data) {
        toast('User already exist. Please try again with different email!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const emailAddress = email.split('@');
        const data = {
          category: 'Users',
          action: 'Join Us click phase 1',
          label: `${emailAddress[0] || ''} - ${name || ''} - ${
            contact || ''
          } - ${emailAddress[1] || ''}`,
          value: 1,
        };
        triggerGAEvent(data);

        handlePageNav('next');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }, [activePage, email, contact]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !joinUsDisabled && activePage === 1) {
        handleJoinUsClick();
      }
    },
    [joinUsDisabled],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [joinUsDisabled, activePage]);

  const handlePlanClick = useCallback(
    (planType) => {
      setActivePlan(planType);
    },
    [activePlan],
  );

  const paymentDetails = useMemo(() => {
    const paymentData = getPlanDetailsById(activePlan);
    const discountedAmount = Math.ceil(
      (paymentData?.amount * couponCodeData?.discount) / 100,
    );
    return {
      amount:
        planConfig?.expired === false
          ? planConfig[`plan${activePlan}`] * 100
          : couponCodeData?.discount && paymentData.months > 6
            ? paymentData.amount - discountedAmount
            : paymentData.amount,
      months: paymentData.months,
      planType: paymentData.planType,
    };
  }, [activePlan, couponCodeData, planConfig]);

  const discountDetails = useMemo(() => {
    const paymentData = getPlanDetailsById(activePlan);
    const discountedAmount = Math.ceil(
      (paymentData?.amount * couponCodeData?.discount) / 10000,
    ).toFixed(2);
    return couponCodeData?.discount && paymentData.months > 6
      ? `${discountedAmount} off applied!!`
      : '';
  }, [activePlan, couponCodeData]);

  const handlePaymentSuccess = (paymentResponse = {}) => {
    if (paymentResponse.razorpay_payment_id) {
      setTransactionLoading(true);
      const endDate = new Date();
      if (paymentDetails.months === 3) {
        endDate.setMonth(new Date().getMonth() + 3);
      } else if (paymentDetails.months === 6) {
        endDate.setMonth(new Date().getMonth() + 6);
      } else if (paymentDetails.months === 12) {
        endDate.setMonth(new Date().getMonth() + 12);
      }
      const signUpPayload = {
        name,
        email,
        contactNo: contact,
        password,
        portfolio,
        experience,
        investorType,
        userType: 'normal',
        payment: {
          amount: paymentDetails.amount / 100,
          txnId: paymentResponse.razorpay_payment_id,
          startDate: new Date(),
          endDate,
          planType: paymentDetails.planType,
          orderId: paymentResponse.razorpay_order_id,
        },
        active: true,
      };

      axiosAuth
        .post('auth/signup', signUpPayload)
        .then(async (res) => {
          if (
            res.data
            && res.data.status
            && res.data.data
            && res.data.data.user
            && res.data.data.token
          ) {
            toast('Registration success!!', { type: toast.TYPE.SUCCESS });
            setLoggedInUserData(res.data.data);
            localStorage.setItem('authToken', res.data.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.data.user));

            // Assigning course
            if (paymentDetails.months === 6 || paymentDetails.months === 12) {
              const userExistData = await axiosAuth.post(
                'learning/user/isUserExists',
                { email },
              );
              if (!userExistData?.data?.data) {
                const courseSignupPayload = {
                  name,
                  email,
                  contactNo: contact,
                  password,
                  userType: 'normal',
                  profileImg: '',
                  active: true,
                };

                const courseSignupData = await axiosAuth.post(
                  'learning/auth/signup',
                  courseSignupPayload,
                );

                if (
                  courseSignupData?.data?.status
                  && courseSignupData?.data?.data
                  && courseSignupData?.data?.data?.token
                ) {
                  const coursesToAssign = [
                    {
                      amount: COURSE_PLAN_DETAILS.course_amount,
                      courseId: COURSE_PLAN_DETAILS._id,
                      startDate: moment().format('YYYY/MM/DD'),
                      isRefundPeriodOver: false,
                      course_name: COURSE_PLAN_DETAILS.course_name,
                      slug: COURSE_PLAN_DETAILS.slug,
                      txnId: paymentResponse.razorpay_payment_id,
                      endDate: moment().add(12, 'months'),
                      userType: 'course',
                    },
                  ];

                  if (paymentDetails.months === 12) {
                    coursesToAssign.push({
                      amount: SECOND_COURSE_PLAN_DETAILS.course_amount,
                      courseId: SECOND_COURSE_PLAN_DETAILS._id,
                      startDate: moment().format('YYYY/MM/DD'),
                      isRefundPeriodOver: false,
                      course_name: SECOND_COURSE_PLAN_DETAILS.course_name,
                      slug: SECOND_COURSE_PLAN_DETAILS.slug,
                      txnId: paymentResponse.razorpay_payment_id,
                      endDate: moment().add(12, 'months'),
                      userType: 'course',
                    });
                  }

                  const courseAxiosAuth = axios.create({
                    baseURL: getEnvValue('API_BASE_URL'),
                    headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${courseSignupData?.data?.data?.token}`,
                    },
                  });

                  await courseAxiosAuth.post(
                    'learning/courses/purchaseCourse',
                    coursesToAssign,
                  );
                }
              }
            }

            // marking coupon code as used
            if (couponCodeData?.code) {
              axiosAuth
                .post('learning/coupon/markAsUsed', {
                  code: couponCodeData?.code,
                })
                .then((res) => {
                  setPaymentLoading(false);
                  handleLinkClick('/thank-you');
                })
                .catch(() => {
                  setPaymentLoading(false);
                  handleLinkClick('/thank-you');
                });
            } else {
              handleLinkClick('/thank-you');
              setPaymentLoading(false);
              setTransactionLoading(false);
            }
          } else {
            setPaymentLoading(false);
            setTransactionLoading(false);
            toast(
              res?.data?.message
                || 'Failed to register. Please contact admin for futher support!',
              { type: toast.TYPE.ERROR },
            );
          }
        })
        .catch(() => {
          toast(
            'Failed to register. Please contact admin for futher support!',
            { type: toast.TYPE.ERROR },
          );
          setTransactionLoading(false);
        });
    }
  };

  const createOrderandPay = () => {
    setFormLoading(true);
    axiosAuth
      .post('/order/create', {
        amount: paymentDetails.amount,
        name,
        email,
      })
      .then((res) => {
        childRef.current.handlePayment(res?.data?.data?.orderId);
        setFormLoading(false);
      })
      .catch((err) => {
        toast('Failed to complete payment process. Please try again', {
          type: toast.TYPE.ERROR,
        });
        setFormLoading(false);
        const emailAddress = email.split('@');
        const data = {
          category: 'Payment',
          action: 'Payment Failed',
          label: `${emailAddress[0] || ''} - ${name || ''} - ${
            contact || ''
          } - ${emailAddress[1] || ''}`,
          value: paymentDetails.amount,
        };
        triggerGAEvent(data);
      });
  };

  const timerData = getReturnValues(countDown);

  const getActivePageFields = () => {
    const isDataAvailable = portfolio || experience || investorType;
    if (activePage === 1) {
      return (
        <SignUpStepOneContainer className="leadform" noMargin>
          <HeadingUnderline title="Join Us" />
          <SignUpStepOneParent>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control
                value={name}
                name="name"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Enter Full Name"
                isInvalid={errors.name}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                Name is required!!
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Email address</Form.Label>
              <Form.Control
                value={email}
                name="email"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="email"
                placeholder="Enter email"
                isInvalid={errors.email}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email === 1 ? 'Invalid Email' : 'Email is required!!'}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Contact</Form.Label>
              <Form.Control
                value={contact}
                name="contact"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="number"
                placeholder="Enter Contact Number"
                isInvalid={errors.contact}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact === 1
                  ? 'Invalid contact number'
                  : 'Contact is required!!'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Password</Form.Label>
              <Form.Control
                value={password}
                name="password"
                className="custom-field-focus"
                onBlur={handleBlur}
                onChange={handleInputChange}
                type="password"
                placeholder="Enter password"
                autoComplete="off"
                isInvalid={errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password === 1
                  ? 'Password must be of 8-20 characters'
                  : 'Password is required!!'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Confirm Password</Form.Label>
              <Form.Control
                value={confirmPassword}
                name="confirmPassword"
                className="custom-field-focus"
                onBlur={handleBlur}
                onChange={handleInputChange}
                type="password"
                placeholder="Confirm password"
                isInvalid={errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword === 1
                  ? 'Confirm password is required!!'
                  : 'Passwords do no match!!'}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Checkbox
                checked={termsAgreed}
                onChange={() => setTermsAgreed(!termsAgreed)}
              >
                I agree to accept the
                {' '}
                <Link to="/terms-and-conditions">Terms & Conditions,</Link>
                {' '}
                <Link to="/privacy-policy">Privacy Policy,</Link>
                {' '}
                and
                {' '}
                <Link to="/disclaimer">Disclaimer</Link>
              </Checkbox>
            </Form.Group>
            <Button
              disabled={joinUsDisabled}
              onClick={handleJoinUsClick}
              className="signUpBtn"
              style={{ marginTop: '15px' }}
              loading={joinUsLoading}
              size="large"
              block
            >
              Join Us
            </Button>
            {!noMargin && (
              <AlreadyAccountText onClick={() => handleLinkClick('/login')}>
                Already have an account?
                {' '}
                <LoginText>Login Now</LoginText>
              </AlreadyAccountText>
            )}
          </SignUpStepOneParent>
        </SignUpStepOneContainer>
      );
    }

    if (activePage === 2) {
      return (
        <SignUpStepOneContainer>
          <SignUpStepOneParent>
            <Form.Group>
              <Form.Label>Portfolio Value</Form.Label>
              <Form.Control
                name="portfolio"
                className="custom-field-focus"
                onChange={handleInputChange}
                value={portfolio}
                as="select"
              >
                <option value="" disabled>
                  Choose total portfolio value
                </option>
                <option value="1">Under 100000</option>
                <option value="2">100000 - 250000</option>
                <option value="3">250000 - 500000</option>
                <option value="4">500000 - 1000000</option>
                <option value="5">Above 1000000</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>How long you have been investing?</Form.Label>
              <Form.Control
                name="experience"
                className="custom-field-focus"
                onChange={handleInputChange}
                value={experience}
                as="select"
              >
                <option value="" disabled>
                  Total experience
                </option>
                <option value="1">Less than 1 year</option>
                <option value="2">1 - 3 years</option>
                <option value="3">3 - 5 years</option>
                <option value="4">5 - 10 years</option>
                <option value="5">More than 10 years</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Investing Preference</Form.Label>
              <Form.Control
                name="investorType"
                className="custom-field-focus"
                onChange={handleInputChange}
                value={investorType}
                as="select"
              >
                <option value="" disabled>
                  Choose your preference
                </option>
                <option value="1">Short Term</option>
                <option value="2">Long Term</option>
                <option value="3">Intraday</option>
              </Form.Control>
            </Form.Group>
            <NavActionButtonGroup>
              <BackContainer onClick={() => handlePageNav('prev')}>
                <ArrowLeftOutlined className="go-back-icon" />
                <GradientText>Go back</GradientText>
              </BackContainer>
            </NavActionButtonGroup>
            <Button
              onClick={handleJoinUsClick}
              className="signUpBtn"
              size="large"
              block
            >
              {isDataAvailable ? 'Proceed' : 'Skip'}
            </Button>
          </SignUpStepOneParent>
        </SignUpStepOneContainer>
      );
    }

    if (activePage === 3) {
      return (
        <CardsParent className="leadform">
          {formLoading && (
            <LoadingDiv>
              <LoaderContent>
                <Spin
                  indicator={(
                    <LoadingOutlined
                      style={{ fontSize: 34, color: 'white' }}
                      spin
                    />
                  )}
                />
                <br />
                <p style={{ fontSize: '20px', color: 'white' }}>
                  Please wait...
                </p>
              </LoaderContent>
            </LoadingDiv>
          )}
          <CardsContainer>
            <Row
              justify="center"
              style={{
                marginBottom: planConfig?.expired === false
                && countDown > -1200
                && countDown !== 0
                && timerData ? '20px' : '45px',
              }}
            >
              <DiscountCards />
            </Row>
            <Row justify="center">
              {planConfig?.expired === false
                && countDown > -1200
                && countDown !== 0
                && timerData && (
                  <div style={{ marginBottom: '45px' }}>
                    Offer expiring in
                    {' '}
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                      {timerData[1] < 10 ? `0${timerData[1]}` : timerData[1]}
                      Hr&nbsp;:&nbsp;
                      {timerData[2] < 10 ? `0${timerData[2]}` : timerData[2]}
                      Min&nbsp;:&nbsp;
                      {timerData[3] < 10 ? `0${timerData[3]}` : timerData[3]}
                      Sec
                    </span>
                  </div>
              )}
            </Row>
            <Row>

              {/* <Col xs={24} md={8} className="plan-card">
                <PlanCard
                  active={activePlan === 1}
                  onClick={() => {
                    handlePlanClick(1);
                    if (activePlan === 1) {
                      createOrderandPay();
                    }
                  }}
                >
                  <PlanCardHeader>3 Months Membership</PlanCardHeader>
                  <PlanCardContent>
                    <PriceDiv>
                      {planConfig?.plan1 ? (
                        <>
                          <PriceText>
                            ₹
                            {PLAN_PRICES.three.base}
                          </PriceText>
                          &nbsp; &nbsp;
                          <RealPriceText>
                            ₹
                            {planConfig.plan1}
                          </RealPriceText>
                        </>
                      ) : (
                        <RealPriceText>
                          ₹
                          {PLAN_PRICES.three.base}
                        </RealPriceText>
                      )}
                    </PriceDiv>
                    <EMIText>&nbsp;</EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(1)}
                        className="get-started-btn"
                        size="large"
                      >
                        {activePlan === 1 ? 'Go' : 'Select'}
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col> */}
              <Col xs={24} md={8} className="plan-card">
                <PlanCard
                  active={activePlan === 2}
                  onClick={() => {
                    handlePlanClick(2);
                    if (activePlan === 2) {
                      createOrderandPay();
                    }
                  }}
                >
                  <PlanCardHeader>6 Months Membership</PlanCardHeader>
                  {/* <BestValueTag>Most Popular</BestValueTag> */}
                  <PlanCardContent>
                    <PriceDiv>
                      {planConfig?.plan2 ? (
                        <>
                          <PriceText>
                            ₹
                            {PLAN_PRICES.six.base}
                          </PriceText>
                          &nbsp; &nbsp;
                          <RealPriceText>
                            ₹
                            {planConfig.plan2}
                          </RealPriceText>
                        </>
                      ) : (
                        <RealPriceText>
                          ₹
                          {PLAN_PRICES.six.base}
                        </RealPriceText>
                      )}
                    </PriceDiv>
                    <EMIText>
                      6 months no cost EMI at ₹
                      {PLAN_PRICES.six.emi}
                      /month
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem isDisabled>
                          <Tick src={close} />
                          "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(2)}
                        className="get-started-btn"
                        size="large"
                      >
                        {activePlan === 2 ? 'Go' : 'Select'}
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
              <Col xs={24} md={8} className="plan-card">
                <PlanCard
                  active={activePlan === 3}
                  exclusive
                  onClick={() => {
                    handlePlanClick(3);
                    if (activePlan === 3) {
                      createOrderandPay();
                    }
                  }}
                >
                  <PlanCardHeader>12 Months Membership</PlanCardHeader>
                  <PlanCardContent>
                    <PriceDiv>
                      {planConfig?.plan3 ? (
                        <>
                          <PriceText>
                            ₹
                            {PLAN_PRICES.twelve.base}
                          </PriceText>
                          &nbsp; &nbsp;
                          <RealPriceText>
                            ₹
                            {planConfig.plan3}
                          </RealPriceText>
                        </>
                      ) : (
                        <RealPriceText>
                          ₹
                          {PLAN_PRICES.twelve.base}
                        </RealPriceText>
                      )}
                    </PriceDiv>
                    <EMIText>
                      6 months no cost EMI at
                      {' '}
                      {PLAN_PRICES.twelve.emi}
                      /month
                    </EMIText>
                    <CenteredContainer>
                      <FeatureList>
                        <FeatureListItem className="main-point">
                          <Tick src={tick} />
                          Community Forum where you get:
                          <FeatureListInnerUl>
                            <FeatureListItemInnerUlLI>
                              Trades we take
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Stop loss of our trades
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Reason for taking a trade
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Our watchlist
                            </FeatureListItemInnerUlLI>
                            <FeatureListItemInnerUlLI>
                              Educational content
                            </FeatureListItemInnerUlLI>
                          </FeatureListInnerUl>
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          Our monthly newsletter
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Profitable Trading Masterclass"
                          <br />
                          {' '}
                          course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                        <FeatureListItem>
                          <Tick src={tick} />
                          "Trading Psychology Masterclass" course for&nbsp;
                          <UndelinedSpan>FREE</UndelinedSpan>
                          !
                        </FeatureListItem>
                      </FeatureList>
                    </CenteredContainer>
                    <CenteredContainer>
                      <Button
                        onClick={() => handlePlanClick(3)}
                        className="get-started-btn"
                        size="large"
                      >
                        {activePlan === 3 ? 'Go' : 'Select'}
                      </Button>
                    </CenteredContainer>
                  </PlanCardContent>
                </PlanCard>
              </Col>
            </Row>

            <PayContainer>
              {/* <div>
                <Input.Group compact>
                  <Input
                    value={coupon}
                    name="coupon"
                    style={{
                      width: '250px',
                      marginBottom: '15px',
                      height: '32px',
                    }}
                    className="custom-field-focus"
                    onChange={handleInputChange}
                    placeholder="Enter coupon code"
                  />
                  <Button
                    disabled={paymentDetails.months < 7}
                    loading={applyingCoupon}
                    onClick={handleApplyClick}
                    className="get-started-btn apply-button"
                    type="primary"
                  >
                    Apply
                  </Button>
                </Input.Group>
                {couponError && (
                  <p
                    style={{
                      color: 'red',
                      marginTop: '-15px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {couponError}
                  </p>
                )}
                {couponCodeData?.code && (
                  <p
                    style={{
                      color: paymentDetails.months > 6 ? 'green' : 'red',
                      marginTop: '-15px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {paymentDetails.months > 6 ? (
                      <span>
                        &#8377;
                        {discountDetails}
                      </span>
                    ) : (
                      'Not applicable on 6 months membership plan!!'
                    )}
                  </p>
                )}
              </div> */}

              <PayByRazorPay
                ref={childRef}
                description={`${paymentDetails.planType} Plan`}
                amount={paymentDetails.amount}
                handlePaymentSuccess={handlePaymentSuccess}
                name={name}
                contact={contact}
                email={email}
                isDisabled={isSignUpDisabled}
                paymentLoading={paymentLoading}
                setPaymentLoading={setPaymentLoading}
              />
              <Row>
                <Col offset={1}>
                  <NavActionButtonGroup style={{ marginTop: '10px' }}>
                    <BackContainer onClick={() => handlePageNav('prev')}>
                      <ArrowLeftOutlined className="go-back-icon" />
                      <GradientText>Go back</GradientText>
                    </BackContainer>
                  </NavActionButtonGroup>
                </Col>
              </Row>
              <Row>
                <Container>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {/* <SafeCheckoutImg src={`${baseUrl}/gsc.png`} /> */}
                    {/* <p>
                      To know more about our refund policy,{" "}
                      <Link to='/refund-policy'>click here</Link>
                    </p> */}
                  </div>
                </Container>
              </Row>
            </PayContainer>
          </CardsContainer>
        </CardsParent>
      );
    }
  };

  return (
    <div ref={parentDivRef}>
      {transactionLoading && (
        <BlackLoader>
          <Spin
            indicator={(
              <LoadingOutlined
                style={{
                  fontSize: isMobileOnly ? 30 : 50,
                  color: '#f9ae00',
                  marginBottom: '15px',
                }}
                spin
              />
            )}
          />
          <TransactionProcess>
            Please wait while your transaction is in process.
            <br />
            Do not refresh your browser or press the back button.
          </TransactionProcess>
        </BlackLoader>
      )}
      {getActivePageFields()}
      <Spacer />
      {activePage === 3 ? (
        <Row className="testimonials-brief-bg grey-bg">
          <Col span={24}>
            <Testimonials showNoMargin showGreyBg />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoggedInUserData: (userData) => dispatch(setUserData(userData)),
});

export default connect(null, mapDispatchToProps)(SignUp);

const LoadingDiv = styled.div`
  z-index: 9;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlackLoader = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TransactionProcess = styled.h5`
  color: white;
  text-align: center;
`;
