import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Container } from 'react-bootstrap';
import { slide as Hamburger } from 'react-burger-menu';
import {
  Button,
  Col,
  Divider,
  Row,
  Form,
  InputNumber,
  Tag,
  Upload,
  notification,
  Radio,
  Spin,
  Collapse,
  Input,
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  MenuOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import * as R from 'ramda';
import './post.scss';
import { Editor } from 'react-draft-wysiwyg';
import { useHistory } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/lib/input/TextArea';
import Lightbox from 'react-image-lightbox';
import { uuid } from 'uuidv4';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { TRADE_TAGS } from './constants';
import { AsyncS3Upload } from '../../utils/s3Uploader';
import { axiosAuth } from '../../api';
import LoadingSkeleton from './LoadingSkeleton';
import PostItem from './PostItem';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  capitalizeText,
  checkImageSize,
  setFileListBySize,
  logoutUserHandler,
} from '../../utils/helper';
import {
  PostsContainer,
  MainBox,
  LeftSidebarProfile,
  LeftSidebarProfileHeader,
  UserImg,
  CenteredDiv,
  UserName,
  UserPlan,
  PostBox,
  RightMainBox,
  PostInputBox,
  PostBoxImg,
  FiltersDiv,
  SelectFilterHeading,
  SelectFilterText,
  RolesLink,
  MainBoxParentLeft,
  MainBoxFull,
} from './styled';
import RulesModal from './RulesModal';
import logo1 from '../../assets/logo1.svg';
import AuthorBioModal from './AuthorBioModal';
import BottomTabNavigator from '../../components/BottomTabNavigator';
import defaultUser from '../../assets/defaultUser.jpg';
import MenuBar from './MenuBar';
import { logout } from '../../actions/common';
import QuantityCalculator from '../../components/QuantityCalculator';

const Posts = ({ logoutUser }) => {
  const hasMore = useRef(false);
  const currentPage = useRef(0);
  const user = useSelector((state) => state.common.user);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const [showPostModal, togglePostModal] = useState(false);
  const [title, setTitle] = useState('');
  const [target1, setTarget1] = useState('');
  const [target2, setTarget2] = useState('');
  const [risk, setRisk] = useState('');
  const [stopLoss, setStopLoss] = useState('');
  const [lightboxImage, setLightBoxImage] = useState([]);
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [selectedTags, setTags] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [parentFilter, setParentFilter] = useState('');
  const [loadingMore, setloadingMore] = useState(false);
  const [showCommentBox, setCommentBox] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [editorData, setEditorData] = useState(EditorState.createEmpty());
  const [editId, setEditId] = useState(false);
  const [isMenuOpen, toggleHamburger] = useState(false);
  const [showRulesModal, handleRulesModalToggle] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [activeKey, changeKey] = useState(0);
  const [isOpenBioModal, setOpenBioModal] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showChildCommentBox, setChildCommentBox] = useState(null);
  const [likeLoading, setLikeLoading] = useState(false);
  const [isAddingComment, toggleAddingComment] = useState(null);
  const [isAddingReply, toggleAddingReply] = useState(null);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const childRef = useRef();
  const history = useHistory();
  const { Panel } = Collapse;

  const fetchMore = (explicitPage) => {
    if (!hasMore.current || isInitialLoad) {
      return;
    }
    const endpoint = `/post/allPosts?verificationStatus=approved&offset=${
      (explicitPage ?? currentPage.current) * 10
    }&limit=10`;
    const payload = {};
    if (parentFilter) {
      payload.postedByRole = parentFilter;
    }
    if (activeFilters?.length) {
      payload.tags = activeFilters;
    }
    axiosAuth
      .post(endpoint, payload)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllPosts(res?.data?.data);
            currentPage.current += 1;
          }
          if (res?.data?.data?.length < 10) {
            hasMore.current = false;
          }
        }
        setloadingMore(false);
        setLoading(false);
      })
      .catch((err) => {
        setloadingMore(false);
        setLoading(false);
      });
  };

  const [sentryRef] = useInfiniteScroll(
    {
      loading: loadingMore,
      hasNextPage: hasMore.current,
      onLoadMore: fetchMore,
      // When there is an error, we stop infinite loading.
      // It can be reactivated by setting "error" state as undefined.
      disabled: !hasMore.current || loadingMore || loading,
      // `rootMargin` is passed to `IntersectionObserver`.
      // We can use it to trigger 'onLoadMore' when the sentry comes near to become
      // visible, instead of becoming fully visible on the screen.
      rootMargin: '0px 0px 150px 0px',
    },
    [currentPage, hasMore, loadingMore, loading],
  );

  const getPostsList = () => {
    if (!R.isEmpty(user) && !user?.agree) {
      history.push('/community-terms-and-conditions');
    }
    setLoading(true);
    const endpoint = `/post/allPosts?verificationStatus=approved&offset=${
      currentPage.current * 10
    }&limit=10`;
    const payload = {};
    if (parentFilter) {
      payload.postedByRole = parentFilter;
    }
    if (activeFilters?.length) {
      payload.tags = activeFilters;
    }
    axiosAuth
      .post(endpoint, payload)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllPosts(res?.data?.data);
            currentPage.current += 1;
            hasMore.current = true;
          }
          if (res?.data?.data?.length < 10) {
            hasMore.current = false;
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to load posts. Please try again later!!',
          });
        }
        setLoading(false);
        setInitialLoad(false);
      })
      .catch((err) => {
        setLoading(false);
        setInitialLoad(false);
      });
  };

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    if (user && user?._id) {
      getPostsList();
    }
  }, [user]);

  useEffect(() => {
    const finalDataNew = R.uniqBy(R.prop('_id'), [...finalData, ...allPosts]);
    setFinalData(finalDataNew);
  }, [allPosts]);

  useEffect(() => {
    currentPage.current = 0;
    setLoading(true);
    setAllPosts([]);
    setFinalData([]);
    setloadingMore(false);
    hasMore.current = true;
    setCommentBox(null);
    fetchMore(0);
  }, [activeFilters]);

  useEffect(() => {
    fetchMore(0);
  }, [parentFilter]);

  const showHidePostModal = (value) => {
    if (!value) {
      return;
    }
    togglePostModal(true);
  };

  const handleTagClick = (tag) => {
    const itemIndex = selectedTags.findIndex((item) => item == tag);
    if (itemIndex > -1) {
      setTags(R.remove(itemIndex, 1, selectedTags));
    } else {
      setTags([...selectedTags, tag]);
    }
  };

  const resetData = () => {
    togglePostModal(false);
    setTitle('');
    setTarget1('');
    setTarget2('');
    setRisk('');
    setStopLoss('');
    setTags([]);
    setFileList([]);
    setEditorData(EditorState.createEmpty());
    setEditId(null);
    setNotificationTitle('');
    setNotificationBody('');
    setShowDisclaimer(false);
  };

  const handleData = useCallback(
    (e) => {
      const { items } = e.clipboardData || e.originalEvent.clipboardData;
      let blob = null;
      let filetype = '';
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === 0) {
          blob = items[i].getAsFile();
          filetype = items[i].type;
          break;
        }
      }
      // load image if there is a pasted image
      if (blob !== null) {
        if (filesList.length < 4) {
          const reader = new FileReader();
          reader.onload = function (event) {
            fetch(event.target.result)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], 'clipboard-image.png', {
                  type: filetype,
                });
                const fileObj = {
                  type: filetype,
                  thumbUrl: URL.createObjectURL(file),
                  uid: uuid(),
                  percent: 100,
                  originFileObj: file,
                  status: 'done',
                  name: file.name,
                };
                setFileList([...filesList, fileObj]);
              });
          };
          reader.readAsDataURL(blob);
        } else {
          notification.error({
            message: 'Clipboard paste error',
            description: 'Sorry, maximum image upload limit is 4',
          });
        }
      }
    },
    [filesList],
  );

  const handlePreview = async (file) => {
    setLightBoxImage([
      file?.originFileObj
        ? URL.createObjectURL(file.originFileObj)
        : file?.thumbUrl,
    ]);
    toggleLightBox(true);
  };

  const handleRemove = async (file) => {
    if (file?.alreadyUploaded) {
      const index = filesList.findIndex(
        (item) => item.thumbUrl === file.thumbUrl,
      );
      setFileList(R.remove(index, 1, filesList));
      return false;
    }
    return true;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSavePost = () => {
    let errMsg = '';
    const titleContent = draftToHtml(
      convertToRaw(editorData.getCurrentContent()),
    ).toString();
    if (user?.userType === 'admin') {
      if (!titleContent) {
        notification.error({
          message: 'Create Post Error',
          description: 'Please enter title!!',
        });
        return;
      }
    }
    if (!title && user?.userType != 'admin') {
      errMsg = 'Please enter title!!';
    } else if (!filesList?.length && user?.userType !== 'admin') {
      errMsg = 'Please upload at least one chart image!!';
    } else if (!selectedTags?.length) {
      errMsg = 'Please select at least one tag!!';
    } else if (
      user?.userType === 'admin'
      && notificationBody
      && !notificationTitle
    ) {
      errMsg = 'Please enter notification title';
    }
    if (errMsg) {
      notification.error({
        message: 'Create Post Error',
        description: errMsg,
      });
      return;
    }
    setLoading(true);
    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq('alreadyUploaded', true)),
        R.map(R.prop('thumbUrl')),
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      let payload = {
        postedBy: user?._id,
        verificationStatus: user?.userType == 'admin' ? 'approved' : 'pending',
        title:
          user?.userType == 'admin'
            ? draftToHtml(
              convertToRaw(editorData.getCurrentContent()),
            ).toString()
            : title,
        images: alreadyUploadedImages.concat(images),
        targetPrice1: target1,
        targetPrice2: target2,
        risk,
        stopLoss,
        postedByRole: user?.userType == 'admin' ? 'admin' : 'user',
        comments: [],
        tags: selectedTags,
        showDisclaimer: user?.userType == 'admin' ? showDisclaimer : false,
      };
      if (editId) {
        payload._id = editId;
        delete payload.comments;
      }

      if (user?.userType === 'admin' && notificationTitle) {
        payload = {
          ...payload,
          notification: {
            title: notificationTitle,
            body: notificationBody,
          },
        };
      }

      const apiProvider = !editId ? axiosAuth.post : axiosAuth.patch;
      apiProvider(editId ? 'post/update' : 'post/create', payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (editId) {
              notification.success({
                message: 'Success',
                description: 'Post updated successfully!!',
              });
              const updatedData = R.update(
                R.findIndex(R.propEq('_id', editId))(finalData),
                res?.data?.data,
                finalData,
              );
              setFinalData(updatedData);
            } else {
              if (user?.userType == 'admin') {
                setFinalData(R.insert(0, res?.data?.data, finalData));
              }
              notification.success({
                message: 'Success',
                description: 'Post created successfully and is under review!!',
              });
            }
            resetData();
          } else {
            notification.error({
              message: 'Error',
              description: 'Failed to post. Please try again later!!',
            });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }).catch(() => {
      notification.error({
        message: 'Failure',
        description: 'Failed to create post!!',
      });
      setLoading(false);
    });
  };

  const handleUserType = (target) => {
    window.scrollTo(0, 0);
    currentPage.current = 0;
    setLoading(true);
    setAllPosts([]);
    setFinalData([]);
    setloadingMore(false);
    hasMore.current = true;
    setParentFilter(target?.value);
    setCommentBox(null);
  };

  const handleFilterTagClick = (item) => {
    window.scrollTo(0, 0);
    const itemIndex = activeFilters.findIndex((tag) => tag == item);
    if (itemIndex > -1) {
      setActiveFilters(R.remove(itemIndex, 1, activeFilters));
    } else {
      setActiveFilters([...activeFilters, item]);
    }
  };

  const handleSendComment = (content, postId, setMethod, index) => {
    const payload = {
      commentedBy: user?._id,
      content,
      postId,
    };
    if (!content) {
      return;
    }
    toggleAddingComment(postId);
    axiosAuth
      .post('post/createComment', payload)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setMethod('');
          const updatedData = R.update(
            R.findIndex(R.propEq('_id', postId))(finalData),
            res?.data?.data,
            finalData,
          );
          setFinalData(updatedData);
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to add comment. Please try again later!!',
          });
        }
        toggleAddingComment(null);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Failed to add comment. Please try again later!!',
        });
        toggleAddingComment(null);
      });
  };

  const handleReplayComment = (
    content,
    postId,
    commentId,
    setMethod,
    index,
  ) => {
    if (!content) {
      return;
    }
    toggleAddingReply({
      postId,
      commentId,
    });
    const payload = {
      commentedBy: user?._id,
      content,
      postId,
      commentId,
    };
    axiosAuth
      .post('post/createCommentReply', payload)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setMethod('');
          const updatedData = R.update(
            R.findIndex(R.propEq('_id', postId))(finalData),
            res?.data?.data,
            finalData,
          );
          setFinalData(updatedData);
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to add reply. Please try again later!!',
          });
        }
        toggleAddingReply(null);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Failed to add reply. Please try again later!!',
        });
        toggleAddingReply(null);
      });
  };

  const handleEditPost = (e, data) => {
    e.preventDefault();
    setTarget1(data?.targetPrice1);
    setTarget2(data?.targetPrice2);
    setRisk(data?.risk);
    setStopLoss(data?.stopLoss);
    setTags(data?.tags ?? []);
    setFileList(
      data?.images?.map((item) => ({ thumbUrl: item, alreadyUploaded: true })),
    );
    if (user?.userType == 'admin') {
      const blocksFromHTML = convertFromHTML(data?.title);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorData(EditorState.createWithContent(state));
    } else {
      setTitle(data?.title);
    }
    togglePostModal(true);
    setEditId(data?._id);
    setShowDisclaimer(data?.showDisclaimer);
  };

  const handleDeletePost = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this post?')) {
      axiosAuth
        .delete(`post/delete/${id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Post deleted successfully!!',
            });
            const itemIndex = finalData.findIndex((item) => item._id === id);
            const updatedData = R.remove(itemIndex, 1, finalData);
            setFinalData(updatedData);
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete post. Please try again!!',
          });
        });
    }
  };

  const handleDeleteComment = (e, postData, commentData) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this comment?')) {
      axiosAuth
        .delete(
          `post/deleteComment?postId=${postData._id}&commentId=${commentData._id}`,
        )
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Comment deleted successfully!!',
            });

            const comments = R.pipe(R.prop('comments'))(postData);

            const itemIndex = comments.findIndex(
              (item) => item._id == commentData._id,
            );
            const modifiedComments = R.remove(itemIndex, 1, comments);

            const updatedDataModify = {
              ...postData,
              comments: modifiedComments,
            };

            const postIndex = finalData.findIndex(
              (item) => item._id == postData._id,
            );

            const updatedData = R.update(
              postIndex,
              updatedDataModify,
              finalData,
            );

            setFinalData(updatedData);
          } else {
            notification.error({
              message: 'Error',
              description: 'Failed to delete comment. Please try again!!',
            });
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete comment. Please try again!!',
          });
        });
    }
  };

  const handleDeleteReplayComment = (e, postData, commentData, item) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this comment?')) {
      axiosAuth
        .delete(
          `post/deleteCommentReply?postId=${postData._id}&commentId=${item._id}&replyCommentId=${commentData._id}`,
        )
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Comment Reply deleted successfully!!',
            });
            const updatedData = R.update(
              R.findIndex(R.propEq('_id', postData._id))(finalData),
              res?.data?.data,
              finalData,
            );
            setFinalData(updatedData);
          } else {
            notification.error({
              message: 'Error',
              description:
                'Failed to delete replay comment. Please try again!!',
            });
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete replay comment. Please try again!!',
          });
        });
    }
  };

  const MainUserBox = () => (
    <>
      <MainBox isMobile={isMobile}>
        <LeftSidebarProfile>
          <LeftSidebarProfileHeader />
          <CenteredDiv>
            <UserImg
              className="cursor-pointer"
              src={user?.profileImg || defaultUser}
              onClick={() => history.push('/profile')}
            />
            <UserName
              className="cursor-pointer"
              onClick={() => history.push('/profile')}
            >
              {capitalizeText(user?.name ?? '')}
            </UserName>
            <UserPlan>
              <Row justify="center">
                <div className="mr-1">{user?.investorType ?? 'Author bio'}</div>
                <EditOutlined
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleHamburger(false);
                    setOpenBioModal(true);
                  }}
                />
              </Row>
            </UserPlan>
          </CenteredDiv>
        </LeftSidebarProfile>
        <div>
          <SelectFilterHeading className="mt-10">
            Trade Tag Filters
          </SelectFilterHeading>
          <FiltersDiv>
            <Collapse
              className="site-collapse-custom-collapse"
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              activeKey={activeKey}
              onChange={(key) => changeKey(key)}
            >
              <Panel
                className="site-collapse-custom-panel"
                header="Select tag to filter"
                key={1}
              >
                {TRADE_TAGS.map((item) => (
                  <Tag
                    key={item}
                    onClick={() => handleFilterTagClick(item)}
                    className="tag-item"
                    color={activeFilters.includes(item) ? '#F9AE00' : 'gold'}
                  >
                    {item}
                  </Tag>
                ))}
              </Panel>
            </Collapse>
          </FiltersDiv>
        </div>
      </MainBox>

      <Row className="mt-10 ml-2 action-buttons-box">
        <RolesLink
          onClick={() => {
            childRef.current.toggleCalcModal(true);
            toggleHamburger(false);
          }}
        >
          Trade Calculator
        </RolesLink>
      </Row>
      <Row className="mt-10 ml-2 action-buttons-box">
        <RolesLink
          onClick={() => {
            handleRulesModalToggle(true);
            toggleHamburger(false);
          }}
        >
          Show Rules
        </RolesLink>
      </Row>
      <Row className="mt-10 ml-2 mb-2 action-buttons-box">
        <RolesLink
          onClick={() => {
            logoutUserHandler(logoutUser, history);
          }}
        >
          Log Out
        </RolesLink>
      </Row>
    </>
  );

  const favorateHandler = (data, isFav) => {
    setFavoriteLoading(data._id);
    axiosAuth
      .post(`post/makeFavorite?isFavorite=${isFav}`, { postId: data._id })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification.success({
            message: 'Success',
            description: isFav
              ? 'Added to favorites'
              : 'Removed from favorites',
          });
          if (parentFilter === 'favorite') {
            const itemIndex = finalData.findIndex(
              (item) => item._id == data._id,
            );
            if (itemIndex > -1) {
              setFinalData(R.remove(itemIndex, 1, finalData));
            }
          } else {
            const updatedData = R.update(
              R.findIndex(R.propEq('_id', data._id))(finalData),
              res?.data?.data,
              finalData,
            );
            setFinalData(updatedData);
          }
        } else {
          notification.error({
            message: 'Error',
            description:
              'Failed to mark post as favorite. Please try again later!!',
          });
        }
        setFavoriteLoading(false);
      })
      .catch((err) => {
        setFavoriteLoading(false);
      });
  };

  const likeHandler = (id, status) => {
    setLikeLoading(id);
    axiosAuth
      .post(`post/likePost?isLike=${status}`, { postId: id })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          const updatedData = R.update(
            R.findIndex(R.propEq('_id', id))(finalData),
            res?.data?.data,
            finalData,
          );
          setFinalData(updatedData);
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to like post. Please try again later!!',
          });
        }
        setLikeLoading(false);
      })
      .catch((err) => {
        setLikeLoading(false);
      });
  };

  const handleReplyClick = (id) => {
    if (
      showChildCommentBox?.postId == id?.postId
      && showChildCommentBox?.commentId == id?.commentId
    ) {
      setChildCommentBox(null);
      return;
    }
    setChildCommentBox(id);
  };

  return (
    <PostsContainer isMobileOnly={isMobile} className="posts">
      <QuantityCalculator ref={childRef} />
      <RulesModal
        showRulesModal={showRulesModal}
        handleToggle={(value) => handleRulesModalToggle(value)}
      />
      <AuthorBioModal
        isOpenBioModal={isOpenBioModal}
        resetData={(data) => setOpenBioModal(data)}
        alreadyAuthoreData={user?.investorType}
      />
      {isMobile && (
        <div>
          <Hamburger
            onOpen={() => toggleHamburger(true)}
            onClose={() => toggleHamburger(false)}
            isOpen={isMenuOpen}
          >
            <div>{MainUserBox()}</div>
          </Hamburger>
        </div>
      )}
      <Modal
        maskClosable={false}
        onCancel={() => resetData()}
        visible={showPostModal}
        title={editId ? 'Edit Post' : 'Create Post'}
        className="create-post-modal"
        footer={[
          <Button
            loading={loading}
            className="save-post-button"
            onClick={handleSavePost}
          >
            Post
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item label="Title" required>
                {user?.userType == 'admin' ? (
                  <>
                    <Editor
                      editorState={editorData}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(editorState) => {
                        setEditorData(editorState);
                      }}
                    />
                    <TextArea
                      placeholder="Paste chart image from clipboard"
                      allowClear
                      value={title}
                      onChange={() => undefined}
                      autoSize={{
                        minRows: 2,
                        maxRows: 4,
                      }}
                      onPaste={handleData}
                    />
                  </>
                ) : (
                  <TextArea
                    placeholder="Share your trade/Paste chart image from clipboard"
                    allowClear
                    value={title}
                    onChange={({ target }) => setTitle(target?.value)}
                    autoSize={{
                      minRows: 2,
                      maxRows: 4,
                    }}
                    onPaste={handleData}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Upload Charts" required>
                <Upload
                  action=""
                  accept=".png,.jpg,.jpeg,image/png,image/jpeg"
                  listType="picture-card"
                  fileList={filesList}
                  onPreview={handlePreview}
                  beforeUpload={(file) => {
                    checkImageSize(file);
                  }}
                  onChange={({ fileList }) => {
                    setFileListBySize(fileList, setFileList);
                  }}
                  onRemove={handleRemove}
                >
                  {filesList.length >= 4 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Risk (%)">
                <InputNumber
                  min={0}
                  max={user?.userType == 'admin' ? 100 : 2}
                  value={risk}
                  onChange={(value) => setRisk(value)}
                  placeholder="Risk"
                  step="0.01"
                  onBlur={() => risk && setRisk(parseFloat(risk).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Target Price 1">
                <InputNumber
                  min={0}
                  value={target1}
                  onChange={(value) => setTarget1(value)}
                  placeholder="Target 1"
                  step="0.01"
                  onBlur={() => target1 && setTarget1(parseFloat(target1).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Target Price 2">
                <InputNumber
                  min={0}
                  value={target2}
                  onChange={(value) => setTarget2(value)}
                  placeholder="Target 2"
                  step="0.01"
                  onBlur={() => target2 && setTarget2(parseFloat(target2).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Stop Loss">
                <InputNumber
                  min={0}
                  value={stopLoss}
                  onChange={(value) => setStopLoss(value)}
                  placeholder="Stop Loss"
                  step="0.01"
                  onBlur={() => stopLoss && setStopLoss(parseFloat(stopLoss).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Tags (Select atleast one)" required>
                <div className="taglist">
                  {TRADE_TAGS.map((item) => (
                    <Tag
                      key={item}
                      onClick={() => handleTagClick(item)}
                      className="tag-item"
                      color={selectedTags.includes(item) ? '#F9AE00' : 'gold'}
                    >
                      {item}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </Col>
            {user?.userType === 'admin' && !editId && (
              <>
                <Col span={24}>
                  <Form.Item label="Notification Title">
                    <Input
                      value={notificationTitle}
                      onChange={(e) => setNotificationTitle(e.target.value)}
                      placeholder="Notification Title"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Notification Body">
                    <TextArea
                      placeholder="Notification Body"
                      allowClear
                      value={notificationBody}
                      onChange={({ target }) => setNotificationBody(target?.value)}
                      autoSize={{
                        minRows: 2,
                        maxRows: 4,
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {user?.userType === 'admin' && (
              <Col>
                <Checkbox
                  checked={showDisclaimer}
                  onChange={() => setShowDisclaimer(!showDisclaimer)}
                >
                  Show Disclaimer
                </Checkbox>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      {isLightBoxVisible && (
        <Lightbox
          mainSrc={lightboxImage[currentImageIndex]}
          nextSrc={
            lightboxImage[(currentImageIndex + 1) % lightboxImage.length]
          }
          prevSrc={
            lightboxImage[
              (currentImageIndex + lightboxImage.length - 1)
                % lightboxImage.length
            ]
          }
          onCloseRequest={() => {
            toggleLightBox(false);
            setCurrentImageIndex(null);
          }}
          onMovePrevRequest={() => setCurrentImageIndex(
            (currentImageIndex + lightboxImage.length - 1)
                % lightboxImage.length,
          )}
          onMoveNextRequest={() => setCurrentImageIndex((currentImageIndex + 1) % lightboxImage.length)}
        />
      )}
      <Container>
        {isMobile && <BottomTabNavigator />}
        {isMobile && (
          <div className="menu-btn-heading-parent">
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: '25px',
                position: 'absolute',
                zIndex: 1000,
              }}
            />
            <div className="posts-heading text-center">
              <img
                aria-hidden
                onClick={() => history.push('/')}
                alt=""
                src={logo1}
                className="d-inline-block align-top brand-image"
              />
            </div>
          </div>
        )}
        <Row>
          {!isMobile && (
            <>
              {' '}
              <Col xs={0} sm={0} md={0} lg={5}>
                <div className="sticky-box">
                  <MainBoxFull>
                    <LeftSidebarProfile>
                      <LeftSidebarProfileHeader />
                      <CenteredDiv>
                        <UserImg
                          className="cursor-pointer"
                          src={user?.profileImg || defaultUser}
                          onClick={() => history.push('/profile')}
                        />
                        <UserName
                          className="cursor-pointer"
                          onClick={() => history.push('/profile')}
                        >
                          {capitalizeText(user?.name ?? '')}
                        </UserName>
                        <UserPlan>
                          <Row justify="center">
                            <div className="mr-1">
                              {user?.investorType ?? 'Author bio'}
                            </div>
                            <EditOutlined
                              onClick={(event) => {
                                event.stopPropagation();
                                setOpenBioModal(true);
                              }}
                            />
                          </Row>
                        </UserPlan>
                      </CenteredDiv>
                    </LeftSidebarProfile>
                    <LeftSidebarProfile>
                      <MenuBar history={history} />
                    </LeftSidebarProfile>
                  </MainBoxFull>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={1} />
            </>
          )}
          <Col xs={24} sm={24} md={24} lg={isMobile ? 24 : 12}>
            <p>
              <i>This is a beta version. Contact us if you find any errors</i>
            </p>
            <PostBox>
              <Row>
                <Col span={24}>
                  <PostInputBox>
                    <PostBoxImg src={user?.profileImg || defaultUser} />
                    <Button
                      onClick={() => showHidePostModal(true)}
                      className="post-button"
                      shape="round"
                      icon={<EditOutlined />}
                      size="large"
                    >
                      Write a post
                    </Button>
                  </PostInputBox>
                </Col>
                <Divider className="reduced-divider-margin" />
                <Radio.Group
                  onChange={({ target }) => handleUserType(target)}
                  value={parentFilter}
                  className="radio-grp-custom"
                >
                  <Radio.Button className="radio-btn-filter" value="">
                    All
                  </Radio.Button>
                  <Radio.Button className="radio-btn-filter" value="admin">
                    Admin
                  </Radio.Button>
                  <Radio.Button className="radio-btn-filter" value="mine">
                    My
                  </Radio.Button>
                  <Radio.Button className="radio-btn-filter" value="favorite">
                    Favorites
                  </Radio.Button>
                </Radio.Group>
              </Row>
            </PostBox>
            <Row>
              <Divider />
            </Row>
            <Row>
              <Col span={24}>
                {loading ? (
                  <LoadingSkeleton />
                ) : (
                  finalData?.map((item) => (
                    <PostItem
                      key={item._id}
                      data={item}
                      showCommentBox={showCommentBox === item?._id}
                      handleCommentClick={(id) => {
                        if (id == showCommentBox) {
                          setCommentBox(null);
                          return;
                        }
                        setCommentBox(id);
                      }}
                      handleAddComment={(value, id, setMethod) => handleSendComment(value, id, setMethod)}
                      setLightBoxImage={setLightBoxImage}
                      toggleLightBox={toggleLightBox}
                      handleEditPost={handleEditPost}
                      handleDeletePost={handleDeletePost}
                      favorateHandler={favorateHandler}
                      favoriteLoading={favoriteLoading}
                      handleDeleteComment={handleDeleteComment}
                      likeHandler={likeHandler}
                      likeLoading={likeLoading}
                      clickOnReplay={(id) => handleReplyClick(id)}
                      showChildCommentBox={showChildCommentBox}
                      isAddingComment={isAddingComment}
                      isAddingReply={isAddingReply}
                      handleAddReplayComment={(
                        value,
                        postId,
                        commentId,
                        setMethod,
                      ) => handleReplayComment(
                        value,
                        postId,
                        commentId,
                        setMethod,
                      )}
                      handleDeleteReplayComment={handleDeleteReplayComment}
                      setCurrentImageIndex={setCurrentImageIndex}
                    />
                  ))
                )}
              </Col>
              <Col span={24}>
                <Row className="mb-20" justify="center">
                  {loadingMore || hasMore.current ? (
                    <div ref={sentryRef}>
                      <Spin tip="Fetching more posts..." />
                    </div>
                  ) : !hasMore.current ? (
                    finalData?.length ? (
                      <p className="mb-20">That's all folks</p>
                    ) : (
                      <p className="mb-20"><strong>No Posts Found</strong></p>
                    )
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} />
          <Col xs={0} sm={0} md={0} lg={5}>
            <MainBoxParentLeft className="sticky-box">
              <RightMainBox>
                <div>
                  <SelectFilterHeading className="mt-10">
                    Trade Tag Filters
                  </SelectFilterHeading>
                  <SelectFilterText>Select tag to filter</SelectFilterText>
                  <hr />
                  <FiltersDiv>
                    {TRADE_TAGS.map((item) => (
                      <Tag
                        key={item}
                        onClick={() => handleFilterTagClick(item)}
                        className="tag-item"
                        color={
                          activeFilters.includes(item) ? '#F9AE00' : 'gold'
                        }
                      >
                        {item}
                      </Tag>
                    ))}
                  </FiltersDiv>
                </div>
              </RightMainBox>
            </MainBoxParentLeft>
          </Col>
        </Row>
      </Container>
      <div className="whatsapp-button">
        <div
          className="write-post-icon"
          onClick={() => showHidePostModal(true)}
        >
          <EditOutlined color="black" />
        </div>
      </div>
    </PostsContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Posts);
