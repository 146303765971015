import {
  Col, InputNumber, Radio, Row, Form, Select,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import './quantityCalendar.scss';

const allCommodityLotOption = [
  { label: 'ALUMINIUM (5 MT)', value: 5000.00001 },
  { label: 'COPPER (2.5 MT)', value: 2500 },
  { label: 'CRUDEOIL (100 BBL)', value: 100 },
  { label: 'GOLD (1 Kg)', value: 100.0001 },
  { label: 'GOLDGUINEA (8 GRMS)', value: 0.8 },
  { label: 'GOLDM (100 GRMS)', value: 10 },
  { label: 'GOLDPETAL (1 GRMS)', value: 0.1 },
  { label: 'LEAD (5 MT)', value: 5000.0001 },
  { label: 'NICKEL (1500 Kgs)', value: 1500 },
  { label: 'SILVER (30 Kgs)', value: 30 },
  { label: 'SILVERM (5 Kgs)', value: 5 },
  { label: 'SILVERMIC (1 Kg)', value: 1 },
  { label: 'ZINC (5 MT)', value: 5000.000001 },
  { label: 'NATURAL GAS (1250 BTU mm)', value: 1250 },
];

const QuantityCalculator = forwardRef(({}, ref) => {
  const [isVisible, setVisibility] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');
  const [portfolioValue, setPortfolioValue] = useState(null);
  const [risk, setRisk] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [stopLoss, setStopLoss] = useState(null);
  const [lotSize, setLotSize] = useState(null);

  useImperativeHandle(ref, () => ({
    toggleCalcModal(value) {
      setVisibility(value);
    },
  }));

  const shareQuantity = useMemo(() => {
    if (selectedTab == 1) {
      if (!portfolioValue || !risk || !currentPrice || !stopLoss) {
        return '';
      }
      const numerator = portfolioValue * (risk / 100);
      const denominator = Math.abs(currentPrice - stopLoss);
      return (numerator / denominator).toFixed(2);
    } if (selectedTab == 2) {
      if (!portfolioValue || !risk || !currentPrice || !stopLoss || !lotSize) {
        return '';
      }
      const numerator = portfolioValue * (risk / 100);
      const denominator = Math.abs(currentPrice - stopLoss);
      const newNumerator = numerator / denominator;
      return (newNumerator / lotSize).toFixed(2);
    } if (selectedTab == 3) {
      if (!portfolioValue || !risk || !currentPrice || !stopLoss || !lotSize) {
        return '';
      }
      const numerator = portfolioValue * (risk / 100);
      const denominator = Math.abs(currentPrice - stopLoss);
      const newNumerator = numerator / denominator;
      return (newNumerator / lotSize).toFixed(2);
    }
    if (selectedTab == 4) {
      if (!portfolioValue || !risk || !currentPrice || !stopLoss || !lotSize) {
        return '';
      }
      const numerator = portfolioValue * (risk / 100);
      const denominator = Math.abs(currentPrice - stopLoss);
      const newNumerator = numerator / denominator;
      return (newNumerator / lotSize).toFixed(2);
    }
    return '';
  }, [portfolioValue, risk, currentPrice, stopLoss, lotSize]);

  const showSell = useMemo(() => {
    if (currentPrice - stopLoss < 0) {
      return true;
    }
    return false;
  }, [currentPrice, stopLoss]);

  const sharesQuantityText = useMemo(() => {
    if (!shareQuantity) {
      return '';
    }
    if (selectedTab == 1) {
      return (
        <p>
          You can&nbsp;
          {showSell ? 'short sell' : 'buy'}
&nbsp;
          <b>{Math.floor(shareQuantity)}</b>
          {' '}
          shares to keep risk at
          {' '}
          <b>{risk}</b>
          % or lower
        </p>
      );
    }
    if (selectedTab == 2 || selectedTab == 3 || selectedTab == 4) {
      return (
        <p>
          You can&nbsp;
          {showSell ? 'short sell' : 'buy'}
&nbsp;
          <b>{Math.floor(shareQuantity)}</b>
          {' '}
          lot(s) to keep risk at
          {' '}
          <b>{risk}</b>
          % or lower
        </p>
      );
    }
  }, [shareQuantity, showSell]);

  const resetData = (value) => {
    setSelectedTab(value);
    setPortfolioValue(null);
    setRisk(null);
    setCurrentPrice(null);
    setStopLoss(null);
    if (value == 4) {
      setLotSize(1000);
    } else {
      setLotSize(null);
    }
  };

  return (
    <Modal
      maskClosable={false}
      className="create-post-modal"
      visible={isVisible}
      onCancel={() => setVisibility(false)}
      footer={null}
      title={<b>Trade Allocation Calculator</b>}
    >
      <div className="calc-modal">
        <Row justify="center">
          <Radio.Group
            onChange={(e) => {
              resetData(e.target.value);
            }}
            value={selectedTab}
            buttonStyle="solid"
          >
            <Radio.Button value="1">Equity</Radio.Button>
            <Radio.Button value="2">Options</Radio.Button>
            <Radio.Button value="3">Commodities</Radio.Button>
            <Radio.Button value="4">Currencies</Radio.Button>
          </Radio.Group>
        </Row>
        <Row justify="center">
          {isMobileOnly ? (
            <em
              style={{
                marginTop: '15px',
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              Fill in the following details to know the trade size
            </em>
          ) : (
            <em style={{ marginTop: '10px', fontSize: '16px' }}>
              Fill in the following details to know the trade size
            </em>
          )}
        </Row>
        <Form layout="vertical" style={{ marginTop: '25px' }}>
          <Row>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item required label="Your Portfolio Value">
                <InputNumber
                  min={0}
                  onChange={(value) => setPortfolioValue(Number(value))}
                  value={portfolioValue}
                  style={{ width: '90%' }}
                  formatter={(value) => `₹${value}`}
                  parser={(value) => value.replace('₹', '')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item required label="Risk (%)">
                <InputNumber
                  className={risk && risk > 2 ? 'danger-risk' : ''}
                  min={0}
                  style={{ width: '90%' }}
                  onChange={(value) => setRisk(Number(value))}
                  value={risk}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                required
                label={
                  selectedTab == 1
                    ? 'Current Share Price'
                    : selectedTab == 2
                      ? 'Current Option Price'
                      : selectedTab == 3
                        ? 'Current Contract Price'
                        : 'Current Currency Pair Price'
                }
              >
                <InputNumber
                  min={0}
                  onChange={(value) => setCurrentPrice(Number(value))}
                  value={currentPrice}
                  style={{ width: '90%' }}
                  formatter={(value) => `₹${value}`}
                  parser={(value) => value.replace('₹', '')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item required label="Stop Loss">
                <InputNumber
                  min={0}
                  onChange={(value) => setStopLoss(Number(value))}
                  value={stopLoss}
                  style={{ width: '90%' }}
                  formatter={(value) => `₹${value}`}
                  parser={(value) => value.replace('₹', '')}
                />
              </Form.Item>
            </Col>
          </Row>
          {selectedTab == 2 && (
            <Row>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item required label="Lot Size">
                  <InputNumber
                    min={0}
                    onChange={(value) => setLotSize(value)}
                    value={lotSize}
                    style={{ width: '90%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedTab == 3 && (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item required label="Lot Size">
                  <Select
                    onChange={(value) => setLotSize(value)}
                    value={lotSize}
                    style={{ width: '90%' }}
                  >
                    {allCommodityLotOption.map((item) => (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedTab == 4 && (
            <Row>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item required label="Lot Size">
                  <InputNumber
                    min={0}
                    onChange={(value) => setLotSize(value)}
                    value={lotSize}
                    style={{ width: '90%' }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <b>Result:&nbsp;</b>
            {shareQuantity}
          </Row>
          {shareQuantity != '' && (
            <Row style={{ marginTop: '5px' }}>
              {shareQuantity < 1 ? (
                <p style={{ color: 'red' }}>
                  Sorry! Your portfolio is too small to take this trade. Please
                  reduce risk.
                </p>
              ) : (
                sharesQuantityText
              )}
            </Row>
          )}
          {risk && risk > 2 && (
            <Row>
              <p style={{ color: 'red', marginBottom: 0, marginTop: '5px' }}>
                It is not recommended to take a risk of more than 2% on a trade
              </p>
            </Row>
          )}
        </Form>
      </div>
    </Modal>
  );
});

export default QuantityCalculator;
