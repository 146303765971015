import React, { memo, useState } from 'react';
import {
  Col, Divider, Popover, Row, Tag,
} from 'antd';
import { MoreOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import defaultUser from '../../assets/defaultUser.jpg';
import RupeeBag from '../../assets/rupee-bag.png';
import './post.scss';
import {
  PostItemHeader,
  UserProfileImageParent,
  UserImage,
  UserNameParent,
  UserNameBold,
  InvestorType,
  PostContentDiv,
  PostImg,
  CommentListBox,
  CommentUserImage,
  CommentParent,
  CommentContent,
  AdminProfitableTag,
  RupeeBagImage,
  CommentActionButton,
  ProfitableTagContainer,
  ProfitableTradersText,
  AdminTag,
  PostInactive,
} from './styled';
import { capitalizeText, getDateTextFromDate } from '../../utils/helper';
import {
  getTagsDetails,
  getDisclaimerText,
  getActionButtons,
  getParentCommentSection,
  getChildCommentSection,
  commentBox,
} from './common';
import Preloader from '../../components/Preloader';

const PostItem = ({
  data,
  showCommentBox,
  handleCommentClick,
  handleAddComment,
  setLightBoxImage,
  toggleLightBox,
  handleEditPost,
  handleDeletePost,
  favorateHandler,
  favoriteLoading,
  handleDeleteComment,
  likeHandler,
  likeLoading,
  clickOnReplay,
  showChildCommentBox,
  handleAddReplayComment,
  handleDeleteReplayComment,
  isAddingComment,
  isAddingReply,
  setCurrentImageIndex,
}) => {
  const user = useSelector((state) => state.common.user);
  const [commentText, setCommentText] = useState('');
  const [commentReplayText, setReplayCommentText] = useState('');
  const [isVisible, setVisibility] = useState(false);
  const [lessMore, setLessMore] = useState(false);
  const inactivityTime = moment().diff(moment(data?.updatedAt), 'days');

  const getImagesComp = (images) => {
    if (images?.length == 1) {
      return (
        <Row className="mb-10">
          <Col span={24}>
            <Preloader
              onClick={() => {
                setLightBoxImage(images);
                toggleLightBox(true);
                setCurrentImageIndex(0);
              }}
              src={images[0]}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row className="mb-10">
        {images.map((item, index) => (
          <Col span={12} key={item}>
            <div style={{ padding: '15px', height: '100%' }}>
              <PostImg
                onClick={() => {
                  setLightBoxImage(images);
                  toggleLightBox(true);
                  setCurrentImageIndex(index);
                }}
                showBorder
                src={item}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const getTitleText = () => {
    const titleText = ReactHtmlParser(data?.title ?? '');
    // if (titleText?.length > 160) {
    //   return (
    //     <PostContentDiv>{titleText.subString(1, 160) + "..."}</PostContentDiv>
    //   );
    // } else {
    // }
    return <PostContentDiv>{titleText}</PostContentDiv>;
  };

  const isPostOfAdmin = data?.postedBy?.userType == 'admin';
  const isPostUserProfitable = data?.postedBy?.isProfitable;

  let isFavorite = false;
  const result = data?.favorites?.find((post) => post === user._id);

  if (result) {
    isFavorite = true;
  }

  const isLike = data?.likes?.find((post) => post._id === user._id);
  const shouldSpin = likeLoading === data._id;
  return (
    <div className="post-box">
      <PostItemHeader>
        <UserProfileImageParent>
          <UserImage src={data?.postedBy?.profileImg ?? defaultUser} />
        </UserProfileImageParent>
        <UserNameParent>
          <div>
            <UserNameBold>{capitalizeText(data?.postedBy?.name)}</UserNameBold>
            <AdminProfitableTag>
              <InvestorType>
                {getDateTextFromDate(data?.createdAt)}
              </InvestorType>
              {isPostOfAdmin && <AdminTag color="#0088cc">Admin</AdminTag>}
            </AdminProfitableTag>
          </div>
        </UserNameParent>
        {isPostUserProfitable && (
          <div className="cursor-pointer">
            <Popover
              trigger={['click', 'hover']}
              content={(
                <div>
                  <p>
                    This trader has been profitable in the current calendar
                    year.
                    <br />
                    We've verified the trader's PnL statement of the current
                    year.
                  </p>
                  <p style={{ marginBottom: 0, textAlign: 'center' }}>
                    <b>Want your own badge?</b>
                  </p>
                  <p style={{ marginBottom: 0, textAlign: 'center' }}>
                    Send us the video of your PnL of the current year on
                    WhatsApp/Email
                  </p>
                </div>
              )}
            >
              <ProfitableTagContainer>
                <RupeeBagImage src={RupeeBag} />
                <ProfitableTradersText>Profitable Trader</ProfitableTradersText>
              </ProfitableTagContainer>
            </Popover>
          </div>
        )}
        <Popover
          trigger={['hover']}
          content={(
            <div>
              {isFavorite ? 'Remove from favorites' : 'Mark as favorite'}
            </div>
          )}
        >
          <div className="favorate-container">
            {isFavorite ? (
              <StarFilled
                spin={favoriteLoading === data._id}
                style={{ fontSize: '20px', color: 'orange' }}
                onClick={() => favorateHandler(data, !isFavorite)}
              />
            ) : (
              <StarOutlined
                spin={favoriteLoading === data._id}
                style={{ fontSize: '20px' }}
                onClick={() => favorateHandler(data, !isFavorite)}
              />
            )}
          </div>
        </Popover>

        {user?.userType == 'admin' && (
          <div>
            <Popover
              trigger="click"
              content={(
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <a href="#" onClick={(e) => handleEditPost(e, data)}>
                    Edit Post
                  </a>
                  <Divider className="reduced-divider-margin-10" />
                  <a href="#" onClick={(e) => handleDeletePost(e, data?._id)}>
                    Delete Post
                  </a>
                </div>
              )}
            >
              <MoreOutlined className="more-item-icon" />
            </Popover>
          </div>
        )}
      </PostItemHeader>
      <Divider className="reduced-divider-margin-10" />
      <div style={{ display: 'flex', margin: '0 15px', flexFlow: 'wrap' }}>
        {data?.tags?.map((item) => (
          <Tag key={item} className="text-bold tag-item-post" color="#F9AE00">
            {item}
          </Tag>
        ))}
      </div>
      <div className="mt-15">{getTitleText()}</div>
      <div className="mt-15">{getImagesComp(data?.images)}</div>
      {getTagsDetails({ ...data, setVisibility, isVisible })}
      {getDisclaimerText(
        data,
        lessMore,
        setLessMore,
        data?.postedBy?.userType === 'admin',
      )}
      <Divider className="reduced-divider-margin" />
      {getActionButtons(
        data,
        shouldSpin,
        isLike,
        likeHandler,
        handleCommentClick,
      )}
      {showCommentBox && (
        <div>
          {inactivityTime < 8 ? (
            getParentCommentSection(
              data,
              setCommentText,
              handleAddComment,
              commentText,
              user,
              isAddingComment,
            )
          ) : !data?.comments?.length ? (
            <PostInactive className="create-comment-modal">
              <p>This Post has been closed due to inactivity</p>
            </PostInactive>
          ) : (
            ''
          )}

          {data?.comments?.length > 0 && (
            <>
              <Divider className="reduced-divider-margin" />
              <CommentListBox>
                {data?.comments?.map((item) => {
                  const isCommentOpen = showChildCommentBox?.postId === data?._id
                    && showChildCommentBox?.commentId === item._id;

                  const isReplyOwner = user?.userType == 'admin'
                    || user?._id === item?.commentedBy?._id
                    || item?.reply?.find(
                      (repItem) => repItem?.commentedBy?._id === user?._id,
                    );
                  return (
                    <div key={item?._id}>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '10px',
                        }}
                      >
                        <CommentUserImage
                          src={item?.commentedBy?.profileImg ?? defaultUser}
                        />
                        <CommentParent>
                          <UserNameBold>
                            {capitalizeText(item?.commentedBy?.name ?? '')}
                          </UserNameBold>
                          <InvestorType>
                            {getDateTextFromDate(item?.createdAt)}
                          </InvestorType>
                          <CommentContent>{item?.content}</CommentContent>
                          {(user?.userType == 'admin'
                            || user?._id === item?.commentedBy?._id) && (
                            <CommentActionButton>
                              <Popover
                                trigger="click"
                                content={(
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) => handleDeleteComment(e, data, item)}
                                    >
                                      Delete Comment
                                    </a>
                                  </div>
                                )}
                              >
                                <MoreOutlined className="more-item-icon" />
                              </Popover>
                            </CommentActionButton>
                          )}
                        </CommentParent>
                      </div>

                      <div className="see-replies-box">
                        <a
                          href="#"
                          style={{ marginTop: '10px' }}
                          onClick={(e) => {
                            e.preventDefault();
                            clickOnReplay({
                              postId: data._id,
                              commentId: item._id,
                            });
                          }}
                        >
                          {!item?.reply?.length
                            ? inactivityTime < 8
                              ? 'Reply'
                              : ''
                            : isCommentOpen
                              ? 'Hide all replies'
                              : 'See all replies'}
                        </a>
                      </div>
                      <div style={{ marginLeft: '40px' }}>
                        {isCommentOpen && (
                          <>
                            {item?.reply?.length > 0
                              && item?.reply.map((itemRep) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    marginBottom: '10px',
                                    marginLeft: '15px',
                                  }}
                                  key={itemRep?._id}
                                >
                                  {commentBox(
                                    user,
                                    itemRep,
                                    defaultUser,
                                    handleDeleteReplayComment,
                                    data,
                                    item,
                                  )}
                                </div>
                              ))}
                            {inactivityTime < 8 && isReplyOwner ? (
                              getChildCommentSection(
                                data,
                                setReplayCommentText,
                                handleAddReplayComment,
                                commentReplayText,
                                item,
                                user,
                                isAddingReply,
                              )
                            ) : (
                              <PostInactive className="create-comment-modal">
                                <p>
                                  This Post has been closed due to inactivity
                                </p>
                              </PostInactive>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </CommentListBox>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(PostItem);
