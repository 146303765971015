export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_DATA_PATCH = "SET_USER_DATA_PATCH";
export const LOGOUT = "LOGOUT";

export const setModalType = (type) => {
  return {
    payload: type,
    type: SET_MODAL_TYPE,
  };
};

export const setUserData = (userData) => {
  return {
    payload: userData,
    type: SET_USER_DATA,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
