import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect, useDispatch } from 'react-redux';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import moment from 'moment';
import './App.scss';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';
import Auth from './components/Auth';
import ScrollToTop from './utils/scrollToTop';
import { SET_USER_DATA } from './actions/common';
import HamburgerMenu from './components/HamburgerMenu';
import ExitIntentModal from './components/ExitIntentModal';
import { axiosAuth } from './api';
import { isUserDetailsUpdate } from './utils/helper';

const ALL_LANDING_PAGES = [
  '/landing-page',
  '/landing-page-webinar',
  '/landing-page-ebook',
  '/join-us',
  '/posts',
  '/notifications',
  '/trade-wisdom',
  '/watchlist',
  '/webinar',
  '/webinar-registration',
  '/webinar-register-success',
];

const ALL_LANDING_PAGES_PATHNAME = [
  '/landing-page',
  '/landing-page-webinar',
  '/landing-page-ebook',
  '/join-us',
  '/webinar',
  '/webinar-registration',
  '/webinar-register-success',
];

const isMobileHeaderHidden = (pathname) => {
  if (isMobile) {
    return (
      pathname.includes('/posts/')
      || pathname.includes('/watchlist/')
      || pathname.includes('/trade-wisdom/')
      || pathname.includes('/renew-subscription/')
    );
  }
  return false;
};

function AppContainer() {
  const location = useLocation();
  const usePathname = () => location.pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem('user');
    const token = localStorage.getItem('authToken') || '';

    // For setting the updated user data
    if (userData) {
      const user = JSON.parse(userData);
      if (
        user?.payment?.endDate
        && moment(new Date()).isAfter(user?.payment?.endDate)
      ) {
        dispatch({
          type: SET_USER_DATA,
          payload: { user: {}, token: '' },
        });
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        window.location.origin = '/';
      } else {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            user: userData ? { ...JSON.parse(userData) } : null,
            token,
          },
        });
      }

      axiosAuth.get('auth').then((res) => {
        if (res?.data?._id) {
          const result = isUserDetailsUpdate(user, res.data);
          if (!result) {
            localStorage.setItem('user', JSON.stringify(res.data));
            dispatch({
              type: SET_USER_DATA,
              payload: { user: res?.data ?? null },
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    ReactGA.set({ page: window.location.origin + location.pathname });
    ReactGA.pageview(window.location.origin + location.pathname);
    ReactPixel.pageView();
  }, [location]);

  const currentPath = usePathname();

  return (
    <div className="App">
      <ScrollToTop>
        {/* <ExitIntentModal /> */}
        <Auth />
        {(isMobileOnly || isTablet)
          && !isMobileHeaderHidden(currentPath)
          && !ALL_LANDING_PAGES.includes(currentPath)
          && !currentPath.includes('/renew-subscription') && <HamburgerMenu />}

        {!isMobileHeaderHidden(currentPath)
          && !ALL_LANDING_PAGES.includes(currentPath)
          && !currentPath.includes('/renew-subscription') && <Header />}

        <div className="margin-app">
          <Routes />
        </div>
        {!ALL_LANDING_PAGES_PATHNAME.includes(currentPath) && <Footer />}
      </ScrollToTop>
    </div>
  );
}

export default AppContainer;
