import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { axiosAuth } from "../../api";
import { SET_USER_DATA_PATCH } from "../../actions/common";
import { useDispatch, useSelector } from "react-redux";

const AuthorBioModal = (props) => {
  const user = useSelector((state) => state.common.user);
  const { isOpenBioModal, resetData, alreadyAuthoreData } = props;
  const [bio, setBio] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alreadyAuthoreData) {
      setBio(alreadyAuthoreData);
    }
  }, [alreadyAuthoreData]);

  const handleUpdateBio = () => {
    setLoading(true);
    axiosAuth
      .patch("user/update", { _id: user._id, investorType: bio })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification["success"]({
            message: "Success",
            description: "Successfully update author bio",
          });
          dispatch({
            type: SET_USER_DATA_PATCH,
            payload: { investorType: res?.data?.data?.investorType ?? "" },
          });
          let userDataSaved = localStorage.getItem("user");
          userDataSaved = userDataSaved ? JSON.parse(userDataSaved) : {};
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...userDataSaved,
              investorType: res?.data?.data?.investorType ?? "",
            })
          );
          resetData(false);
        } else {
          notification["error"]({
            message: "Error",
            description:
              "Failed to update author bio. Please try again later!!",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleBio = (data) => {
    if (data.length <= 100) {
      setBio(data);
    }
  };

  return (
    <Modal
      onCancel={() => resetData(false)}
      visible={isOpenBioModal}
      title='Author Bio'
      className='create-post-modal'
      footer={[
        <Button
          loading={loading}
          className='save-post-button'
          onClick={() => handleUpdateBio()}
        >
          Update
        </Button>,
      ]}
    >
      <Form layout='vertical'>
        <Row>
          <Col span={24}>
            <Form.Item label='Author bio' required>
              <TextArea
                placeholder='Author bio'
                allowClear
                value={bio}
                onChange={({ target }) => handleBio(target?.value)}
                autoSize={{
                  minRows: 2,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AuthorBioModal;
