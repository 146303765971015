import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import Logo from "../../assets/logo1.svg";
import LogoFace from "../../assets/logo-face.svg";
import gmailIcon from "../../assets/Gmail.svg";
import whatsappIcon from "../../assets/whatsup.svg";
import WhatsAppWidget from "react-whatsapp-widget";
import LandingFooter from "../../components/Footer/LandingFooter";
import { getEnvValue } from "../../utils/helper";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useLocation } from "react-router";
import SignUp from "../../components/SignUp";
import { Helmet } from "react-helmet";

const SocialIcons = styled.img`
  height: 15px;
  margin-right: 6px;
  margin-top: -5px;
`;

const WhatsAppIcon = styled(SocialIcons)`
  height: 20px;
`;

const LinksContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const JoinUsLandingPage = () => {
  const [scrollPosition, setScrollPosition] = useState(null);
  const location = useLocation();
  const listenToScroll = useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const showWhatsApp = useMemo(() => {
    if (location.pathname == "/landing-page") {
      if (isMobile) {
        return scrollPosition > 0.071;
      } else {
        return scrollPosition > 0.081;
      }
    }
    return true;
  }, [scrollPosition, location]);

  return (
    <>
      <Helmet>
        <title>Become a member of The Logical Traders</title>
        <meta
          name='description'
          content='The Logical Traders membership, Should you join The Logical Traders, The Logical Traders community, The Logical Traders calls, The Logical Traders accuracy, The Logical Traders profits, how to become a member of The Logical Traders, The Logical Traders membership free,'
        />
      </Helmet>
      <div className={showWhatsApp ? "showing" : "not-showing"}>
        {!isMobileOnly && (
          <div className='contact-us-text-landing'>
            <span
              style={{
                color: "#333333",
                textAlign: "center",
                fontWeight: "bolder",
              }}
            >
              Contact Us
            </span>
          </div>
        )}

        <div className='whatsapp-button whatsapp-button-landing'>
          <WhatsAppWidget
            companyName='The Logical Traders'
            phoneNumber='+916267990054'
          />
        </div>

        {/* <div className='fb-chat-support'>
          <MessengerCustomerChat
            pageId={getEnvValue("FACEBOOK_PAGE_ID")}
            appId={getEnvValue("FACEBOOK_APP_ID")}
            htmlRef='https://www.facebook.com/thelogicaltraders/'
          />
        </div> */}
      </div>

      <Row>
        <Col xs={8} sm={8} md={12} lg={12}>
          <img
            src={isMobileOnly ? LogoFace : Logo}
            width={isMobileOnly ? "150px" : "250px"}
            style={{ padding: isMobileOnly ? "15px 15px 5px 15px" : "15px" }}
          />
        </Col>
        <Col xs={16} sm={16} md={12} lg={12}>
          <LinksContainer>
            <div style={{ marginRight: "15px" }}>
              <a
                href='mailto:support@thelogicaltraders.com'
                style={{ marginRight: "10px" }}
              >
                <SocialIcons src={gmailIcon} />
                <span>
                  <b>Email Us</b>
                </span>{" "}
              </a>
              <a
                href='https://api.whatsapp.com/send?phone=+916267990054'
                target='_blank'
              >
                <WhatsAppIcon src={whatsappIcon} />
                <span>
                  <b>WhatsApp Us</b>
                </span>
              </a>
            </div>
          </LinksContainer>
        </Col>
      </Row>
      <div className='white-bg padding-30'>
        <SignUp noMargin />
      </div>
      <LandingFooter />
    </>
  );
};

export default JoinUsLandingPage;
