import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import ReactGA from 'react-ga';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Logo from '../../assets/logo1.svg';
import LogoFace from '../../assets/logo-face.svg';
import BetterForYou from '../../components/BetterForYou';
import HowItWorks from '../../components/HowItWorks';
import MainSection from '../../components/LandingPage/MainSection';
import gmailIcon from '../../assets/Gmail.svg';
import whatsappIcon from '../../assets/whatsup.svg';
import FeaturedOn from '../../components/FeaturedOnSection';
import Experts from '../../components/OurExperts';
import CTAContent from '../../components/LandingPage/CTAContent';
import Testimonials from '../../components/Testimonials';
import HowYouBenefitLanding from '../../components/HowYouBenefit/HowYouBenefitLanding';
import LandingFooter from '../../components/Footer/LandingFooter';
import { getEnvValue, triggerGAEvent } from '../../utils/helper';
import LandingFAQS from '../../components/LandingPage/LandingFaqs';
import VideoSection from '../../components/HomeBg/VideoSection';
import WhatYouGet from '../../components/WhatYouGet';
import homeImage from '../../assets/bannerImage.png';
import mobileImage from '../../assets/banner-m.png';

const SocialIcons = styled.img`
  height: 15px;
  margin-right: 6px;
  margin-top: -5px;
`;

const WhatsAppIcon = styled(SocialIcons)`
  height: 20px;
`;

const LinksContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HomeBackground = styled.div`
    background-image: url(${!isMobileOnly ? homeImage : mobileImage});
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
  `;

const LandingPage = () => {
  const [scrollPosition, setScrollPosition] = useState(null);
  const location = useLocation();
  const intersectTarget = useRef(null);
  const intersectTarget1 = useRef(null);
  const listenToScroll = useCallback(() => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height = document.documentElement.scrollHeight
      - document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
    const callback = (list) => {
      list.forEach((entry) => {
        console.log(entry);
        if (entry.isIntersecting) {
          const data = {
            category: 'Scroll',
            action:
              entry.target.id == 'video-section'
                ? 'Scrolled to video 1 landing page'
                : 'Scrolled to Testimonials',
            label:
              entry.target.id == 'video-section'
                ? 'Scrolled to video 1 landing page'
                : 'Scrolled to Testimonials',
            value: entry.intersectionRatio,
          };
          triggerGAEvent(data);
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);

    observerScroll.observe(intersectTarget.current);
    observerScroll.observe(intersectTarget1.current);
  }, []);

  const showWhatsApp = useMemo(() => {
    if (location.pathname == '/landing-page') {
      if (isMobile) {
        return scrollPosition > 0.071;
      }
      return scrollPosition > 0.081;
    }
    return true;
  }, [scrollPosition, location]);

  return (
    <>
      <Helmet>
        <title>Helping traders succeed in the Indian Stock Market</title>
        <meta
          name="description"
          content="How to become a trader, can you become a full time trader, best Indian stock market service, which stocks to buy, daily stock tips, best shares to buy, best Indian share market course, how to trade commodities, how to invest in shares, how to trade futures and options, how to trade forex, intraday calls, free stock market tips, best trading websites in India, commodity market trading in India, how to make money in stock market, how to become a profitable trader, can you make money regularly in the stock market, bank nifty options trading, nifty options trading, free stock tips, daily intraday calls free, The Logical Traders free, The Logical Traders Quora The Logical Traders review Quora,"
        />
      </Helmet>
      <div className={showWhatsApp ? 'showing' : 'not-showing'}>
        {!isMobileOnly && (
          <div className="contact-us-text-landing">
            <span
              style={{
                color: '#333333',
                textAlign: 'center',
                fontWeight: 'bolder',
              }}
            >
              Got Doubts?
              <br />
              Text Us
            </span>
          </div>
        )}

        <div className="whatsapp-button whatsapp-button-landing">
          <WhatsAppWidget
            companyName="The Logical Traders"
            phoneNumber="+916267990054"
          />
        </div>

        {/* <div className='fb-chat-support'>
          <MessengerCustomerChat
            pageId={getEnvValue("FACEBOOK_PAGE_ID")}
            appId={getEnvValue("FACEBOOK_APP_ID")}
            htmlRef='https://www.facebook.com/thelogicaltraders/'
          />
        </div> */}
      </div>

      <Row>
        <Col xs={8} sm={8} md={12} lg={12}>
          <img
            src={isMobileOnly ? LogoFace : Logo}
            width={isMobileOnly ? '150px' : '250px'}
            style={{ padding: isMobileOnly ? '15px 15px 5px 15px' : '15px' }}
            alt="logo"
          />
        </Col>
        <Col xs={16} sm={16} md={12} lg={12}>
          <LinksContainer>
            <div style={{ marginRight: '15px' }}>
              <a
                href="mailto:support@thelogicaltraders.com"
                style={{ marginRight: '10px' }}
              >
                <SocialIcons src={gmailIcon} />
                <span>
                  <b>Email Us</b>
                </span>
                {' '}
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=+916267990054"
                target="_blank"
              >
                <WhatsAppIcon src={whatsappIcon} />
                <span>
                  <b>WhatsApp Us</b>
                </span>
              </a>
            </div>
          </LinksContainer>
        </Col>
      </Row>
      <HomeBackground>
        <MainSection />
      </HomeBackground>
      <Row className="black-bg padding-30">
        <Col span={24}>
          <WhatYouGet todayText />
        </Col>
      </Row>
      <div
        className="grey-bg padding-30"
        id="video-section"
        ref={intersectTarget}
      >
        <VideoSection
          heading1="1497.3% returns in 2020"
          heading2="We did it & you can too."
          url="https://www.youtube.com/watch?v=9J90XAL-LUU"
          showTopMargin={!isMobileOnly}
          videoRight={isMobileOnly}
          showBottomMargin={isMobileOnly}
          type={1}
        />
      </div>
      {isMobileOnly && <hr style={{ background: 'black', margin: 0 }} />}
      <div
        className="grey-bg padding-30"
        style={{ paddingTop: !isMobileOnly && '60px' }}
      >
        <VideoSection
          heading1="100% returns in 6 months"
          heading2="in 2021"
          url="https://youtu.be/5naFLpEJy7M"
          videoRight
          showBottomMargin
          type={2}
        />
      </div>
      {isMobileOnly && <hr style={{ background: 'black', margin: 0 }} />}
      <div
        className="grey-bg padding-30"
        id="video-section"
        ref={intersectTarget}
      >
        <VideoSection
          heading1="Learn from experts who have"
          heading2="a track record of over 20 years"
          url="https://youtu.be/ZdAS2vdugPM"
          showTopMargin={!isMobileOnly}
          videoRight={isMobileOnly}
          showBottomMargin={isMobileOnly}
          type={1}
        />
      </div>
      <div
        className="grey-bg padding-30"
        id="video-section"
        ref={intersectTarget}
      >
        <Row>
          <CTAContent
            text={(
              <span>
                If you waste thousands of rupees on trading courses now,
                <br />
                {' '}
                you’ll hate yourself later.
              </span>
            )}
          />
        </Row>
      </div>
      <div className="black-bg padding-30">
        <BetterForYou signUpText="Become a Member Today" />
      </div>
      <Row className="testimonials-brief-bg">
        <Col span={24}>
          <HowItWorks />
        </Col>
        <CTAContent
          text={(
            <span>
              It takes years to become a profitable trader.
              <br />
              {' '}
              Shorten your learning curve massively with our help.
            </span>
          )}
        />
      </Row>
      <div className="grey-bg padding-30">
        <HowYouBenefitLanding />
      </div>
      <div className="clearfix" />
      <Row className="white-bg padding-30" ref={intersectTarget1}>
        <Col span={24}>
          <Testimonials />
        </Col>
        <CTAContent
          text={(
            <span>
              99% of traders lose money in the stock market.
              <br />
              {' '}
              But you can become a part of the exclusive 1%.
            </span>
          )}
        />
      </Row>
      <div className="blogs-brief-bg padding-30">
        <FeaturedOn />
      </div>
      <div className="black-bg padding-30">
        <Experts showExperts />
        <Row>
          <CTAContent
            text={(
              <span>
                Convinced by our performance?
                <br />
                You can become rich in the stock market too.
              </span>
            )}
            showWhite
          />
        </Row>
      </div>
      <div className="white-bg padding-30">
        <LandingFAQS />
        <Row className="white-bg padding-30">
          <CTAContent
            text={(
              <span>
                Had all your doubts cleared?
                <br />
                Great! Join us today.
              </span>
            )}
          />
        </Row>
      </div>
      <LandingFooter />
    </>
  );
};

export default LandingPage;
