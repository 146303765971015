import React from 'react';
import { isMobile } from 'react-device-detect';
import { Container } from 'react-bootstrap';
import BannerSection from '../../components/Webinar/BannerSection';
import WhatYouGet from '../../components/Webinar/WhatYouGet';
import Scam from '../../components/Webinar/Scam';
import '../../fonts/Gilroy/Gilroy-Regular.ttf';
import '../../fonts/Gilroy/Gilroy-Bold.ttf';
// import ActionButton from '../../components/Webinar/ActionButton';
import WebinarFooter from '../../components/Webinar/WebinarFooter';
import CourseFaqs from '../../components/Webinar/CourseFaqs';
import { MoneyBack } from '../../components/Webinar/AboutCourse';
import { Spacer } from '../about';

const Webinar = () => (
  <div>
    <BannerSection />
    <Container>
      <Spacer />
      <MoneyBack
        heading="&#8377;1,000 CHALLENGE*"
        hideFirst
        secondHeading="Get Paid &#8377;1000"
        secondSubheading="*I will show you my profit & loss statement from 1st January 2020 to 31st December 2022 live during the webinar and if my absolute profit is less than &#8377;45.87 lakhs by a margin of 1% or more, I will pay you &#8377;1,000"
      />
    </Container>
    <WhatYouGet />
    <Scam />
    <CourseFaqs />
    <WebinarFooter />
    {/* {isMobile && <ActionButton />} */}
  </div>
);

export default Webinar;
