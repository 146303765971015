import { Row, Col } from 'antd';
import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import OfferImg from '../../assets/offer.svg';
import Tick from '../../assets/white-tick.svg';
import { SignUpNavItem } from '../../styledComponents';
import { triggerGAEvent } from '../../utils/helper';

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${isTablet ? '800px' : '500px'};
  @media (max-width: 767px) {
    padding: 10px;
    height: 100%;
  }
`;

const DiscountImage = styled.img`
  height: 250px;
  @media (max-width: 767px) {
    height: 220px;
    margin-bottom: 25px;
  }
`;

const MainHeading = styled.span`
  font-weight: 800;
  font-size: 25px;
  color: #f9ae00;
  text-align: center;
  margin-bottom: 15px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 21px;
  }
  @media (max-width: 767px) {
    text-align: center;
    font-size: 21px;
  }
`;

const FirstMainHeading = styled(MainHeading)`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  color: black;
`;

const MainContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 10px;
    height: calc(100vh - 100px);
  }
`;

const Subheading = styled.p`
  text-align: center;
  color: white;
`;

const PCSubHeading = styled.p`
  text-align: center;
  color: white;
  margin-top: 50px;
`;

const Chapter1 = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainSection = () => {
  const history = useHistory();
  const handleLinkClick = useCallback(() => {
    const data = {
      category: 'Links',
      action: 'Join Us | Landing Page',
      label: 'Clicked on join us | Landing Page',
      value: 1,
    };
    triggerGAEvent(data);
    history.push('/join-us');
  }, []);
  return (
    <Container>
      <Row
        justify="center"
        className="blogs-brief"
        style={{ minHeight: !isMobile ? '400px' : 'initial' }}
      >
        {isMobileOnly ? (
          <>
            <Col span={24}>
              <FlexDiv>
                <MainContentDiv>
                  <Chapter1>
                    <FirstMainHeading style={{ marginTop: '0px' }}>
                      GET ACCESS TO OUR PORTFOLIO
                    </FirstMainHeading>
                    <ReturnsText>
                      <BlackBoldText>
                        2020 RETURNS
                        <br />
                        {' '}
                        &#8377;1.54 Lakh to &#8377;24.44 Lakh
                      </BlackBoldText>
                      <br />
                      <br />
                      <BlackBoldText>
                        2021 RETURNS
                        <br />
                        {' '}
                        &#8377;6.93 Lakh to &#8377;15.19 Lakh
                      </BlackBoldText>
                    </ReturnsText>
                    {/* <MainSectionList>
                      <li style={{ fontWeight: 'bold' }}>
                        <TickImage src={Tick} />
                        TURNED
                        {' '}
                        <YellowText>₹1.54 LAKH TO ₹24.44 LAKH</YellowText>
                        {' '}
                        IN
                        JUST ONE YEAR!
                      </li>
                      <li style={{ fontWeight: 'bold' }}>
                        <TickImage src={Tick} />
                        HAVE
                        {' '}
                        <YellowText>
                          20+ YEARS OF TRACK RECORD
                        </YellowText>
                        {' '}
                        OF PROFITS IN THE STOCK MARKETS!
                      </li>
                    </MainSectionList> */}
                  </Chapter1>

                  <Chapter1>
                    <FlexDiv>
                      <DiscountImage src={OfferImg} />
                    </FlexDiv>

                    <Subheading>
                      Know our secrets & the trades we place in real time
                    </Subheading>

                    <Row justify="center">
                      <SignUpNavItem
                        className="pulse"
                        isButton
                        onClick={() => handleLinkClick('/join-us')}
                      >
                        Become a Member Today
                      </SignUpNavItem>
                    </Row>
                  </Chapter1>
                </MainContentDiv>
              </FlexDiv>
            </Col>
          </>
        ) : (
          <>
            <Col span={16}>
              <FlexDiv>
                <MainContentDiv>
                  <Chapter1>
                    <FirstMainHeading>GET ACCESS TO OUR PORTFOLIO</FirstMainHeading>
                    <ReturnsText>
                      <BlackBoldText>2020 RETURNS: &#8377;1.54 Lakh to &#8377;24.44 Lakh</BlackBoldText>
                      <br />
                      <BlackBoldText>2021 RETURNS: &#8377;6.93 Lakh to &#8377;15.19 Lakh</BlackBoldText>
                    </ReturnsText>
                    {/* <MainSectionList>
                      <li style={{ fontWeight: 'bold' }}>
                        <TickImage src={Tick} />
                        TURNED
                        {' '}
                        <YellowText>₹1.54 LAKH TO ₹24.44 LAKH</YellowText>
                        {' '}
                        IN
                        JUST ONE YEAR!
                      </li>
                      <li style={{ fontWeight: 'bold' }}>
                        <TickImage src={Tick} />
                        HAVE
                        {' '}
                        <YellowText>
                          20+ YEARS OF TRACK RECORD
                        </YellowText>
                        {' '}
                        OF PROFITS IN THE STOCK MARKETS!
                      </li>
                    </MainSectionList> */}
                  </Chapter1>

                  <Chapter1>
                    <PCSubHeading>
                      Know our secrets & the trades we place in real time
                    </PCSubHeading>
                    <Row justify="center">
                      <SignUpNavItem
                        className="pulse"
                        isButton
                        onClick={() => handleLinkClick('/join-us')}
                      >
                        Become a Member Today
                      </SignUpNavItem>
                    </Row>
                  </Chapter1>
                </MainContentDiv>
              </FlexDiv>
            </Col>
            <Col span={8}>
              <FlexDiv>
                <DiscountImage src={OfferImg} />
              </FlexDiv>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default MainSection;

export const ReturnsText = styled.h4`
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
  color: black;
`;

export const BlackText = styled.span`
  color: black;
`;

export const BlackBoldText = styled(BlackText)`
  font-weight: bold;
`;
