import React, { useState } from 'react';
import { Col, Row, Modal } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import DiscountImg from '../../assets/discount.svg';
import EmiImg from '../../assets/emiOffer.svg';
import { SignUpNavItem } from '../../styledComponents';

const CardsParent = styled.div`
  display: flex;
  background: #f6effe;
  border-radius: 10px;
  margin: 0 15px;
  padding: 10px;
  height: 100%;
  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const CardImage = styled.img`
  height: 80px;
  width: 80px;
`;

const Flex1Div = styled.div`
  flex: 1;
`;

const ParentDiv = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const CardDiscountTextParent = styled.ul`
  padding: 0;
  margin: 0;
  li:first-child {
    font-weight: 600;
    font-size: 16px;
    color: #0c0e0f;
    line-height: 15px;
    margin-top: 15px;
  }
  li {
    list-style-type: none;
  }
`;

const RedDiscountBigText = styled.span`
  font-weight: bold;
  font-size: 32px;
  color: #de4c3c;
  margin-left: 5px;
`;

const ModifiedDiscountRedText = styled(RedDiscountBigText)`
  margin-top: -20px;
  margin-left: -2px;
  line-height: 40px;
`;

const MobileRedDiscountBigText = styled(RedDiscountBigText)`
  font-size: 22px;
`;

const DiscountRedContentUL = styled.ul`
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    font-weight: 500;
    font-size: 15px;
    color: #0c0e0f;
  }
`;

const Flex1DivImage = styled(Flex1Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Flex1DivDiscount = styled(Flex1Div)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -4px;
`;

const Flex1DivDiscountContent = styled(Flex1Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RedText = styled.span`
  color: #de4c3c;
`;

const EmiContent = styled.div`
  flex: 2;
`;

const EmiUl = styled.ul`
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    margin-bottom: 3px;
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 15px;
    }
  }
  li: first-child {
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    color: #de4c3c;
  }
  li: nth-child(2) {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #0c0e0f;
  }
`;

const MainParent = styled.div`
  width: 1000px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const MobileDiscountDiv = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;

const MobileDiscountUL = styled.ul`
  padding: 0;
  margin-bottom: 0;
  li {
    list-style-type: none;
    font-weight: 500;
  }
`;

const OfferDetails = styled.p`
  font-weight: bold;
`;

const DiscountCards = ({ handlePlanClick }) => {
  const [isVisible, toggleModal] = useState(false);

  const handleToggleModal = () => {
    toggleModal(!isVisible);
  };

  const handleKnowMoreClick = (e) => {
    e.preventDefault();
    handleToggleModal();
  };

  return (
    <ParentDiv>
      <Modal
        title="EMI Offer Details"
        visible={isVisible}
        onCancel={handleToggleModal}
        footer={null}
      >
        <OfferDetails>
          1) No cost EMI valid only on merchants/banks/credit cards supported by
          RazorPay.
        </OfferDetails>

        <OfferDetails>
          2) No cost EMI valid only on 12 months membership plan.
        </OfferDetails>

        <OfferDetails>
          3) No cost EMI valid only on EMI tenure of 6 months or less.
        </OfferDetails>
        <Row justify="center">
          {handlePlanClick && (
            <SignUpNavItem
              className="pulse"
              isButton
              onClick={() => handlePlanClick(3)}
            >
              BECOME A MEMBER NOW
            </SignUpNavItem>
          )}
        </Row>
      </Modal>
      <MainParent>
        <Row justify="center">
          <Col className="mb-10" xs={24} sm={24} md={12}>
            <CardsParent>
              <Flex1DivImage>
                <CardImage src={EmiImg} />
              </Flex1DivImage>
              <EmiContent>
                <EmiUl>
                  <li>Have Cash Crunch?</li>
                  <li>
                    Pay in easy 6 months installments at no extra cost.
                    <br />
                    0% interest. Most bank supported.
                  </li>
                  <li>
                    <a href="#" onClick={handleKnowMoreClick}>
                      Know More
                    </a>
                  </li>
                </EmiUl>
              </EmiContent>
            </CardsParent>
          </Col>
        </Row>
      </MainParent>
    </ParentDiv>
  );
};

export default DiscountCards;
