import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { axiosAuth } from "../../api";

const QueriesContainer = styled.div`
  margin-top: 40px;
`;

const AllLeads = ({ common }) => {
  const user = common?.user?.userType || "";
  const [allQueriesList, setQueriesList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [] = useState([]);
  const [] = useState(false);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (sno, data, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => contact ?? "--",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      render: (age) => age ?? "--",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY HH:MM:SS"),
    },
  ];

  const freeColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (sno, data, index) => index + 1,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => contact ?? "--",
    },
    {
      title: "Requested On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY HH:MM:SS"),
    },
  ];

  useEffect(() => {
    if (user == "admin") {
      setLoading(true);
      axiosAuth
        .get("lead/allLeads")
        .then((res) => {
          if (res?.data?.data) {
            setQueriesList(res.data.data.reverse());
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [user]);
  return (
    <QueriesContainer>
      {user == "admin" ? (
        <Container>
          <h4>All Leads</h4>
          <Table
            loading={loading}
            dataSource={allQueriesList.filter((item) => !item.gender)}
            columns={columns}
          />
          <h4>Free Content Requestors</h4>
          <Table
            loading={loading}
            dataSource={allQueriesList.filter((item) => item.gender === "free")}
            columns={freeColumns}
            pagination={{
              defaultPageSize: "100",
              pageSizeOptions: ["100", "200", "500"],
            }}
          />
        </Container>
      ) : (
        <></>
      )}
    </QueriesContainer>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(AllLeads);
