import { Container } from 'react-bootstrap';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import HYB1 from '../../assets/hyb-first.png';
import HYB2 from '../../assets/hyb-second.png';
import HYB4 from '../../assets/hyb-third.png';
import HYB5 from '../../assets/hyb-fourth.png';
import HYB3 from '../../assets/hyb-fifth.png';
import HeadingUnderline from '../HeadingUnderline';

const ContentList = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const ContentListIcon = styled.img`
  height: 70px;
  margin-bottom: 10px;
`;

const ContentListText = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentSubText = styled.span`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentBorderDiv = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 7px -2px #000000;
  background: white;
  border-radius: 8px;
  width: 80%;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const HowYouBenefitLanding = () => (
  <Container>
    <Row justify="center">
      <Col>
        <HeadingUnderline title="How You Benefit" />
      </Col>
    </Row>
    <Row>
      <Col xs={24} sm={24} md={8}>
        <ContentList>
          <ContentBorderDiv>
            <ContentListIcon src={HYB2} />
            <ContentListText>
              Live Trading
              <br />
              & Practical Learning
            </ContentListText>
            <ContentSubText>
              You get to know what we trade
              <br />
              and why we trade
              <br />
              in real time
              <br />
              with explanation.
            </ContentSubText>
          </ContentBorderDiv>
        </ContentList>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ContentList>
          <ContentBorderDiv>
            <ContentListIcon src={HYB4} />
            <ContentListText>
              Our Courses
              <br />
              For Free
            </ContentListText>
            <ContentSubText>
              Our &#34;Profitable Trading Masterclass&#34;
              <br />
              and &#34;Trading Psychology Masterclass&#34;
              <br />
              course worth &#8377;2000
              <br />
              {' '}
              for Free
            </ContentSubText>
          </ContentBorderDiv>
        </ContentList>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ContentList>
          <ContentBorderDiv>
            <ContentListIcon src={HYB5} />
            <ContentListText>
              Quick Learning &
              <br />
              Minimal Losses
            </ContentListText>
            <ContentSubText>
              99% of traders lose money.
              <br />
              It takes years to be profitable.
              <br />
              Achieve your goals way
              {' '}
              <br />
              faster with our help.
            </ContentSubText>
          </ContentBorderDiv>
        </ContentList>
      </Col>
    </Row>
  </Container>
);

export default HowYouBenefitLanding;
