import { Button, Row } from 'antd';
import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaBookReader, GiFlatPlatform } from 'react-icons/all';
import styled from 'styled-components';
import { setModalType, setUserData } from '../../actions/common';
import { axiosAuth } from '../../api';
import {
  AuthActionsContainer,
  MultiPurposeText,
  LoginFormWrapper,
  LoginContainer,
} from '../../styledComponents';
import HeadingUnderline from '../HeadingUnderline';

const MultiPurposeTextCenter = styled(MultiPurposeText)`
  text-align: center;
`;

const Login = ({ setAuthModalType, setLoggedInUserData, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: null, password: null });
  const [showLoginPage, toggleLoginPage] = useState(false);

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
    const { email: rememberEmail = '', password: rememberPass = '' } = JSON.parse(localStorage.getItem('loggedInUser')) || {};
    if (rememberMeValue) {
      setEmail(rememberEmail);
      setPassword(rememberPass);
      setRememberMe(rememberMeValue);
    }
  }, []);

  const validateError = useCallback(
    (name, value) => {
      if (name === 'email' || name === 'password') {
        const modifiedErrors = {
          ...errors,
          [name]: !value,
        };
        setErrors(modifiedErrors);
      }
    },
    [email, password, errors],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value, checked } = e.target;
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        setPassword(value);
      } else {
        setRememberMe(checked);
      }
      validateError(name, value);
    },
    [email, password, rememberMe, errors],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [email, password, errors],
  );

  const handleForgotPassClick = useCallback(() => {
    setAuthModalType('fp');
  }, []);

  const isLoginDisabled = useMemo(() => !email || !password, [email, password]);

  const handleCourseLogin = () => {
    window.location.href = 'https://course.thelogicaltraders.com';
  };

  const handleLogin = useCallback(() => {
    if (isLoginDisabled) {
      return;
    }
    setLoading(true);
    localStorage.setItem('rememberMe', rememberMe);
    if (rememberMe) {
      localStorage.setItem('loggedInUser', JSON.stringify({ email, password }));
    } else {
      localStorage.removeItem('loggedInUser');
    }
    axiosAuth
      .post('auth/login', {
        email,
        password,
      })
      .then((res) => {
        if (res?.data?.code === 401) {
          toast(
            'Your account is suspended. Please contact admin for further support',
            { type: toast.TYPE.ERROR },
          );
        } else if (res?.data?.code === 402) {
          toast(
            'Subscription Expired. Please renew your subscription to continue using our services!!',
            { type: toast.TYPE.ERROR },
          );
          if (res?.data?.data?.token) {
            history.push(`/renew-subscription/${res?.data?.data?.token}`);
          }
        } else if (res?.data?.code === 403) {
          toast('Email or Password incorrect!!', { type: toast.TYPE.ERROR });
        } else if (res?.data?.data?.token && res?.data?.data?.user?.active) {
          setLoggedInUserData(res.data.data);
          localStorage.setItem('authToken', res.data.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.data.user));
          history.push('/posts');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast('Login failed. Please try again or contact admin!!', {
          type: toast.TYPE.ERROR,
        });
      });
  }, [isLoginDisabled, email, password, rememberMe]);

  const handleSignUpClick = useCallback(() => {
    setAuthModalType(null);
    history.push('/register');
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleLogin();
      }
    },
    [isLoginDisabled, email, password, handleLogin],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <LoginContainer>
      <HeadingUnderline title="Login" />
      {!showLoginPage
        ? (
          <Row justify="center">
            <div
              aria-hidden="true"
              onClick={handleCourseLogin}
              className="new-box"
            >
              <div className="box">
                <div className="boxContent">
                  <FaBookReader style={{ fontSize: '50px' }} />
                  <h1 className="title">Course Login</h1>
                </div>
              </div>
            </div>

            <div
              aria-hidden="true"
              onClick={() => toggleLoginPage(true)}
              className="new-box"
            >
              <div className="box">
                <div className="boxContent">
                  <GiFlatPlatform style={{ fontSize: '50px' }} />
                  <h1 className="title">Platform Login</h1>
                </div>
              </div>
            </div>
          </Row>
        )
        : (
          <LoginFormWrapper>
            <Form.Group>
              <Form.Label className="required">Email address</Form.Label>
              <Form.Control
                value={email}
                name="email"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="email"
                placeholder="Enter email"
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                Email is required!!
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Password</Form.Label>
              <Form.Control
                value={password}
                name="password"
                className="custom-field-focus"
                onChange={handleInputChange}
                onBlur={handleBlur}
                type="password"
                placeholder="Enter password"
                isInvalid={errors.password}
              />
              <Form.Control.Feedback type="invalid">
                Password is required!!
              </Form.Control.Feedback>
            </Form.Group>
            <AuthActionsContainer>
              <Form.Group>
                <Form.Check
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={handleInputChange}
                  type="checkbox"
                  label="Remember me"
                />
              </Form.Group>
              <MultiPurposeText hoverOnParent onClick={handleForgotPassClick}>
                Forgot password?
              </MultiPurposeText>
            </AuthActionsContainer>
            <Button
              disabled={isLoginDisabled}
              onClick={handleLogin}
              loading={loading}
              className="signUpBtn"
              size="large"
              block
            >
              Platform Login
            </Button>
            {/* <MultiPurposeText>
              Don't have an account?
              <span onClick={handleSignUpClick}> Join us now</span>
            </MultiPurposeText> */}
            <MultiPurposeTextCenter>
              &#8592;&nbsp;
              <span onClick={() => toggleLoginPage(false)}>Go Back</span>
            </MultiPurposeTextCenter>
          </LoginFormWrapper>
        )}
    </LoginContainer>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalType: (type) => dispatch(setModalType(type)),
  setLoggedInUserData: (userData) => dispatch(setUserData(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
