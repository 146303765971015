import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import commonReducer from './commonReducer';

const AppReducers = combineReducers({
  home: homeReducer,
  common: commonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }

  return AppReducers(state, action);
};

export default rootReducer;
