import React from 'react';
import { Skeleton } from 'antd';

const LoadingSkeleton = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
  <div className="post-box">
    <div style={{ padding: '15px' }}>
      <Skeleton loading active avatar>
        <p>Hello</p>
        <p>Hello</p>
      </Skeleton>
    </div>
  </div>
));

export const LoadingSingleSkeleton = () => [10].map((item) => (
  <div className="post-box">
    <div style={{ padding: '15px' }}>
      <Skeleton loading active avatar>
        <p>Hello</p>
        <p>Hello</p>
      </Skeleton>
    </div>
  </div>
));

export const LoadingNotificationSkeleton = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
  <div className="post-box" key={item}>
    <div style={{ padding: '5px' }}>
      <Skeleton loading active />
    </div>
  </div>
));

export default LoadingSkeleton;
