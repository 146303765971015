import React from "react";
import { Button, Card } from "antd";
import { useHistory } from "react-router-dom";
import Meta from "antd/lib/card/Meta";
import lock from "../../assets/lock.svg";

const BlogBrief = ({
  imageSrc,
  title,
  description,
  onClick,
  isLocked,
  mobileStyles,
}) => {
  const history = useHistory();

  return (
    <div
      className='blog-parent-div'
      style={
        mobileStyles ? { ...mobileStyles } : { width: "80%", margin: "0 auto" }
      }
    >
      <Card
        onClick={onClick}
        hoverable
        style={{ width: "100%" }}
        cover={
          <img alt='example' style={{ maxHeight: "197px" }} src={imageSrc} />
        }
      >
        <Meta title={title} description={description} />
      </Card>
    </div>
  );
};

export default BlogBrief;
