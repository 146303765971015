import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import WhatsAppWidget from 'react-whatsapp-widget';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import Logo from '../../assets/logo1.svg';
import LogoFace from '../../assets/logo-face.svg';
import BetterForYou from '../../components/BetterForYou';
import KeySecrets from '../../components/LandingPage/KeySecrets';
import TelegramSection from '../../components/LandingPage/TelegramSection';
import gmailIcon from '../../assets/Gmail.svg';
import whatsappIcon from '../../assets/whatsup.svg';
import FeaturedOn from '../../components/FeaturedOnSection';
import Experts from '../../components/OurExperts';
import HowYouBenefitLandingFree from '../../components/HowYouBenefit/HowYouBenefitLandingFree';
import LandingFooter from '../../components/Footer/LandingFooter';
import { getEnvValue } from '../../utils/helper';
import TelegramButton from '../../components/LandingPage/TelegramButton';

const SocialIcons = styled.img`
  height: 15px;
  margin-right: 6px;
  margin-top: -5px;
`;

const WhatsAppIcon = styled(SocialIcons)`
  height: 20px;
`;

const LinksContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LandingPageWebinar = () => {
  const [scrollPosition, setScrollPosition] = useState(null);
  const location = useLocation();
  const listenToScroll = useCallback(() => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height = document.documentElement.scrollHeight
      - document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const showWhatsApp = useMemo(() => {
    if (location.pathname == '/landing-page-ebook') {
      if (isMobile) {
        return scrollPosition > 0.05;
      }
      return scrollPosition > 0.076;
    }
    return true;
  }, [scrollPosition, location]);

  return (
    <>
      {showWhatsApp ? (
        <>
          {!isMobile && (
            <div className="contact-us-text-landing">
              <span
                style={{
                  color: '#333333',
                  textAlign: 'center',
                  fontWeight: 'bolder',
                }}
              >
                Contact Us
              </span>
            </div>
          )}

          <div className="whatsapp-button whatsapp-button-landing">
            <WhatsAppWidget
              companyName="The Logical Traders"
              phoneNumber="+916267990054"
            />
          </div>

          <div className="fb-chat-support">
            <MessengerCustomerChat
              pageId={getEnvValue('FACEBOOK_PAGE_ID')}
              appId={getEnvValue('FACEBOOK_APP_ID')}
              htmlRef="https://www.facebook.com/thelogicaltraders/"
            />
          </div>
        </>
      ) : null}

      <Row>
        <Col xs={8} sm={8} md={12} lg={12}>
          <img
            src={isMobileOnly ? LogoFace : Logo}
            width={isMobileOnly ? '150px' : '250px'}
            style={{ padding: isMobileOnly ? '15px 15px 5px 15px' : '15px' }}
          />
        </Col>
        <Col xs={16} sm={16} md={12} lg={12}>
          <LinksContainer>
            <div style={{ marginRight: '15px' }}>
              <a
                href="mailto:support@thelogicaltraders.com"
                style={{ marginRight: '10px' }}
              >
                <SocialIcons src={gmailIcon} />
                <span>
                  <b>Email Us</b>
                </span>
                {' '}
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=+916267990054"
                target="_blank"
              >
                <WhatsAppIcon src={whatsappIcon} />
                <span>
                  <b>WhatsApp Us</b>
                </span>
              </a>
            </div>
          </LinksContainer>
        </Col>
      </Row>
      <div className="black-bg padding-30">
        <KeySecrets />
      </div>
      <div className="grey-bg padding-30">
        <TelegramSection />
      </div>
      <div className="black-bg padding-30">
        <BetterForYou signUpText="Become a Member Today" showTelegram />
      </div>
      <div className="grey-bg padding-30">
        <HowYouBenefitLandingFree />
      </div>
      <div className="clearfix" />

      <div className="black-bg padding-30">
        <Experts showExperts />
        <Row>
          <TelegramButton
            customStyle={{
              marginTop: !isMobileOnly ? '30px' : '0px',
              marginBottom: '15px',
            }}
          />
        </Row>
      </div>
      <div className="white-bg padding-30">
        <FeaturedOn />
      </div>
      <LandingFooter />
    </>
  );
};

export default LandingPageWebinar;
