import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Row, Collapse } from 'antd';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Spacer } from '../../routes/about';
import { StyledResultText } from './AboutCourse';
import ActionButton from './ActionButton';
import { ColoredContainer } from './Common';

const { Panel } = Collapse;

const CourseFaqs = () => {
  const [_, changeKey] = useState('');
  const handleFaqChange = (key) => {
    changeKey(key);
  };
  return (
    <GrauColoredContainer>
      <WhiteStyledResultText>FAQs</WhiteStyledResultText>
      <Container>
        <Collapse
          className="site-collapse-custom-collapse"
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          onChange={handleFaqChange}
        >
          <Panel
            className="site-collapse-custom-panel"
            header="What is the language of the Webinar? "
            key="1"
          >
            <p>The webinar will be conducted in Hindi & English separately.</p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="How will I see the Profit & Loss Statement?"
            key="2"
          >
            <p>
              We will login into our Zerodha account live on the webinar and
              show the profit & loss statement of the last 3 years.
            </p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="I am unable to join the WhatsApp group. What should I do?"
            key="30"
          >
            <p>
              Just drop an email on support@thelogicaltraders.com with your
              registered email and share your active WhatsApp number.
            </p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Will you only show the indicators or give them to us too?"
            key="40"
          >
            <p>You will be able to get the indicators on Trading View.</p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Will the strategy & indicators be the same that were used to generate profits?"
            key="3"
          >
            <p>
              Yes. These are the part of the strategies & secrets we used to
              generate profits over the last 3 years.
            </p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Will I get reminder for the webinar?"
            key="4"
          >
            <p>
              Yes, you will get a reminder 1 hour before the commencement of the
              webinar.
            </p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Will I get a recording of the webinar?"
            key="5"
          >
            <p>No. We do not provide recording on the webinar.</p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Do I need any prior stock market knowledge to join the webinar?"
            key="6"
          >
            <p>
              Basic understanding of the stock market and trading is enough to
              attend and benefit from the webinar.
            </p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Can I ask questions during the webinar?"
            key="7"
          >
            <p>
              Yes, there’s a dedicated section of the webinar for queries and
              doubts.
            </p>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header="Is the webinar for people with large capital?"
            key="8"
          >
            <p>
              No. Even people with capital under Rs 1 lakh can attend the
              webinar and get valuable information.
            </p>
          </Panel>
        </Collapse>
      </Container>

      {!isMobile && (
        <Row>
          <Col span={3} />
          <Col span={18}>
            <Spacer />
            <Spacer />
            <ActionButton />
          </Col>
          <Col span={3} />
        </Row>
      )}
    </GrauColoredContainer>
  );
};

export default CourseFaqs;

const GrauColoredContainer = styled(ColoredContainer)`
  background: #fafafa;
  min-height: inherit;
`;

const WhiteStyledResultText = styled(StyledResultText)`
  color: black;
  text-align: center;
  font-size: 20px;
`;
