import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ttiPolyfill from "tti-polyfill";
import { isMobileOnly } from "react-device-detect";
import configureStore from "./store";
import Container from "./Container";
import { theme } from "./utils/theme";
import { checkIsLocalhost, getEnvValue } from "./utils/helper";
import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import { axiosAuth } from "./api";
import maintenanceImage from "./assets/maintenance.jpg";
import maintenanceImageMobile from "./assets/mobileMaintenance.jpg";
import UnderMaintenanceContent from "./components/Maintenance";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const UnderMaintenance = styled.div`
  background-image: url(${isMobileOnly
    ? maintenanceImageMobile
    : maintenanceImage});
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const advancedMatching = {};

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.timing({
    category: "Load Performace",
    variable: "Time to Interactive",
    value: tti,
  });
});

const callback = (list) => {
  list.getEntries().forEach((entry) => {
    ReactGA.timing({
      category: "First Meaningful Paint",
      variable: entry.name,
      value: entry.responseEnd,
    });
  });
};

const observer = new PerformanceObserver(callback);
observer.observe({
  entryTypes: [
    "navigation",
    "paint",
    // "resource",
    // "mark",
    // "measure",
    // "frame",
    // "longtask",
  ],
});

function App() {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  useEffect(() => {
    axiosAuth.get("underMaintenance").then((res) => {
      if (res?.data?.data?.isTLT) {
        setIsUnderMaintenance(true);
      }
    });
    window.scrollTo(0, 0);
    const isLocalhost = checkIsLocalhost();
    if (!isLocalhost) {
      ReactPixel.init(
        getEnvValue("FACEBOOK_PIXEL_ID"),
        advancedMatching,
        options
      );
      ReactGA.initialize(getEnvValue("GA_TRACKING_ID"));
    }

    // Runs only for the first time
    ReactGA.set({ page: window.location.origin + window.location.pathname });
    ReactGA.pageview(window.location.origin + window.location.pathname);
    ReactPixel.pageView();
  }, []);

  return (
    <Provider store={configureStore()}>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        newestOnTop
        pauseOnHover
      />
      <ThemeProvider theme={theme}>
        {isUnderMaintenance ? (
          <>
            <UnderMaintenance>
              <UnderMaintenanceContent />
            </UnderMaintenance>
          </>
        ) : (
          <Router>
            <Container />
          </Router>
        )}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
