import { Container } from 'react-bootstrap';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { FiActivity, FiStar } from 'react-icons/fi';
import { FaComments, FaMagic } from 'react-icons/fa';
import { theme } from '../../utils/theme';
import ActionButton from './ActionButton';
import { ActionButtonContainer } from './BannerSection';
import { SectionHeadingText } from './Common';

const ContentList = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const ContentListText = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentSubText = styled.span`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
`;

const ContentBorderDiv = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 7px -2px #000000;
  border-radius: 8px;
  width: 80%;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const WhatYouGet = () => (
  <Container>
    <div style={{ padding: '20px 0' }}>
      <Row justify="center">
        <Col>
          <SectionHeadingText>
            Here&apos;s What You&apos;ll Get During The Live Webinar For Free
          </SectionHeadingText>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <BankOutlined
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                Bank Nifty Strategy &
                <br />
                1 Indicator
              </ContentListText>
              <ContentSubText>
                1 intraday/positional strategy and
                {' '}
                <br />
                1 indicator that I have used to
                <br />
                make profit over the last 3 years.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <FiActivity
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                Live Profit & Loss
                <br />
                Statement
              </ContentListText>
              <ContentSubText>
                I will show you my Zerodha Profit &
                <br />
                Loss Statement of the Last 3 years
                <br />
                proving that I&apos;m a full time trader
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContentList>
            <ContentBorderDiv>
              <IconDiv>
                <FaMagic
                  style={{ color: theme.colors.main, fontSize: 30 }}
                  size={30}
                />
              </IconDiv>
              <ContentListText>
                Opportunity To
                <br />
                Trade With Me
                {' '}
              </ContentListText>
              <ContentSubText>
                Give you an opportunity to trade
                <br />
                {' '}
                with me and generate a relaible
                <br />
                source of income by trading.
              </ContentSubText>
            </ContentBorderDiv>
          </ContentList>
        </Col>
      </Row>
    </div>
    <ButtonText>
      This is the best Risk/Reward ratio you can find for FREE
    </ButtonText>
    <SpacedActionButtonContainer>
      <ActionButton />
    </SpacedActionButtonContainer>
  </Container>
);

export default WhatYouGet;

const IconDiv = styled.div`
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const ButtonText = styled.p`
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
  color: ${theme.colors.main};
  margin-bottom: 0;
`;

export const SpacedActionButtonContainer = styled(ActionButtonContainer)`
  margin-bottom: 30px;
`;
