import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Container } from 'react-bootstrap';
import {
  Button,
  Col,
  Row,
  Form,
  InputNumber,
  Tag,
  Upload,
  notification,
} from 'antd';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import * as R from 'ramda';
import '../posts/post.scss';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/lib/input/TextArea';
import Lightbox from 'react-image-lightbox';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { uuid } from 'uuidv4';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { TRADE_TAGS } from '../posts/constants';
import { AsyncS3Upload } from '../../utils/s3Uploader';
import { axiosAuth } from '../../api';
import { LoadingSingleSkeleton } from '../posts/LoadingSkeleton';
import PostItem from '../posts/PostItem';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { PostsContainer } from '../posts/styled';
import logo1 from '../../assets/logo1.svg';
import { useHistory, useParams } from 'react-router-dom';
import { checkImageSize, setFileListBySize } from '../../utils/helper';
import GoBack from '../../components/GoBack';
import BottomTabNavigator from '../../components/BottomTabNavigator';
import HamburgerMenuLinks from '../../components/HamburgerMenuLink';

const Posts = () => {
  const user = useSelector((state) => state.common.user);
  const [showPostModal, togglePostModal] = useState(false);
  const [title, setTitle] = useState('');
  const [target1, setTarget1] = useState('');
  const [target2, setTarget2] = useState('');
  const [risk, setRisk] = useState('');
  const [stopLoss, setStopLoss] = useState('');
  const [lightboxImage, setLightBoxImage] = useState([]);
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [selectedTags, setTags] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [parentFilter, setParentFilter] = useState('');
  const [showCommentBox, setCommentBox] = useState(null);
  const [editorData, setEditorData] = useState(EditorState.createEmpty());
  const [editId, setEditId] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [likeLoading, setLikeLoading] = useState(false);
  const [showChildCommentBox, setChildCommentBox] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [isMenuOpen, toggleHamburger] = useState(false);

  const history = useHistory();
  const { postId, notificationId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!R.isEmpty(user) && !user?.agree) {
      history.push('/community-terms-and-conditions');
    }
    setLoading(true);

    const endpoint = '/post/allPosts';
    axiosAuth
      .post(endpoint, { postId })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setAllPosts(res?.data?.data);
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to load posts. Please try again later!!',
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    if (notificationId) {
      axiosAuth.post('/notification/markAsRead', {
        notificationIds: [notificationId],
      });
    }
  }, [user]);

  useEffect(() => {
    const finalDataNew = R.uniqBy(R.prop('_id'), [...finalData, ...allPosts]);
    setFinalData(finalDataNew);
  }, [allPosts]);

  const handleTagClick = (tag) => {
    const itemIndex = selectedTags.findIndex((item) => item == tag);
    if (itemIndex > -1) {
      setTags(R.remove(itemIndex, 1, selectedTags));
    } else {
      setTags([...selectedTags, tag]);
    }
  };

  const resetData = () => {
    togglePostModal(false);
    setTitle('');
    setTarget1('');
    setTarget2('');
    setRisk('');
    setStopLoss('');
    setTags([]);
    setFileList([]);
    setEditorData(EditorState.createEmpty());
    setEditId(null);
    setShowDisclaimer(false);
  };

  const handleData = useCallback(
    (e) => {
      const { items } = e.clipboardData || e.originalEvent.clipboardData;
      let blob = null;
      let filetype = '';
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === 0) {
          blob = items[i].getAsFile();
          filetype = items[i].type;
          break;
        }
      }
      // load image if there is a pasted image
      if (blob !== null) {
        if (filesList.length < 4) {
          const reader = new FileReader();
          reader.onload = function (event) {
            fetch(event.target.result)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], 'clipboard-image.png', {
                  type: filetype,
                });
                const fileObj = {
                  type: filetype,
                  thumbUrl: URL.createObjectURL(file),
                  uid: uuid(),
                  percent: 100,
                  originFileObj: file,
                  status: 'done',
                  name: file.name,
                };
                setFileList([...filesList, fileObj]);
              });
          };
          reader.readAsDataURL(blob);
        } else {
          notification.error({
            message: 'Clipboard paste error',
            description: 'Sorry, maximum image upload limit is 4',
          });
        }
      }
    },
    [filesList],
  );

  const handlePreview = async (file) => {
    setLightBoxImage([
      file?.originFileObj
        ? URL.createObjectURL(file.originFileObj)
        : file?.thumbUrl,
    ]);
    toggleLightBox(true);
  };

  const handleRemove = async (file) => {
    if (file?.alreadyUploaded) {
      const index = filesList.findIndex(
        (item) => item.thumbUrl === file.thumbUrl,
      );
      setFileList(R.remove(index, 1, filesList));
      return false;
    }
    return true;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSavePost = () => {
    let errMsg = '';
    const titleContent = draftToHtml(
      convertToRaw(editorData.getCurrentContent()),
    ).toString();
    if (user?.userType === 'admin') {
      if (!titleContent) {
        notification.error({
          message: 'Create Post Error',
          description: 'Please enter title!!',
        });
        return;
      }
    }
    if (!title && user?.userType != 'admin') {
      errMsg = 'Please enter title!!';
    } else if (!filesList?.length && user?.userType !== 'admin') {
      errMsg = 'Please upload at least one chart image!!';
    } else if (!selectedTags?.length) {
      errMsg = 'Please select at least one tag!!';
    }
    if (errMsg) {
      notification.error({
        message: 'Create Post Error',
        description: errMsg,
      });
      return;
    }
    setLoading(true);
    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq('alreadyUploaded', true)),
        R.map(R.prop('thumbUrl')),
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      const payload = {
        postedBy: user?._id,
        verificationStatus: user?.userType == 'admin' ? 'approved' : 'pending',
        title:
          user?.userType == 'admin'
            ? draftToHtml(
              convertToRaw(editorData.getCurrentContent()),
            ).toString()
            : title,
        images: alreadyUploadedImages.concat(images),
        targetPrice1: target1,
        targetPrice2: target2,
        risk,
        stopLoss,
        postedByRole: user?.userType == 'admin' ? 'admin' : 'user',
        comments: [],
        tags: selectedTags,
        showDisclaimer: user?.userType == 'admin' ? showDisclaimer : false,
      };
      if (editId) {
        payload._id = editId;
        delete payload.comments;
      }
      const apiProvider = !editId ? axiosAuth.post : axiosAuth.patch;
      apiProvider(editId ? 'post/update' : 'post/create', payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (editId) {
              notification.success({
                message: 'Success',
                description: 'Post updated successfully!!',
              });
              const updatedData = R.update(
                R.findIndex(R.propEq('_id', editId))(finalData),
                res?.data?.data,
                finalData,
              );
              setFinalData(updatedData);
            } else {
              if (user?.userType == 'admin') {
                setFinalData(R.insert(0, res?.data?.data, finalData));
              }
              notification.success({
                message: 'Success',
                description: 'Post created successfully and is under review!!',
              });
            }
            resetData();
          } else {
            notification.error({
              message: 'Error',
              description: 'Failed to post. Please try again later!!',
            });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    });
  };

  const handleSendComment = (content, postId, setMethod) => {
    const payload = {
      commentedBy: user?._id,
      content,
      postId,
    };
    axiosAuth
      .post('post/createComment', payload)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setMethod('');
          const alter = R.curry((prop, items) => R.map(
            R.when(
              R.propEq('_id', prop),
              R.assoc('comments', res?.data?.data),
            ),
            items,
          ));

          const newData = alter(postId, finalData);
          setFinalData(newData);
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to add comment. Please try again later!!',
          });
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Failed to add comment. Please try again later!!',
        });
      });
  };

  const handleEditPost = (e, data) => {
    e.preventDefault();
    setTarget1(data?.targetPrice1);
    setTarget2(data?.targetPrice2);
    setRisk(data?.risk);
    setStopLoss(data?.stopLoss);
    setTags(data?.tags ?? []);
    setFileList(
      data?.images?.map((item) => ({ thumbUrl: item, alreadyUploaded: true })),
    );
    if (user?.userType == 'admin') {
      const blocksFromHTML = convertFromHTML(data?.title);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorData(EditorState.createWithContent(state));
    } else {
      setTitle(data?.title);
    }
    togglePostModal(true);
    setEditId(data?._id);
    setShowDisclaimer(data?.showDisclaimer);
  };

  const handleReplayComment = (
    content,
    postId,
    commentId,
    setMethod,
    index,
  ) => {
    const payload = {
      commentedBy: user?._id,
      content,
      postId,
      commentId,
    };
    axiosAuth
      .post('post/createCommentReply', payload)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setMethod('');
          const alter = R.curry((prop, items) => R.map(
            R.when(
              R.propEq('_id', prop),
              R.assoc('comments', res?.data?.data),
            ),
            items,
          ));

          const newData = alter(postId, finalData);
          setFinalData(newData);
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to add comment. Please try again later!!',
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Failed to add comment. Please try again later!!',
        });
      });
  };

  const handleDeleteReplayComment = (e, postData, commentData) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this comment?')) {
      axiosAuth
        .delete(
          `post/deleteComment?postId=${postData._id}&commentId=${commentData._id}`,
        )
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Comment deleted successfully!!',
            });

            const comments = R.pipe(R.prop('reply'))(postData);

            const itemIndex = comments.findIndex(
              (item) => item._id == commentData._id,
            );
            const modifiedComments = R.remove(itemIndex, 1, comments);

            const updatedDataModify = {
              ...postData,
              comments: modifiedComments,
            };

            const postIndex = finalData.findIndex(
              (item) => item._id == postData._id,
            );

            const updatedData = R.update(
              postIndex,
              updatedDataModify,
              finalData,
            );
            setFinalData(updatedData);
          } else {
            notification.error({
              message: 'Error',
              description:
                'Failed to delete replay comment. Please try again!!',
            });
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete replay comment. Please try again!!',
          });
        });
    }
  };

  const handleDeletePost = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this post?')) {
      axiosAuth
        .delete(`post/delete/${id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Post deleted successfully!!',
            });
            history.push('/posts');
            const itemIndex = finalData.findIndex((item) => item._id === id);
            const updatedData = R.remove(itemIndex, 1, finalData);
            setFinalData(updatedData);
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete post. Please try again!!',
          });
        });
    }
  };

  const handleDeleteComment = (e, postData, commentData) => {
    e.preventDefault();
    if (window.confirm('Are you sure want to delete this comment?')) {
      axiosAuth
        .delete(
          `post/deleteComment?postId=${postData._id}&commentId=${commentData._id}`,
        )
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: 'Success',
              description: 'Comment deleted successfully!!',
            });

            const comments = R.pipe(R.prop('comments'))(postData);

            const itemIndex = comments.findIndex(
              (item) => item._id == commentData._id,
            );
            const modifiedComments = R.remove(itemIndex, 1, comments);

            const updatedDataModify = {
              ...postData,
              comments: modifiedComments,
            };

            const postIndex = finalData.findIndex(
              (item) => item._id == postData._id,
            );

            const updatedData = R.update(
              postIndex,
              updatedDataModify,
              finalData,
            );

            setFinalData(updatedData);
          } else {
            notification.error({
              message: 'Error',
              description: 'Failed to delete comment. Please try again!!',
            });
          }
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to delete comment. Please try again!!',
          });
        });
    }
  };

  const favorateHandler = (data, isFav) => {
    setFavoriteLoading(data._id);
    axiosAuth
      .post(`post/makeFavorite?isFavorite=${isFav}`, { postId: data._id })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification.success({
            message: 'Success',
            description: isFav
              ? 'Successfully Added post in favorite list'
              : 'Successfully Removed post from favorite list',
          });
          if (parentFilter === 'favorite') {
            const itemIndex = finalData.findIndex(
              (item) => item._id == data._id,
            );
            if (itemIndex > -1) {
              setFinalData(R.remove(itemIndex, 1, finalData));
            }
          } else {
            const updatedData = R.update(
              R.findIndex(R.propEq('_id', data._id))(finalData),
              res?.data?.data,
              finalData,
            );
            setFinalData(updatedData);
          }
        } else {
          notification.error({
            message: 'Error',
            description:
              'Failed to mark post as favorite. Please try again later!!',
          });
        }
        setFavoriteLoading(false);
      })
      .catch(() => {
        setFavoriteLoading(false);
      });
  };

  const likeHandler = (id, shouldLike) => {
    setLikeLoading(id);
    axiosAuth
      .post(`post/likePost?isLike=${shouldLike}`, { postId: id })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          const updatedData = R.update(
            R.findIndex(R.propEq('_id', id))(finalData),
            res?.data?.data,
            finalData,
          );
          setFinalData(updatedData);
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to like post. Please try again later!!',
          });
        }
        setLikeLoading(false);
      })
      .catch(() => {
        setLikeLoading(false);
      });
  };

  return (
    <PostsContainer isMobileOnly={isMobileOnly} className="posts">
      <HamburgerMenuLinks
        isMenuOpen={isMenuOpen}
        toggleMenu={(value) => toggleHamburger(value)}
      />
      <Modal
        maskClosable={false}
        onCancel={() => resetData()}
        visible={showPostModal}
        title="Edit Post"
        className="create-post-modal"
        footer={[
          <Button
            loading={loading}
            className="save-post-button"
            onClick={handleSavePost}
          >
            Post
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item label="Title" required>
                {user?.userType == 'admin' ? (
                  <>
                    <Editor
                      editorState={editorData}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(editorState) => {
                        setEditorData(editorState);
                      }}
                    />
                    <TextArea
                      placeholder="Paste chart image from clipboard"
                      allowClear
                      value={title}
                      onChange={() => undefined}
                      autoSize={{
                        minRows: 2,
                        maxRows: 4,
                      }}
                      onPaste={handleData}
                    />
                  </>
                ) : (
                  <TextArea
                    placeholder="Share your trade/Paste chart image from clipboard"
                    allowClear
                    value={title}
                    onChange={({ target }) => setTitle(target?.value)}
                    autoSize={{
                      minRows: 2,
                      maxRows: 4,
                    }}
                    onPaste={handleData}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Upload Charts" required>
                <Upload
                  action=""
                  accept=".png,.jpg,.jpeg,image/png,image/jpeg"
                  listType="picture-card"
                  fileList={filesList}
                  onPreview={handlePreview}
                  beforeUpload={(file) => {
                    checkImageSize(file);
                  }}
                  onChange={({ fileList }) => {
                    setFileListBySize(fileList, setFileList);
                  }}
                  onRemove={handleRemove}
                >
                  {filesList.length >= 4 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Risk (%)">
                <InputNumber
                  min={0}
                  max={user?.userType == 'admin' ? 100 : 2}
                  value={risk}
                  onChange={(value) => setRisk(value)}
                  placeholder="Risk"
                  step="0.01"
                  onBlur={() => risk && setRisk(parseFloat(risk).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Target Price 1">
                <InputNumber
                  min={0}
                  value={target1}
                  onChange={(value) => setTarget1(value)}
                  placeholder="Target 1"
                  step="0.01"
                  onBlur={() => target1 && setTarget1(parseFloat(target1).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Target Price 2">
                <InputNumber
                  min={0}
                  value={target2}
                  onChange={(value) => setTarget2(value)}
                  placeholder="Target 2"
                  step="0.01"
                  onBlur={() => target2 && setTarget2(parseFloat(target2).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item label="Stop Loss">
                <InputNumber
                  min={0}
                  value={stopLoss}
                  onChange={(value) => setStopLoss(value)}
                  placeholder="Stop Loss"
                  step="0.01"
                  onBlur={() => stopLoss && setStopLoss(parseFloat(stopLoss).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Tags (Select atleast one)" required>
                <div className="taglist">
                  {TRADE_TAGS.map((item) => (
                    <Tag
                      onClick={() => handleTagClick(item)}
                      className="tag-item"
                      color={selectedTags.includes(item) ? '#F9AE00' : 'gold'}
                    >
                      {item}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </Col>
            {user?.userType === 'admin' && (
              <Col>
                <Checkbox
                  checked={showDisclaimer}
                  onChange={() => setShowDisclaimer(!showDisclaimer)}
                >
                  Show Disclaimer
                </Checkbox>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      {isLightBoxVisible && (
        <Lightbox
          mainSrc={lightboxImage[photoIndex]}
          nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length]}
          prevSrc={
            lightboxImage[
              (photoIndex + lightboxImage.length - 1) % lightboxImage.length
            ]
          }
          onCloseRequest={() => toggleLightBox(false)}
          onMovePrevRequest={() => setPhotoIndex(
            (photoIndex + lightboxImage.length - 1) % lightboxImage.length,
          )}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxImage.length)}
        />
      )}
      <Container>
        {isMobile && (
          <div className="menu-btn-heading-parent">
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: '25px',
                position: 'absolute',
                zIndex: 1000,
              }}
            />
            <div className="posts-heading text-center">
              <img
                onClick={() => history.push('/')}
                alt=""
                src={logo1}
                className="d-inline-block align-top brand-image"
              />
            </div>
          </div>
        )}
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col className="mb-10">
                <GoBack path="/posts" />
              </Col>
              <Col span={24}>
                {loading ? (
                  <LoadingSingleSkeleton />
                ) : (
                  finalData?.map((item) => (
                    <PostItem
                      key={item._id}
                      data={item}
                      showCommentBox={showCommentBox === item?._id}
                      handleCommentClick={(id) => setCommentBox(id)}
                      handleAddComment={(value, id, setMethod) => handleSendComment(value, id, setMethod)}
                      setLightBoxImage={setLightBoxImage}
                      toggleLightBox={toggleLightBox}
                      handleEditPost={handleEditPost}
                      handleDeletePost={handleDeletePost}
                      favorateHandler={favorateHandler}
                      favoriteLoading={favoriteLoading}
                      handleDeleteComment={handleDeleteComment}
                      likeHandler={likeHandler}
                      likeLoading={likeLoading}
                      clickOnReplay={(id) => setChildCommentBox(id)}
                      showChildCommentBox={showChildCommentBox}
                      handleAddReplayComment={(
                        value,
                        postId,
                        commentId,
                        setMethod,
                      ) => handleReplayComment(
                        value,
                        postId,
                        commentId,
                        setMethod,
                      )}
                      handleDeleteReplayComment={handleDeleteReplayComment}
                    />
                  ))
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {isMobile && <BottomTabNavigator />}
      </Container>
    </PostsContainer>
  );
};

export default Posts;
