import styled from 'styled-components';

export const NavbarItem = styled.div`
  font-size: 16px;
  position: relative;
  display: inline-block;
  color: ${({ theme, isButton }) => (isButton ? theme.colors.black : theme.colors.lightgrey)};
  overflow: hidden;
  font-weight: 500;
  font-weight: ${({ isBold }) => isBold && 'bold !important'};
  background: ${({ theme, isButton }) => (!isButton
    ? `linear-gradient(to right, ${theme.backgrounds.main}, ${
      theme.backgrounds.main
    } 50%, ${isButton ? theme.colors.black : theme.colors.lightgrey} 50%)`
    : '')};
  background-clip: ${({ isButton }) => !isButton && 'text'};
  -webkit-background-clip: ${({ isButton }) => !isButton && 'text'};
  -webkit-text-fill-color: ${({ isButton }) => !isButton && 'transparent'};
  background-size: ${({ isButton }) => !isButton && '200% 100%'};
  background-position: ${({ isButton }) => !isButton && '100%'};
  transition: ${({ isButton }) => !isButton && 'background-position 275ms ease'};
  text-decoration: none;
  margin-right: 15px;
  border: ${({ isButton }) => isButton && '1px solid black'};
  border-radius: ${({ isButton }) => isButton && '6px'};
  padding: ${({ isButton }) => isButton && '0 20px'};
  line-height: ${({ isButton }) => isButton && '30px'};
  &:hover {
    cursor: pointer;
    background-position: 0 100%;
  }
  &:focus {
    outline: 0;
  }
`;

export const MobileNavbarItem = styled(NavbarItem)`
  margin-bottom: 10px;
`;

export const SignUpNavItem = styled(NavbarItem)`
  background: ${({ theme }) => theme.backgrounds.darkorchid};
  margin-right: 0;
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.darkorchid}`};
  color: ${({ theme }) => theme.colors.white};
  line-height: 50px;
  font-size: 25px;
  display: none;
  @media (max-width: 767px) {
    line-height: 35px !important;
    font-size: 16px !important;
    margin: 0 auto !important;
  }
`;

export const HeaderSignUpButton = styled(SignUpNavItem)`
  line-height: 33px !important;
  font-size: 18px !important;
  @media (max-width: 767px) {
    line-height: 28px !important;
    font-size: 16px !important;
    margin: 0 auto !important;
  }
`;

export const CommunityButton = styled(SignUpNavItem)`
  line-height: 33px !important;
  font-size: 18px !important;
  background: ${({ theme }) => theme.backgrounds.main};
  margin-right: 10px;
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.main}`};
  color: ${({ theme }) => theme.colors.black};
  max-width: 140px;
  margin-left: 0 !important;
  @media (max-width: 767px) {
    line-height: 28px !important;
    font-size: 16px !important;
  }
`;

export const LoginNavItem = styled(NavbarItem)`
  background: ${({ theme }) => theme.backgrounds.black};
  color: ${({ theme }) => theme.colors.main};
  border: ${({ isButton, theme }) => isButton && `1px solid ${theme.backgrounds.black}`};
`;

export const GradientText = styled.div`
  color: black;
  overflow: hidden;
  font-weight: 500;
  background: ${({ theme }) => `linear-gradient(to right, ${theme.backgrounds.main}, ${theme.backgrounds.main} 50%, ${theme.black} 50%)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-position: 0 100%;
  }
`;

export const TimersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: -webkit-linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
  background: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
`;

export const TimerItem = styled.div`
  flex: 1;
  height: 120px;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 768px) {
    height: 78px;
    margin-bottom: 10px;
  }
`;

export const TimerData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerCount = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

export const TimerTitle = styled.div`
  max-width: 300px;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
`;

export const TestimonialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TestimonialsSlider = styled.div``;

export const TestimonialImage = styled.img`
  width: 100px !important;
  height: 100px;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexCenteredContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
`;

export const HomeAboutContent = styled.div`
  padding: 35px 20px;
  width: 100%;
  text-align: center;
`;

export const HomeAboutPara = styled.p`
  line-height: 32px;
  color: #808080;
  text-align: center;
  margin: 50px 0px;
  font-size: 18px;
  font-weight: 500;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.backgrounds.black};
`;

export const ShareContainer = styled.div`
  position: fixed;
  right: 0;
  z-index: 9;
  width: 40px;
  top: 25vh;
  height: 150px;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
  @media (max-width: 768px) {
    height: ${({ showContainer }) => (showContainer ? '150px' : '1px')};
  }
`;

export const ShareButton = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: NaNpx;
  height: 48px;
  line-height: 24px;
  margin-bottom: 0;
  opacity: 1;
  overflow: hidden;
  padding: 12px;
  position: relative;
  text-align: left;
  top: 0;
  vertical-align: top;
  white-space: nowrap;
  width: 48px;
  &:hover {
    width: 120px;
  }
`;

export const ShareLi = styled.li`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  background-color: ${({ theme, type }) => 'grey' || theme.backgrounds[type] || 'rgba(0, 0, 0, 0.5)'};
  width: 123px;
  text-align: left;
  padding: 8px;
  border-radius: 30px 0 0 30px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  &:hover {
    transform: translatex(-80px);
    cursor: pointer;
  }
  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ShareLiArrow = styled.li`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  background-color: #f9ae00;
  width: 120px;
  text-align: left;
  padding: 8px;
  border-radius: 30px 0 0 30px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ShareTextLi = styled.li`
  list-style-type: none;
  color: #f9ae00;
  font-weight: bold;
`;

export const ShareText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 15px;
`;

export const SignUpStepOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ noMargin }) => (noMargin ? '0px' : '40px')};
`;

export const ResetPasswordContainer = styled(SignUpStepOneContainer)`
  margin-top: 0px;
`;

export const SignUpStepOneParent = styled.div`
  width: 340px;
  border: 1px solid #f9ae00;
  padding: 20px;
  border-radius: 6px;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 280px);
  margin-top: 35px;
`;

export const LoginFormWrapper = styled.div`
  width: 300px;
  border: 1px solid #f9ae00;
  padding: 20px;
  border-radius: 6px;
`;

export const AlreadyAccountText = styled.p`
  font-weight: 500;
  margin-top: 10px;
`;

export const LoginText = styled.span`
  color: ${({ theme }) => theme.colors.main};
  &:hover {
    cursor: pointer;
  }
`;

export const NavActionButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BackContainer = styled.div`
  display: flex;
  width: 85px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export const SkipContainer = styled.span`
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const CardsParent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardsContainer = styled.div`
  width: 1000px;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 800px;
  }
`;

export const PricingCardsContainer = styled(CardsContainer)``;

export const PlanCardHeader = styled.div`
  height: 33px;
  position: absolute;
  background: #fceec9;
  width: 221px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  top: -34px;
  left: -1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.black} !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

export const EMIText = styled.p`
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`;

export const PriceText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 0;
  text-decoration: line-through solid red;
`;

export const RealPriceText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
`;

export const PlanCard = styled.div`
  width: 300px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: ${({ theme, exclusive }) => (exclusive
    ? `5px solid ${theme.borders.main}`
    : `5px solid ${theme.borders.light}`)};
  box-sizing: border-box;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 15px 0px;
  background: ${({ theme, active }) => active && theme.backgrounds.black} !important;
  color: ${({ theme, active }) => active && theme.colors.white} !important;
  .main-point {
    color: ${({ theme, active }) => active && theme.colors.white} !important;
  }
  p {
    color: ${({ theme, active }) => active && theme.colors.white} !important;
  }
  ${PlanCardHeader} {
    width: ${({ active }) => (active ? '301px' : '300px')};
    left: ${({ active }) => (active ? '-5.5px' : '-5.0px')};
  }
  border: ${({ theme, active }) => active && `5px solid ${theme.borders.main}`};
  ${PriceText} {
    color: ${({ active }) => (active ? 'white' : 'black')};
  }
  ${RealPriceText} {
    color: ${({ active }) => (active ? 'white' : 'black')};
  }
  ${EMIText} {
    color: ${({ active }) => (active ? 'white' : 'black')};
  }
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.backgrounds.black} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    border: ${({ theme }) => `5px solid ${theme.borders.main}`} !important;
    p {
      color: ${({ theme }) => theme.colors.white} !important;
    }
    ${PriceText} {
      color: white;
    }
    ${RealPriceText} {
      color: white;
    }
    ${EMIText} {
      color: white;
    }
    ${PlanCardHeader} {
      width: 301px;
      left: -5.5px;
    }
    .main-point {
      color: white !important;
    }
  }
  @media (max-width: 767px) {
    width: 280px;
    ${PlanCardHeader} {
      width: ${({ active }) => (active ? '280px' : '281px')};
    }
    &:hover {
      ${PlanCardHeader} {
        width: 281px;
        left: -5.5px;
      }
    }
  }
`;

export const PlanCardContent = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export const FeatureList = styled.ul`
  padding: 0;
`;

export const FeatureListItem = styled.li.attrs(() => ({
  className: 'main-point',
}))`
  list-style-type: none;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  padding: 0 10px;
  text-indent: -20px;
  margin-left: 20px;
  margin-bottom: 5px;
  text-decoration: ${({ isDisabled }) => isDisabled && 'line-through solid red;'};
`;

export const FeatureListInnerUl = styled.ul`
  padding-left: 25px;
`;

export const UndelinedSpan = styled.span`
  text-decoration: underline;
  font-weight: 800;
`;

export const FeatureListItemInnerUlLI = styled.li`
  list-style-type: disc !important;
  color: ${({ theme }) => theme.colors.lightgrey};
  font-weight: 500 !important;
  padding-left: 15px !important;
  margin: 4px 0px;
  text-decoration: ${({ isDisabled }) => isDisabled && 'line-through solid red;'};
`;

export const PriceDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlanCards = styled.div`
  display: flex;
  width: 1000px;
  justify-content: space-between;
`;

export const MultiPurposeText = styled.p`
  margin: 5px 0px;
  &:hover {
    cursor: ${({ hoverOnParent }) => hoverOnParent && 'pointer'};
  }
  span {
    color: ${({ theme }) => theme.colors.main};
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const AuthActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${MultiPurposeText} {
    margin: 0;
  }
`;

export const CapitalizedText = styled.div`
  text-transform: capitalize;
  &:focus {
    outline: 0;
  }
`;

export const PayContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BlogImage = styled.img`
  width: 60%;
  height: 350px;
  margin-top: 10px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
`;

export const BlogContentStyles = styled.div`
  font-family: "TiemposText";
  font-weight: 400;
  line-height: 1.75;
  color: #221F20;
  background: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  font-size: 15px;
  margin-bottom: 30px;
  h2 {
    margin-bottom: 0;
    margin-top: 15px;
  }
`;
