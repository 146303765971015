import styled from "styled-components";

export const Heading = styled.div`
  font-weight: bolder;
  font-size: 15px;
  display: list-item;
`;

export const HeadingBold = styled.span`
  font-weight: bolder;
  font-size: 15px;
  margin-left: 2px;
  margin-right: 2px;
`;

export const ContentContainer = styled.div`
  padding: 0 10px;
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
