import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const GoBack = ({ path }) => {
  const history = useHistory();

  const goBackHandler = () => {
    history.push(path);
  };

  return (
    <Button
      onClick={goBackHandler}
      className='save-post-button'
      style={{ float: "right" }}
    >
      Go Back
    </Button>
  );
};

export default GoBack;
