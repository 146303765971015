import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Row, Col, Carousel, Button,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState, useRef } from 'react';
import { Container, Form } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { SignUpNavItem } from '../../styledComponents';
import bannerTexture from '../../assets/banner-texture.png';
import { axiosAuth } from '../../api';

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 350px;
`;

const MainHeading = styled.span`
  font-weight: 800;
  font-size: 25px;
  color: #f9ae00;
  margin-bottom: 15px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 21px;
  }
  @media (max-width: 767px) {
    text-align: center;
    font-size: 21px;
  }
`;

const FirstMainHeading = styled(MainHeading)`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0;
`;

const MainContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const YellowText = styled.span`
  color: #f9ae00;
`;

const Subheading = styled.p`
  text-align: center;
  color: white;
  font-weight: bold;
`;

const Chapter1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const SubHeading1 = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`;

const FormDiv = styled.div`
  width: 400px;
  padding: 5px;
  height: 100%;
  @media (max-width: 767px) {
    width: 300px !important;
  }
`;

const Forms = styled.div`
  padding: 20px;
  border: ${({ theme }) => `1px solid ${theme.colors.main}`};
  border-radius: 6px;
  background: white;
  margin: auto;
`;

const KeySecrets = () => {
  const [isVisible, toggleModal] = useState(false);
  const [data, setData] = useState({});
  const handleToggleModal = useCallback(() => {
    toggleModal(!isVisible);
  }, [isVisible]);
  const slider = useRef();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmitClick = () => {
    const {
      email, contact, name, age,
    } = data;
    const payload = {
      email: 'Gift form 1',
      name,
      age,
      gender: 'free',
      contactNo: contact,
    };
    setLoading(true);
    axiosAuth
      .post('lead/create', payload)
      .then((res) => {
        if (res.status == 200 && res.data && res.data.code == 200) {
          slider.current.goTo(1);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Something went wrong. Please try again!!', {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const handleSubmitClickFinal = () => {
    const { email, contact, name } = data;
    const payload = {
      email: `${email} || Duplicacy request`,
      name,
      gender: 'free',
      contactNo: contact,
    };
    setLoading(true);
    axiosAuth
      .post('lead/create', payload)
      .then((res) => {
        if (res.status == 200 && res.data && res.data.code == 200) {
          setData({});
          toast(
            'Thanks for sharing the details. We will send you the gift soon on your email',
            {
              type: toast.TYPE.SUCCESS,
            },
          );
          toggleModal();
        } else {
          toast('Failed to create query. Please try again!!', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Failed to create query. Please try again!!', {
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <Container>
      <Modal
        visible={isVisible}
        onCancel={handleToggleModal}
        footer={null}
        className="telegram-popup"
      >
        <CloseCircleOutlined
          className="close-icon"
          onClick={handleToggleModal}
        />
        <Row justify="center">
          <h4>Claim The Free Gift</h4>
        </Row>
        <hr style={{ marginBottom: '25px' }} />
        <Carousel
          dots={false}
          autoplay={false}
          ref={(ref) => {
            slider.current = ref;
          }}
        >
          <div>
            <Row style={{ backgroundImage: `url(${bannerTexture})` }}>
              <Col span={24}>
                <FormContainer className="leadform">
                  <FormDiv>
                    <Forms>
                      <Form.Group>
                        <Form.Label className="required">Name</Form.Label>
                        <Form.Control
                          value={data.name || ''}
                          name="name"
                          className="custom-field-focus"
                          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                          placeholder="Enter Name"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="required">Contact</Form.Label>
                        <Form.Control
                          value={data.contact || ''}
                          name="contact"
                          className="custom-field-focus"
                          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                          type="number"
                          placeholder="Enter contact no."
                        />
                        <Form.Text className="text-muted">
                          We will share the details of the FREE webinar with you
                          on WhatsApp.
                          <br />
                          We will not share your number with anyone or call you
                          without asking.
                        </Form.Text>
                      </Form.Group>

                      <Button
                        disabled={!data.name || !data.contact || loading}
                        onClick={handleSubmitClick}
                        loading={loading}
                        size="medium"
                        className="signUpBtn"
                        block
                        style={{ marginTop: '10px' }}
                      >
                        Submit
                      </Button>
                    </Forms>
                  </FormDiv>
                </FormContainer>
              </Col>
            </Row>
          </div>
          <div>
            <Row style={{ backgroundImage: `url(${bannerTexture})` }}>
              <Col span={24}>
                <FormContainer className="leadform">
                  <FormDiv>
                    <Forms>
                      <Form.Group>
                        <Form.Label className="required">
                          Email address
                        </Form.Label>
                        <Form.Control
                          value={data.email || ''}
                          name="email"
                          className="custom-field-focus"
                          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                          type="email"
                          placeholder="Enter email"
                        />
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>

                      <Button
                        disabled={!data.email || loading}
                        onClick={handleSubmitClickFinal}
                        loading={loading}
                        size="medium"
                        className="signUpBtn"
                        block
                        style={{ marginTop: '10px' }}
                      >
                        Submit
                      </Button>
                    </Forms>
                  </FormDiv>
                </FormContainer>
              </Col>
            </Row>
          </div>
        </Carousel>
      </Modal>

      <Row justify="center" className="blogs-brief">
        <Col span={24}>
          <FlexDiv>
            <MainContentDiv>
              <Chapter1>
                <FirstMainHeading>KNOW OUR 24 KEY SECRETS TO</FirstMainHeading>
                {!isMobileOnly ? (
                  <>
                    <FirstMainHeading>
                      MAKING MONEY IN THE STOCK MARKET
                    </FirstMainHeading>
                    <FirstMainHeading style={{ marginBottom: '15px' }}>
                      FOR FREE!!
                      {' '}
                    </FirstMainHeading>
                  </>
                ) : (
                  <>
                    <FirstMainHeading>MAKING MONEY IN THE</FirstMainHeading>
                    <FirstMainHeading style={{ marginBottom: '15px' }}>
                      STOCK MARKET FOR FREE!!
                      {' '}
                    </FirstMainHeading>
                  </>
                )}
                <SubHeading1>
                  WE TURNED
                  {' '}
                  <YellowText>₹1.54 LAKH TO ₹24.42 LAKH</YellowText>
                  {' '}
                  IN JUST ONE YEAR
                </SubHeading1>
                <SubHeading1>USING THESE SECRETS</SubHeading1>
              </Chapter1>

              <Chapter1>
                <Subheading>
                  Apply these to your trading &
                  {' '}
                  <br />
                  become a money making machine.
                </Subheading>
                <Row justify="center">
                  <SignUpNavItem
                    className="pulse"
                    isButton
                    onClick={handleToggleModal}
                  >
                    CLAIM THE FREE GIFT
                  </SignUpNavItem>
                </Row>
              </Chapter1>
            </MainContentDiv>
          </FlexDiv>
        </Col>
      </Row>
    </Container>
  );
};

export default KeySecrets;
