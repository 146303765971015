import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { Dropdown, Menu } from 'antd';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logout, setModalType } from '../../actions/common';
import logo1 from '../../assets/logo1.svg';
import logoFace from '../../assets/logo-face.svg';
import {
  CapitalizedText,
  NavbarItem,
  SignUpNavItem,
  HeaderSignUpButton,
  CommunityButton,
} from '../../styledComponents';
import {
  handleBecomeMemberTrackingClick,
  logoutUserHandler,
} from '../../utils/helper';

const Header = ({ logoutUser }) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const tokenIsExist = useSelector((state) => state.common.authToken);
  const user = useSelector((state) => state.common.user);
  const headerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const handleLinkClick = useCallback((path) => {
    handleBecomeMemberTrackingClick();
    history.push(path);
  }, []);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      if (location.pathname === '/') {
        setSticky({ isSticky: true, offset: elHeight });
        document.body.classList.add('sticky-header-enabled');
      } else {
        setSticky({ isSticky: false, offset: 0 });
        document.body.classList.remove('sticky-header-enabled');
      }
    } else {
      setSticky({ isSticky: false, offset: 0 });
      document.body.classList.remove('sticky-header-enabled');
    }
  };

  useEffect(() => {
    const header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [location]);

  const handleLogoutClick = async () => {
    logoutUserHandler(logoutUser, history);
  };

  const menu = (
    <Menu className="menu-key-list">
      <Menu.Item className="menu-key" key="0" style={{ textAlign: 'center' }}>
        <CapitalizedText>
          <strong>{user?.name || ''}</strong>
        </CapitalizedText>
      </Menu.Item>
      <Menu.Divider />
      {user?.userType === 'admin' && (
        <Menu.Item
          onClick={() => handleLinkClick('/dashboard')}
          className="menu-key"
          key="1"
        >
          Dashboard
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => handleLinkClick('/profile')}
        className="menu-key"
        key="1"
      >
        My Profile
      </Menu.Item>
      <Menu.Item
        className="menu-key"
        onClick={handleLogoutClick}
        key="3"
        style={{ textAlign: 'center' }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const handlePostCheck = (path) => {
    handleLinkClick(user?.agree ? path : '/community-terms-and-conditions');
  };

  const isBlogPage = window.location.href.includes('/blogs/');
  return (
    <Navbar
      expand="sm"
      bg="light"
      variant="light"
      className={`navbar${sticky.isSticky ? ' sticky' : ''} ${isBlogPage ? 'blog-underline' : ''}`}
      ref={headerRef}
    >
      <Container>
        {!isMobileOnly ? (
          <Navbar.Brand onClick={() => handleLinkClick('/')}>
            <img
              alt=""
              src={logo1}
              className="d-inline-block align-top brand-image"
            />
          </Navbar.Brand>
        ) : (
          <img
            aria-hidden
            alt=""
            src={logoFace}
            className="d-inline-block align-top brand-image-mobile"
            onClick={() => handleLinkClick('/')}
          />
        )}

        {isMobile && !tokenIsExist && (
          <SignUpNavItem
            className={`pulse ${!isMobileOnly && 'show-margin'}`}
            isButton
            onClick={() => handleLinkClick('/register')}
          >
            BECOME A MEMBER NOW
          </SignUpNavItem>
        )}
        {!isMobile && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" />
              <Nav className="nav-list-custom">
                <NavbarItem onClick={() => handleLinkClick('/')}>
                  Home
                </NavbarItem>
                {/* <NavbarItem onClick={() => handleLinkClick('/blogs')}>
                  Blogs
                </NavbarItem> */}
                {/* {!tokenIsExist && (
                  <NavbarItem onClick={() => handleLinkClick('/pricing-plans')}>
                    Pricing
                  </NavbarItem>
                )} */}
                {tokenIsExist && (
                  <CommunityButton
                    className="pulse-main"
                    isButton
                    onClick={() => handlePostCheck('/posts')}
                  >
                    Community
                  </CommunityButton>
                )}
                {tokenIsExist ? (
                  <Dropdown overlay={menu} trigger={['click']}>
                    {user?.profileImg ? (
                      <img
                        aria-hidden
                        className="profile-image"
                        style={{
                          height: '60px',
                          width: '60px',
                          borderRadius: '40px',
                        }}
                        src={user?.profileImg}
                        alt="profile"
                      />
                    ) : (
                      <div
                        aria-hidden
                        className="initials"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span>{user.name.charAt(0)}</span>
                      </div>
                    )}
                  </Dropdown>
                ) : (
                  <>
                    <NavbarItem onClick={() => handleLinkClick('/login')}>
                      Login
                    </NavbarItem>
                    <HeaderSignUpButton
                      className="pulse"
                      isButton
                      onClick={() => handleLinkClick('/register')}
                    >
                      BECOME A MEMBER NOW
                    </HeaderSignUpButton>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setModalType: (type) => dispatch(setModalType(type)),
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Header);
