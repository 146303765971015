import React, { memo } from "react";
import { MainBoxParentLeft } from "../../routes/posts/styled";
import { Carousel } from "react-responsive-carousel";
import { getRandomNumberInRange } from "../../utils/helper";
import { authoreList } from "./constants";
import {
  AuthoreName,
  AuthoreTitle,
  LessPaddedRightBox,
  SliderContainer,
} from "./styled";

const QuotesSidebar = ({ isSticky }) => {
  const defaultItem = getRandomNumberInRange(0, authoreList.length - 1);

  return (
    <MainBoxParentLeft className={isSticky ? "sticky-box" : ""}>
      <LessPaddedRightBox>
        <div className='testimonial-slider max-height-330'>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            swipeable={false}
            showStatus={false}
            showArrows={true}
            infiniteLoop={true}
            autoPlay={true}
            interval={15000}
            stopOnHover
            useKeyboardArrows
            selectedItem={defaultItem}
            transitionTime={1000}
          >
            {authoreList.map((item) => {
              return (
                <SliderContainer>
                  <AuthoreTitle>{item.title}</AuthoreTitle>
                  <AuthoreName>{`- ${item.author}`}</AuthoreName>
                </SliderContainer>
              );
            })}
          </Carousel>
        </div>
      </LessPaddedRightBox>
    </MainBoxParentLeft>
  );
};

export default memo(QuotesSidebar);
