import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TradeWisdomItem from "../tradeWisdom/tradeWisdomItem";
import LoadingSkeleton from "../posts/LoadingSkeleton";
import { AsyncS3Upload } from "../../utils/s3Uploader";
import { axiosAuth } from "../../api";
import { Col, Row, notification } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import * as R from "ramda";
import Lightbox from "react-image-lightbox";
import CreateEditModal from "../tradeWisdom/createEditModal";
import { isMobile } from "react-device-detect";
import { PostsContainer } from "../posts/styled";
import { useParams, useHistory } from "react-router-dom";
import GoBack from "../../components/GoBack";
import BottomTabNavigator from "../../components/BottomTabNavigator";
import logo1 from "../../assets/logo1.svg";
import HamburgerMenuLinks from "../../components/HamburgerMenuLink";

const TradeWisdom = () => {
  const [finalData, setFinalData] = useState(null);
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxImage, setLightBoxImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditCreateModal, setShowEditCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [createUpdateLoading, setCreateUpdateLoading] = useState(false);
  const [isMenuOpen, toggleHamburger] = useState(false);
  const { wisdomId, notificationId } = useParams();
  const history = useHistory();
  const getTradeList = () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const endpoint = `/tradeWisdom/byId/${wisdomId}`;
    axiosAuth
      .get(endpoint)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setFinalData(res.data.data);
        } else {
          notification.error({
            message: "Error",
            description: "Failed to load posts. Please try again later!!",
          });
        }
        setLoading(false);
        if (notificationId) {
          axiosAuth.post("/notification/markAsRead", {
            notificationIds: [notificationId],
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTradeList();
  }, []);

  const handleDeletePost = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure want to delete this record?")) {
      axiosAuth
        .delete(`tradeWisdom/delete/${id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification.success({
              message: "Success",
              description: "Trade Wisdom deleted successfully!!",
            });
            history.push("/trade-wisdom");
          }
        })
        .catch(() => {
          notification.error({
            message: "Error",
            description: "Failed to delete record. Please try again!!",
          });
        });
    }
  };

  const handleCreateUpdate = (data) => {
    const { title, filesList } = data;
    if (!title) {
      notification["error"]({
        message: "Create Trade Wisdom Error",
        description: "Please enter title!!",
      });
      return;
    }
    if (!filesList?.length) {
      notification["error"]({
        message: "Create Trade Wisdom Error",
        description: "Please upload at least one chart image!!",
      });
      return;
    }
    setCreateUpdateLoading(true);

    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq("alreadyUploaded", true)),
        R.map(R.prop("thumbUrl"))
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      let payload = {
        images: alreadyUploadedImages.concat(images),
        title,
        _id: showEditCreateModal._id,
      };

      if (isEdit) {
        payload = {
          ...payload,
          _id: showEditCreateModal._id,
        };
      }

      const apiProvider = !isEdit ? axiosAuth.post : axiosAuth.patch;
      apiProvider(isEdit ? "tradeWisdom/update" : "tradeWisdom/create", payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (isEdit) {
              notification["success"]({
                message: "Success",
                description: "Trade Wisdom updated successfully!!",
              });
              setFinalData(res.data.data);
            } else {
              setFinalData(R.insert(0, res?.data?.data, finalData));
              notification["success"]({
                message: "Success",
                description: "Trade Wisdom created successfully!!",
              });
            }
          } else {
            notification["error"]({
              message: "Error",
              description: "Failed to post. Please try again later!!",
            });
          }
          setCreateUpdateLoading(false);
          setShowEditCreateModal(false);
          setIsEdit(false);
        })
        .catch(() => {
          setCreateUpdateLoading(false);
        });
    });
  };

  return (
    <PostsContainer isMobileOnly={isMobile} className='posts'>
      <HamburgerMenuLinks
        isMenuOpen={isMenuOpen}
        toggleMenu={(value) => toggleHamburger(value)}
      />
      <Container>
        {isMobile && (
          <div className='menu-btn-heading-parent'>
            <MenuOutlined
              onClick={() => toggleHamburger(true)}
              style={{
                fontSize: "25px",
                position: "absolute",
                zIndex: 1000,
              }}
            />
            <div className='posts-heading text-center'>
              <img
                onClick={() => history.push("/")}
                alt=''
                src={logo1}
                className='d-inline-block align-top brand-image'
              />
            </div>
          </div>
        )}
        {showEditCreateModal && (
          <CreateEditModal
            isEdit={isEdit}
            showEditCreateModal={showEditCreateModal}
            toggleEditModal={(data) => {
              setShowEditCreateModal(data);
              setIsEdit(false);
            }}
            handleCreateUpdate={handleCreateUpdate}
            createUpdateLoading={createUpdateLoading}
          />
        )}
        {isLightBoxVisible && (
          <Lightbox
            mainSrc={lightboxImage[photoIndex]}
            nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length]}
            prevSrc={
              lightboxImage[
                (photoIndex + lightboxImage.length - 1) % lightboxImage.length
              ]
            }
            onCloseRequest={() => toggleLightBox(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + lightboxImage.length - 1) % lightboxImage.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightboxImage.length)
            }
          />
        )}
        <Row>
          <Col className='mb-10 mt-10'>
            <GoBack path='/trade-wisdom' />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              finalData && (
                <TradeWisdomItem
                  key={finalData?._id}
                  data={finalData}
                  setLightBoxImage={setLightBoxImage}
                  toggleLightBox={toggleLightBox}
                  handleDeletePost={handleDeletePost}
                />
              )
            )}
          </Col>
        </Row>
        {isMobile && <BottomTabNavigator />}
      </Container>
    </PostsContainer>
  );
};

export default TradeWisdom;
