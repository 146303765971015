import React, { useCallback, useMemo, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { setModalType } from "../../actions/common";
import { axiosAuth } from "../../api";

const ForgotPassword = ({ setAuthModalType }) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: null, password: null });
  const [loading, setLoading] = useState(false);

  const validateError = useCallback(
    (name, value) => {
      if (name === "email" || name === "password") {
        const modifiedErrors = {
          ...errors,
          [name]: !value ? true : false,
        };
        setErrors(modifiedErrors);
      }
    },
    [email, errors]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value, checked } = e.target;
      if (name === "email") {
        setEmail(value);
      }
      validateError(name, value);
    },
    [email, errors]
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      validateError(name, value);
    },
    [email, errors]
  );

  const isSubmitDisabled = useMemo(() => {
    return !email;
  }, [email]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    axiosAuth
      .post("user/forgetPassword", { email })
      .then((res) => {
        if (res.status == 200 && res.data && res.data.code == 200) {
          toast("Please check your email for password reset link", {
            type: toast.TYPE.SUCCESS,
          });
          setEmail("");
          setAuthModalType(null);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast("Failed to send email. Please try again or contact admin!", {
          type: toast.TYPE.ERROR,
        });
      });
  }, [email]);

  return (
    <Modal.Body>
      <Form.Group>
        <Form.Label className='required'>Email address</Form.Label>
        <Form.Control
          value={email}
          name='email'
          className='custom-field-focus'
          onChange={handleInputChange}
          onBlur={handleBlur}
          type='email'
          placeholder='Enter email'
          isInvalid={errors.email}
        />
        <Form.Control.Feedback type='invalid'>
          Email is required!!
        </Form.Control.Feedback>
        <Form.Text className='text-muted'>
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Button
        className='signUpBtn'
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
        loading={loading}
      >
        Submit
      </Button>
    </Modal.Body>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalType: (type) => dispatch(setModalType(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPassword));
