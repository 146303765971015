import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'antd';
import { axiosAuth } from '../../api';
import HeadingUnderline from '../../components/HeadingUnderline';
import { Spacer } from '../about';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const FormDiv = styled.div`
  padding: 20px;
  width: 500px;
  border: ${({ theme }) => `1px solid ${theme.colors.main}`};
  border-radius: 6px;
  @media (min-width: 1024px) {
    width: 400px;
  }
  @media (max-width: 768px) {
    width: inherit;
  }
`;
const ContactUs = () => {
  const [data, setData] = useState({});
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const handleInputChange = (name, value) => {
    setMessage('');
    if (name === 'image') {
      getBase64(value).then((baseImage) => {
        setData({
          ...data,
          [name]: baseImage,
        });
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleSubmitClick = () => {
    const {
      email, contact, description, image, name,
    } = data;
    const payload = {
      email,
      image,
      message: description,
      contactNo: contact,
      active: true,
      name,
    };
    setMessage('');
    setLoading(true);
    axiosAuth
      .post('contactus/createQuery', payload)
      .then((res) => {
        if (res.status == 200 && res.data && res.data.code == 200) {
          setData({});
          setMessage(
            'Query submitted successfully. One of our executive will get in touch with you soon!',
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Failed to create query. Please try again!!', {
          type: toast.TYPE.ERROR,
        });
        setMessage('');
      });
  };

  return (
    <Row>
      <Col xs={24}>
        <FormContainer>
          <Spacer />
          <HeadingUnderline title="Contact Us" />
          <FormDiv>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control
                value={data.name || ''}
                name="name"
                className="custom-field-focus"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                type="text"
                placeholder="Enter name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Email address</Form.Label>
              <Form.Control
                value={data.email || ''}
                name="email"
                className="custom-field-focus"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Contact</Form.Label>
              <Form.Control
                value={data.contact || ''}
                name="contact"
                className="custom-field-focus"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                type="number"
                placeholder="Enter contact no."
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Description</Form.Label>
              <Form.Control
                as="textarea"
                value={data.description || ''}
                name="description"
                className="custom-field-focus"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                placeholder="Enter description"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Image</Form.Label>
              <br />
              <input
                type="file"
                onChange={(e) => handleInputChange('image', e.target.files[0])}
              />
            </Form.Group>
            {data.image && (
              <>
                <img
                  style={{ marginBottom: '10px' }}
                  height="100px"
                  width="100px"
                  src={data.image}
                />
                <br />
              </>
            )}
            <Button
              disabled={
                !data.name || !data.description || !data.email || loading
              }
              onClick={handleSubmitClick}
              loading={loading}
              className="signUpBtn"
              size="large"
            >
              Submit
            </Button>
            <p
              className="text-center"
              style={{ marginTop: '10px', color: '#228B22' }}
            >
              {message}
            </p>
          </FormDiv>
        </FormContainer>
      </Col>
    </Row>
  );
};

export default ContactUs;
