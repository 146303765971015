import React, { useEffect, useState, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as R from "ramda";
import { axiosAuth } from "../../api";
import {
  Button,
  Col,
  Row,
  Form,
  InputNumber,
  Tag,
  Upload,
  notification,
  Divider,
} from "antd";
import PostItem from "./PostItem";
import LoadingSkeleton from "./LoadingSkeleton";
import { Container } from "react-bootstrap";
import { PostsContainer } from "./styled";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";
import Lightbox from "react-image-lightbox";
import { TRADE_TAGS } from "./constants";
import { uuid } from "uuidv4";
import { AsyncS3Upload } from "../../utils/s3Uploader";
import { PlusOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {checkImageSize ,setFileListBySize} from '../../utils/helper'

const ReviewPosts = () => {
  const history = useHistory();
  const user = useSelector((state) => state.common.user);
  const [loading, setLoading] = useState(false);
  const [pendingPosts, setPendingPosts] = useState([]);
  const [showCommentBox, setCommentBox] = useState(null);
  const [lightboxImage, setLightBoxImage] = useState("");
  const [isLightBoxVisible, toggleLightBox] = useState(false);
  const [showPostModal, togglePostModal] = useState(false);
  const [title, setTitle] = useState("");
  const [target1, setTarget1] = useState("");
  const [target2, setTarget2] = useState("");
  const [risk, setRisk] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [editId, setEditId] = useState(false);
  const [editPostUserId, setEditingPostUserId] = useState("");
  const [selectedTags, setTags] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [loadingAcceptReject, setLoadingAcceptReject] = useState("");
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    if (!R.isEmpty(user) && user?.userType != "admin") {
      history.push("/");
      return;
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const endpoint = `/post/allPosts`;
    axiosAuth
      .post(endpoint, { verificationStatus: "pending" })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          if (res?.data?.data.length > 0) {
            setPendingPosts(res?.data?.data);
          }
        } else {
          notification["error"]({
            message: "Error",
            description: "Failed to load posts. Please try again later!!",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleEditPost = (e, data) => {
    e.preventDefault();
    setTarget1(data?.targetPrice1);
    setTarget2(data?.targetPrice2);
    setRisk(data?.risk);
    setStopLoss(data?.stopLoss);
    setTags(data?.tags ?? []);
    setFileList(
      data?.images?.map((item) => ({ thumbUrl: item, alreadyUploaded: true }))
    );
    setTitle(data?.title);
    togglePostModal(true);
    setEditId(data?._id);
    setEditingPostUserId(data?.postedBy?._id);
    setShowDisclaimer(data?.showDisclaimer);
  };

  const handleDeletePost = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure want to delete this post?")) {
      axiosAuth
        .delete(`post/delete/${id}`)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            notification["success"]({
              message: "Success",
              description: "Post deleted successfully!!",
            });
            const itemIndex = pendingPosts.findIndex((item) => item._id === id);
            if (itemIndex > -1) {
              const updatedData = R.remove(itemIndex, 1, pendingPosts);
              setPendingPosts(updatedData);
            }
          }
        })
        .catch(() => {
          notification["error"]({
            message: "Error",
            description: "Failed to delete post. Please try again!!",
          });
        });
    }
  };

  const resetData = () => {
    togglePostModal(false);
    setTitle("");
    setTarget1("");
    setTarget2("");
    setRisk("");
    setStopLoss("");
    setTags([]);
    setFileList([]);
    setEditId(null);
    setEditingPostUserId("");
  };

  const handleSavePost = () => {
    let errMsg = "";
    if (!title && user?.userType != "admin") {
      errMsg = "Please enter title!!";
    } else if (!filesList?.length) {
      errMsg = "Please upload at least one chart image!!";
    } else if (!selectedTags?.length) {
      errMsg = "Please select at least one tag!!";
    }
    if (errMsg) {
      notification["error"]({
        message: "Create Post Error",
        description: errMsg,
      });
      return;
    }
    setLoading(true);
    const promises = [];
    for (let i = 0; i < filesList.length; i++) {
      const currentFile = filesList[i];
      if (!currentFile?.alreadyUploaded) {
        promises.push(AsyncS3Upload(currentFile?.originFileObj));
      }
    }
    Promise.all(promises).then((res) => {
      const alreadyUploadedImages = R.pipe(
        R.filter(R.propEq("alreadyUploaded", true)),
        R.map(R.prop("thumbUrl"))
      )(filesList);

      filesList.filter((item) => item?.alreadyUploaded == true);

      const images = res?.map(({ location }) => location);
      const payload = {
        postedBy: editPostUserId,
        verificationStatus:
          user?.userType == "admin" && !editId ? "approved" : "pending",
        title: title,
        images: alreadyUploadedImages.concat(images),
        targetPrice1: target1,
        targetPrice2: target2,
        risk,
        stopLoss,
        postedByRole: user?.userType == "admin" ? "admin" : "user",
        comments: [],
        tags: selectedTags,
        showDisclaimer: showDisclaimer,
      };
      if (editId) {
        payload._id = editId;
        delete payload.comments;
      }
      const apiProvider = !editId ? axiosAuth.post : axiosAuth.patch;
      apiProvider(editId ? "post/update" : "post/create", payload)
        .then((res) => {
          if (res?.data?.data && res?.data?.status) {
            if (editId) {
              notification["success"]({
                message: "Success",
                description: "Post updated successfully!!",
              });
              const updatedData = R.update(
                R.findIndex(R.propEq("_id", editId))(pendingPosts),
                res?.data?.data,
                pendingPosts
              );
              setPendingPosts(updatedData);
            } else {
              if (user?.userType == "admin") {
                setPendingPosts(R.insert(0, res?.data?.data, pendingPosts));
              }
              notification["success"]({
                message: "Success",
                description: "Post created successfully and is under review!!",
              });
            }
            resetData();
          } else {
            notification["error"]({
              message: "Error",
              description: "Failed to post. Please try again later!!",
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    });
  };

  const handleData = useCallback(
    (e) => {
      var items = (e.clipboardData || e.originalEvent.clipboardData).items;
      var blob = null;
      var filetype = "";
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
          filetype = items[i].type;
          break;
        }
      }
      // load image if there is a pasted image
      if (blob !== null) {
        if (filesList.length < 4) {
          var reader = new FileReader();
          reader.onload = function (event) {
            fetch(event.target.result)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], "clipboard-image.png", {
                  type: filetype,
                });
                const fileObj = {
                  type: filetype,
                  thumbUrl: URL.createObjectURL(file),
                  uid: uuid(),
                  percent: 100,
                  originFileObj: file,
                  status: "done",
                  name: file.name,
                };
                setFileList([...filesList, fileObj]);
              });
          };
          reader.readAsDataURL(blob);
        } else {
          notification["error"]({
            message: "Clipboard paste error",
            description: "Sorry, maximum image upload limit is 4",
          });
        }
      }
    },
    [filesList]
  );

  const handlePreview = async (file) => {
    setLightBoxImage(URL.createObjectURL(file.originFileObj));
    toggleLightBox(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleTagClick = (tag) => {
    const itemIndex = selectedTags.findIndex((item) => item == tag);
    if (itemIndex > -1) {
      setTags(R.remove(itemIndex, 1, selectedTags));
    } else {
      setTags([...selectedTags, tag]);
    }
  };

  const handleAcceptReject = (item, status) => {
    setLoadingAcceptReject(status);
    axiosAuth
      .patch("post/update", { _id: item._id, verificationStatus: status })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification["success"]({
            message: "Success",
            description:
              status === "approved" ? "Post Approved!!" : "Post Rejected!!",
          });
          const itemIndex = pendingPosts.findIndex(
            (data) => data._id === item._id
          );
          if (itemIndex > -1) {
            const updatedData = R.remove(itemIndex, 1, pendingPosts);
            setPendingPosts(updatedData);
          }
        } else {
          notification["error"]({
            message: "Error",
            description: "Failed to update post. Please try again later!!",
          });
        }
        setLoadingAcceptReject("");
      })
      .catch((err) => {
        setLoadingAcceptReject("");
      });
  };

  const favorateHandler = (data, isFav) => {
    setFavoriteLoading(data._id);
    axiosAuth
      .post(`post/makeFavorite?isFavorite=${isFav}`, { postId: data._id })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification["success"]({
            message: "Success",
            description: isFav
              ? "Successfully Added post in favorite list"
              : "Successfully Removed post from favorite list",
          });
          const updatedData = R.update(
            R.findIndex(R.propEq("_id", data._id))(pendingPosts),
            res?.data?.data,
            pendingPosts
          );
          setPendingPosts(updatedData);
        } else {
          notification["error"]({
            message: "Error",
            description:
              "Failed to mark post as favorite. Please try again later!!",
          });
        }
        setFavoriteLoading(false);
      })
      .catch((err) => {
        setFavoriteLoading(false);
      });
  };

  const handleRemove = async (file) => {
    if (file?.alreadyUploaded) {
      const index = filesList.findIndex(
        (item) => item.thumbUrl === file.thumbUrl
      );
      setFileList(R.remove(index, 1, filesList));
      return false;
    }
    return true;
  };

  return (
    <PostsContainer>
      {isLightBoxVisible && (
        <Lightbox
          mainSrc={lightboxImage[photoIndex]}
          nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length]}
          prevSrc={
            lightboxImage[
              (photoIndex + lightboxImage.length - 1) % lightboxImage.length
            ]
          }
          onCloseRequest={() => toggleLightBox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImage.length - 1) % lightboxImage.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightboxImage.length)
          }
          onCloseRequest={() => toggleLightBox(false)}
        />
      )}
      <Modal
        onCancel={() => resetData()}
        visible={showPostModal}
        title='Update Post'
        className='create-post-modal'
        footer={[
          <Button
            loading={loading}
            className='save-post-button'
            onClick={handleSavePost}
          >
            Update
          </Button>,
        ]}
      >
        <Form layout='vertical'>
          <Row>
            <Col span={24}>
              <Form.Item label='Title' required>
                <TextArea
                  placeholder='Share your trade/Paste chart image from clipboard'
                  allowClear
                  value={title}
                  onChange={({ target }) => setTitle(target?.value)}
                  autoSize={{
                    minRows: 2,
                    maxRows: 4,
                  }}
                  onPaste={handleData}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Upload Charts' required>
                <Upload
                  action=''
                  accept='.png,.jpg,.jpeg,image/png,image/jpeg'
                  listType='picture-card'
                  fileList={filesList}
                  onPreview={handlePreview}
                  beforeUpload={(file) => {
                    checkImageSize(file);
                  }}
                  onChange={({ fileList }) => {
                    setFileListBySize(fileList, setFileList);
                  }}
                  onRemove={handleRemove}
                >
                  {filesList.length >= 4 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Target Price 1'>
                <InputNumber
                  min={0}
                  value={target1}
                  onChange={(value) => setTarget1(value)}
                  placeholder='Target 1'
                  step='0.01'
                  onBlur={() =>
                    target1 && setTarget1(parseFloat(target1).toFixed(2))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Target Price 2'>
                <InputNumber
                  min={0}
                  value={target2}
                  onChange={(value) => setTarget2(value)}
                  placeholder='Target 2'
                  step='0.01'
                  onBlur={() =>
                    target2 && setTarget2(parseFloat(target2).toFixed(2))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Stop Loss'>
                <InputNumber
                  min={0}
                  value={stopLoss}
                  onChange={(value) => setStopLoss(value)}
                  placeholder='Stop Loss'
                  step='0.01'
                  onBlur={() =>
                    stopLoss && setStopLoss(parseFloat(stopLoss).toFixed(2))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Risk (%)'>
                <InputNumber
                  min={0}
                  max={user?.userType == "admin" ? 100 : 2}
                  value={risk}
                  onChange={(value) => setRisk(value)}
                  placeholder='Risk'
                  step='0.01'
                  onBlur={() => risk && setRisk(parseFloat(risk).toFixed(2))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Tags (Select atleast one)' required>
                <div className='taglist'>
                  {TRADE_TAGS.map((item) => (
                    <Tag
                      onClick={() => handleTagClick(item)}
                      className='tag-item'
                      color={selectedTags.includes(item) ? "#F9AE00" : "gold"}
                    >
                      {item}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </Col>
            {user?.userType === "admin" && (
              <Col>
                <Checkbox
                  checked={showDisclaimer}
                  onChange={() => setShowDisclaimer(!showDisclaimer)}
                >
                  Show Disclaimer
                </Checkbox>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      <Container>
        <Row>
          <Col span={24}>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              pendingPosts?.map((item) => (
                <Fragment>
                  <PostItem
                    key={item._id}
                    data={item}
                    handleCommentClick={(id) => setCommentBox(id)}
                    setLightBoxImage={setLightBoxImage}
                    toggleLightBox={toggleLightBox}
                    handleEditPost={handleEditPost}
                    handleDeletePost={handleDeletePost}
                    favorateHandler={favorateHandler}
                    favoriteLoading={favoriteLoading}
                  />
                  <Row>
                    <Col span={3}>
                      <Button
                        loading={loadingAcceptReject === "rejected"}
                        type='danger'
                        onClick={() => handleAcceptReject(item, "rejected")}
                      >
                        REJECT
                      </Button>
                    </Col>
                    <Col span={3}>
                      <Button
                        loading={loadingAcceptReject === "approved"}
                        type='primary'
                        onClick={() => handleAcceptReject(item, "approved")}
                      >
                        ACCEPT
                      </Button>
                    </Col>
                  </Row>
                  <Divider />
                </Fragment>
              ))
            )}
            {pendingPosts?.length === 0 && (
              <Row justify='center'>
                <p className='mb-20'>No Posts Found</p>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </PostsContainer>
  );
};

export default ReviewPosts;
