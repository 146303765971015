import {
  CheckOutlined,
} from '@ant-design/icons';
import React, { } from 'react';
import { isMobile } from 'react-device-detect';
import { GiTakeMyMoney } from 'react-icons/gi';
import styled from 'styled-components';
import bannerImg from '../../assets/banner-bg.png';
import bannerRightImg from '../../assets/banner-right.png';
import {
  InfoAnalyticsBox,
  InfoAnalyticsBoxContainer,
} from './BannerSection';

export const MoneyBack = ({
  heading, hideFirst, secondHeading, secondSubheading,
}) => {
  if (isMobile) {
    return (
      <MobileFlexDiv>
        <TCTextMobile>T&C Apply*</TCTextMobile>
        <YellowBigTextMobile>
          {heading || '100% MONEY-BACK GUARANTEE*'}
        </YellowBigTextMobile>

        <CenteredContentParent>
          <GiTakeMyMoney style={{ fontSize: '50px', color: '#F9AE00' }} />
          <MobileContentUl>
            <li>{secondHeading ?? 'Guaranteed To Learn From Extremely Profitable Traders'}</li>
          </MobileContentUl>
        </CenteredContentParent>
        {secondSubheading ? <SubtextSecondary>{secondSubheading}</SubtextSecondary> : (
          <Subtext>
            If we don&apos;t share our profit & loss statement showing that
            we&apos;ve turned
            {' '}
            <strong>&lt;&#8377;2L to &#8377;40.73L in 32 months</strong>
            ,
            you get your money back
          </Subtext>
        )}
      </MobileFlexDiv>
    );
  }

  return (
    <MoneyBackParent>
      <FlexDiv>
        <ContentContainer>
          <TCText>T&C Apply*</TCText>
          <YellowBigText>{heading || '100% MONEY-BACK GUARANTEE*'}</YellowBigText>
          <ContentParent>
            <GiTakeMyMoney
              style={{ fontSize: '50px', color: '#F9AE00' }}
            />
            <ContentUl>
              <li>
                {secondHeading ?? 'Guaranteed To Learn From Extremely Profitable Traders'}
              </li>
              {secondSubheading ? <SubtextSecondaryLi>{secondSubheading}</SubtextSecondaryLi> : (
                <li>
                  If we don&apos;t share our profit & loss statement
                  showing that we&apos;ve turned
                  {' '}
                  <strong>
                    &lt;&#8377;2L to &#8377;40.73L in 32 months
                  </strong>
                  , you get your money back
                </li>
              )}
            </ContentUl>
          </ContentParent>
        </ContentContainer>
      </FlexDiv>
    </MoneyBackParent>
  );
};

const MoneyBackParent = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${bannerImg});
  border-radius: 5px;
  margin-bottom: 20px;
`;

const FlexDiv = styled.div`
  flex: 1;
  min-height: 300px;
  background-image: url(${bannerRightImg});
  background-size: cover;
  border-radius: 5px;
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  padding-top: 15px;
`;

export const StyledCheckCircleOutlined = styled(CheckOutlined)`
  font-size: 20px;
  margin-top: 5px;
  margin-right: 7px;
  color: #f9ae00;
`;

export const HeadingText = styled.p`
  padding-top: 30px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
`;

export const StyledResultText = styled.p`
  padding: 30px 0;
  font-weight: bold;
`;

export const Box = styled.div`
  background-color: #fafafa;
  flex: 1;
  border-radius: 5px;
  padding: 24px 30px;
`;

export const HeadingSection = styled.div`
  display: flex;
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: 22px;
  flex: 1;
`;

export const IndentText = styled.div`
  padding-left: 26px;
  text-indent: -14px;
  margin-top: 15px;
  flex: 1;
  font-family: GilroyRegular;
  font-size: 16px;
  font-weight: 600;
`;

export const IconDiv = styled.div`
  height: 50px;
  width: 50px;
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HiddenIconDiv = styled(IconDiv)`
  visibility: hidden;
`;

const YellowBigText = styled.div`
  font-size: 30px;
  color: #f9ae00;
  font-family: GilroyBold;
  margin-bottom: 10px;
`;

const ContentParent = styled.div`
  display: flex;
`;

const ContentUl = styled.ul`
  padding-left: 14px;
  li {
    list-style-type: none;
  }
  li:first-child {
    font-size: 20px;
    font-family: GilroyBold;
  }
  li:nth-child(2) {
    font-size: 12px;
  }
`;

const ContentContainer = styled.div`
  width: 55%;
  position: relative;
`;

const MobileFlexDiv = styled.div`
  background-image: url(${bannerImg});
  min-height: 200px;
  background-size: cover;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  margin-bottom: 20px;
  align-content: center;
  position: relative;
`;

const YellowBigTextMobile = styled(YellowBigText)`
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const MobileContentUl = styled.ul`
  padding-left: 14px;
  li {
    list-style-type: none;
  }
  li:first-child {
    font-size: 18px;
    font-weight: 500;
  }
`;

const CenteredContentParent = styled(ContentParent)`
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  line-height: 24px;
  height: 60px;
`;

const Subtext = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const SubtextSecondary = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 18px;
`;

const SubtextSecondaryLi = styled.li`
  font-size: 16px !important;
`;

export const StyledInfoAnalyticsBox = styled(InfoAnalyticsBox)`
  margin-bottom: 0;
  display: flex;
`;

export const StyledInfoAnalyticsBoxContainer = styled(
  InfoAnalyticsBoxContainer,
)`
  display: flex;
  margin-bottom: 2px;
`;

const TCText = styled.span`
  position: absolute;
  bottom: -12px;
  right: -85px;
  font-size: 6px;
  color: white;
`;

const TCTextMobile = styled(TCText)`
  bottom: 5px;
  right: 5px;
  color: gray;
`;
