import { Container } from 'react-bootstrap';
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import HeadingUnderline from '../HeadingUnderline';
import megaphone from '../../assets/megaphone.jpeg';
import course from '../../assets/course.jpeg';
import newsletter from '../../assets/newsletter-1.png';
import newCourse from '../../assets/newCourse.png';
import platformMob from '../../assets/platformMob.jpeg';
import { SignUpNavItem } from '../../styledComponents';
import hub from '../../assets/hubapp.jpg';
import calc from '../../assets/tradeCalc.png';

const WhatYouGet = ({ todayText }) => {
  const history = useHistory();
  if (!isMobileOnly) {
    return (
      <Container>
        <HeadingUnderline title="What You Get" whitetext />
        {/* <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image75 src={megaphone} alt="platform" />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Our Trades</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                You get
                {' '}
                <BoldText>alerts to trades that we take in our trading accounts</BoldText>
                ,
                along with our stop loss, risk tolerance, and our explanation
                for taking the trades via our Telegram channel. We don’t give
                investment advice and share our trades for
                educational purposes only.
                {' '}

              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row> */}

        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image35 src={hub} alt="platform" />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>The Logical Traders Hub</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Get access to The Logical Traders Hub where you will be getting
                <br />
                <BoldText>1) Our trades & watchlist:</BoldText>
                {' '}
                Get the trades
                that we&apos;re taking and eyeing in all segments; cash, futures & options,
                currencies & commodities.
                <br />
                <BoldText>2) Educational content:</BoldText>
                {' '}
                We post about
                macroeconomics, geopolitics, technical & fundamental analysis.
                <br />
                <BoldText>3) Our Newsletter:</BoldText>
                {' '}
                A highly detailed
                analysis of the things impacting the Indian & global financial
                markets
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>

        <SectionSpacer />
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Profitable Trading Masterclass Course</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Our trading course, which is for both beginners & experts, that consists
                {' '}
                <BoldText>
                  33
                  of our key trading secrets
                </BoldText>
                . Other trading courses can cost
                you
                {' '}
                <BoldText>₹15,000 or more</BoldText>
                {' '}
                & they don’t teach you anything that
                you can’t find in a book. But
                {' '}
                <BoldText>
                  our course is cheaper & more
                  informative
                </BoldText>
                {' '}
                than the trading courses you can find online.
              </Text>
            </TextContent>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image85 src={course} alt="course" />
            </ContentDiv>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <SectionSpacer />

        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image85 src={calc} alt="course" />
            </ContentDiv>

          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Trade Allocation Calculator</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Use the trade allocation calculator in our mobile app to
                {' '}
                <BoldText>manage risk</BoldText>
                {' '}
                and determine how much quantity
                you need to
                {' '}
                <BoldText> buy or sell </BoldText>
                depending on the value of your portfolio.
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>

        {/* <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image60 src={newsletter} alt="newsletter" />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>The Great Awakening Newsletter</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Want to know why we think crude oil is going to $150 or
                why there’s going to be a global food shortage & how
                we’re preparing for it? You’ll get to know
                that in our monthly newsletter.
                <BoldText>
                  &nbsp;Hedge fund level
                  research
                </BoldText>
                {' '}
                will cost you thousands of dollars, but
                with us you get it for a
                <BoldText>&nbsp;fraction of the price</BoldText>
                .
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row> */}
        <SectionSpacer />
        <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Trading Psychology Masterclass Course</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                More Secrets. More Content. More Learning. More Profits.
                As a subscriber, you get access to our upcoming course
                on Trading Psychology. That’s right,
                {' '}
                <BoldText>
                  you get two courses for less than the price of one.
                </BoldText>
              </Text>
            </TextContent>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <img src={newCourse} alt="course" />
            </ContentDiv>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row>
        <SectionSpacer />
        {/* <Row>
          <Col sm={0} md={0} lg={2} />
          <Col sm={12} md={12} lg={10}>
            <ContentDiv>
              <Image75 src={platform} alt="platform" />
            </ContentDiv>
          </Col>
          <Col sm={12} md={12} lg={10}>
            <TextContent>
              <BlogsHeading>Our Community Platform</BlogsHeading>
              <BlogsHeadingUnderline />
              <Text>
                Access to our social media platform where
                {' '}
                <BoldText>you can post&nbsp;</BoldText>
                your trades, queries, ideas and interact with other users
                and us. We post about the things happening around the globe
                that could affect the financial markets on the platform.
              </Text>
            </TextContent>
          </Col>
          <Col sm={0} md={0} lg={2} />
        </Row> */}
        <Row justify="center" style={{ marginTop: '45px' }}>
          <SignUpNavItem
            className="pulse"
            isButton
            onClick={() => history.push('/register')}
          >
            BECOME A MEMBER
            {' '}
            {todayText ? 'TODAY' : 'NOW'}
          </SignUpNavItem>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <HeadingUnderline title="What You Get" whitetext />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Our Trades</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image75 src={megaphone} alt="megaphone" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              You get
              {' '}
              <BoldText>alerts to trades that we take in our trading accounts</BoldText>
              ,
              along with our stop loss, risk tolerance, and our explanation
              for taking the trades via our Telegram channel. We don’t give
              investment advice and share our trades for
              educational purposes only.
              {' '}

            </Text>
          </TextContent>
        </Col>
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Profitable Trading Masterclass Course</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image85 src={course} alt="course" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              Our trading course, which is for both beginners & experts, that consists
              {' '}
              <BoldText>
                33
                of our key trading secrets
              </BoldText>
              . Other trading courses can cost
              you
              {' '}
              <BoldText>₹15,000 or more</BoldText>
              {' '}
              & they don’t teach you anything that
              you can’t find in a book. But
              {' '}
              <BoldText>
                our course is cheaper & more
                informative
              </BoldText>
              {' '}
              than the trading courses you can find online.
            </Text>
          </TextContent>
        </Col>
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>The Great Awakening Newsletter</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image60 src={newsletter} alt="newsletter" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              Want to know why we think crude oil is going to $150 or
              why there’s going to be a global food shortage & how
              we’re preparing for it? You’ll get to know
              that in our monthly newsletter.
              <BoldText>
                &nbsp;Hedge fund level
                research
              </BoldText>
              {' '}
              will cost you thousands of dollars, but
              with us you get it for a
              <BoldText>&nbsp;fraction of the price</BoldText>
              .
            </Text>
          </TextContent>
        </Col>
        <Col sm={0} md={0} lg={2} />
      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Trading Psychology Masterclass Course</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <img src={newCourse} alt="new-course" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              More Secrets. More Content. More Learning. More Profits.
              As a subscriber, you get access to our upcoming course
              on Trading Psychology. That’s right,
              {' '}
              <BoldText>
                you get two courses for less than the price of one.
              </BoldText>
            </Text>
          </TextContent>
        </Col>

      </Row>
      <SectionSpacer />
      <Row>
        <Col span={24}>
          <MobileCentered>
            <BlogsHeading>Our Community Platform</BlogsHeading>
            <BlogsHeadingUnderline />
          </MobileCentered>
        </Col>
        <Col span={24}>
          <ContentDiv>
            <Image75 src={platformMob} alt="platform" />
          </ContentDiv>
        </Col>
        <Col span={24}>
          <TextContent>
            <Text>
              Access to our social media platform where
              {' '}
              <BoldText>you can post&nbsp;</BoldText>
              your trades, queries, ideas and interact with other users
              and us. We post about the things happening around the globe
              that could affect the financial markets on the platform.
            </Text>
          </TextContent>
        </Col>
        <Col sm={0} md={0} lg={2} />
      </Row>
      <Row justify="center" style={{ marginTop: '45px' }}>
        <SignUpNavItem
          className="pulse"
          isButton
          onClick={() => history.push('/register')}
        >
          BECOME A MEMBER
          {' '}
          {todayText ? 'TODAY' : 'NOW'}
        </SignUpNavItem>
      </Row>
    </Container>
  );
};

export default WhatYouGet;

const ContentDiv = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextContent = styled(ContentDiv)`
  padding: 0 20px;
`;

const Text = styled.div`
  color: white;
  text-align: center;
  padding: 0 10px;
`;

const MobileCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

const BlogsHeading = styled.div`
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  text-align: center;
  color: white;
  width: 100%;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const BlogsHeadingUnderline = styled.div`
  height: 5px;
  width: 80px;
  border-radius: 2.5px;
  background: #f9ae00;
  margin-bottom: 10px;
`;

const BoldText = styled.span`
  color: #f9ae00;
  font-weight: bold;
`;

const ImageDiv = styled.img`
  width: 100%;
  border-radius: 8px;
  max-height: 200px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Image75 = styled(ImageDiv)`
  width: 75%;
  @media (max-width: 767px) {
    max-height: inherit;
  }
`;

const Image85 = styled(ImageDiv)`
  width: 85%;
`;

const Image60 = styled(ImageDiv)`
  width: 60%;
`;

const SectionSpacer = styled.div`
  height: 50px;
`;

const Image35 = styled(ImageDiv)`
  width: 35%;
  max-height: 310px;
  @media (max-width: 767px) {
    width: 55%;
    max-height: inherit;
  }
`;
