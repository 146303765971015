import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import HeadingUnderline from "../../components/HeadingUnderline";

const Spacer = styled.div`
  height: 20px;
`;

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimer</title>
        <meta
          name='description'
          content='The Logical Traders tips, The Logical Traders calls, The Logical Traders free tips, The Logical Traders predictions, The Logical Traders market calls, Do The Logical Traders give market tips, The Logical Traders stock recommendation, The Logical Traders stock picks, The Logical Traders recommendations,'
        />
      </Helmet>
      <Container>
        <Spacer />
        <HeadingUnderline title='Disclaimer' />
        <p>
          Technical and fundamental analysis, macroeconomics and geopolitical
          commentary on TheLogicalTraders.com, or on email sent by
          TheLogicalTraders.com should not be constructed as investment advice.
          The same goes for the free and paid Telegram channel(s), and the Slack
          group(s) run by TheLogicalTraders.com. All the content across all
          platforms is for educational and illustration purposes only.
        </p>
        <p>
          Paid members and free users of TheLogicalTraders.com should NOT
          consider anything on the website or on other platforms as investment
          advice or an endorsement to purchase or sell any financial instrument
          or security.
        </p>
        <p>
          TheLogicalTraders.com is not a Securities and Exchange Board of India
          (SEBI) registered investment advisor. No employee or persons
          associated with TheLogicalTraders.com is authorized or registered to
          make recommendations by SEBI. TheLogicalTraders.com will not be liable
          for any monetary or punitive losses arising from the use of our free
          or paid services.
        </p>
        <p>
          TheLogicalTraders.com gives its paid users real time access to the
          trades we place for educational purposes only. Due to lack of
          liquidity, or for any other reason, some or all of our trades during
          any given time period may be paper trades. Disclosing which of our
          trades are paper trades is solely at our discretion.
        </p>
        <p>
          It is the responsibility of visitors of TheLogicalTraders.com to
          ascertain the terms of and comply with any local laws or regulations
          to which they are subject. Nothing on the TheLogicalTraders.com shall
          be construed as a solicitation to buy or an offer to sell any product,
          service and/or investment/trading opportunity to any person in any
          jurisdiction where such offer, purchase, sale or solicitation would be
          considered unlawful under the laws of such jurisdiction.
        </p>
        <p>
          TheLogicalTraders.com respects the rights, including the intellectual
          property rights of others, and we ask our users to conduct themselves
          accordingly. TheLogicalTraders.com may, under appropriate
          circumstances and at its sole discretion, terminate the accounts of
          any users who infringe upon or otherwise violate the stated rights of
          others.
        </p>
        <p>
          The information on TheLogicalTraders.com is not intended for
          distribution to, or use by, any person in any country or jurisdiction
          where such distribution or use would be contrary to local laws or
          regulations. None of the products, services and/or investment/trading
          opportunities referred to in TheLogicalTraders.com is available to
          persons residing in any country where the provision of such products,
          services and/or investment/trading opportunities would be contrary to
          local laws or regulations.
        </p>
        <Spacer />
      </Container>
    </>
  );
};

export default Disclaimer;
