import React, { useState } from "react";
import { useCountUp } from "react-countup";
import {
  TimerCount,
  TimerData,
  TimerItem,
  TimerTitle,
} from "../../styledComponents";

const Timer = ({ start, end, delay, duration, title, showPlusSign = true }) => {
  function numberWithCommas(x) {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  }
  const [showPlus, setShowPlus] = useState(false);
  const { countUp } = useCountUp({
    start,
    end,
    delay,
    duration,
    onEnd: () => setShowPlus(true),
  });
  return (
    <TimerItem>
      <TimerData>
        <TimerCount>{`${numberWithCommas(countUp)}${
          showPlus && showPlusSign ? "+" : ""
        }`}</TimerCount>
        <TimerTitle>{title}</TimerTitle>
      </TimerData>
    </TimerItem>
  );
};

export default Timer;
