import { Row, Col } from "antd";
import React, { useCallback } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import styled from "styled-components";
import { SignUpNavItem } from "../../styledComponents";
import { triggerGAEvent } from "../../utils/helper";
import ReactGA from "react-ga";

const Content = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin: 20px 0px;
  color: ${({ showWhite }) => (showWhite ? "white" : "#0c0e0f")};
`;

const CTAContent = ({ text, showWhite }) => {
  const history = useHistory();
  const handleLinkClick = useCallback(() => {
    const data = {
      category: "Links",
      action: "Join Us | Landing Page",
      label: "Clicked on join us | Landing Page",
      value: 1,
    };
    triggerGAEvent(data);
    history.push("/join-us");
  }, []);

  return (
    <Container>
      <Row justify='center'>
        <Col span={24}>
          <Content showWhite={showWhite} md={24}>
            {text}
          </Content>
        </Col>
        <SignUpNavItem className='pulse' isButton onClick={handleLinkClick}>
          Become a Member Today
        </SignUpNavItem>
      </Row>
    </Container>
  );
};

export default CTAContent;
