import React, { useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  Button, notification, Row, Col, Divider,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import HeadingUnderline from '../../components/HeadingUnderline';
import formula from '../../assets/formula.png';
import { axiosAuth } from '../../api';
import { SET_USER_DATA_PATCH } from '../../actions/common';
import {
  ActionButtonContainer,
  ContentContainer,
  Heading,
  HeadingBold,
} from './styled';
import { getRulesList } from '../posts/common';
import { FormulaImg } from '../posts/styled';

const Spacer = styled.div`
  height: 20px;
`;

const CommunityTerms = () => {
  const user = useSelector((state) => state.common.user);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('1');
  const history = useHistory();
  const dispatch = useDispatch();

  const title = useMemo(() => {
    if (section == '1') {
      return 'A Note from The Logical Traders';
    } if (section == '2') {
      return 'Disclaimer';
    }
    return 'Community Rules';
  }, [section]);

  const handleSubmitClick = () => {
    if (!isChecked) {
      notification.error({
        message: 'Error',
        description:
          'Please agree to the terms and conditions to check our community!!',
      });
      return;
    }
    setLoading(true);
    axiosAuth
      .patch('user/update', { _id: user._id, agree: true })
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          notification.success({
            message: 'Success',
            description: 'Congrats! You can now access the community!!',
          });
          dispatch({
            type: SET_USER_DATA_PATCH,
            payload: { agree: res?.data?.data?.agree ?? '' },
          });
          let userDataSaved = localStorage.getItem('user');
          userDataSaved = userDataSaved ? JSON.parse(userDataSaved) : {};
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...userDataSaved,
              agree: res?.data?.data?.agree ?? '',
            }),
          );
          history.push('/posts');
        } else {
          notification.error({
            message: 'Error',
            description:
              'Failed to Agree the terms and conditions. Please try again later!!',
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleGoToPage = () => {
    history.push('/posts');
  };

  const getSection1 = () => (
    <div>
      <div>Here’s a few things you should know about our trading style:</div>
      <Spacer />
      <Heading>We go through losing streaks</Heading>
      <p>
        The graph of stock market profits, or losses, is never linear for
        anyone and we are not an exception. We go through losing streaks
        (sometimes of more than 10 trades in a row) and drawdowns because
        those are probabilistic certainties and a part of every trader’s
        journey. It’s impossible to avoid losses, losing streak and drawdowns.
        We’re not bothered by drawdowns
      </p>
      <Heading>
        We understand that there are no consistent profits in the stock market
      </Heading>
      <p>
        As we’ve already said, the graph of profits or losses is never linear.
        So making a profit every week, every month or even every quarter is
        not possible for anyone. Although we trade to make money, we also
        realize that it’s possible for us to even have a losing year.
      </p>
      <Heading>
        We don’t focus on accuracy or frequency but on making money
      </Heading>
      <p>
        We do not trade for entertainment or to soothe our egos. So we don’t
        care if our trading accuracy is less than 50%. Our focus is not of
        being right, but on making money.
      </p>
      <p>
        Similarly, we don’t trade for entertainment, so we may not take a
        trade for weeks if we don’t find a suitable opportunity. Sitting on
        cash is also a trading position, something that most newcomers don’t
        realize.
      </p>
      <p>
        A lot of newcomers have a misconception that more trades = more
        profits. But this is certainly not the case. In fact, the opposite is
        mostly true because more trades = more trading costs = less returns.
      </p>
      <p>
        Of course, this doesn’t guarantee that we will end up making money,
        but this is what has worked best for us over time.
      </p>
      <Heading>
        We realize that stock market is not a get-rich-quick scheme
      </Heading>
      <p>
        Making money in the stock market is a slow and boring process. Feeling
        excitement in the stock market usually arises from gambling and not
        trading, which is destined to lose money in the long-run.
      </p>
      <p>
        So we don’t expect to the trades we take to give us big returns every
        week. Good opportunities don’t come every day, so don’t think the
        stocks we buy will start hitting upper circuit right after you join.
      </p>
      <p>
        Great opportunities are rarer. They come about maybe once a year (or
        less) and luck plays a big part is spotting and successfully trading
        such opportunities.
      </p>
      <Heading>We keep our expectations grounded and realistic.</Heading>
      <p>
        Keeping our expectations realistic and not aiming for impossible
        targets makes us trade better and look at things rationally.
      </p>
      <Heading>We don’t think about recovering lost money.</Heading>
      <p>
        We are at peace with our drawdowns and losing streaks and don’t try to
        “recover” our losses, something that a lot of newcomers try. Our focus
        is always on the trading process and not on recovering money. People
        tend to take unnecessary or big trades when they think about
        “recovering” lost money.
      </p>
      <Heading>
        We take bigger risk when we are on a winning streak, and cut down our
        risk when we are on a losing streak.
      </Heading>
      <p>
        This ensures we make a lot of money when we are trading at the best of
        our abilities and our drawdowns are smaller when we are going through
        a losing streak.
      </p>
      <Heading>
        Risk is calculated on the total portfolio not on individual stock
        price. The formula we use for calculating the quantity of shares to
        buy (or sell in case of shorting) for each trade.
      </Heading>
      <FormulaImg>
        <img
          width="250px"
          height="70px"
          src={formula}
          style={{ margin: '10px 0px' }}
        />
      </FormulaImg>
      <div>
        <span style={{ margin: '10px 0px' }}>
          <strong>Example</strong>
        </span>
        {isMobileOnly ? (
          <>
            <p>
              If your capital is Rs 1,00,000.
              {' '}
              <br />
              And you buy a stock worth Rs
              <br />
              100, taking a risk of 1% (Rs 1000)
              <br />
              and the stop loss is at
              <br />
              Rs 95, the number of shares you
              <br />
              should buy will be
              {' '}
            </p>
            <p>= (1,00,000 *1%) / (100-95)</p>
            <p>= (1,000) / (5)</p>
            <p>= 200 shares</p>
            <p>
              200 shares means you will only
              <br />
              allocate Rs 20,000 to this
              <br />
              trade. If the stop loss at Rs 95
              <br />
              {' '}
              gets hit, you lose only Rs
              <br />
              1000 (1% of your portfolio).
            </p>
          </>
        ) : (
          <>
            <p>
              If your capital is Rs 1,00,000. And you buy a stock worth Rs
              <br />
              100, taking a risk of 1% (Rs 1000) and the stop loss is at
              <br />
              Rs 95, the number of shares you should buy will be
              {' '}
            </p>
            <p>= (1,00,000 *1%) / (100-95)</p>
            <p>= (1,000) / (5)</p>
            <p>= 200 shares</p>
            <p>
              200 shares means you will only allocate Rs 20,000 to this
              <br />
              trade. If the stop loss at Rs 95 gets hit, you lose only Rs
              <br />
              1000 (1% of your portfolio).
            </p>
          </>
        )}
      </div>
    </div>
  );

  const getSection2 = () => (
    <>
      <p>
        TheLogicalTraders.com is
        <HeadingBold>
          NOT a Securities and Exchange Board of India (SEBI) registered
          investment advisory.
        </HeadingBold>
        {' '}
        No employee or persons associated with TheLogicalTraders.com is
        authorized or registered to make recommendations by SEBI.
        {' '}
        <HeadingBold>The platform is for educational purposes</HeadingBold>
        {' '}
        only and TheLogicalTraders.com will not be liable for any monetary or
        punitive losses arising from the use of our free or paid services.
      </p>
      <p>
        Please consult your financial advisor or do your own due diligence
        before making any investment decisions.
      </p>
      <p>
        All content you find on TheLogicalTraders.com is for educational
        purposes only and should NEVER be construed as investment advice.
      </p>
      <p>
        The information on TheLogicalTraders.com is not intended for
        distribution to, or use by, any person in any country or jurisdiction
        where such distribution or use would be contrary to local laws or
        regulations. TheLogicalTraders.com may, under appropriate
        circumstances and at its sole discretion, terminate the accounts of
        any user who distribute the information found on our website without
        our permission.
      </p>
      <p>
        The paid membership of TheLogicalTraders.com is meant for single-user
        access only. If it is found that multiple people are accessing a
        single user’s account, then we reserve the right to terminate the
        account of the said user.
      </p>
    </>
  );

  const getSection3 = () => (
    <>
      <HeadingBold>Community Rules</HeadingBold>
      <Spacer />
      {getRulesList()}
      <Spacer />
      <Row>
        <Col span={24}>
          {!user?.agree && (
          <Row justify="center">
            <Checkbox
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            >
              I have read and I accept the Disclaimer & Terms of Use
            </Checkbox>
          </Row>
          )}
        </Col>
      </Row>
    </>
  );

  const setSectionDetails = (value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSection(value);
  };

  const getActionButtons = () => {
    if (section === '1') {
      return (
        <Button className="signUpBtn" onClick={() => setSectionDetails('2')}>
          Next
        </Button>
      );
    } if (section === '2') {
      return (
        <>
          <Button
            className="signUpBtn mr-5px"
            onClick={() => setSectionDetails('1')}
          >
            Back
          </Button>
          <Button className="signUpBtn" onClick={() => setSectionDetails('3')}>
            Next
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          className="signUpBtn mr-5px"
          onClick={() => setSectionDetails('2')}
        >
          Back
        </Button>
        {!user?.agree ? (
          <Button
            style={{ width: '100px' }}
            onClick={handleSubmitClick}
            loading={loading}
            className="save-post-button"
          >
            Agree
          </Button>
        ) : (
          <Button onClick={handleGoToPage} className="save-post-button">
            Go to community page
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Community Terms and Conditions</title>
        <meta
          name="description"
          content="The Logical Traders tips, The Logical Traders calls, The Logical Traders free tips, The Logical Traders predictions, The Logical Traders market calls, Do The Logical Traders give market tips, The Logical Traders stock recommendation, The Logical Traders stock picks, The Logical Traders recommendations,"
        />
      </Helmet>
      <Container>
        <Spacer />
        <HeadingUnderline title={title} />
        <ContentContainer>
          {section === '1' && getSection1()}
          {section === '2' && (
            <>
              <Spacer />
              {getSection2()}
            </>
          )}
          {section === '3' && (
            <>
              <Spacer />
              {getSection3()}
            </>
          )}
        </ContentContainer>
        <Divider />
        <Row>
          <ActionButtonContainer>
            <span>{getActionButtons()}</span>
          </ActionButtonContainer>
        </Row>
        <Spacer />
      </Container>
    </>
  );
};

export default CommunityTerms;
