import React from "react";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { getRulesList } from "./common";

const RulesModal = ({ showRulesModal, handleToggle }) => {
  return (
    <Modal
      onCancel={() => handleToggle(false)}
      visible={showRulesModal}
      title={<div className='rules-title'>Community Rules</div>}
      className='create-post-modal'
      footer={[
        <Button
          className='save-post-button'
          onClick={() => handleToggle(false)}
        >
          Close
        </Button>,
      ]}
    >
      {getRulesList()}
    </Modal>
  );
};

export default RulesModal;
