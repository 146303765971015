import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import greenTick from '../../assets/green-tick.svg';
import Logo from '../../assets/bull-logo.svg';
import tipranksBadge from '../../assets/tipranksBadge.svg';
import HeadingUnderline from '../HeadingUnderline';
import {
  LogoSection,
  LogoImage,
  TextSection,
  Spacer,
  ExpertsContent,
} from '../../routes/about';
import { SignUpNavItem } from '../../styledComponents';
import {
  getEnvValue,
  handleBecomeMemberTrackingClick,
  isUserLoggedIn,
} from '../../utils/helper';

export const TextData = styled.ul`
  margin-bottom: 0px;
  li {
    margin-bottom: 20px;
    text-indent: -38px;
    margin-left: 38px;
    color: white;
    list-style-type: none;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  @media (max-width: 991px) {
    padding-right: 0;
    margin-top: 20px;
    padding-left: 0;
  }
`;

export const TipRanksText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: #554d56;
`;

export const TipRanksTextMobile = styled.div`
  margin-top: 100px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: #554d56;
  @media (max-width: 767px) {
    text-align: center !important;
  }
`;

export const TipRanks = styled.div`
  margin-top: 60px;
  margin-bottom: 35px;
  background-color: white;
  border: 3px solid #f9ae00;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const TipRanksLogo = styled.img`
  position: absolute;
  height: 150px;
  top: -50px;
  right: 70px;
  @media (max-width: 1024px) {
    top: -31px;
  }
  @media (max-width: 991px) {
    right: 15px;
    height: 170px;
  }
  @media (max-width: 767px) {
    right: 70px;
    top: -60px;
    height: 150px;
  }
`;

export const StarsRating = styled.div`
  margin-top: 10px;
  svg{
    margin-top: -7px;
  }
}
`;

const ExpertsContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  justify-content: center;
}
`;

const ExpertsImage = styled(LogoImage)`
  height: 280px;
  width: initial;
  @media (max-width: 768px) {
    height: 180px;
  }
`;

const GreenTick = styled.img`
  margin-right: 10px;
`;

const Experts = ({ showExperts }) => {
  const baseUrl = getEnvValue('IMAGE_URL');
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    handleBecomeMemberTrackingClick();
    history.push(path);
  }, []);
  return (
    <Container fluid style={{ padding: 0 }}>
      <div className="black-bg padding-30" style={{ flex: 1 }}>
        <Container>
          <HeadingUnderline
            title={showExperts ? 'Know The Experts' : 'Know Your Experts'}
            whitetext
            noMargin={isMobileOnly}
          />
          <ExpertsContainer>
            <Row>
              {showExperts ? (
                !isMobileOnly ? (
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <LogoSection>
                      <ExpertsImage src={Logo} />
                    </LogoSection>
                  </Col>
                ) : null
              ) : (
                <Col xs={24} sm={24} md={24} lg={12}>
                  <LogoSection>
                    <ExpertsImage src={`${baseUrl}/experts.png`} />
                  </LogoSection>
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={12}>
                <TextSection>
                  <TextData>
                    <li>
                      <GreenTick src={greenTick} />
                      <span>
                        Profitable trading record in Indian & U.S. stock
                        markets.
                      </span>
                    </li>
                    <li>
                      <GreenTick src={greenTick} />
                      Internationally-awarded & trusted financial experts.
                    </li>
                    <li>
                      <GreenTick src={greenTick} />
                      Ranked among best financial analysts on Wall Street.
                    </li>
                    <li>
                      <GreenTick src={greenTick} />
                      20+ years of combined experience of successful stock
                      picking.
                    </li>
                    <li>
                      <GreenTick src={greenTick} />
                      Proficient in macroeconomic, technical, fundamental &
                      geopolitical analysis.
                    </li>
                  </TextData>
                </TextSection>
              </Col>
            </Row>
          </ExpertsContainer>
          {showExperts && (
            <>
              <Row>
                <Spacer />
              </Row>
              <Row>
                <Spacer />
              </Row>
              <Row>
                <Spacer />
              </Row>
              <Row>
                <ExpertsContent />
              </Row>
            </>
          )}
          <ExpertsContainer>
            <Row>
              <Spacer />
            </Row>
          </ExpertsContainer>
          <ExpertsContainer>
            <Row justify="center" style={{ width: '100%' }}>
              <Col span="20">
                <TipRanks>
                  <Row>
                    <Col xs={0} sm={0} md={8}>
                      {!isMobileOnly && <TipRanksLogo src={tipranksBadge} />}
                    </Col>
                    <Col
                      md={16}
                      className={
                        isMobileOnly && 'text-center position-relative'
                      }
                    >
                      {isMobileOnly && <TipRanksLogo src={tipranksBadge} />}
                      {!isMobileOnly ? (
                        <TipRanksText>
                          Voted in Top 10 Financial Bloggers of Wall Street in
                          2014, 2020 & 2021.
                          <br />
                          <div style={{ display: 'flex' }}>
                            <StarsRating>
                              Awarded
                              <StarFilled
                                style={{
                                  color: '#F9AE00',
                                  marginTop: '-5px',
                                  marginLeft: '5px',
                                }}
                              />
                              <StarFilled
                                style={{
                                  color: '#F9AE00',
                                  marginTop: '-5px',
                                }}
                              />
                              <StarFilled
                                style={{
                                  color: '#F9AE00',
                                  marginTop: '-5px',
                                }}
                              />
                              <StarFilled
                                style={{
                                  color: '#F9AE00',
                                  marginTop: '-5px',
                                }}
                              />
                              <StarFilled
                                style={{
                                  color: '#F9AE00',
                                  marginTop: '-5px',
                                  marginRight: '5px',
                                }}
                              />
                              Rating for International Stock Market Performance.
                            </StarsRating>
                          </div>
                        </TipRanksText>
                      ) : (
                        <TipRanksTextMobile>
                          Voted in Top 10 Financial Bloggers of Wall Street in
                          2014, 2020 & 2021.
                          <br />
                          <div style={{ display: 'flex' }}>
                            <StarsRating>
                              Awarded
                              <span>
                                <StarFilled
                                  style={{
                                    color: '#F9AE00',
                                    marginTop: '-5px',
                                    marginLeft: '5px',
                                  }}
                                />
                                <StarFilled
                                  style={{
                                    color: '#F9AE00',
                                    marginTop: '-5px',
                                  }}
                                />
                                <StarFilled
                                  style={{
                                    color: '#F9AE00',
                                    marginTop: '-5px',
                                  }}
                                />
                                <StarFilled
                                  style={{
                                    color: '#F9AE00',
                                    marginTop: '-5px',
                                  }}
                                />
                                <StarFilled
                                  style={{
                                    color: '#F9AE00',
                                    marginTop: '-5px',
                                    marginRight: '5px',
                                  }}
                                />
                              </span>
                              Rating for International Stock Market Performance.
                            </StarsRating>
                          </div>
                        </TipRanksTextMobile>
                      )}
                    </Col>
                  </Row>
                </TipRanks>
              </Col>
            </Row>
          </ExpertsContainer>

          {!showExperts && (
            <ExpertsContainer>
              <Row justify="center" className="blogs-brief">
                {!isMobileOnly && !isUserLoggedIn() ? (
                  <SignUpNavItem
                    style={{ marginTop: '30px', marginBottom: '10px' }}
                    className="pulse"
                    isButton
                    onClick={() => handleLinkClick('/register')}
                  >
                    BECOME A MEMBER NOW
                  </SignUpNavItem>
                ) : !isUserLoggedIn() ? (
                  <div style={{ marginTop: '25px', marginBottom: '10px' }}>
                    <SignUpNavItem
                      className="pulse"
                      isButton
                      onClick={() => handleLinkClick('/register')}
                    >
                      BECOME A MEMBER NOW
                    </SignUpNavItem>
                  </div>
                ) : (
                  <></>
                )}
              </Row>
            </ExpertsContainer>
          )}
        </Container>
      </div>
    </Container>
  );
};

export default Experts;
