import { Col, Row } from "antd";
import React, { useCallback } from "react";
import HeadingUnderline from "../HeadingUnderline";
import { Container } from "react-bootstrap";
import InfoImage from "../../assets/betterForYou.svg";
import InfoResponsiveImage from "../../assets/betterForYouMobile.svg";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { SignUpNavItem } from "../../styledComponents";
import {
  handleBecomeMemberTrackingClick,
  isUserLoggedIn,
} from "../../utils/helper";
import TelegramButton from "../LandingPage/TelegramButton";

const BetterForYou = ({ signUpText, showTelegram }) => {
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    handleBecomeMemberTrackingClick();
    history.push(path);
  }, []);
  return (
    <Container>
      <Row justify='center' className='blogs-brief'>
        <Col>
          <HeadingUnderline title='Why We’re Better For You' whitetext />
        </Col>
      </Row>
      <Row justify='center' className='blogs-brief'>
        {isMobileOnly ? (
          <img
            width='100%'
            src={InfoResponsiveImage}
            style={{ marginBottom: "30px" }}
          />
        ) : (
          <img width='100%' src={InfoImage} className='better-for-you' />
        )}
      </Row>
      {!showTelegram && (
        <Row justify='center' className='blogs-brief'>
          {!isMobileOnly && !isUserLoggedIn() ? (
            <SignUpNavItem
              style={{ marginTop: "45px", marginBottom: "10px" }}
              className='pulse'
              isButton
              onClick={() => handleLinkClick("/register")}
            >
              {signUpText || "BECOME A MEMBER NOW"}
            </SignUpNavItem>
          ) : !isUserLoggedIn() ? (
            <div style={{ marginTop: "25px", marginBottom: "10px" }}>
              <SignUpNavItem
                className='pulse'
                isButton
                onClick={() => handleLinkClick("/register")}
              >
                {signUpText || "BECOME A MEMBER NOW"}
              </SignUpNavItem>
            </div>
          ) : (
            <></>
          )}
        </Row>
      )}
      {showTelegram && (
        <Row justify='center' className='blogs-brief'>
          <TelegramButton
            customStyle={{
              marginTop: !isMobileOnly ? "40px" : "15px",
              marginBottom: "15px",
            }}
          />
        </Row>
      )}
    </Container>
  );
};

export default BetterForYou;
