import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton,
} from 'react-share';
import { isMobileOnly } from 'react-device-detect';
import { axiosAuth } from '../../api';
import { CenteredDiv } from '../blogs';
import { BlogContentStyles, SignUpNavItem } from '../../styledComponents';
import logo from '../../assets/logo-new.svg';
import {
  SocialMediaButton, SocialMediaIcon, SocialMediaIconsContainer, SocialMediaText,
} from '../thankYou';
import {
  FB, TELEGRAM, TWITTER, WHATSAPP,
} from '../../utils/icons';

const BlogDate = styled.p`
  font-family: "GT-America-Condensed";
  font-size: 13px;
  font-weight: 500;
  color: #767676;
`;

const BlogImageLightbox = styled.img`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  height: 150px;
  @media (min-width: 768px) {
    width: 100%;
    height: 250px;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 300px;
  }
  @media (min-width: 1200px) {
    width: 100%;
    height: 200px;
  }
`;

const BlogHead = styled.h1`
  font-family: "superclarendon";
  font-weight: 700;
  line-height: 1.1;
  color: #221F20;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-bottom: 15px;
  font-size: 40px;
`;

const BlogSubHead = styled.p`
  font-family: "TiemposHeadline";
  font-weight: 300 !important;
  font-size: 17px;
  line-height: 1.4;
`;

export const BlogsParent = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Disclaimer = styled.p`
  font-size: 14px;
  margin: 15px 0px;
  color: #53565A;
`;

const BlogParentImage = styled.img`
  max-width: 100%;
  margin: 20px 0;
`;

const AuthorDetails = styled.div`
  display: flex;
  margin: 5px 0 15px 0;
`;

const AuthorImage = styled.img`
  height: 55px;
  width: 55px;
  border: 3px solid #F9AE00;
  border-radius: 50%;
  margin-right: 15px;
`;

const AuthorName = styled.p`
  font-family: "GT-America-Condensed";
  font-weight: 500;
  padding-top: 5px;
`;

const BlogDetail = () => {
  const history = useHistory();
  const [isLightBoxOpen, toggleLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [blogDetail, setBlogDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const { title } = useParams();
  const isUserLoggedIn = useSelector((state) => state.common.authToken);

  useEffect(() => {
    setLoading(true);
    axiosAuth
      .get(`blogs/bySlug/${title}`)
      .then((res) => {
        if (res?.data?.data && res?.data?.status) {
          setBlogDetail(res?.data?.data);
          if (res?.data?.data?.otherImages) {
            const allImages = res?.data?.data?.otherImages?.split(' | ');
            setImages(allImages);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [title]);

  const handleLinkClick = () => history.push('/register');

  if (loading) {
    return (
      <CenteredDiv>
        <LoadingOutlined style={{ fontSize: 30, color: '#f9ae00' }} spin />
      </CenteredDiv>
    );
  }

  const description = (blogDetail?.description ?? '')
    .replaceAll('\r\n\r\n\r\n', '<br/><br/>')
    .replaceAll('\r\n\r\n', '<br/><br/>')
    .replaceAll('<img', "<img class='blog-img-class' ")
    .replaceAll('&nbsp;', '');

  const isLocked = !isUserLoggedIn && blogDetail?.is_locked?.toLowerCase() === 'yes';
  return (
    <>
      <Helmet>
        <title>{blogDetail?.title}</title>
        <meta
          name="description"
          content={`${blogDetail?.title} ${blogDetail?.shortDescription}`}
        />
      </Helmet>
      <Container className="blog-detail-container">
        <BlogsParent>
          <BlogHead>{blogDetail?.title}</BlogHead>
          <BlogSubHead>{blogDetail?.shortDescription}</BlogSubHead>
          <AuthorDetails>
            <div><AuthorImage src={logo} alt="logo" /></div>
            <AuthorName>
              Ayush Singh and The Logical Traders Team
            </AuthorName>
          </AuthorDetails>
          <BlogDate>
            {blogDetail?.published_date
              ? moment(blogDetail?.published_date, 'YYYY-MM-DD').format(
                'DD MMM YYYY',
              )
              : 'N/A'}
          </BlogDate>
          {blogDetail?.image?.length ? <BlogParentImage src={blogDetail?.image[0]?.imageUrl} /> : null}
          <BlogContentStyles>
            {ReactHtmlParser(
              !isLocked ? blogDetail?.description ? description : '<div></div>' : blogDetail?.locked_content ? blogDetail?.locked_content : '<div></div>',
              {
                transform: function transform(node) {
                  // do not render any <span> tags
                  if (node.type === 'tag' && node.name === 'img') {
                    return <div className="blog-img-div"><a href={node?.attribs?.src ?? ''} target="_blank"><img className="blog-img-class" height={node?.attribs?.height} width={node?.attribs?.width ?? '100%'} src={node?.attribs?.src ?? ''} /></a></div>;
                  }
                },
              },
            )}
          </BlogContentStyles>
          {isLocked && (
          <>
            <Row justify="center">
              <h4 className="text-center">
                This content is exclusively for premium subscribers.
              </h4>
              <h4 className="text-center">
                If you wish to read the full article, please subscribe to the premium membership of The
                Logical Traders
              </h4>
            </Row>
            <Row justify="center" style={{ marginTop: '25px' }}>
              <SignUpNavItem className="pulse" isButton onClick={handleLinkClick}>
                Become a Member Now
              </SignUpNavItem>
            </Row>
          </>
          )}
          <SocialMediaIconsContainer>
            <SocialMediaButton color="#3e68c0">
              <FacebookShareButton
                url={window.location.href}
                quote="The Logical Traders"
              >
                <>
                  <SocialMediaIcon>
                    <FB />
                  </SocialMediaIcon>
                  {!isMobileOnly && (
                  <SocialMediaText>Facebook</SocialMediaText>
                  )}
                </>
              </FacebookShareButton>
            </SocialMediaButton>
            <SocialMediaButton color="#23abff">
              <TwitterShareButton url={window.location.href}>
                <>
                  <SocialMediaIcon>
                    <TWITTER />
                  </SocialMediaIcon>
                  {!isMobileOnly && (
                  <SocialMediaText>Twitter</SocialMediaText>
                  )}
                </>
              </TwitterShareButton>
            </SocialMediaButton>
            <SocialMediaButton color="#13d25a">
              <WhatsappShareButton url={window.location.href}>
                <>
                  <SocialMediaIcon>
                    <WHATSAPP />
                  </SocialMediaIcon>
                  {!isMobileOnly && (
                  <SocialMediaText>Whats App</SocialMediaText>
                  )}
                </>
              </WhatsappShareButton>
            </SocialMediaButton>
            <SocialMediaButton color="#2ca5e0">
              <TelegramShareButton url={window.location.href}>
                <>
                  <SocialMediaIcon>
                    <TELEGRAM />
                  </SocialMediaIcon>
                  {!isMobileOnly && (
                  <SocialMediaText>Telegram</SocialMediaText>
                  )}
                </>
              </TelegramShareButton>
            </SocialMediaButton>
          </SocialMediaIconsContainer>
          {!isLocked && (
          <Disclaimer>
            <br />
            <i>
              <strong>Disclaimer</strong>
              : The Logical Traders is NOT a SEBI registered investment advisory. All the information provided on our platform is for educational purposes only. Please do your own due diligence before making any investment decision.
            </i>
            <br />
            <br />
            <i>
              <strong>Unauthorized Disclosure Prohibited</strong>
              : The information provided in this blog is private, privileged, and confidential, licensed for your sole individual use as a subscriber. The Logical Traders reserves all rights to the content of this publication and related materials. Forwarding, copying, disseminating, or distributing this report in whole or in part, including substantial quotation of any portion of the publication or any release of specific recommendations, is strictly prohibited.
            </i>
          </Disclaimer>
          )}
          {!isLocked && images.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <h5>All Images</h5>
              <Row>
                {images.map((item, index) => (
                  <Col
                    key={item}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ padding: '5px' }}
                  >
                    <BlogImageLightbox
                      onClick={() => {
                        setPhotoIndex(index);
                        toggleLightBox(true);
                      }}
                      src={item}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </BlogsParent>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => toggleLightBox(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </Container>
    </>
  );
};

export default BlogDetail;
