import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import AyushRanking from '../../assets/a-rank.png';
import HarshRanking from '../../assets/h-rank.png';
import DhairyaRanking from '../../assets/d-rank.png';
import threedPrinting from '../../assets/3d-printer.png';
import nvidia from '../../assets/nvidia.png';
import silver from '../../assets/silver.png';
import trend from '../../assets/trend.png';
import HeadingUnderline from '../../components/HeadingUnderline';
import PerformanceSection from '../../components/PerformanceSection';
import { SignUpNavItem } from '../../styledComponents';
import { isUserLoggedIn } from '../../utils/helper';
import {
  StarsRating,
  TipRanks,
  TipRanksLogo,
  TipRanksText,
  TipRanksTextMobile,
} from '../../components/OurExperts';
import tipranksBadge from '../../assets/tipranksBadge.svg';
import VideoSection from '../../components/HomeBg/VideoSection';

const Spacer = styled.div`
  height: 20px;
`;

export const RankingImage = styled.img`
  height: 250px;
  border: 4px solid #f9ae00;
  border-radius: 6px;
  margin-bottom: 15px;
`;

const UnderlinedHeader = styled.h5`
  text-decoration: underline;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
`;

const Performance = () => {
  const history = useHistory();
  const handleLinkClick = useCallback((path) => {
    history.push(path);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Our record of success in the Indian stock market and the American
          stock market
        </title>
        <meta
          name="description"
          content="Are the Logical Traders good, are the Logical Traders profitable, Should I buy The Logical Traders membership, The Logical Traders review, The Logical Traders Performance, The Logical Traders Track record, The Logical Traders legitimate, The Logical Traders authentic, The Logical Traders scam, The Logical Traders review Quora, The Logical Traders calls, Are The Logical Traders calls accurate, The Logical Traders accuracy, The Logical Traders profit, The Logical Traders profitable"
        />
      </Helmet>
      <Container>
        <Spacer />
        <HeadingUnderline title="Our Performance" />
        <h5 className="text-center">
          Our experts have over 2 decades of combined experience and our track
          record is verified independently.
        </h5>

        <Spacer />
        <Row>
          <Col md={8}>
            <CenteredContainer>
              <h5>Ayush Singh</h5>
              <RankingImage src={AyushRanking} />
              <a
                href="https://www.tipranks.com/bloggers/ayush-singh"
                target="_blank"
              >
                Ayush Singh | financial blogger Performance -TipRanks
              </a>
            </CenteredContainer>
          </Col>
          <Col md={8}>
            <CenteredContainer>
              <h5>Harsh Chauhan</h5>
              <RankingImage src={HarshRanking} />
              <a
                href="https://www.tipranks.com/bloggers/harsh-chauhan"
                target="_blank"
              >
                Harsh Chauhan | financial blogger Performance -TipRanks
              </a>
            </CenteredContainer>
          </Col>
          <Col md={8}>
            <CenteredContainer>
              <h5>Dhairya Mehta</h5>
              <RankingImage src={DhairyaRanking} />
              <a
                href="https://www.tipranks.com/bloggers/alpha-investor"
                target="_blank"
              >
                Alpha Investor | financial blogger Performance -TipRanks
              </a>
            </CenteredContainer>
          </Col>
        </Row>
        <Spacer />
        <Row justify="center" style={{ width: '100%' }}>
          <Col span="20">
            <TipRanks>
              <Row>
                <Col xs={0} sm={0} md={8}>
                  {!isMobileOnly && <TipRanksLogo src={tipranksBadge} />}
                </Col>
                <Col
                  md={16}
                  className={isMobileOnly && 'text-center position-relative'}
                >
                  {isMobileOnly && <TipRanksLogo src={tipranksBadge} />}
                  {!isMobileOnly ? (
                    <TipRanksText>
                      Top 10 Financial Bloggers of Wall Street in 2014, 2020 & 2021.
                      <br />
                      <div style={{ display: 'flex' }}>
                        <StarsRating>
                          Awarded
                          <StarFilled
                            style={{
                              color: '#F9AE00',
                              marginTop: '-5px',
                              marginLeft: '5px',
                            }}
                          />
                          <StarFilled
                            style={{
                              color: '#F9AE00',
                              marginTop: '-5px',
                            }}
                          />
                          <StarFilled
                            style={{
                              color: '#F9AE00',
                              marginTop: '-5px',
                            }}
                          />
                          <StarFilled
                            style={{
                              color: '#F9AE00',
                              marginTop: '-5px',
                            }}
                          />
                          <StarFilled
                            style={{
                              color: '#F9AE00',
                              marginTop: '-5px',
                              marginRight: '5px',
                            }}
                          />
                          Rating for International Stock Market Performance.
                        </StarsRating>
                      </div>
                    </TipRanksText>
                  ) : (
                    <TipRanksTextMobile>
                      Top 10 Financial Bloggers of Wall Street in 2014, 2020 & 2021.
                      <br />
                      <div style={{ display: 'flex' }}>
                        <StarsRating>
                          Awarded
                          <span>
                            <StarFilled
                              style={{
                                color: '#F9AE00',
                                marginTop: '-5px',
                                marginLeft: '5px',
                              }}
                            />
                            <StarFilled
                              style={{
                                color: '#F9AE00',
                                marginTop: '-5px',
                              }}
                            />
                            <StarFilled
                              style={{
                                color: '#F9AE00',
                                marginTop: '-5px',
                              }}
                            />
                            <StarFilled
                              style={{
                                color: '#F9AE00',
                                marginTop: '-5px',
                              }}
                            />
                            <StarFilled
                              style={{
                                color: '#F9AE00',
                                marginTop: '-5px',
                                marginRight: '5px',
                              }}
                            />
                          </span>
                          Rating for International Stock Market Performance.
                        </StarsRating>
                      </div>
                    </TipRanksTextMobile>
                  )}
                </Col>
              </Row>
            </TipRanks>
          </Col>
        </Row>
      </Container>
      <Spacer />

      <div className="grey-bg padding-30">
        <VideoSection
          heading1="We don’t hide our past
            performance."
          heading2="So you can learn from
            trustworthy experts."
          url="https://youtu.be/ZdAS2vdugPM"
          videoRight
          showBottomMargin
          showTopMargin
        />
      </div>
      <Container>
        <h5 style={{ textAlign: 'center' }} className="mt-25">
          Our Best Trades and Recommendations
        </h5>
        <Spacer />
        <Row>
          <Col xs={24} md={12} lg={12}>
            <PerformanceSection
              image={trend}
              heading="Short Nifty"
              description={(
                <p>
                  <strong>
                    Profit
                    {' '}
                    <span style={{ fontFamily: 'initial' }}>₹</span>
                    15,00,000+
                  </strong>
                  <br />
                  We went short Nifty multiple times via put options in
                  February-March 2020 using a capital of less than ₹1,00,000.
                </p>
              )}
            />
          </Col>
          <Col xs={24} md={12} lg={12}>
            <PerformanceSection
              image={silver}
              heading="Long Silver"
              description={(
                <p>
                  <strong>
                    Profit
                    {' '}
                    <span style={{ fontFamily: 'initial' }}>₹</span>
                    14,00,000+
                  </strong>
                  <br />
                  We went long silver multiple times in mid-2020. Silver rallied
                  strongly and all the trades made us a profit of over
                  {' '}
                  <span style={{ fontFamily: 'initial' }}>₹</span>
                  14,00,000.
                </p>
              )}
            />
          </Col>
          <Col xs={24} md={12} lg={12}>
            <PerformanceSection
              image={nvidia}
              heading="NVIDIA"
              description={(
                <p>
                  <strong>Up 4,000+%</strong>
                  <br />
                  <a
                    href="https://www.aol.com/2011/09/08/nvidia-slowly-chips-its-way-up/"
                    target="_blank"
                  >
                    First Recommended at $13.04
                  </a>
                  {' '}
                  in September 2011, the stock now trades over $600. We’ve
                  recommended it over 25 times since 2011.
                </p>
              )}
            />
          </Col>
          <Col xs={24} md={12} lg={12}>
            <PerformanceSection
              image={threedPrinting}
              heading="Short 3D Systems"
              description={(
                <p>
                  <strong>Dropped 90+%</strong>
                  <br />
                  We recommended shorting 3D Systems multiple times to out
                  overseas clients and the stock dropped over 90% in roughly a
                  year.
                </p>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={0} sm={0} md={2} />
          <Col xs={24} sm={24} md={8}>
            <p>
              <strong>
                We’ve also recommended stocks to international investors that
                have gone up 4x-10x in value like:
              </strong>
            </p>
            <ul>
              <li>Advanced Micro Devices</li>
              <li>Skyworks Solutions</li>
              <li>Twilio</li>
              <li>Apple</li>
              <li>Qorvo</li>
            </ul>
          </Col>
          <Col xs={0} sm={0} md={4} />
          <Col xs={24} sm={24} md={8}>
            <p>
              <strong>
                We’ve also helped investors steer clear of stocks that have gone
                bankrupt or crash over 70% like:
              </strong>
            </p>
            <ul>
              <li>GoPro</li>
              <li>RadioShack</li>
              <li>DryShips</li>
              <li>JCPenney</li>
              <li>Halcon Resources</li>
            </ul>
          </Col>
          <Col xs={0} sm={0} md={2} />
        </Row>

        <Row style={{ marginTop: '15px' }}>
          <Col xs={0} sm={0} md={2} />
          <Col xs={24} sm={24} md={22}>
            <p>
              <em>
                You can find all these calls on our TipRanks profiles mentioned
                above.
              </em>
            </p>
          </Col>
        </Row>

        <Spacer />

        {!isUserLoggedIn() && (
          <Row justify="center" className="blogs-brief">
            <Col span="24">
              <h5 className="text-center">Convinced by our performance?</h5>
            </Col>
            <Col className="text-center" span="24">
              {!isMobileOnly ? (
                <SignUpNavItem
                  style={{ marginBottom: '20px' }}
                  className="pulse"
                  isButton
                  onClick={() => handleLinkClick('/register')}
                >
                  BECOME A MEMBER NOW
                </SignUpNavItem>
              ) : (
                <div style={{ marginBottom: '10px' }}>
                  <SignUpNavItem
                    className="pulse"
                    isButton
                    onClick={() => handleLinkClick('/register')}
                  >
                    BECOME A MEMBER NOW
                  </SignUpNavItem>
                </div>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Performance;
